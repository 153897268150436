import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

import { SessionService } from '../../framework/services/session.service'
import { Mode } from '../../framework/generic-form/generic-form.component'

@Component({
  selector: 'app-reset-form',
  templateUrl: './reset-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class ResetFormComponent {

  Mode = Mode
  name: string = ''
  mail: string = ''
  valid: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { client: string },
    protected sessionService: SessionService,
  ) { }

  check() {
    let regex = new RegExp("[a-z0-9]+@[a-z]+\.[a-z]{2,3}")
    this.valid = this.name > ' ' && regex.test(this.mail)
  }

  onReset() { this.sessionService.reset(this.data.client, this.name, this.mail) }
}
