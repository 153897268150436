import { Component } from '@angular/core'

import { GenericFormComponent } from '../../framework/generic-form/generic-form.component'
import { TillService, Till } from '../till.service'

@Component({
  selector: 'app-till-form',
  templateUrl: './till-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class TillFormComponent extends GenericFormComponent<Till> {

  constructor(protected override service: TillService) { super(service)
  }

  override isValid(): boolean { return this.subject.name > ' ' }
}
