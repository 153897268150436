import { Component } from '@angular/core'

import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'
import { TransactionService, Transaction } from '../transaction.service'

@Component({
  selector: 'app-transaction-page',
  templateUrl: './transaction-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css'],
})
export class TransactionPageComponent extends GenericPageComponent<Transaction> {

  constructor(protected override service: TransactionService) {
    super(service) ; this.sorting = { field: 'timestamp', order: 'DESC' }
  }

  override ngOnInit() { /* No default loading */ }
}
