import { Component } from '@angular/core'

import { GenericOverviewComponent, Shortcut } from '../../generic-overview/generic-overview'
import { User, UserService,  } from '../user.service'

@Component({
  selector: 'app-user-overview',
  templateUrl: '../../generic-overview/generic-overview.html',
  styleUrls: ['../../generic-overview/generic-overview.css']
})
export class UserOverviewComponent extends GenericOverviewComponent<User> {

  override shortcuts: Shortcut[] = [
    { field: 'all', filter: {}, name: 'All' },
    { field: 'active', filter: { status: 'ACTIVE' }, name: 'Active users' },
    { field: 'blocked', filter: { status: 'BLOCKED' }, name: 'Blocked users' },
    { field: 'deleted', filter: { status: 'DELETED' }, name: 'Archived users' },
  ]

  constructor(override service: UserService) { super(service) }
}
