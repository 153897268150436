import { NgModule } from '@angular/core'
import { FrameworkModule } from '../framework/framework.module'

import { DumpFormComponent } from './dump-form/dump-form.component'
import { DumpListComponent } from './dump-list/dump-list.component'
import { DumpOverviewComponent } from './dump-overview/dump-overview.component'
import { DumpPageComponent } from './dump-page/dump-page.component'

@NgModule({
  declarations: [
    DumpFormComponent,
    DumpListComponent,
    DumpOverviewComponent,
    DumpPageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [ DumpPageComponent ]
})
export class DumpModule { }
