import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { Account } from '../account.service'
import { CardService, Card } from '../../card/card.service'
import { CardListComponent } from '../../card/card-list/card-list.component'
import { ConfirmationFormComponent } from '../../components/confirmation-form/confirmation-form.component'
import { Sorting } from '../../framework/services/generic.service'

@Component({
  selector: 'app-account-cards',
  templateUrl: './account-cards.component.html',
  styleUrls: ['./account-cards.component.css']
})
export class AccountCardsComponent implements OnInit {

  binding: boolean = false              // Filter out multiple bindings of the same card.
  card: any = ''
  cards: Card[] = []
  frozen: boolean = false
  sorting: Sorting = { field: 'serial', order: 'ASC'}

  @ViewChild(CardListComponent) list !: CardListComponent

  @Input() account !: Account
  @Output() event = new EventEmitter<string>()

  constructor(
    protected service: CardService,
    protected dialog: MatDialog,
  ) { service.clear() }

  cardLabel(card: Card): string { return card ? card.label : ''}

  doFilterCards() {
    if (this.card.length > 1)
      this.service.suggest(this.card).subscribe(cards => this.cards = cards)
  }

  ngOnInit() {
    this.onSort(null)
    this.suggestCard()
  }

  noSelection(): boolean { return typeof this.card == 'string' }

  onAdd() {
    if (!this.binding) {
      this.binding = true
      if (this.card.waste.countOf) {
        const dialog = this.dialog.open(ConfirmationFormComponent, {
          data: {text: 'card.used_confirmation'}, width: '600px', panelClass: 'dialog'
        })
        dialog.afterClosed().subscribe((result: any) => {
          if (result.OK) this.addCard()
          else this.binding = false
        })
      } else this.addCard()
    }
  }

  addCard() {
    this.service.bind(this.card, this.account.id).subscribe(_ => {
      this.onSort(null)
      this.suggestCard()
      this.binding = false
    })
  }

  // Edition events from card list must be bubbled up to account view.
  onEvent(event: string) {
    this.frozen = event == 'edit'
    // Refresh the list when a card was unbound from the account.
    if (event == 'delete') this.list.onSort('serial', 'ASC')
    this.event.next(event)
  }

  onSort(sorting: Sorting | null) {
    if (sorting) this.sorting = sorting
    this.service.fetch({ accountId: this.account.id }, this.sorting)
  }

  suggestCard() {
    this.card = ''
    this.service.suggest('').subscribe(cards => {
      this.cards = cards
      if (cards && cards.length == 1) this.card = cards[0]
    })
  }
}
