import { Component } from '@angular/core'

import { GenericListComponent } from '../../framework/generic-list/generic-list.component'
import { FlowTypeService, FlowType } from '../../framework/flow-type/flow-type.service'

@Component({
  selector: 'app-flow-type-list',
  templateUrl: './flow-type-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class FlowTypeListComponent  extends GenericListComponent<FlowType> {

  constructor(protected override service: FlowTypeService) { super(service) }
}
