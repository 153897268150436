import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { ContainerService, Container } from '../../framework/container/container.service'
import { ContainerTypeService, ContainerType } from '../../container-type/container-type.service'
import { EcologSelectComponent } from '../../framework/container/ecolog-select/ecolog-select.component'
import { Ecopoint } from '../../ecopoint/ecopoint.service'
import { GenericFormComponent, Mode } from '../../framework/generic-form/generic-form.component'
import { StockService, Stock } from '../../stock/stock.service'
import { ZoneService, Zone } from '../../zone/zone.service'
import { DatabaseObject } from '../../framework/objects'
import { FlowTypeService, FlowType } from '../../framework/flow-type/flow-type.service'

@Component({
  selector: 'app-container-form',
  templateUrl: './container-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class ContainerFormComponent extends GenericFormComponent<Container> {

  Mode = Mode

  containerTypes: ContainerType[] = []
  ecolog: DatabaseObject | null = null
  ecologs: DatabaseObject[] = []
  ecopoint: DatabaseObject | null = null
  ecopoints: DatabaseObject[] = []
  flowTypes: FlowType[] = []
  installationTypes: string[]
  stocks: Stock[] = []
  zones: Zone[] = []

  constructor(
    protected containerTypeService: ContainerTypeService,
    protected dialog: MatDialog,
    protected flowTypeService: FlowTypeService,
    protected override service: ContainerService,
    protected stockService: StockService,
    protected zoneService: ZoneService
  ) {
    super(service)
    this.installationTypes = this.service.installationTypes()
    containerTypeService.list(containerTypes => this.containerTypes = containerTypes)
    flowTypeService.list(flowTypes => this.flowTypes = flowTypes)
    stockService.list(stocks => this.stocks = stocks)
    zoneService.list(zones => this.zones = zones,'LOCATION')
  }

  // TODO Access rights ?
  action(size: string): string[] {
    if (this.subject.installationType > '')
      if (size == 'SMALL')
        return ['miscellaneous_services', 'construction']
      else
        return ['miscellaneous_services']
    else
      return ['delete']
  }

  override initializeForm() {
    super.initializeForm()
    if (this.subject.installationType)
      this.subject.installationType = this.object.installationType.replace(/_/g, '-')
    if (this.object.ecolog) this.ecolog = {
      id: this.object.ecolog.id,
      identifier: '',
      name: this.object.ecolog.serial + ' (' + this.object.ecolog.firmware + ')'
    }
    if (this.object.ecopoint) this.ecopoint = {
      id: this.object.ecopoint.id,
      identifier: '',
      name: this.object.ecopoint.location }
  }

  override isValid(): boolean {
    return this.subject.identifier > ' ' && this.subject.containerType != null && this.subject.flowType != null
    && (this.subject.ecopoint != null || this.subject.stock != null)
  }

  onEcopoint(ecopoint: Ecopoint | null) { if (ecopoint) this.subject.stock = null ; this.check() }

  override onEvent(event: string) {
    if (event == 'miscellaneous_services')
      this.selectEcolog()
    else
      super.onEvent(event)
  }

  onStore() { if (this.subject.stock) this.subject.ecopoint = null ; this.check() }

  selectEcolog() {
    const dialog = this.dialog.open(EcologSelectComponent, {
      data: { container: this.object },
      width: '600px',
      panelClass: 'dialog'
    })
    dialog.afterClosed().subscribe(result => {
      if (result && result != 'cancel') {
        if (this.object.ecolog)
          if (result.ecolog)
            this.service.replaceEcolog(this.object.id, result.ecolog.id, result.warranty,() => this.event.next('update'))
          else
            this.service.removeEcolog(this.object.id,() => this.event.next('update'))
        else
          this.service.replaceEcolog(this.object.id, result.ecolog.id, false, () => this.event.next('update'))
      }
    })
  }

  statuses(): string[] { return this.service.statuses() }
}
