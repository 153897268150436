import { Component } from '@angular/core';

import { GenericListComponent } from '../../framework/generic-list/generic-list.component'
import { DumpService, Dump } from '../dump.service'

@Component({
  selector: 'app-dump-list',
  templateUrl: './dump-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class DumpListComponent extends GenericListComponent<Dump> {

  constructor(protected override service: DumpService) { super(service) }
}
