import { Injectable } from '@angular/core'

import { GenericService } from '../framework/services/generic.service'
import { DatabaseObject, Point } from '../framework/objects'

export interface Stock extends DatabaseObject {
  center: Point
  id: string
  identifier: string
  name: string
  zone: DatabaseObject | null
}

@Injectable({
  providedIn: 'root'
})
export class StockService extends GenericService<Stock>{

  endpoint(version: string = 'v1'): string { return this.sessionService.url('stocks', version) }

}
