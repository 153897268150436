<div class="ribbon">
  <div>
    <button [class]="this.period == 1 ? 'highlight' : null" mat-button (click)="onPeriod(1)">1</button>
    <button [class]="this.period == 3 ? 'highlight' : null" mat-button (click)="onPeriod(3)">3</button>
    <button [class]="this.period == 6 ? 'highlight' : null" mat-button (click)="onPeriod(6)">6</button>
    <button [class]="this.period == 12 ? 'highlight' : null" mat-button (click)="onPeriod(12)">12</button>
    <button [class]="this.period == 24 ? 'highlight' : null" mat-button (click)="onPeriod(24)">24</button>
    {{'months' | translate}}
  </div>
  <div>
    <button [class]="this.measure == 'MEASURE' ? 'highlight' : null" mat-button
      (click)="onMeasure('MEASURE')">{{'measure.MEASURE' | translate}}</button>
    <button [class]="this.measure == 'VOLTAGE' ? 'highlight' : null" mat-button
      (click)="onMeasure('VOLTAGE')">{{'measure.VOLTAGE' | translate}}</button>
    <button [class]="this.measure == 'TEMPERATURE' ? 'highlight' : null" mat-button
      (click)="onMeasure('TEMPERATURE')">{{'measure.TEMPERATURE' | translate}}</button>
    <button [class]="this.measure == 'CODE' ? 'highlight' : null" mat-button
      (click)="onMeasure('CODE')">{{'measure.CODE' | translate}}</button>
  </div>
</div>
<div *ngIf="measure=='CODE'; then bubbleChart else lineChart"></div>
<ng-template #bubbleChart>
  <app-bubble-chart [colors]="colors" [series]="bubbles"></app-bubble-chart>
</ng-template>
<ng-template #lineChart>
  <app-line-chart [series]="series" [min]="min" [max]="max" [units]="units"></app-line-chart>
</ng-template>
