<div class="editor">
  <div class="form">
    <div class="row">
      <app-dropdown class="width-15" label="Status" prefix="card" [mode]="mode"
        [(value)]="subject.status" [values]="statuses('SETTINGS' | write)"></app-dropdown>
      <app-text-field class="width-85" label="Comment" [length]="110" [mode]="mode"
        [(value)]="subject.comment" ></app-text-field>
    </div>
  </div>
  <app-form-buttons [actions]="(unbindable && this.object.waste.countOf == 0) ? ['delete'] : []" [mode]="mode"
    [domain]="service.isNormal(subject.status) ? 'ACCOUNTS' : 'SETTINGS'" (event)="onEvent($event)">
  </app-form-buttons>
</div>
