<div class="editor embedded">
  <div class="form">
    <h3>{{'Last Communication' | translate}}</h3>
    <div class="row">
      <app-text-field class="width-20" [label]="'Date'" [value]="object.heartbeat | timestamp"></app-text-field>
      <app-text-field class="width-15" [label]="'Measure'" value="{{object.measure}} mm"></app-text-field>
      <app-text-field class="width-15" [label]="'Temperature'" value="{{object.temperature}} ºC"></app-text-field>
      <app-text-field class="width-15" [label]="'Voltage'" value="{{object.voltage}} mV"></app-text-field>
    </div>
    <h3>{{'Delivery & Warranty' | translate}}</h3>
    <div *ngIf="'AFTER_SALES' | read" class="row">
      <app-text-field class="width-20" [label]="'Supply Reference'" value="{{object.supplyRef}}"></app-text-field>
      <app-text-field class="width-20" [label]="'Supply Date'" [value]="object.supplyDate | datestamp"></app-text-field>
      <app-text-field *ngIf="object.client || object.reseller" class="width-20" [label]="'Resell Reference'" value="{{object.resellRef}}"></app-text-field>
      <app-text-field *ngIf="object.client || object.reseller" class="width-20" [label]="'Resell Date'" [value]="object.resellDate | datestamp"></app-text-field>
    </div>
    <div class="row">
      <app-text-field *ngIf="object.client" class="width-20" [label]="'Delivery Reference'" value="{{object.deliveryRef}}"></app-text-field>
      <app-text-field *ngIf="object.client" class="width-20" [label]="'Delivery Date'" [value]="object.deliveryDate | datestamp"></app-text-field>
      <app-text-field *ngIf="object.client" class="width-20" [label]="'Warranty'" [value]="object.warrantyDate | datestamp"></app-text-field>
    </div>
  </div>
  <app-form-buttons [actions]="object.status == 'IN_STOCK' ? ['delete'] : []" domain="MANUFACTURER" [mode]="Mode.action" (event)="onEvent($event)"></app-form-buttons>
</div>
