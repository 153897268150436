import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.css']
})
export class NavigatorComponent implements OnInit {

  upper: number                        // Upper navigation boundary (current year).
  @Input() lower: number               // Lower navigation boundary (user configurable).

  year: number

  @Output() emitter = new EventEmitter<number>();

  constructor() {
    this.year = new Date().getFullYear()
    this.upper = this.year
    this.lower = this.year - 2
  }

  navigate(year: number) {
    this.year = year
    this.emitter.emit(year)
  }

  ngOnInit() { this.navigate(this.year) }
}
