<div class="pane-container">
  <app-location-overview title="Locations" (selection)="onShortcut($event)"></app-location-overview>
  <app-action-pane domain="AFTER_SALES" [hint]="['Reference', 'Name']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event})"></app-action-pane>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-location-form [creation]="true" [mode]="Mode.edition" [object]="location" (event)="onEvent($event)"></app-location-form>
</ng-template>
<ng-template #showList>
  <app-location-list #list [sorting]="sorting" (event)="onEvent($event)" (sort)="onSort($event)"></app-location-list>
</ng-template>

