import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import { EcopointModule } from '../ecopoint/ecopoint.module'

import { WasteCriteriaFormComponent } from './waste-criteria-form/waste-criteria-form.component'
import { WasteFormComponent } from './waste-form/waste-form.component'
import { WasteListComponent } from './waste-list/waste-list.component'
import { WastePageComponent } from './waste-page/waste-page.component'
@NgModule({
  declarations: [
    WasteCriteriaFormComponent,
    WasteFormComponent,
    WasteListComponent,
    WastePageComponent,
  ],
  imports: [ FrameworkModule, EcopointModule ],
  exports: [
    // Waste form and list are used in Account module.
    WasteFormComponent,
    WasteListComponent,
    WastePageComponent,
  ]
})
export class WasteModule { }
