<div class="ribbon">
  <button mat-icon-button (click)="onAdd()" [disabled]="frozen"><mat-icon>add</mat-icon></button>
  <a mat-icon-button matTooltip="{{'waste.ellipsis' | translate}}" routerLink="/waste/account/{{account.id}}/{{abstract}}/{{account.closingDate}}"><mat-icon>more_horiz</mat-icon></a>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-waste-form [accountId]="account.id" [creation]="true" [mode]="Mode.edition" [object]="waste" (event)="onEvent($event)">
</app-waste-form>
</ng-template><ng-template #showList>
  <app-navigator (emitter)="navigate($event)" [lower]="2019"></app-navigator>
  <app-waste-list [account]="true" [sorting]="sorting" (event)="onEvent($event)" (sort)="onSort($event)"></app-waste-list>
  <div *ngIf="wasteService.more()" class="center"><p>{{'waste.warning' | translate}}</p></div>
</ng-template>
