import { Component } from '@angular/core'

import { BatchService, Batch } from '../batch.service'
import { GenericFormComponent, Mode } from '../../generic-form/generic-form.component'
import { SessionService, Client } from '../../services/session.service'

@Component({
  selector: 'app-batch-form',
  templateUrl: './batch-form.component.html',
  styleUrls: ['../../generic-form/generic-form.component.css']
})
export class BatchFormComponent extends GenericFormComponent<Batch> {

  Mode = Mode

  date !: Date
  region: string = ''
  zone: string = ''

  constructor(
    protected override service: BatchService,
    protected sessionService: SessionService
  ) { super(service) }

  generable(): boolean {
    return this.object.generationDate == null && this.object.encryption != 'UID'
      && (this.object.mode == 'NOMINATIVE' || this.object.mode == 'ANONYMOUS')
  }

  override initializeForm() {
    super.initializeForm()
    if (this.creation && this.sessionService.wise()) {
      const client = this.sessionService.client()
      if (client) this.setRegionalCode(client)
    } else if (this.subject.code) {
      const code = this.subject.code.toString(16).padStart(10, '0')
      this.region = code.slice(0, 8)
      this.zone = code.slice(8, 10)
    }
  }

  override isValid(): boolean {
    const client: boolean = this.sessionService.wise() || this.subject.client != null
    if (this.subject.encryption != 'UID') {
      const code: boolean = this.region.length == 8 && this.zone.length == 2 && parseInt(this.region, 16) > 0 && parseInt(this.zone, 16) > 0
      return client && this.subject.first > '0' && this.subject.last > '0' && code && this.subject.encryption > ''
    } else
      return client
  }

  onClient(client: Client | null) { this.subject.client = client ; this.setRegionalCode(client) ; this.check() }

  override ngOnInit() {
    super.ngOnInit()
    if (this.object.deliveryDate) this.date = new Date(this.object.deliveryDate)
  }

  override onEvent(event: string) {
    if (event == 'add_box')
      this.service.generate(this.object.id, () => { console.log('generated') ; super.onEvent('update')})
    else
      super.onEvent(event)
  }

  setRegionalCode(client: Client | null) {
    console.log(client)
    if (client) {
      if (client.regionalCode)
        this.region = client.regionalCode
      else
        this.region =  '0101' + (+client.id).toString(16).padStart(4, '0')
    } else
      this.region = ''
    console.log(this.subject.code)
  }

  override updateSubject() { this.subject.code = parseInt(this.region + this.zone, 16) }
}
