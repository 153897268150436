import { Component, Input, OnInit } from '@angular/core'

import { EcologService, Ecolog } from '../ecolog.service'
import { Mode } from '../../generic-form/generic-form.component'
import { DatabaseObject } from '../../objects'

enum Index {

  // Dynamic Information
  ccid, diag, fs, xlist,

  // Parameters
  ac, ae, ah, al,
  am, ap, at, bl,
  cc, ce, cg, cl,
  cm, cp, cs, ct,
  dp, dw, es, et,
  hw, lt, pi, pw,
  st, sz, te, th,
  tp, vp, wi,

  // Regions
  ra, rb, rc, rd,
  re, rf, rg, rh,
  ri, rj, rk, rl,

  max
}

export interface Parameter {
  color: string,
  name: string,
  timestamp: string,
  value: string,
}

// TEMP Until a code has been added to Zone objects...
interface Zone extends DatabaseObject {
  code: number
}

@Component({
  selector: 'app-ecolog-parameters',
  templateUrl: './ecolog-parameters.component.html',
  styleUrls: ['../../generic-form/generic-form.component.css']
})
export class EcologParametersComponent implements OnInit {

  Index = Index

  // Template values for incorrect configuration detection.
  // Some values are set or modified in view initialization.
  model = [
    '', '', 'x', '',
    '', '', '', '',                     // ac - ae - ah - al
    'Controlled', '0', '', '',          // am - ap - at - bl
    '', '6', 'C1', '999',               // cc - ce - cg - cl
    'Serial', '1440', '22', '120',      // cm - cp - cs - ct
    '60', '', '2400', '',               // dp - dw - es - et
    '', '', '', '',                     // hw - lt - pi - pw
    '', '', '100', '',                  // st - sz - te - th
    '15', '1440', ''                    // tp - vp - wi

    // Don't care for regions.
  ]

  // Correct firmware signatures (not exhaustive)
  signatures = [
    '00571410',    // 5.4.2.4
    '004EA503',    // 5.4.3.4
    '003AD04E',    // 5.4.4.1
    '004F74A5',    // 5.4.5.2
    '0050976E',    // 5.4.5.4
    '0050B0BB',    // 5.4.5.4
    '005069D4',    // 5.4.5.4
    '00557753',    // 5.4.5.5
    '0054BF84',    // 5.4.5.6
    '00500BC4',    // 5.4.5.7
    '004FCB83',    // 5.4.5.9
    '004F5A2D',    // 5.4.6.0
    '004F7FC8'     // 5.4.6.1
  ]

  // General region codes (EcoWaste, Metas)
  zones: Zone[] = [
    {id: '1', identifier:'', name: 'EcoWaste', code: 0x01000101 },
    {id: '1', identifier:'', name: 'Metas - FR', code: 0x01002603 },
    {id: '1', identifier:'', name: 'Metas - NE', code: 0x01001C01 },
    {id: '1', identifier:'', name: 'Metas - SG', code: 0x01001F01 },
    {id: '1', identifier:'', name: 'Metas - VD', code: 0x01002601 },
    {id: '1', identifier:'', name: 'Metas - VS', code: 0x01002602 },
  ]

  // Selection lists
  accessModes = ['Closed', 'Barrier', 'Twins', 'Controlled', 'Open']
  cardModes = ['Serial', 'Serial', 'UID', 'All']
  consoleGenerations = ['C1', 'C13']
  encryptionTypes = ['125k', '13A', '13C-S1', 'UNKNOWN', '13C-S5']
  lockTypes = ['None', 'Bi-Stable', 'Mono-Stable', 'Pulse', 'Anti-Cheat']
  weighingInterfaces = ['None', 'Busch', 'Kolly', 'Pottinger' ]
  weighingTypes = ['None', 'PIMK4', 'PI5']

  // Condition facilitators
  pi: number = 0
  st: number = 0
  wi: number = 0

  generation: number = 0
  initialized: boolean = false
  lastRegion: Index = Index.ra
  mode: Mode = Mode.readonly
  params: Parameter[] = new Array(Index.max)
  timestamps: string[] = new Array(Index.max)

  @Input() ecolog !: Ecolog

  constructor(protected service: EcologService) { }

  ngOnInit() {
    this.generation = +this.ecolog.hardware[0]
    this.service.parameters(this.ecolog.id).subscribe(parameters => {

      // CP timestamp is reference timestamp to avoid repeating the same value in all parameter hints.
      const reference = parameters.find(parameter => parameter.name == 'CP')?.timestamp.slice(0,10)

      for (let index = 0; index < Index.max; index++) {
        let name = Index[index]
        if (index != Index.pi) name= name.toUpperCase()
        let parameter = parameters.find(parameter => parameter.name == name)
        if (parameter) {
          this.params[index] = { color: 'black', name: name, value: parameter.value, timestamp: '' }
          let timestamp = parameter.timestamp.slice(0,10)
          if (timestamp != reference) this.params[index].timestamp = timestamp
        }
        else
          this.params[index] = { color: '', name: name, value: '', timestamp: '' }
      }

      this.params[Index.cp].timestamp = reference ?? ''

      this.st = +this.params[Index.st].value
      this.pi = +this.params[Index.pi].value
      this.wi = +this.params[Index.wi].value

      // Adjust selection values.
      this.params[Index.am].value = this.accessModes[+this.params[Index.am].value]
      this.params[Index.cg].value = this.consoleGenerations[+this.params[Index.cg].value]
      this.params[Index.cm].value = this.cardModes[+this.params[Index.cm].value]
      this.params[Index.et].value = this.encryptionTypes[+this.params[Index.et].value]
      this.params[Index.lt].value = this.lockTypes[+this.params[Index.lt].value]
      this.params[Index.pi].value = this.weighingTypes[this.pi]
      this.params[Index.st].value = this.sensorTypes()[this.st]
      this.params[Index.wi].value = this.weighingInterfaces[this.wi]

      // Until FW 5.4.6.2 the X-List date is uploaded with / instead of - separator.
      this.params[Index.xlist].value = this.params[Index.xlist].value.replace('/','-').replace('/','-')

      // Adjust region code names.
      for (let index = Index.ra; index < Index.max; index++) {
        let code = parseInt(this.params[index].value,16)
        if (code > 0) {
          const zone = this.zones.find(zone => zone.code == code)
          if (zone)
            this.params[index].name = zone.name
          else
            this.params[index].color = 'orange'
          this.lastRegion = index
        } else {
          this.params[index].value = ''
          this.params[index].timestamp = ''
        }
      }

      // Colorize the X-list date if different than yesterday.
      let today = new Date()
      today.setDate(today.getDate() - 1)
      this.model[Index.xlist] = today.toISOString().slice(2,10)

      // Anti-Passback makes sense only in barrier mode.
      if (this.params[Index.am].value == 'Barrier') this.model[Index.ap] = ''

      if (this.pi) this.model[Index.es] = '2200'

      // Adjust parameter colorization.
      for (let index = 0; index < Index.max; index++)
        if (this.model[index] && this.model[index] != this.params[index].value)
          this.params[index].color = index == Index.ap ? 'red' : 'orange'

      if (this.signatures.find(signature => signature == this.params[Index.fs].value))
        this.params[Index.fs].color = 'black'

      // Set flag for end of view initialization as this is asynchronous.
      this.initialized = true
    })
  }

  sensorTypes() {
    if (this.ecolog.hardware[0] == '3')
      return ["None", "N3ab", "N3a", "N3b", "Capacity"]
    else
      return ["None", "N4XF", "N5", "Capacity"]
  }
}
