import { Component } from '@angular/core'

import { BatchService, Batch } from '../batch.service'
import { GenericPageComponent } from '../../generic-page/generic-page.component'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-batch-page',
  templateUrl: './batch-page.component.html',
  styleUrls: ['../../generic-page/generic-page.component.css']
})
export class BatchPageComponent extends GenericPageComponent<Batch> {

  batch: Batch = { client: null, code: 0, comment: '', deliveryDate: null, deliveryRef: '', encryption: '', first: '',
    generationDate: '', id: '', mode: 'NOMINATIVE', last: '' }

  constructor(
    protected override service: BatchService,
    protected sessionService: SessionService,
  ) {
    super(service)
    this.sorting = sessionService.wise() ? { field: 'first', order: 'ASC' } : { field: 'LAST_MODIFICATION_DATE', order: 'DESC' }
  }
}
