import { NgModule } from '@angular/core'
import { FrameworkModule } from '../framework/framework.module'

import { CardCriteriaFormComponent } from './card-criteria-form/card-criteria-form.component'
import { CardFormComponent } from './card-form/card-form.component'
import { CardListComponent } from './card-list/card-list.component'
import { CardPageComponent } from './card-page/card-page.component'

@NgModule({
  declarations: [
    CardCriteriaFormComponent,
    CardFormComponent,
    CardListComponent,
    CardPageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [
    // Card form and list are also used in the Account module.
    CardFormComponent,
    CardListComponent,
    CardPageComponent,
  ]
})
export class CardModule { }
