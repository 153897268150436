<div class="editor">
  <div class="form">
    <div class="row">
      <app-text-field class="width-20" label="username" [mode]="mode" [required]="true"
        [(value)]="subject.username" (valueChange)="check()"></app-text-field>
      <app-text-field class="width-20" label="firstName" [mode]="mode"
        [(value)]="subject.firstname" ></app-text-field>
      <app-text-field class="width-20" label="lastName" [mode]="mode"
        [(value)]="subject.lastname" ></app-text-field>
      <mat-form-field class="width-35">
        <mat-label>{{'Email' | translate}}</mat-label>
        <input matInput class="input" [disabled]="mode != Mode.edition" (input)="check()" required type="email" [(ngModel)]="subject.email" >
      </mat-form-field>
    </div>
    <div class="row">
      <app-dropdown class="width-20" label="Status" [mode]="mode" prefix="user"
        [(value)]="subject.status" [values]="['ACTIVE', 'BLOCKED', 'DELETED']"></app-dropdown>
      <app-object-dropdown class="width-20" label="Role" [mode]="mode" [objects]="roles" [required]="true"
        [(object)]="subject.role" (objectChange)="check()"></app-object-dropdown>
      <app-object-dropdown class="width-20" label="Till" [mode]="mode" [objects]="tills"
        [(object)]="subject.till"></app-object-dropdown>
    </div>
    <h3>{{'Restrictions' | translate}}</h3>
    <div class="row">
      <app-object-dropdown class="width-20" label="Flow Type" [mode]="mode" [objects]="flowTypes"
        [(object)]="subject.flowType"></app-object-dropdown>
      <app-object-dropdown class="width-20" label="Accounts Zone" [mode]="mode" [objects]="accountZones"
        [(object)]="subject.accountZone"></app-object-dropdown>
      <app-object-dropdown class="width-20" [mode]="mode" label="Locations Zone" [objects]="locationZones"
        [(object)]="subject.locationZone"></app-object-dropdown>
    </div>
    <h3>{{'Subscriptions' | translate}}</h3>
    <div class="row">
      <app-dropdown class="width-20" label="Access Control" [mode]="mode" prefix='subscription'
        [values]="notificationTypes" [(value)]="subject.subscriptions.access"></app-dropdown>
      <app-dropdown class="width-20" label="After Sales" [mode]="mode" prefix='subscription'
        [values]="notificationTypes" [(value)]="subject.subscriptions.afterSales"></app-dropdown>
      <app-dropdown class="width-20" label="Collection" [mode]="mode" prefix='subscription'
        [values]="notificationTypes" [(value)]="subject.subscriptions.collection"></app-dropdown>
      <app-dropdown class="width-20" label="Technical" [mode]="mode" prefix='subscription'
        [values]="notificationTypes" [(value)]="subject.subscriptions.technical"></app-dropdown>
    </div>
  </div>
  <app-form-buttons [actions]="object.status == 'DELETED' ? [] : ['delete']" [mode]="mode" [valid]="valid"
    (event)="onEvent($event)"></app-form-buttons>
</div>
