import { Component } from '@angular/core';

import { GenericListComponent } from '../../framework/generic-list/generic-list.component'
import { LocalityService, Locality } from '../../framework/locality/locality.service'

@Component({
  selector: 'app-locality-list',
  templateUrl: './locality-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class LocalityListComponent extends GenericListComponent<Locality> {

  constructor(protected override service: LocalityService) { super(service) }
}
