import { NgModule } from '@angular/core'
import { MatDialogModule } from '@angular/material/dialog'
import { MatTabsModule } from '@angular/material/tabs'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatRadioModule } from '@angular/material/radio'

import { ChartsModule } from '../framework/charts/charts.module'
import { FrameworkModule } from '../framework/framework.module'
import { HistoryModule } from '../framework/history/history.module'

import { EcopointModule } from '../ecopoint/ecopoint.module'
import { WasteModule } from '../waste/waste.module'

// Shared Components
import { ContainerListComponent } from '../framework/container/container-list/container-list.component'
import { ContainerRequestsComponent } from '../framework/container/container-requests/container-requests.component'
import { EcologSelectComponent } from '../framework/container/ecolog-select/ecolog-select.component'
import { RequestFormComponent } from '../framework/container/request-form/request-form.component'

// Container Components
import { ContainerCalibrationComponent } from './container-calibration/container-calibration.component'
import { ContainerFilterComponent } from './container-filter/container-filter.component'
import { ContainerFormComponent } from './container-form/container-form.component'
import { ContainerOverviewComponent } from './container-overview/container-overview.component'
import { ContainerPageComponent } from './container-page/container-page.component'
import { ContainerStatisticsComponent } from './container-statistics/container-statistics.component'
import { ContainerViewComponent } from './container-view/container-view.component'
import { ContainerWasteComponent } from './container-waste/container-waste.component'

@NgModule({
  declarations: [
    ContainerCalibrationComponent,
    ContainerFilterComponent,
    ContainerFormComponent,
    ContainerListComponent,
    ContainerOverviewComponent,
    ContainerPageComponent,
    ContainerRequestsComponent,
    ContainerStatisticsComponent,
    ContainerViewComponent,
    ContainerWasteComponent,
    EcologSelectComponent,
    RequestFormComponent,
  ],
  imports: [
    MatDialogModule,
    MatProgressBarModule,
    MatTabsModule,
    MatRadioModule,

    FrameworkModule,
    ChartsModule,
    HistoryModule,

    EcopointModule,
    WasteModule,
  ],
  exports: [ ContainerPageComponent ]
})
export class ContainerModule { }
