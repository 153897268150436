<div class="criteria-pane">
  <h1>{{'Reports' | translate}}
    <span *ngIf="filter.id"> : {{filter.id}} </span>
    <span *ngIf="total | async as total">({{count | async}} / {{total | big}})</span>
  </h1>
  <div class="row">
    <mat-form-field class="width-30" appearance="fill">
      <mat-label>{{'Date range' | translate}}</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <!-- Manual date entry requires third party date adapter.-->
        <input matStartDate [(ngModel)]="filter.startDate" [placeholder]="'Start date' | translate" readonly>
        <input matEndDate [(ngModel)]="filter.endDate" [placeholder]="'End date' | translate" readonly>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <app-dropdown class="width-15" label="Status" prefix="report" [required]="false" [values]="statuses()" [(value)]="filter.status"></app-dropdown>
    <mat-form-field class="width-15" >
      <mat-label>{{'Identifier' | translate}}</mat-label>
      <input matInput maxlength="8" type="number" [(ngModel)]="filter.identifier" (keydown.enter)="onSearch()">
    </mat-form-field>
    <app-client-dropdown *ngIf="sessionService.rise()" class="width-20" label="Client" (client)="onClient($event)"></app-client-dropdown>
  </div>
  <div class="row right">
    <button mat-flat-button (click)="onClear()">{{'Clear' | translate}}</button>
    <button mat-flat-button (click)="onSearch()">{{'Search' | translate}}</button>
    <button *ngIf="sessionService.wise()" mat-flat-button (click)="action.next()">{{'New' | translate}}</button>
  </div>
</div>
