import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatTabChangeEvent } from '@angular/material/tabs'

import { Account } from '../account.service'

@Component({
  selector: 'app-account-view',
  templateUrl: './account-view.component.html',
  styleUrls: ['../../framework/generic-view/generic-view.css']
})
export class AccountViewComponent {

  dirty: boolean = false                // Indicates that balance may have changed.

  @Input() account !: Account

  @Output() accountChange = new EventEmitter<Account>()
  @Output() event = new EventEmitter<string>()

  abstract(): string {
    const firstName = this.account.address.firstName
    const name = firstName ? firstName + ' ' + this.account.address.lastName : this.account.address.lastName
    const identifier = this.account.identifier
    return identifier ? identifier + ' - ' + name : name
  }

  onEvent(event: string) { this.event.next(event) }

  onFilterEvent(event: string) {
    // Waste or a transaction has been added or invalidated, hence balance may be incorrect.
    if (event == 'add' || event == 'save') {
      this.dirty = true
      // Save event is bubbled as cancel only to unfreeze parents.
      this.event.next('cancel')
    }
    else
      // Sublist deletion is not bubbled up as that triggers a main list update.
      if (event != 'delete') this.event.next(event)
  }

  onTabChange(event: MatTabChangeEvent) {
    // Only the list can refresh a list item.
    if (event.index == 0 && this.dirty) this.event.next('update') }
}
