<div class="pane-container">
  <app-stock-overview #overview [sorting]="sorting.field" title="Stocks"
    (selection)="onShortcut($event)"></app-stock-overview>
  <app-action-pane domain="LOCATIONS" [hint]="['Identifier', 'Location']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event })"></app-action-pane>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-stock-form [creation]="true" [mode]="Mode.edition" [object]="stock" (event)="onEvent($event)"></app-stock-form>
</ng-template>
<ng-template #showList>
  <app-stock-list #list [sorting]="sorting" (event)="onEvent($event)" (page)="onScroll()" (sort)="onSort($event)"></app-stock-list>
</ng-template>
