<div class="embedded-header">
  <list-header class="width-5" [embedded]="true" identifier="#"></list-header>
  <list-header *ngIf="rise()" class="width-15" [embedded]="true" identifier="client_name"></list-header>
  <list-header class="width-15" [embedded]="true" identifier="Identifier"></list-header>
  <list-header class="width-30" [embedded]="true" identifier="Location"></list-header>
  <list-header class="width-10" [embedded]="true" identifier="last_collection_date" ></list-header>
  <list-header class="width-10" [embedded]="true" identifier="last_access_date"></list-header>
  <list-header *ngIf="rise()" class="width-15" [embedded]="true" identifier="heartbeat"></list-header>
  <list-header *ngIf="wise()" class="width-10" [embedded]="true" identifier="Forecast"></list-header>
  <list-header *ngIf="wise()" class="width-15" [embedded]="true" identifier="Level"></list-header>
</div>
<cdk-accordion>
  <cdk-accordion-item *ngFor="let stop of stops; let index = index" tabindex="0" [attr.id]="'header-' + index">
    <div class="embedded-item" (click)="onMap(stop, index)">
      <span class="width-5 number">{{stop.order}}</span>
      <span *ngIf="rise() && stop.container && stop.container.client" class="width-15">
        {{stop.container.client.shortName}}</span>
      <span class="width-15">{{stop.container?.identifier}}{{stop.dump?.identifier}}</span>
      <span class="width-30" *ngIf="stop.container && stop.container.ecopoint">
        {{stop.container.ecopoint.location | shorten:30}}</span>
      <span class="width-30" *ngIf="stop.dump">{{stop.dump.name | shorten:30}}</span>
      <span *ngIf="stop.container" class="width-10">{{stop.container.lastCollectionDate | datestamp}}</span>
      <span *ngIf="stop.container" class="width-10">{{stop.container.lastAccessDate | datestamp}}</span>
      <span *ngIf="rise() && stop.container && stop.container.ecolog" class="width-15">
        {{stop.container.ecolog.heartbeat | timestamp}}</span>
      <span *ngIf="wise() && stop.container" class="width-10">{{stop.container.forecastDate}}</span>
      <span *ngIf="wise() && stop.container && stop.container.level != null" class="width-15 wrapper">
        <span [style.color]="color(stop.container.levelSource)">{{stop.container.level}}%</span>
        <mat-progress-bar *ngIf="stop.container.levelSource == 'FORECAST'"
          color="{{stop.container.level > 80 ? 'warn' : 'default'}}"
          class="overlay" mode="determinate" [value]="stop.container.level">
        </mat-progress-bar>
      </span>
  </div>
  </cdk-accordion-item>
</cdk-accordion>
