<div class="container">
  <div class="sticky">
    <app-screensize (event)="size = $event"></app-screensize>
    <app-header></app-header>
    <div class="spacer"></div>
    <app-menu-bar></app-menu-bar>
  </div>
  <div class="overlay" id="overlay">
    <span class="loader"></span>
  </div>
  <div [class]="production ? 'prod' : 'test'">
    <router-outlet [trace]="'router-outlet'"></router-outlet>
    <app-footer></app-footer>
  </div>
</div>
