import { Component, Input, OnInit } from '@angular/core'

import { EcologService, Ecolog, Measure } from '../ecolog.service'
import { Mode } from '../../generic-form/generic-form.component'
import { Series } from '../../charts/line-chart/line-chart.component'

@Component({
  selector: 'app-ecolog-measures',
  templateUrl: './ecolog-measures.component.html',
  styleUrls: ['./ecolog-measures.component.css']
})
export class EcologMeasuresComponent implements OnInit {

  Mode = Mode

  bubbles: any[] = []                   // Bubble chart data.
  colors: any[] = []
  max = 2500                             // Scale max
  measure: string = 'MEASURE'
  min = 0                               // Scale min
  period: number = 6
  series: Series[] = []                  // Line charts series.
  start: Date = new Date()
  end: Date = new Date()
  units: string = 'mm'

  @Input() ecolog !: Ecolog

  constructor(protected service: EcologService) {
    const measure = window.sessionStorage.getItem('measure')
    const period = window.sessionStorage.getItem('period')
    if (period) this.period = +period
    if (measure && ['MEASURE', 'VOLTAGE', 'TEMPERATURE', 'CODE'].includes(measure)) this.measure = measure
    this.setStart(this.period)
  }

  formatCodes(measures: Measure[]) {
    // Format event codes as an array of series, each series being:
    // { name: event, series: [{ name: Date, x: Date, y: event, r: number (count), color, timestamps: [Date] }
    let series: any[] = [
      { name: 'NO_EVENT', series: [], color: '#808080' },                       // Code : 0
      { name: 'FIRMWARE_UPDATE', series: [], color: '#2693bc' },                // Blue
      { name: 'CHECKSUM_ERROR', series: [], color: '#C7B42C' },                 // Orange
      { name: 'EXCEPTION', series: [], color: '#A10A28' },                      // Red
      { name: 'FATAL_ERROR', series: [], color: '#A10A28' },
      { name: 'LOG_ERASURE', series: [], color: '#2693bc' },
      { name: 'ILLEGAL_ARG', series: [], color: '#A10A28' },
      { name: 'INITIALIZATION', series: [], color: '#5AA454' },                 // Green
      { name: 'LOOPING', series: [], color: '#A10A28' },
      { name: 'GENERAL_OVERFLOW', series: [], color: '#A10A28' },
      { name: 'STORAGE_OVERFLOW', series: [], color: '#C7B42C' },               // Code : 10
      { name: 'STRING_OVERFLOW', series: [], color: '#C7B42C' },
      { name: 'UNKNOWN_CASE', series: [], color: '#C7B42C' },
      { name: 'UNKNOWN_TAG', series: [], color: '#C7B42C' },
      { name: 'UNKNOWN_REASON', series: [], color: '#C7B42C' },
      { name: 'WARM_RESET', series: [], color: '#2693bc' },
      { name: 'WATCHDOG', series: [], color: '#A10A28' },
      { name: 'ACCELEROMETER', series: [], color: '#5AA454' },
      { name: 'UNEXPECTED_INTERRUPT', series: [], color: '#A10A28' },
      { name: 'BSL_UPDATE', series: [], color: '#5AA454' },
      { name: 'QUARTZ_ERROR', series: [], color: '#A10A28' },                   // Code : 20
      { name: 'BUFFER_OVERFLOW', series: [], color: '#A10A28' },
      { name: 'NO_MEASURE', series: [], color: '#2693bc' },
      { name: 'COLLECTION', series: [], color: '#5AA454' },
      { name: 'HARDWARE_ERROR', series: [], color: '#A10A28' },
      { name: 'SELF_TEST_REPORT', series: [], color: '#5AA454' },
      { name: 'WRONG_FIRMWARE', series: [], color: '#A10A28' },
      { name: 'ASSERTION_VIOLATION', series: [], color: '#A10A28' },
      { name: 'PARAMETER_CORRUPTION', series: [], color: '#A10A28' },
      { name: 'FIRMWARE_CORRUPTION', series: [], color: '#A10A28' },
      { name: 'XLIST_ERROR', series: [], color: '#C7B42C' },                    // Code : 30
      { name: 'AIRLOCK_BLOCKED', series: [], color: '#2693bc' },
      { name: 'SPACETIME_CORRUPTION', series: [], color: '#A10A28' },
      { name: 'AIRLOCK_CLUTTERED', series: [], color: '#2693bc' },
      { name: 'LID_OPEN', series: [], color: '#2693bc' },
      { name: 'STACK_OVERFLOW', series: [], color: '#A10A28' },
      { name: 'VOID_FRAME', series: [], color: '#A10A28' },
      { name: 'COLD_RESET', series: [], color: '#5AA454' },
      { name: 'BROWNOUT', series: [], color: '#C7B42C' },
      { name: 'LOG_ERROR', series: [], color: '#A10A28' },
      { name: 'ALARM', series: [], color: '#2693bc' },                          // Code : 40
      { name: 'AIRLOCK_FROZEN', series: [], color: '#2693bc' },
      { name: 'DIAGNOSTIC', series: [], color: '#2693bc' },
      { name: 'MAX_EVENT_CODES', series: [], color: '#A10A28' },
      ]

    for (let index = 0; index < measures.length; index++) {
      const time = measures[index].timestamp.slice(11,19)
      const date = new Date(measures[index].timestamp.slice(0,10))
      const code = measures[index].value
      if (code > 0 && code < series.length) {
        const serie = series[code].series
        if (serie.length && date <= serie[serie.length - 1].x) {
          serie[serie.length - 1].r++
          serie[serie.length - 1].timestamps.push(time)
        }
        else
          serie.push({ name: date, x: date, y: series[code].name, r: 1, color: series[code].color, timestamps: [time] })
    }}

    // Add a pseudo-code for the beginning and the end of the period.
    series[0].series.push({ name: this.start, x: this.start, y: 'NO_EVENT', r: 0.1, color: '#0', timestamps: [] })
    series[0].series.push({ name: this.end, x: this.end, y: 'NO_EVENT', r: 0.1, color: '#0', timestamps: [] })

    // Filter empty series and create colors array.
    this.colors = []
    let index = 0
    while (index < series.length) {
      if (series[index].series.length) {
        this.colors.push({ name: series[index].name, value: series[index].color })
        index++
      }
      else
        series.splice(index,1)
    }
    this.bubbles = series
  }

  formatTemperatures(measures: Measure[]) {
    // Format temperatures as two series of daily minimum and maximum temperature, each series being:
    // { name: min/max, series: [{ name: Date, value: temperature }]}
    let max: Series = { name: 'Max', series: [] }
    let min: Series = { name: 'Min', series: [] }
    let maxCount = -1
    let minCount = -1

    for (let index = 0; index < measures.length; index++) {
      const date = new Date(measures[index].timestamp)
      // Detect maximum values.
      if (maxCount > -1 && date <= max.series[maxCount].name) {
        if (max.series[maxCount].value < measures[index].value)
          max.series[maxCount].value = measures[index].value
      } else {
        max.series.push({ name: date, value: measures[index].value })
        maxCount++
      }
      // Detect minimum values.
      if (minCount > -1 && date <= min.series[minCount].name) {
        if (min.series[minCount].value > measures[index].value)
          min.series[minCount].value = measures[index].value
      } else {
        min.series.push({ name: date, value: measures[index].value })
        minCount++
      }
    }

    // Add a dummy data series to include the entire date range.
    this.series = [min, max, { name: '', series: [{ name: this.start, value: 0 }, { name: this.end, value: 0 }] }]
  }

  onMeasure(measure: string) {
    this.bubbles = []
    this.measure = measure
    window.sessionStorage.setItem('measure', measure)
    switch (measure) {
      case 'MEASURE':
        this.max = 2500
        this.min = 0
        this.units = 'mm'
        break
      case 'CODE':
        this.units = ''
        break
      case 'TEMPERATURE':
        this.max = 90
        this.min = -40
        this.units = 'ºC'
        break
      case 'VOLTAGE':
        this.max = this.ecolog.hardware.length &&  this.ecolog.hardware[0] == '5' ? 4000 : 14000
        this.min = 0
        this.units = 'mV'
    }
    this.update()
  }

  ngOnInit() { this.onMeasure(this.measure) }

  onPeriod(period: number) {
    window.sessionStorage.setItem('period', period.toString())
    this.setStart(period)
    this.update()
  }

  setStart(period: number) {
    this.period = period
    this.start = new Date()
    this.start.setDate(this.start.getDate() - 30 * period)
  }

  update() {
    this.service.measures(this.ecolog.id, this.start, this.end, this.measure)
    .subscribe(measures => {
      if (this.measure == 'CODE')
        this.formatCodes(measures)
      if (this.measure == 'TEMPERATURE')
        this.formatTemperatures(measures)
      else {
        let serie: Series = { name: this.measure, series: []}
        for (let index = 0; index < measures.length; index++)
          serie.series.push({
            measure: measures[index].measure,
            name: new Date(measures[index].timestamp),
            value: measures[index].value
          })

        // Add dummy data series to include the entire date range.
        this.series = [
          serie,
          { name: 'x', series: [] },
          { name: 'y', series: [{ name: this.start, value: 0 }, { name: this.end, value: 0 }] }
        ]
      }
    })
  }
}
