import { Component, OnInit } from '@angular/core'

import { GenericFormComponent} from '../../framework/generic-form/generic-form.component'
import { GroupingService, Grouping } from '../grouping.service'
import { ZoneService, Zone } from "../../zone/zone.service";

@Component({
  selector: 'app-grouping-form',
  templateUrl: './grouping-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class GroupingFormComponent extends GenericFormComponent<Grouping> implements OnInit {

  zones: Zone[] = []

  constructor(
    protected override service: GroupingService,
    protected zoneService: ZoneService,
  ) {
    super(service)
    zoneService.list(zones => this.zones = zones,'LOCATION')
  }

  override isValid(): boolean { return this.subject.name > ' ' }
}
