import { Component } from '@angular/core'

import { FlowTypeService, FlowType } from '../../framework/flow-type/flow-type.service'
import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'

@Component({
  selector: 'app-flow-type-page',
  templateUrl: './flow-type-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css']
})
export class FlowTypePageComponent extends GenericPageComponent<FlowType> {

  flowType: FlowType = { id: '', identifier: '', name: '' }

  constructor(protected override service: FlowTypeService) { super(service) }
}
