import { Component } from '@angular/core'

import { LocationService, Location } from '../location.service'
import { GenericFormComponent, Mode } from '../../generic-form/generic-form.component'

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['../../generic-form/generic-form.component.css']
})
export class LocationFormComponent extends GenericFormComponent<Location> {

  Mode = Mode

  constructor(protected override service: LocationService) { super(service) }
}
