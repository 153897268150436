<div class="list-header">
  <list-header class="width-15" [identifier]="'Identifier'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [identifier]="'Model'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [identifier]="'Type'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [identifier]="'Brand'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [identifier]="'Name'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [identifier]="'Gross vol. [l]'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [identifier]="'Net vol. [l]'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as containerTypes">
  <cdk-accordion-item *ngFor="let containerType of containerTypes; let index = index"
      #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span class="width-15">{{containerType.identifier}}</span>
      <span class="width-15">{{containerType.containerTypeModel?.name}}</span>
      <span class="width-15">{{'containerType.' + containerType.kind | translate}}</span>
      <span class="width-10">{{containerType.brand}}</span>
      <span class="width-15">{{containerType.name}}</span>
      <span class="width-15">{{containerType.v1}}</span>
      <span class="width-15">{{containerType.v2}}</span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-container-type-form [object]="containerType" (objectChange)="onUpdate(index, $event)" (event)="onEvent($event)"></app-container-type-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count"><span *ngIf="total | async as total">{{count | async}} / {{total | big}}</span></div>