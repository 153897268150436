import { Component } from '@angular/core';

import { EcopointService, Ecopoint } from '../ecopoint.service'
import { GenericFormComponent } from '../../framework/generic-form/generic-form.component'
import { LocalityService, Locality } from '../../framework/locality/locality.service'
import { DatabaseObject } from '../../framework/objects'
import { ZoneService, Zone } from '../../zone/zone.service'

@Component({
  selector: 'app-ecopoint-form',
  templateUrl: './ecopoint-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class EcopointFormComponent extends GenericFormComponent<Ecopoint> {

  localities: Locality[] = []
  zones: Zone[] = []

  constructor(
    protected localityService: LocalityService,
    protected override service: EcopointService,
    protected zoneService: ZoneService,
  ) {
    super(service)
    localityService.list(localities => this.localities = localities)
    zoneService.list(zones => this.zones = zones,'LOCATION')
  }

  override isValid(): boolean { return this.subject.location > ' ' && this.subject.locality != null }

  onLocality(object: DatabaseObject | null) {
    if (object) {
      let locality = this.localities.find(locality => locality.id == object.id)
      if (locality && locality.center && locality.center.x) this.subject.center = { ...locality.center }
    }
    this.check()
  }}
