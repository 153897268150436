import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import { ZoneFormComponent } from './zone-form/zone-form.component'
import { ZoneListComponent } from './zone-list/zone-list.component'
import { ZoneOverviewComponent } from './zone-overview/zone-overview.component'
import { ZonePageComponent } from './zone-page/zone-page.component'

@NgModule({
  declarations: [
    ZoneFormComponent,
    ZoneListComponent,
    ZoneOverviewComponent,
    ZonePageComponent
  ],
  imports: [ FrameworkModule ],
  exports: [ ZonePageComponent ]
})
export class ZoneModule { }
