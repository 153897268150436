import { Component } from '@angular/core'

import { GenericOverviewComponent } from '../../generic-overview/generic-overview'
import { RoleService, Role } from '../role.service'

@Component({
  selector: 'app-role-overview',
  templateUrl: '../../generic-overview/generic-overview.html',
  styleUrls: ['../../generic-overview/generic-overview.css']
})
export class RoleOverviewComponent extends GenericOverviewComponent<Role> {

  constructor(protected override service: RoleService) { super(service) }
}
