<div class="list-header">
  <list-header class="width-15" identifier="Name" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-8" identifier="WISE"></list-header>
  <list-header class="width-8" identifier="KIOSK"></list-header>
  <list-header *ngIf="sessionService.rise()" class="width-8" identifier="RISE"></list-header>
  <list-header class="width-12" identifier="Accounts"></list-header>
  <list-header class="width-12" identifier="Locations"></list-header>
  <list-header class="width-12" identifier="Settings"></list-header>
  <list-header *ngIf="sessionService.rise()" class="width-12" identifier="After Sales"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as roles">
  <cdk-accordion-item *ngFor="let role of roles; let index = index"
      #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span class="width-15">{{role.name}}</span>
      <span class="width-8"><mat-icon>{{application(role,'WISE')}}</mat-icon></span>
      <span class="width-8"><mat-icon>{{application(role,'KIOSK')}}</mat-icon></span>
      <span *ngIf="sessionService.rise()" class="width-8"><mat-icon>{{application(role,'RISE')}}</mat-icon></span>
      <span class="width-12">{{right(role,'ACCOUNTS') | translate}}</span>
      <span class="width-12">{{right(role,'LOCATIONS') | translate}}</span>
      <span class="width-12">{{right(role,'SETTINGS') | translate}}</span>
      <span *ngIf="sessionService.rise()" class="width-12">{{right(role, 'AFTER_SALES') | translate}}</span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-role-form [object]="role" (objectChange)="onUpdate(index, $event)" (event)="onEvent($event)"></app-role-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count"><span *ngIf="total | async as total">{{count | async}} / {{total}}</span></div>
