import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

export interface DialogData {
  text: string
}

@Component({
  selector: 'app-confirmation-form',
  templateUrl: './confirmation-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class ConfirmationFormComponent {

  text: string = ''

  constructor(
    public dialog: MatDialogRef<ConfirmationFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {  this.text = data.text }

  onOK() { this.dialog.close({ OK: true }) }
}
