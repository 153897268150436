import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import { EcopointModule } from '../ecopoint/ecopoint.module'

import { CollectionCriteriaFormComponent } from './collection-criteria-form/collection-criteria-form.component'
import { CollectionFormComponent } from './collection-form/collection-form.component'
import { CollectionListComponent } from './collection-list/collection-list.component'
import { CollectionPageComponent } from './collection-page/collection-page.component'

@NgModule({
  declarations: [
    CollectionCriteriaFormComponent,
    CollectionFormComponent,
    CollectionListComponent,
    CollectionPageComponent,
  ],
  imports: [ FrameworkModule, EcopointModule ],
  exports: [ CollectionPageComponent ]
})
export class CollectionModule { }
