import { NgModule } from '@angular/core'

import { MatChipsModule } from '@angular/material/chips'

import { ChartsModule } from '../framework/charts/charts.module'
import { FrameworkModule } from '../framework/framework.module'

import { DashboardPageComponent } from './dashboard-page/dashboard-page.component'
import { NotificationListComponent } from './notification-list/notification-list.component'

@NgModule({
  declarations: [
    DashboardPageComponent,
    NotificationListComponent,
  ],
  imports: [
    ChartsModule,
    FrameworkModule,
    MatChipsModule
  ],
  exports: [ DashboardPageComponent ]
})
export class DashboardModule { }
