import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core'

import { Action } from '../../components/action-pane/action-pane.component'
import { EcologService, Ecolog } from '../ecolog.service'
import { GenericPageComponent } from '../../generic-page/generic-page.component'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-ecolog-page',
  templateUrl: './ecolog-page.component.html',
  styleUrls: ['../../generic-page/generic-page.component.css']
})
export class EcologPageComponent extends GenericPageComponent<Ecolog> implements OnInit {

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected override service: EcologService,
    protected sessionService: SessionService,
  ) {
    super(service) ; this.sorting = { field: 'serial', order: 'ASC' }
  }

  override onAction(action: Action) {
    if (action == Action.print)
      this.service.print(this.current, this.sorting, this.sessionService.rise())
    else
      super.onAction(action)
  }

  override ngOnInit() {
    this.onSearch({
      id: this.activatedRoute.snapshot.paramMap.get('id'),
      serial: this.activatedRoute.snapshot.paramMap.get('serial'),
    })
  }
}
