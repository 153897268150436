import { Component } from '@angular/core'

import { ContainerTypeService, ContainerType } from '../container-type.service'
import { GenericOverviewComponent } from '../../framework/generic-overview/generic-overview'

@Component({
  selector: 'app-container-type-overview',
  templateUrl: '../../framework/generic-overview/generic-overview.html',
  styleUrls: ['../../framework/generic-overview/generic-overview.css']
})
export class ContainerTypeOverviewComponent extends GenericOverviewComponent<ContainerType> {

  constructor(protected override service: ContainerTypeService) { super(service) }
}
