<div class="list-header">
  <list-header class="width-15" [identifier]="'Identifier'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-25" [identifier]="'firstName'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-30" [identifier]="'Name'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [identifier]="'Status'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [identifier]="'Kind'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [identifier]="'Mode'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as accounts">
  <cdk-accordion-item *ngFor="let account of accounts; let index = index"
    #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span class="width-15">{{account.identifier}}</span>
      <span class="width-25">{{account.address.firstName}}</span>
      <span class="width-30">{{account.address.lastName}}</span>
      <span class="width-10">{{'account.' + account.status | translate}}</span>
      <span class="width-10">{{'account.' + account.kind | translate}}</span>
      <span class="width-10">{{'account.' + account.mode | translate}}</span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-account-view [account]="account" (accountChange)="onUpdate(index, $event)" (event)="onEvent($event)"></app-account-view>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count" #scroll><span *ngIf="total | async as total">{{count | async}} / {{total | big}}</span></div>