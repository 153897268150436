import { Component } from '@angular/core'

import { AccountService, Account } from '../account.service'
import { GenericOverviewComponent, Shortcut } from '../../framework/generic-overview/generic-overview'

@Component({
  selector: 'app-account-overview',
  templateUrl: '../../framework/generic-overview/generic-overview.html',
  styleUrls: ['../../framework/generic-overview/generic-overview.css']
})
export class AccountOverviewComponent extends GenericOverviewComponent<Account> {

  override sorter: Shortcut =  { filter: { }, name: 'Last modified',  sorting: { field: 'last_modification_date', order: 'DESC' }}

  override shortcuts: Shortcut[] = [
    { field: 'all', filter: {}, name: 'All' },
    { field: 'active', filter: { status: 'ACTIVE' }, name: 'Active accounts' },
    { field: 'closed', filter: { status: 'CLOSED' }, name: 'Closed accounts' },
    { field: 'prepaid', filter: { mode: 'PREPAY' }, name: 'Prepaid accounts' },
    { field: 'invoice', filter: { mode: 'INVOICE' }, name: 'Invoiced accounts' },
  ]

  constructor(protected override service: AccountService) { super(service) }
}