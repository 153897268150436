<div class="editor">
  <div class="form">
    <div *ngIf="subject.ecopoint" class="row">
      <app-text-field [style.width]="'46%'" label="EcoPoint" [mode]="mode"
        [value]="subject.ecopoint.location "></app-text-field>
      <app-text-field class="width-12" label="Periodicity" [mode]="mode"
        [value]="subject.periodicity"></app-text-field>
    </div>
    <div class="row">
      <app-text-field class="width-12" [label]="'Estimated Level'" [mode]="mode"
        [value]="subject.estimatedLevel ? subject.estimatedLevel + '%' : '-'"></app-text-field>
      <app-text-field class="width-15" [label]="'Estimated Volume'" [mode]="mode"
        [value]="subject.estimatedVolume ? subject.estimatedVolume + ' l' : '-'" ></app-text-field>
      <app-text-field class="width-15" [label]="'Estimated Weight'" [mode]="mode"
        [value]="subject.estimatedWeight ? (subject.estimatedWeight/1000 | number:'1.2-2') + ' kg' : '-'" ></app-text-field>
      <app-text-field class="width-12" [label]="'Final Level'" [mode]="mode"
        [value]="subject.finalLevel ? subject.finalLevel + '%' : '-'" ></app-text-field>
      <app-text-field class="width-15" [label]="'Final Volume'" [mode]="mode"
        [value]="subject.finalVolume ? subject.finalVolume + ' l' : '-'" ></app-text-field>
      <app-text-field class="width-15" [label]="'Final Weight'" [mode]="mode"
        [value]="subject.finalWeight ? (subject.finalWeight/1000 | number:'1.2-2') + ' kg' : '-'" ></app-text-field>
    </div>
  </div>
</div>