import { Component } from '@angular/core'

import { emptyObject } from '../../framework/objects'
import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'
import { TillService, Till } from '../till.service'

@Component({
  selector: 'app-till-page',
  templateUrl: './till-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css'],
})
export class TillPageComponent extends GenericPageComponent<Till> {

  till: Till = emptyObject

  constructor(protected override service: TillService) { super(service) }
}
