import { Component } from '@angular/core';

import { LocationService, Location } from '../location.service'
import { GenericOverviewComponent, Shortcut } from '../../generic-overview/generic-overview'

@Component({
  selector: 'app-location-overview',
  templateUrl: '../../generic-overview/generic-overview.html',
  styleUrls: ['../../generic-overview/generic-overview.css']
})
export class LocationOverviewComponent extends GenericOverviewComponent<Location>{

  constructor(protected override service: LocationService) { super(service) }
}
