<div class="ribbon">
  <mat-form-field appearance="fill">
    <input matInput [matDatepicker]="picker" [(ngModel)]="date" (dateInput)="onDate()">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
<div class="embedded-header">
  <list-header class="width-10 right" [identifier]="'Card'"></list-header>
  <list-header class="width-5"></list-header>
  <list-header class="width-15" [identifier]="'Level'"></list-header>
  <list-header class="width-10" [identifier]="'Balance'"></list-header>
</div>
<div *ngIf="blacklist | async as blacklist">
  <span>{{setLimits(blacklist)}}</span>
  <div *ngFor="let entry of blacklist.blacklist" class="list-item">
    <span class="width-10 numberItem">{{entry.serial}}</span>
    <span class="width-5"></span>
    <span class="width-15">{{entry.level}}</span>
    <span class="width-5 numberItem">{{entry.balance / 100 | number:'1.2'}}</span>
    <span *ngIf="entry.level == 'DARKLISTED'" class="width-10">< {{darkLimit}}</span>
    <span *ngIf="entry.level == 'GREYLISTED'" class="width-10">< {{greyLimit}}</span>
  </div>
</div>
