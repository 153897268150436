import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core'

import { GenericListComponent } from '../../generic-list/generic-list.component'
import { HistoryEntry, HistoryService } from '../history.service'
import { Mode } from '../../generic-form/generic-form.component'
import { Sorting } from '../../services/generic.service'

@Component({
  selector: 'app-history-view',
  templateUrl: './history-view.component.html',
  styleUrls: ['../../generic-page/generic-page.component.css']
})
export class HistoryViewComponent implements OnInit {

  Mode = Mode

  creation: boolean = false
  frozen: boolean = false
  kinds: string[] = ['COMMENT', 'AFTER_SALES', 'AFTER_SALES_RESELLER']
  sorting: Sorting = { field: 'timestamp', order: 'DESC'}

  entry: HistoryEntry = {
    id: '', comment: '', container: null, ecolog: null, kind: 'COMMENT', report: null, timestamp: new Date().toISOString(), user: null
  }

  @ViewChild('list') list !: GenericListComponent<HistoryEntry>

  @Input() object: any
  @Input() parent: 'CONTAINER' | 'ECOLOG' = 'CONTAINER'

  @Output() event = new EventEmitter<string>()

  constructor(protected service: HistoryService) { }

  ngOnInit() {
    if (this.parent == 'ECOLOG') {
      this.entry.kind = 'AFTER_SALES_EXIT'
      this.kinds = ['AFTER_SALES_EXIT']
    }
    this.update()
  }

  onAdd() {
    this.creation = true
    if (this.parent == 'CONTAINER') {
      this.entry.container = this.object
      this.entry.ecolog = this.object?.ecolog
    } else {
      this.entry.ecolog = this.object
      this.entry.container = this.object?.container
    }
    this.event.next('edit')
  }

  onEvent(event: string) {
    this.frozen = event == 'edit'
    this.creation = false
    if (event == 'add' || event == 'delete') {
      // The list must be refreshed when there is a new, updated or deleted entry.
      if (event == 'delete' && this.list) this.list.resetSelection()
      this.update()
      // Bubble up a cancel event to unfreeze parents.
      if (event == 'add') this.event.next('cancel')
    } else
      this.event.next(event)
  }

  update() {
    if (this.parent == 'CONTAINER')
      this.service.fetch({ containerId: this.object.id }, this.sorting)
    else
      this.service.fetch({ ecologId: this.object.id }, this.sorting)
  }
}
