<div [class]="spacing ? 'spacing' : 'buttons'" [ngSwitch]="mode">
  <span *ngIf="domain | write">
    <span *ngSwitchCase = "Mode.edition">
      <button mat-icon-button (click)="event.emit('cancel')"><mat-icon>clear</mat-icon></button>
      <button mat-icon-button [disabled]="!valid" (click)="event.emit('save')">
        <mat-icon [style.color]="valid ? 'green' : 'red'">check</mat-icon>
      </button>
    </span>
    <span *ngSwitchCase = "Mode.reading">
      <button mat-icon-button (click)="event.emit('edit')"><mat-icon>edit</mat-icon></button>
      <button *ngFor="let action of actions" mat-icon-button (click)="event.emit(action)"><mat-icon>{{action}}</mat-icon></button>
    </span>
    <span *ngSwitchCase = "Mode.readonly">
      <button mat-icon-button disabled><mat-icon>edit_off</mat-icon></button>
    </span>
    <span *ngSwitchCase = "Mode.action">
      <button *ngFor="let action of actions" mat-icon-button (click)="event.emit(action)"><mat-icon>{{action}}</mat-icon></button>
    </span>
  </span>
</div>
