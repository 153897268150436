import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'shorten'
})
export class ShortenPipe implements PipeTransform {

  transform(value: string | null | undefined, length: number): string {
    const maxLength = 1.3 * length
    if (value)
      if (value.length > maxLength )
        return value.substring(0, maxLength) + '...'
      else
        return value
    else
      return ''
  }
}
