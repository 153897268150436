import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'

import { EcologService, Blacklist, Ecolog } from '../ecolog.service'

@Component({
  selector: 'app-ecolog-blacklists',
  templateUrl: './ecolog-blacklists.component.html',
  styleUrls: ['../../generic-list/generic-list.component.css', './ecolog-blacklists.component.css']
})
export class EcologBlacklistsComponent implements OnInit {

  blacklist !: Observable<Blacklist>
  date: Date = new Date()
  darkLimit: string = ''
  greyLimit: string = ''
  idle: boolean = true

  @Input() ecolog !: Ecolog

  constructor(protected service: EcologService) { this.date.setDate(new Date().getDate() - 1) }

  ngOnInit() { this.onDate() }

  onDate() { this.blacklist = this.service.blacklist(this.ecolog.id, this.date) }

  setLimits(blacklist: Blacklist): string {
    this.greyLimit = (blacklist.greylistThreshold / 100).toFixed(2)
    this.darkLimit = (blacklist.darklistThreshold / 100).toFixed(2)
    return ''
  }
}
