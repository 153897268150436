import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { ContainerService, Container } from '../container/container.service'
import { DatabaseObject, Point } from '../objects'
import { FileSaverService } from 'ngx-filesaver'
import { GenericService, Sorting, toString } from '../services/generic.service'
import { HttpClient } from '@angular/common/http'
import { LanguageService } from '../services/language.service'
import { Location } from '../location/location.service'
import { PdfService } from '../services/pdf.service'
import { SpinnerService } from '../services/spinner.service'
import { DumpService, Dump } from '../../dump/dump.service'
import { SessionService } from '../services/session.service'
import { Truck } from '../truck/truck.service'

export interface Stop {
  container ?: Container
  dump ?: Dump
  id: string
  level: number
  type: string
  order: number | null
}

export interface Tour extends DatabaseObject {
  travelDistance: string
  duration: string
  executionDate: string | null
  flowType: DatabaseObject | null
  kind: string
  numberOfContainers: string
  startingPoint: DatabaseObject | Location | null
  stops: Stop[]
  truck: Truck | null
  validationDate: string | null
}

export function stopPosition(stop: Stop | null): Point {

  if (stop) {
    if (stop.container && stop.container.ecopoint)
      return stop.container.ecopoint.center
    else if (stop.dump)
      return stop.dump.position
  }

  return { x: 0, y: 0 }
}

@Injectable({
  providedIn: 'root'
})
export class TourService extends GenericService<Tour> {

  constructor(
    protected dumpService: DumpService,
    protected containerService: ContainerService,
    protected override fileSaverService: FileSaverService,
    protected override httpClient: HttpClient,
    protected languageService: LanguageService,
    protected pdfService: PdfService,
    protected override sessionService: SessionService,
    protected override spinner: SpinnerService,
  ) { super(fileSaverService, httpClient, sessionService, spinner) }

  endpoint(version: string = 'v1'): string { return this.sessionService.url('tours', version) }

  override fetch(filter: any, sorting: Sorting, page: number = 1) {
    if (filter.id)
      this.getOne(filter.id)
    else
      this.getAll({
        magicFilter: filter.magic ?? '',
        validated: filter.validated ?? '',
        orderBy: toString(sorting), 'page': page, 'per_page': 50
      })
  }

  getStops(tour: Tour, callback ?:(stops:Stop[]) => void) {
    this.containerService.getByIds(
      tour.stops.filter(stop => stop.type == 'CONTAINER').map(stop => { return stop.id }), tour.executionDate,
      containers => {
      this.dumpService.getAll({}, dumps => {
        for (let stop of tour.stops) {
          if (stop.type == 'CONTAINER') {
            stop.container = containers.find(container => container.id == stop.id)
            if (stop.container) stop.level = stop.container.level
          }
          if (stop.type == 'DUMP') stop.dump = dumps.find(dump => dump.id = stop.id)
        }
        if (tour.stops.length && tour.stops[0].order == null) tour.stops.sort(
          (a: Stop, b: Stop) => { return this.location(a).localeCompare(this.location(b)) }
        )
        if (callback) callback(tour.stops)
      })
    })
  }

  getSelection(tourId: string, callback: (selection: Stop[]) => void) {
    this.httpClient.get<Stop[]>(this.endpoint() + '/' + tourId + '/stops').subscribe(
      stops => callback(stops)
    )
  }

  format(stage: Stop | null): any[] {
    if (stage && stage.container)
      return [
        stage.order ?? '',
        stage.container.identifier,
        stage.container.locality?.name,
        stage.container.ecopoint?.location,
        stage.container.ecopoint?.center.x.toFixed(5),
        stage.container.ecopoint?.center.y.toFixed(5),
        '', '', ''
      ]
    else if (stage && stage.dump)
      return [
        stage.order ?? '',
        stage.dump.identifier,
        stage.dump.name,
        '',
        stage.dump.position.x.toFixed(5),
        stage.dump.position.y.toFixed(5),
        '', '', ''
      ]
    else
      // Table header.
      return [
        '',
        this.languageService.translate('Identifier'),
        this.languageService.translate('Locality'),
        this.languageService.translate('Location'),
        this.languageService.translate('Position'),
        '',
        this.languageService.translate('Comment'),
        this.languageService.translate('Date'),
        this.languageService.translate('Visa'),
      ]
  }

  location(stop: Stop): string {
    if (stop.dump)
      return stop.dump.name
    else if (stop.container) {
      if (stop.container.stock)
        return stop.container.stock.name + stop.container.identifier
      else if (stop.container.ecopoint)
        return stop.container.ecopoint.location + stop.container.identifier
    }

    return ""
  }

  optimize(id: string) {
    return this.httpClient.post<Tour>(this.endpoint() + '/' + id + '/optimize', { })
  }

  print(tour: Tour) {

    const data: any[] = []

    // Include the table headers.
    data.push(this.format(null))

    for (let stop of tour.stops)
      data.push(this.format(stop))

    this.pdfService.pdf([
      {image: 'ecowaste', height: 35, width: 143}, '\n',
      {style: 'header', text: tour.name + ' - ' + tour.executionDate }, '\n',
      {
        layout: 'lightHorizontalLines',
        table: { body: data, fontSize: 20, headerRows: 1, widths: ['2%', '10%', '13%', '26%', '7%', '7%', '20%', '10%', '5%'] },
      },
    ], true)
  }

  override update(tour: Tour): Observable<Tour> {
    return this.putOne(tour.id, {
      containers: tour.stops.map(stop => { return { id: stop.id, ...stop.order && { order: stop.order }}}),
      ...tour.executionDate && { executionDate: this.sessionService.toISO(new Date(tour.executionDate)) },
      ...tour.flowType && { flowTypeId: tour.flowType.id },
      identifier: tour.identifier,
      kind: this.sessionService.wise() ? tour.kind : 'AFTER_SALES',
      name: tour.name,
      ...tour.startingPoint && { startingPointId: tour.startingPoint.id },
      ...tour.truck && { truckId: tour.truck.id },
    })
  }

  validate(tour: Tour): Observable<Tour> {
    return this.httpClient.put<Tour>(this.endpoint() + '/' + tour.id + '/validate',{
      validationDate: tour.validationDate ? this.sessionService.toISO(new Date(tour.validationDate)) : '',
      containers: tour.stops.map(stop => { return { id: stop.id, level: stop.level }}),
    })
  }
}
