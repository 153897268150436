<div class="criteria-pane">
  <h1>{{'Collections' | translate}}
    <span *ngIf="total | async as total">({{count | async}} / {{total | big}})</span>
  </h1>
  <div class="row">
    <mat-form-field class="width-30" appearance="fill">
      <mat-label>{{'Date range' | translate}}</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <!-- Manual date entry requires third party date adapter.-->
        <input matStartDate [(ngModel)]="filter.startDate" [placeholder]="'Start date' | translate" readonly>
        <input matEndDate [(ngModel)]="filter.endDate" [placeholder]="'End date' | translate" readonly>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <app-object-dropdown [label]="'Flow Type'" [(object)]="flowType" [objects]="flowTypes"></app-object-dropdown>
    <app-object-dropdown [label]="'Zone'" [(object)]="zone" [objects]="zones"></app-object-dropdown>
  </div>
  <div class="row">
    <app-ecopoint-select class="width-30" [ecopoint]="ecopoint" [mode]="Mode.edition" (ecopointChange)="onEcopoint($event)"></app-ecopoint-select>
    <mat-form-field *ngIf="containers | async as containers">
      <mat-label>{{"Container" | translate}}</mat-label>
      <input matInput [matAutocomplete]="auto" [(ngModel)]="container" (ngModelChange)="doFilterContainers()" type="text" >
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="containerRef">
        <mat-option *ngFor="let container of containers" [value]="container">{{container.identifier}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="row right">
    <button mat-flat-button (click)="onClear()">{{'Clear' | translate}}</button>
    <button mat-flat-button (click)="onSearch()">{{'Search' | translate}}</button>
    <button mat-flat-button (click)="onExport()">{{'Export' | translate}}</button>
  </div>
</div>
