import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Observable} from 'rxjs'

import { ClientDropdownComponent } from '../../components/client-dropdown/client-dropdown.component'
import { ReportService } from '../report.service'
import { SessionService, Client } from '../../services/session.service'

@Component({
  selector: 'app-report-criteria-form',
  templateUrl: './report-criteria-form.component.html',
  styleUrls: ['../../generic-criteria/generic-criteria.css']
})
export class ReportCriteriaFormComponent implements OnInit {

  // Client selection dropdown for RISE.
  client: Client | null = null

  count: Observable<number>
  filter: any = { }
  total: Observable<string | null>

  @Output() criteria = new EventEmitter()
  @Output() action = new EventEmitter<void>()

  @ViewChild(ClientDropdownComponent) dropdown !: ClientDropdownComponent

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected service: ReportService,
    protected sessionService: SessionService,
  ) {
    this.count = service.count()
    this.total = service.total()
  }

  ngOnInit() {
    this.filter = {id: this.activatedRoute.snapshot.paramMap.get('id')}
    // Dirty fix as the report page doesn't update when triggered immediately.
    setTimeout(() => this.onSearch(), 500)
  }

  onAdd() {}

  onClear() {
    this.filter = { endDate: null, startDate: null, status: null }
    if (this.dropdown) this.dropdown.clear()
  }

  onClient(client: Client | null) { this.client = client ; this.onSearch() }

  onSearch() {
    this.filter.clientId = this.client?.id
    this.criteria.emit(this.filter)
  }

  statuses(): string[] { return this.service.statuses() }
}
