<div class="pane-container">
  <app-account-overview #overview [sorting]="sorting.field" title="Accounts"
    (selection)="onShortcut($event)"></app-account-overview>
  <app-action-pane domain="ACCOUNTS" [export]="true" [hint]="['Identifier', 'firstName', 'Name']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event })"></app-action-pane>
  <app-account-filter (filterChange)="onFilter($event)"></app-account-filter>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-account-form [creation]="true" [mode]="Mode.edition" [object]="account" (event)="onEvent($event)"></app-account-form>
</ng-template>
<ng-template #showList>
  <app-account-list #list [sorting]="sorting" (event)="onEvent($event)" (page)="onScroll()" (sort)="onSort($event)"></app-account-list>
</ng-template>
