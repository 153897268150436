import { Component } from '@angular/core'
import { Observable } from 'rxjs'

import { LanguageService } from '../../framework/services/language.service'
import { SessionService, Session } from '../../framework/services/session.service'

interface MenuItem {
  domain: string,
  link: string,
  name: string,
  translation: string,
}

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css']
})
export class MenuBarComponent {

  adminMenu: MenuItem[] = [
    { domain: 'WISE', link: '/batchs', name: 'Batches', translation: '' },
    { domain: 'WISE', link: '/categories', name: 'Categories', translation: '' },
    { domain: 'WISE', link: '/closures', name: 'Closures', translation: '' },
    { domain: 'WISE', link: '/container-types', name: 'Container Types', translation: '' },
    { domain: 'WISE', link: '/dumps', name: 'Dumps', translation: '' },
    { domain: 'WISE', link: '/flow-types', name: 'Flow Types', translation: '' },
    { domain: 'WISE', link: '/groupings', name: 'Groupings', translation: '' },
    { domain: 'WISE', link: '/localities', name: 'Localities', translation: '' },
    { domain: 'WISE', link: '/locations', name: 'Locations', translation: '' },
    { domain: 'WISE', link: '/roles', name: 'Roles', translation: '' },
    { domain: 'WISE', link: '/stocks', name: 'Stocks', translation: '' },
    { domain: 'WISE', link: '/tills', name: 'Tills', translation: '' },
    { domain: 'WISE', link: '/trucks', name: 'Trucks', translation: '' },
    { domain: 'SETTINGS', link: '/users', name: 'Users', translation: '' },
    { domain: 'WISE', link: '/zones', name: 'Zones', translation: '' },
  ]

  searchMenu: MenuItem[] = [
    { domain: 'ACCOUNTS', link: '/cards', name: 'Cards', translation: '' },
    { domain: 'LOCATIONS', link: '/collections', name: 'Collections', translation: '' },
    { domain: 'WISE', link: '/waste', name: 'Waste', translation: '' },
    { domain: 'LOCATIONS', link: '/reports', name: 'Reports', translation: '' },
    { domain: 'ACCOUNTS', link: '/transactions', name: 'Transactions', translation: '' },
  ]

  session: Observable<Session | null>

  constructor(
      protected sessionService: SessionService,
      protected languageService: LanguageService,
  ) {
    this.session = this.sessionService.session()
    this.languageService.language().subscribe(language => {
      this.translate(this.adminMenu)
      this.translate(this.searchMenu)
    })
  }

  translate(items: MenuItem[]) {
    for (let item of items) item.translation = this.languageService.translate(item.name)
    items.sort((a, b) => { return a.translation.localeCompare(b.translation) })
  }
}
