import { Component } from '@angular/core';

import { CollectionService, Collection } from '../collection.service'
import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'

@Component({
  selector: 'app-collection-page',
  templateUrl: './collection-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css']
})
export class CollectionPageComponent extends GenericPageComponent<Collection> {

  constructor(protected override service: CollectionService) {
    super(service) ; this.sorting = { field: 'timestamp', order: 'DESC' }
  }
}
