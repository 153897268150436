import { AfterViewInit, Component, Input, ViewChild } from '@angular/core'
import { GoogleMap, MapInfoWindow } from '@angular/google-maps'

import { Container } from '../../container/container.service'
import { Point } from '../../objects'
import { SessionService } from '../../services/session.service'
import { TourService, Tour } from '../tour.service'
import { stopMarker } from '../../utilities/marker'

import Marker = google.maps.Marker

@Component({
  selector: 'app-stop-map',
  templateUrl: './stop-map.component.html',
  styleUrls: ['./stop-map.component.css']
})
export class StopMapComponent implements AfterViewInit {

  @Input() tour !: Tour

  @ViewChild(GoogleMap) map !: GoogleMap
  @ViewChild(MapInfoWindow, { static: false }) info !: MapInfoWindow

  options: google.maps.MapOptions = {
    center: { lng: 0, lat: 0 },
    clickableIcons: true,
    minZoom: 8, maxZoom:20, zoom: 14,
    styles: [
      { featureType: "poi", elementType: "labels.text", stylers: [{ visibility: "off" }] },
      { featureType: "poi", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    ]
  }

  center: Point
  content: string = ''

  constructor(
    protected sessionService: SessionService,
    protected service: TourService,
  ) {
    this.center = this.sessionService.defaultPosition()
    this.options.center = this.convert(this.center)
  }

  convert(point: Point): google.maps.LatLngLiteral { return { lng: point.x, lat: point.y } }

  ngAfterViewInit() {
    this.service.getSelection(this.tour.id, stops => {
      this.tour.stops = stops
      this.service.getStops(this.tour, stops => {
        let previous = ''
        let bounds = new google.maps.LatLngBounds()
        for (let stop of stops)
          if (stop.dump || stop.container && stop.container.ecopoint && stop.container.ecopoint.id != previous) {
            let marker: Marker = stopMarker(stop, this.map)
            let position = marker.getPosition()
            if (position) bounds.extend(position)
            if (stop.container && stop.container.ecopoint) previous = stop.container.ecopoint.id
          }
        if (this.map) this.map.fitBounds(bounds)
      })
    })
  }

  onContainer(marker: any, container ?: Container) {
    console.log(marker, container)
    if (container) {
      this.content = container.identifier + (container.ecopoint ? ' - ' + container.ecopoint.location : '')
      if (this.info) this.info.open(marker)
    }
  }
}
