import { Component } from '@angular/core'

import { Ecopoint, EcopointService } from '../ecopoint.service'
import { GenericListComponent } from '../../framework/generic-list/generic-list.component'

@Component({
  selector: 'app-ecopoint-list',
  templateUrl: './ecopoint-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class EcopointListComponent extends GenericListComponent<Ecopoint> {

  constructor(protected override service: EcopointService) { super(service) }
}
