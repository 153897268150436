<div class="criteria-pane">
  <h1>{{'Waste' | translate}}
    <span *ngIf="filter.accountId" > : <a routerLink="/accounts/{{filter.accountId}}">{{account}}</a></span>
    <span *ngIf="total | async as total">({{count | async}} / {{total | big}})</span>
  </h1>
  <div class="row">
    <mat-form-field class="width-30" appearance="fill">
      <mat-label>{{'Date range' | translate}}</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <!-- Manual date entry requires third party date adapter.-->
        <input matStartDate [(ngModel)]="filter.startDate" [placeholder]="'Start date' | translate" readonly>
        <input matEndDate [(ngModel)]="filter.endDate" [placeholder]="'End date' | translate" readonly>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <app-object-dropdown label="Flow Type" [mode]="flowType ? Mode.readonly : Mode.edition" [objects]="flowTypes" [(object)]="flowType"></app-object-dropdown>
    <app-object-dropdown label="Accounts Zone" [mode]="accountZone ? Mode.readonly : Mode.edition"
      [objects]="accountZones" [(object)]="accountZone"></app-object-dropdown>
    <mat-form-field>
      <mat-label>{{"Card" | translate}}</mat-label>
      <input matInput [(ngModel)]="filter.cardSerial" (keydown.enter)="onSearch()">
      <mat-hint class="hint">{{'Serial number' | translate}}</mat-hint>
    </mat-form-field>
  </div>
  <div class="row">
    <app-ecopoint-select class="width-30" [ecopoint]="ecopoint" [mode]="Mode.edition" (ecopointChange)="onEcopoint($event)"></app-ecopoint-select>
    <mat-form-field *ngIf="containers">
      <mat-label>{{"Container" | translate}}</mat-label>
      <input matInput [matAutocomplete]="auto" [(ngModel)]="container" (ngModelChange)="doFilterContainers()" type="text" >
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="containerRef">
        <mat-option *ngFor="let container of containers" [value]="container">{{container.identifier}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <app-object-dropdown label="Locations Zone" [mode]="locationZone ? Mode.readonly : Mode.edition"
      [objects]="locationZones" [(object)]="locationZone"></app-object-dropdown>
  </div>
  <div class="row right">
    <button mat-flat-button (click)="onClear()">{{'Clear' | translate}}</button>
    <button mat-flat-button (click)="onSearch()">{{'Search' | translate}}</button>
    <button mat-flat-button (click)="onPrint()">{{'Print' | translate}}</button>
    <button mat-flat-button [matMenuTriggerFor]="grouping">{{'Export' | translate}}</button>
    <mat-menu #grouping="matMenu">
      <button mat-menu-item (click)="onExport('')">{{'Without grouping' | translate}}</button>
      <button mat-menu-item *ngIf="'ACCOUNTS' | read" [matMenuTriggerFor]="fullOrNot">{{'per Account' | translate}}</button>
      <button mat-menu-item (click)="onExport('CARD')">{{'per Card' | translate}}</button>
      <button mat-menu-item (click)="onExport('CONTAINER')">{{'per Container' | translate}}</button>
      <button *ngIf="export" mat-menu-item (click)="onExport(export)">{{export}}</button>
    </mat-menu>
    <mat-menu #fullOrNot="matMenu">
      <button mat-menu-item (click)="onExport('ACCOUNT_WITHOUT_WASTE')">{{'all Accounts' | translate}}</button>
      <button mat-menu-item (click)="onExport('ACCOUNT')">{{'with Waste' | translate}}</button>
    </mat-menu>
  </div>
</div>
