import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import { Observable } from 'rxjs'

import { Container} from '../../framework/container/container.service'
import { WasteService} from '../../waste/waste.service'

@Component({
  selector: 'app-container-waste',
  templateUrl: './container-waste.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css']
})
export class ContainerWasteComponent implements OnInit {

  count !: Observable<number>
  total !: Observable<string | null>

  @Input() container !: Container
  @Output() event = new EventEmitter<string>()

  constructor(protected wasteService: WasteService) { wasteService.clear() }

  ngOnInit() {
    this.wasteService.fetch({containerId: this.container.id}, { field: 'timestamp', order: 'DESC' })
  }

  // Edition events from waste list must be bubbled up to container view.
  onEvent(event: string) { this.event.next(event) }
}
