<div class="editor embedded">
  <div class="form" (focusout)="check()">
    <div class="row">
      <app-dropdown class="width-25" label="Status" [prefix]="'account'" [mode]="mode"
        [(value)]="subject.status" [values]="['ACTIVE', 'BLOCKED', 'CLOSED']"></app-dropdown>
      <app-dropdown class="width-25" label="Type" [prefix]="'account'" [mode]="mode"
        [(value)]="subject.kind" [values]="['ADMINISTRATION', 'COLLECTIVE', 'ENTERPRISE', 'PERSON']"></app-dropdown>
      <app-dropdown class="width-25" label="Mode" [prefix]="'account'" [mode]="mode"
        [(value)]="subject.mode" [values]="['INVOICE', 'PREPAY']"></app-dropdown>
      <mat-form-field *ngIf="mode != Mode.edition" style="width: 19%">
        <mat-label>{{'Balance' | translate}}</mat-label>
        <input matInput class="input numeric" disabled value="{{subject.balance | number: '1.2'}}">
      </mat-form-field>
    </div>
    <div class="row">
      <app-text-field class="width-25" label="Identifier" [length]="10" [mode]="mode"
        [(value)]="subject.identifier"></app-text-field>
      <app-object-dropdown class="width-25" label="Category" [(object)]="subject.category"
        [objects]="categories" [mode]="mode"></app-object-dropdown>
      <app-object-dropdown class="width-25" label="Zone" [(object)]="subject.zone"
        [objects]="zones" [mode]="zone ? Mode.readonly : mode"></app-object-dropdown>
    </div>
    <div class="row">
      <app-text-field class="width-100" label="Comment" [length]="200" [mode]="mode"
        [(value)]="subject.comment"></app-text-field>
    </div>
    <app-address-form [address]="subject.address" [mode]="mode" [title]="'Home address'" (change)="check()"></app-address-form>
    <app-address-form [address]="subject.billingAddress" [country]=true [mode]="mode" [title]="'Billing address'"></app-address-form>
  </div>
  <app-form-buttons [actions]="(subject.status != 'CLOSED' ? ['lock'] : ('AFTER_SALES' | write) ? ['delete'] : [])"
    domain="ACCOUNTS" [mode]="mode" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
</div>
