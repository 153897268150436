import { Component } from '@angular/core'

import { GenericOverviewComponent, Shortcut } from '../../generic-overview/generic-overview'
import { EcologService, Ecolog } from '../ecolog.service'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-ecolog-overview',
  templateUrl: '../../generic-overview/generic-overview.html',
  styleUrls: ['../../generic-overview/generic-overview.css']
})
export class EcologOverviewComponent extends GenericOverviewComponent<Ecolog> {

  override shortcuts: Shortcut[] = [
    { field: 'all', filter: {}, name: 'All' },
    { field: 'received', filter: { state: 'RECEIVED'}, name: 'Communicating' },
    { field: 'awaited', filter: { state: 'AWAITED' }, name: 'Communication awaited' },
    { field: 'missing', filter: { state: 'MISSING' }, name: 'Silent' },
  ]

  constructor(
    protected sessionService: SessionService,
    override service: EcologService
  ) {
    super(service)
    if (sessionService.rise()) this.shortcuts.push({ field: 'reseller', filter: { stock: 'RESELLER' }, name: 'Reseller stock' })
  }
}
