import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core'
import {Subscription, take} from 'rxjs'

import { DatabaseObject } from '../../framework/objects'
import { LanguageService } from '../../framework/services/language.service'
import { LocalityService, Locality } from '../../framework/locality/locality.service'
import { Mode } from '../../framework/generic-form/generic-form.component'
import { SessionService } from '../../framework/services/session.service'
import { ZoneService, Zone } from '../../zone/zone.service'

@Component({
  selector: 'app-ecopoint-filter',
  templateUrl: './ecopoint-filter.component.html',
  styleUrls: ['../../framework/generic-filter/generic-filter.component.css']
})
export class EcopointFilterComponent implements OnDestroy {

  Mode = Mode

  localities: Locality[] = []
  locality: Locality | null = null
  subscription: Subscription
  userZone: Zone | null = null
  zone: Zone | null = null
  zones: Zone[] = []

  @Input() filter: any = { zoneId: null }
  @Output() filterChange = new EventEmitter()

  constructor(
    protected languageService: LanguageService,
    protected localityService: LocalityService,
    protected sessionService: SessionService,
    protected zoneService: ZoneService
  ) {
    localityService.list(localities => this.localities = localities)
    zoneService.list(zones => this.zones = zones,'LOCATION', languageService.translate('Without'))
    this.subscription = sessionService.session().subscribe(session => {
      if (session) {
        this.userZone = session.user.locationZone
        this.zone = session.user.locationZone
        this.onZone(this.zone)
      }
    })
  }

  ngOnDestroy() { this.subscription.unsubscribe() }

  onLocality(locality: DatabaseObject | null) {
    this.filter.localityId = locality?.id
    this.filterChange.emit(this.filter)
  }

  onZone(zone: DatabaseObject | null) {
    this.filter.zoneId = zone?.id
    this.filterChange.emit(this.filter)
  }
}
