import { Component } from '@angular/core'

import { FlowTypeService, FlowType } from '../../framework/flow-type/flow-type.service'
import { GenericOverviewComponent } from '../../framework/generic-overview/generic-overview'

@Component({
  selector: 'app-flow-type-overview',
  templateUrl: '../../framework/generic-overview/generic-overview.html',
  styleUrls: ['../../framework/generic-overview/generic-overview.css']
})
export class FlowTypeOverviewComponent extends GenericOverviewComponent<FlowType>  {

  constructor(protected override service: FlowTypeService) { super(service) }
}
