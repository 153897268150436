import { Component } from '@angular/core'

import { GenericPageComponent } from '../../generic-page/generic-page.component'
import { RoleService, Role } from '../role.service'

@Component({
  selector: 'app-role-page',
  templateUrl: './role-page.component.html',
  styleUrls: ['../../generic-page/generic-page.component.css']
})
export class RolePageComponent  extends GenericPageComponent<Role> {

  role: Role = { id: '', identifier: '', name: '', domain: { read: [], write: [] }}

  constructor(protected override service: RoleService) {
    super(service) ;   this.sorting = { field: 'Name', order: 'ASC' }}
}
