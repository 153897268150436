<div class="pane-container">
  <app-batch-overview title="Batches" (selection)="onShortcut($event)"></app-batch-overview>
  <app-action-pane domain="MANUFACTURER" [hint]="['Identifier']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event})"></app-action-pane>
  <app-batch-filter *ngIf="sessionService.rise()" (filterChange)="onFilter($event)"></app-batch-filter>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-batch-form [creation]="true" [mode]="Mode.edition" [object]="batch" (event)="onEvent($event)"></app-batch-form>
</ng-template>
<ng-template #showList>
  <app-batch-list #list [sorting]="sorting" (event)="onEvent($event)" (page)="onScroll()" (sort)="onSort($event)"></app-batch-list>
</ng-template>
