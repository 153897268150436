import {Component, EventEmitter, Input, Output } from '@angular/core'

import { Tour } from '../tour.service'

@Component({
  selector: 'app-tour-view',
  templateUrl: './tour-view.component.html',
  styleUrls: ['../../generic-view/generic-view.css']
})
export class TourViewComponent {

  edition: boolean = false

  @Input() tour !: Tour
  @Output() event = new EventEmitter<string>()
  @Output() tourChange = new EventEmitter<Tour>()

  onEvent(event: string) { this.edition = event == 'edit' ; this.event.next(event) }

}
