import { Component } from '@angular/core'

import { GenericFormComponent, Mode } from '../../generic-form/generic-form.component'
import { DatabaseObject } from '../../objects'
import { ContainerService, Container } from '../../container/container.service'
import { ReportService, Position, Report, Technician } from '../report.service'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['../../generic-form/generic-form.component.css', './report-form.component.css']
})
export class ReportFormComponent extends GenericFormComponent<Report> {

  Mode = Mode

  allContainers: Container[] = []
  allTemplates: Position[] = []
  container: any = ''                   // Either container filter or selected object.
  containers: Container[] = []
  filter: any = ''
  templates: Position[] = []
  technicians: Technician[] = []

  constructor(
    protected containerService: ContainerService,
    protected override service: ReportService,
    protected sessionService: SessionService,
  ) { super(service) }

  containerRef(container: Container): string {
    return container ? container.identifier + (container.ecopoint ? ' - ' + container.ecopoint.location : '') :''
  }

  diagnostics(): string[] { return this.service.diagnostics() }

  doFilterContainers() {
    this.containers = this.allContainers.filter(container => {
      return container.identifier.toLowerCase().includes(this.container) })
    this.check()
  }

  doFilterTemplates() {
    this.templates= this.allTemplates.filter(template => {
      return template.name.toLowerCase().includes(this.filter) || template.description.toLowerCase().includes(this.filter)
    })
  }

  override initializeForm() {
    super.initializeForm()

    // Technician needs a name field (not returned by the endpoint) to be displayed in a dropdown.
    if (this.subject.technician) {
      this.subject.technician.name = this.subject.technician.username
      this.technicians = [this.subject.technician]
    }

    // Copy positions (to be able to cancel changes) and convert time values from minutes to HH:MM.
    this.subject.positions = []
    for (let index = 0; index < this.object.positions.length; index++) {
      let position: Position = { ...this.object.positions[index] }
      this.subject.positions.push(position)
      if (position.units.indexOf(':') == 2)
        position.value = new Date(+position.value * 60000).toISOString().slice(11,16)
    }
  }

  addTimeAndDistance(value: string | undefined) {
    const labour = this.templates.find(position => position.units == 'HH:MM')
    if (labour) this.subject.positions.push({ ...labour, value: '00:30' })
    const distance = this.templates.find(position => position.units == 'km')
    if (distance) this.subject.positions.push({ ...distance, ...(value && { value : value }) })
  }

  override isValid(): boolean {
    return (this.subject.container != null || typeof this.container == 'object') && this.subject.interventionDate > ''
  }

  invoiceable(): boolean { return this.subject.handling > '' && this.subject.handling != 'INTERNAL' }

  override load() {

    // When creating a report without a request, a container must be selected (but cannot be changed).
    if (this.object.request == null && this.object.container == null)
      this.containerService.list(containers => { this.allContainers = containers ; this.doFilterContainers() },'identifier')

    this.service.technicians(technicians => this.technicians = technicians)

    this.service.templates(positions => {
      this.allTemplates = positions
      this.templates = positions
      if (this.object.status == 'CREATED') {
        this.addTimeAndDistance(this.object.locality?.distance)
        this.subject.technician = this.service.technician()
        this.subject.status = 'EDITED'
      }
    })
  }

  onAdd(id: string) {
    const template = this.allTemplates.find(position => position.id == id)
    if (template) this.subject.positions.push({ ...template })
    this.filter = ''
    this.templates = this.allTemplates
  }

  onAddByEvent(event: any) { this.onAdd(event.target.value) }

  onAddByOption(event: any) { this.onAdd(event.option.value.id) }

  onAddTimeAndDistance() {
    if (this.container.locality) this.addTimeAndDistance(this.container.locality.distance)
  }

  onClear(index: number) { this.subject.positions.splice(index,1) }

  override onEvent(event: string) {
    if (event == 'print')
      this.service.print(this.object)
    else if (event == 'download')
      this.service.export(this.object)
    else
      super.onEvent(event)
  }

  onTechnician(object: DatabaseObject | null) { this.subject.technician = object as Technician }

  statuses(): string[] {
    const list = this.service.statuses()
    // Status may only follow the flow defined in the status list.
    return list.slice(Math.max(1, list.indexOf(this.object.status)))
  }

  templateRef(template: Position): string {
    return template ? template.name + ' - ' + template.description : ''
  }

  override updateSubject() {
    // When a creating a report without request, a container must be assigned.
    if (this.subject.container == null) this.subject.container = this.container
  }
}
