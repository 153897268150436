import { Component } from '@angular/core'

import { SessionService } from '../../services/session.service'
import { GenericPageComponent } from '../../generic-page/generic-page.component'
import { Tour, TourService } from '../tour.service'

@Component({
  selector: 'app-tour-page',
  templateUrl: './tour-page.component.html',
  styleUrls: ['../../generic-page/generic-page.component.css']
})
export class TourPageComponent  extends GenericPageComponent<Tour> {

  tour: Tour = { travelDistance: '', duration: '', executionDate: new Date().toISOString(), flowType: null, id: '',
    validationDate: '', identifier: '', kind: 'COLLECTION', name: '', numberOfContainers: '', stops: [],
    startingPoint: null, truck: null
  }

  constructor(
    protected sessionService: SessionService,
    protected override service: TourService
  ) {
    super(service)
    this.current = { validated: false }
    this.sorting = { field: 'executionDate', order: 'DESC' }
    if (sessionService.rise()) this.tour.kind = 'AFTER_SALES'
  }

  override onEvent(event: string) {
    if (event == 'add') this.sorting = { field: 'executionDate', order: 'DESC' }
    super.onEvent(event)
  }
}
