import { Component } from '@angular/core'
import { Observable, take } from 'rxjs'

import { FlowTypeService, FlowType } from '../../framework/flow-type/flow-type.service'
import { Mode } from '../../framework/generic-form/generic-form.component'
import { DatabaseObject } from '../../framework/objects'
import { RoleService, Role } from '../../framework/role/role.service'
import { SessionService, Session, User } from '../../framework/services/session.service'
import { ZoneService, Zone } from '../../zone/zone.service'

function isRole(object: any): object is Role { return true }

@Component({
  selector: 'app-parameter-form',
  templateUrl: './parameter-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class ParameterFormComponent {

  Mode = Mode

  accountZones: Zone[] = []
  domain: string = ''
  flowTypes: FlowType[] = []
  locationZones: Zone[] = []
  session: Observable<Session | null>
  roles: Role[] = []
  user: User | undefined

  constructor(
    protected flowTypeService: FlowTypeService,
    protected roleService: RoleService,
    protected sessionService: SessionService,
    protected zoneService: ZoneService,
  ) {
    this.session = sessionService.session()
    this.user = sessionService.user()
    flowTypeService.list(flowTypes => this.flowTypes = flowTypes)
    roleService.list(roles => this.roles = roles)
    zoneService.list(zones => this.accountZones = zones,'ACCOUNT')
    zoneService.list(zones => this.locationZones = zones,'LOCATION')
  }

  onAccountZone(zone: DatabaseObject | null) { this.sessionService.set('accountZone', zone) }

  onLocationZone(zone: DatabaseObject | null) { this.sessionService.set('locationZone', zone) }

  onFlowType(flowType: DatabaseObject | null) { this.sessionService.set('flowType', flowType) }

  onRemove() { this.sessionService.removeDomainFromRole(this.domain) }

  onRole(role: DatabaseObject | null) { if (isRole(role)) this.sessionService.setRole(role) }

  onUpdate() { if (this.user) this.sessionService.update(this.user.subscriptions) }
}
