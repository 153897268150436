import { Injectable } from '@angular/core'

import { DatabaseObject } from '../framework/objects'
import { GenericService } from '../framework/services/generic.service'

export type Category = DatabaseObject

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends GenericService<Category> {

  endpoint(version: string = 'v1'): string { return this.sessionService.url('categories', version) }

  override list(callback: (categories: Category[]) => void, order: string = 'name', without: string = '') {
    this.httpClient.get<Category[]>(this.endpoint(),{
      params: { orderBy: order + ';ASC' } }).subscribe(categories => {
      if (categories.length && without) categories.unshift({id: '0', identifier: '', name: without })
      callback(categories)
    })
  }
}
