<div class="ribbon">
  <button mat-icon-button [disabled]="!idle" (click)="onAdd()"><mat-icon>add</mat-icon></button>
  <button mat-icon-button [disabled]="!idle" (click)="onGenerate()"><mat-icon [class]="idle ? '' : 'spin'">sync</mat-icon></button>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-commands-form [ecologId]="ecolog.id" (event)="onEvent($event)"></app-commands-form>
</ng-template>
<ng-template #showList>
  <app-commands-list [ecolog]="ecolog"></app-commands-list>
</ng-template>
