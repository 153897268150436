<div class="list-header">
  <list-header class="width-20" [identifier]="'Identifier'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-80" [identifier]="'Name'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as categories">
  <cdk-accordion-item *ngFor="let category of categories; let index = index"
      #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span class="width-20">{{category.identifier}}</span>
      <span class="width-80">{{category.name}}</span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-category-form [object]="category" (event)="onEvent($event)" (objectChange)="onUpdate(index, $event)"></app-category-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count"><span *ngIf="total | async as total">{{count | async}} / {{total}}</span></div>
