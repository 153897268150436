import { Component } from '@angular/core'

import { GenericOverviewComponent } from '../../generic-overview/generic-overview'
import { TruckService, Truck} from '../truck.service'

@Component({
  selector: 'app-truck-overview',
  templateUrl: '../../generic-overview/generic-overview.html',
  styleUrls: ['../../generic-overview/generic-overview.css']
})
export class TruckOverviewComponent extends GenericOverviewComponent<Truck> {

  constructor(override service: TruckService) { super(service) }
}
