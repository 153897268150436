import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework.module'

import { ReportCriteriaFormComponent } from './report-criteria-form/report-criteria-form.component'
import { ReportFormComponent } from './report-form/report-form.component'
import { ReportListComponent } from './report-list/report-list.component'
import { ReportPageComponent } from './report-page/report-page.component'

@NgModule({
  declarations: [
    ReportCriteriaFormComponent,
    ReportFormComponent,
    ReportListComponent,
    ReportPageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [ ReportPageComponent ]
})
export class ReportModule { }
