import { Component, Input } from '@angular/core'

import { SessionService } from '../../services/session.service'
import { Stop } from '../tour.service'

@Component({
  selector: 'app-stop-list',
  templateUrl: './stop-list.component.html',
  styleUrls: ['../../generic-list/generic-list.component.css']
})
export class StopListComponent {

  @Input() stops: Stop[] = []

  constructor(protected sessionService: SessionService) { }

  color(levelSource: string): string { return (levelSource == 'COLLECTION') ? '#5AA454' : '#C7B42C' }

  onMap(_stop: Stop, _index: number) { }

  rise(): boolean { return this.sessionService.rise() }
  wise(): boolean { return this.sessionService.wise() }
}
