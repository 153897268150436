import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { FileSaverService } from 'ngx-filesaver'

import { DateConverter } from '../framework/services/date-converter.service'
import { GenericService, Sorting, toString } from '../framework/services/generic.service'
import { DatabaseObject } from '../framework/objects'
import { LanguageService } from '../framework/services/language.service'
import { PdfService } from '../framework/services/pdf.service'
import { SpinnerService } from '../framework/services/spinner.service'
import { TranslateService } from '@ngx-translate/core'
import {SessionService} from "../framework/services/session.service";

export interface Account {
  id: string
  firstName: string
  identifier: string
  lastName: string
}

export interface Card {
  account ?: Account
  id: number
  label: string
  special: boolean
}

export interface Container {
  id: number
  identifier: string
}

interface Count {
  cost: number
  count: number
  flowType: string
  weight: number
}

export interface Waste {
  accountId ?: string,                  // Only for waste creation on a specific account.
  canceled: boolean
  card: Card | null
  container: Container | null
  flowType: DatabaseObject | null
  id: string
  modifiable: boolean
  price: number
  timestamp: string
  weight: number
}

@Injectable({
  providedIn: 'root'
})

export class WasteService extends GenericService<Waste> {

  constructor(
    protected override fileSaverService: FileSaverService,
    protected override httpClient: HttpClient,
    protected languageService : LanguageService,
    protected pdfService: PdfService,
    protected override sessionService: SessionService,
    protected override spinner: SpinnerService,
  ) { super(fileSaverService, httpClient, sessionService, spinner) }

  endpoint(version: string = 'v1'): string { return this.sessionService.url('waste', version) }

  export(filter: any, grouping: string) {
    this.exportToFile('', {
      accountId: filter.accountId ?? '',
      accountZoneId: filter.acountZoneId ?? '',
      cardSerial: filter.cardSerial ?? '',
      containerId: [filter.containerId ?? ''],
      ecopointId: filter.ecopointId ?? '',
      flowTypeId: filter.flowTypeId ?? '',
      startDate: this.sessionService.toISO(filter.startDate),
      endDate: this.sessionService.toISO(filter.endDate),
      orderBy: 'timestamp;ASC',
     groupBy: grouping
    })
  }

  override fetch(filter: any, sorting: Sorting, page: number = 1, max: number = 50, callback ?: () => void) {
    if (filter.startDate == undefined) this.spinner.show()
    this.getAll({
      accountZoneId: filter.accountZoneId ?? '',
      accountId: filter.accountId ?? '',
      cardSerial: filter.cardSerial ?? '',
      containerId: filter.containerId ?? '',
      ecopointId: filter.ecopointId ?? '',
      flowTypeId: filter.flowTypeId ?? '',
      locationZoneId: filter.locationZoneId ?? '',
      startDate: this.sessionService.toISO(filter.startDate),
      endDate: this.sessionService.toISO(filter.endDate),
      orderBy: toString(sorting), page: page, per_page: max
    }, () => {
      this.spinner.hide()
      if (callback) callback()
    })
  }

  formatCount(count: Count | null): any[] {
    if (count)
      return [
        count.flowType,
        { style: 'number', text: count.count },
        { style: 'number', text: count.weight.toFixed(2) },
        { style: 'number', text: count.cost.toFixed(2) },
      ]
    else
      return [
        this.languageService.translate('Flow Type'),
        { style: 'number', text: this.languageService.translate('Deposits') },
        { style: 'number', text: this.languageService.translate('Weight') + ' [kg]' },
        { style: 'number', text: this.languageService.translate('Price') },
      ]
  }

  format(waste: Waste | null): any[] {
    if (waste)
      return [
        this.languageService.timestamp(new Date(waste.timestamp)).substring(0,17),
        waste.container ? waste.container.identifier : '',
        waste.flowType ? waste.flowType.name : '',
        waste.card?.label,
        { style: 'number', text: waste.weight ? waste.weight.toFixed(2) : '' },
        { style: 'number', text: waste.price ? waste.price.toFixed(2) : '' },
      ]
    else
      // Table header.
      return [
        this.languageService.translate('timestamp'),
        this.languageService.translate('container_identifier'),
        this.languageService.translate('flowtype_name'),
        this.languageService.translate('card_serial'),
        { style: 'number', text: this.languageService.translate('Weight') + ' [kg]' },
        { style: 'number', text: this.languageService.translate('Price') },
      ]
  }

  print(filter: any, account: string | null) {

    this.spinner.show()
    this.fetch(filter,{field: 'timestamp', order: 'DESC' },1,6000, () => {

      const data: any[] = []
      const counts: Count[] = []

      // Include the table headers.
      data.push(this.format(null))

      for (let waste of this._objects.value) {
        if (!waste.canceled) {
          data.push(this.format(waste))
          let index = counts.findIndex(count => waste.flowType && count.flowType == waste.flowType.name)
          if (index < 0 && waste.flowType) index = counts.push({ flowType: waste.flowType.name, cost: 0, count: 0, weight: 0 }) - 1
          counts[index].count++
          counts[index].cost += waste.price
          counts[index].weight += waste.weight
        }
      }

      const summary: any[] = []
      const total: Count = { flowType: 'Total', cost: 0, count: 0, weight: 0}

      summary.push(this.formatCount(null))

      for (let count of counts) {
        total.cost += count.cost
        total.count += count.count
        total.weight += count.weight
        summary.push(this.formatCount(count))
      }

      summary.push(this.formatCount(total))

      this.pdfService.pdf([
        { image: 'ecowaste', height: 35, width: 143 },
        '\n',
        { style: 'header', text: this.languageService.translate('Waste extract') + (account ? ' - ' + account : '') },
        '\n',
        filter.startDate && filter.endDate ? (this.languageService.translate('Period' + ' : ')
          + this.languageService.datestamp(filter.startDate) + ' - '
          + this.languageService.datestamp(filter.endDate)) : '',
        '\n',
        {
          layout: 'lightHorizontalLines',
          table: { body: summary, fontSize: 20, headerRows: 1, widths: ['25%', '10%', '10%', '10%'] },
        },
        '\n',
        {
          layout: 'lightHorizontalLines',
          table: { body: data, fontSize: 20, headerRows: 1, widths: ['20%', '15%', '25%', '15%', '15%', '10%'] },
        },
      ])

      this.spinner.hide()
    })
  }

  override update(waste: Waste): Observable<Waste> {
    if (waste.id)
      return this.putOne(waste.id, { canceled: waste.canceled })
    else {
      return this.httpClient.post<Waste>(this.endpoint() + '/create-for-account', {
        accountId: waste. accountId,
        flowTypeId: waste.flowType?.id,
        creationDate: this.sessionService.toISO(new Date(waste.timestamp)),
        weight: waste.weight
      })
    }
  }

  yearly(account: number, year: number, sorting: Sorting) {
    this.getAll({
      accountId: account, startDate: year + '-01-01', endDate: year + '-12-31',
      orderBy: toString(sorting), page: 1, per_page: 50
    })
  }
}

