<div class="ribbon">
  <button mat-icon-button (click)="onAdd()" [disabled]="frozen"><mat-icon>add</mat-icon></button>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-history-entry-form [creation]="true" [kinds]="kinds" [mode]="Mode.edition" [object]="entry" (event)="onEvent($event)"></app-history-entry-form>
</ng-template>
<ng-template #showList>
  <history-list #list [id]="object.id" [kinds]="kinds" [parent]="parent" (event)="onEvent($event)"></history-list>
</ng-template>
