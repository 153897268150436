<div class="dialog">
  <div class="row split">
    <h1>{{'Password' | translate}}</h1>
    <button mat-icon-button [mat-dialog-close]="true"><mat-icon>close</mat-icon></button>
  </div>
  <div *ngIf="user">
    <mat-form-field>
      <mat-label>{{'Current password' | translate}}</mat-label>
      <input #one matInput [required]="true" type="password" (input)="oldPassword = one.value">
    </mat-form-field>
    <p>{{'password.constraints' | translate}}</p>
    <mat-form-field>
      <mat-label>{{'New password' | translate}}</mat-label>
      <input #two [disabled]="oldPassword == ''" matInput [required]="true" type="password" (input)="newPassword = two.value">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'New password (check)' | translate}}</mat-label>
      <input #three [disabled]="disableCheck()"  matInput [required]="true" [style.color]="newPassword.slice(0, passwordCheck.length)  == passwordCheck ? 'green' : 'red' " type="password"
        (input)="passwordCheck = three.value">
    </mat-form-field>
  </div>
  <div class="row right">
    <button mat-flat-button [mat-dialog-close]="true">{{'Cancel' | translate}}</button>
    <button mat-flat-button [disabled]="passwordCheck == '' || newPassword != passwordCheck" (click)="onClose()">{{'OK' | translate}}</button>
  </div>
</div>
