import { BehaviorSubject, Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import * as Sentry from '@sentry/angular-ivy'

import { DatabaseObject, Point } from '../objects'
import { LanguageService } from './language.service'
import { Report } from '../report/report.service'
import { SnackbarService } from './snackbar.service'

export interface Client extends DatabaseObject {
  center: Point
  comment: string
  configuration: Configuration
  country: string
  language: string
  regionalCode: string
  reseller: Reseller
  shortName: string
  status: string
}

export interface Configuration {
  wasteExportFormat: string | null
}

export interface Message {
  id: number
  location: string
  message: string
}

export interface Reseller {
  center: Point
  country: string
  id: string
  identifier: string
  language: string
  name: string
}

// Role can't be declared in RoleService because this causes a circular (file) dependency Angular can't manage.
export interface Role extends DatabaseObject {
  domain: {
    read: string[]
    write: string[]
  }
}

export interface Session {
  client: Client
  reseller: Reseller
  role: Role
  user: User
}

export interface SessionToken {
  access_token: string
}

export interface Subscriptions {
  access: string
  afterSales: string
  collection: string
  technical: string
}

// As Role User can't be declared in its service because this causes a dependency Angular can't manage.
export interface User {
  accountZone: DatabaseObject | null
  dashboards: string[]
  email: string
  id: string
  firstname: string
  flowType: DatabaseObject | null
  lastname: string
  locationZone: DatabaseObject | null
  role: DatabaseObject | null
  status: string
  till: DatabaseObject | null
  subscriptions: Subscriptions
  username: string
}

@Injectable({
  providedIn: 'root'
})
export abstract class SessionService {

  private isoFormatter  = new Intl.DateTimeFormat('fr-CA')

  protected _session = new BehaviorSubject<Session | null>(null)

  constructor(
    protected httpClient: HttpClient,
    protected languageService: LanguageService,
    protected router: Router,
    protected snackbar: SnackbarService,
  ) { }

  rise(): boolean { return false }
  rose(): boolean { return false }
  wise(): boolean { return false }

  changePassword(oldPassword: string, newPassword: string) {
    this.httpClient.put(this.endpoint() + "/change-password", {
      oldPassword: oldPassword,
      newPassword: newPassword
    }).subscribe( () => this.snackbar.info('password.changed'))
  }

  client(): Client | undefined { return this._session.value?.client }

  clients(callback: (clients: Client[]) => void, _status ?: string[]) {
    this.httpClient.get<Client[]>('/ipServer/wise/public/api/clients').subscribe(
      clients => callback(clients))
  }

  defaultPosition(): Point {
    const client = this._session.value?.client
    if (client)
      return client.center
    else
      return { x: 0, y: 0 }
  }

  endpoint(): string { return this.url('session') }

  getMessages(callback: (messages: Message[]) => void) {
    this.languageService.language().subscribe(language =>
      this.httpClient.get<Message[]>('/ipServer/wise/public/api/v1/application-messages', {
        params: { application: 'WISE', language: language.toUpperCase(), page: 1, perPage: 2 }})
        .subscribe(messages => callback(messages))).unsubscribe()
  }

  // Trades a RISE authentication token for a WISE authentication token.
  link(clientId: string, url: string) {
    this.httpClient.post<SessionToken>(this.endpoint() + '/authenticate/wise',{ clientId: clientId })
      .subscribe(token => window.open('https://wise.ecowaste.ch/link/' + token.access_token + '/' + url,'_blank'))
  }

  loadSession() {
    this.httpClient.get<Client>(this.endpoint() + "/client").subscribe(client => {
      this.httpClient.get<User>(this.endpoint() + "/user").subscribe(user => {
        this.httpClient.get<Role>(this.endpoint() + "/role").subscribe(role => {
          Sentry.setUser({ id: user.id })
          this.languageService.select(client.language)
          this._session.next({ client: client, reseller: client.reseller, role: role, user: user })
        })
      })
    })
  }

  login(identifier: string, username: string, password:string) {
    this.httpClient.post<SessionToken>(
      '/ipServer/wise/public/wise/v1/authenticate',
      {'client': identifier, 'password': password, 'username': username}
    ).subscribe({ next: (token: SessionToken) => this.route(token.access_token, '/') })
  }

  logout(silent: boolean = false) {
    sessionStorage.removeItem('token')
    this._session.next(null)
    if (!silent) this.router.navigateByUrl('/login')
  }

  read(domain: string): boolean {
    if (this._session.value) {
      let domains = this._session.value.role.domain
      return domains.write.includes(domain) || domains.read.includes(domain)
    }
    else
      return false
  }

  refresh() {
    // Dirty trick to refresh the menu bar after a role change, WISE has to be removed from the read domains before.
    let session = this._session.value
    if (session) this._session.next(session)
  }

  removeDomainFromRole(domain: string) {
    let session = this._session.value
    if (session) {
      session.role.domain.write = session.role.domain.write.filter(value => { return domain != value })
      session.role.domain.read = session.role.domain.read.filter(value => { return value == 'WISE' })
      this._session.next(session)
    }
  }

  reportClient(report : Report): string {
    // Clients without their own database may be managed under a client database with same identifier as the reseller.
    const client = this._session.value?.client
    if (client)
      if (client.identifier == client.reseller.identifier)
        return report.locality?.name + ''
      else
        return client.shortName
    else
      return 'error'
  }

  reset(client: string, name: string, mail: string) {
    this.httpClient.post('ipServer/wise/public/wise/v1/reset-password',
      { clientIdentifier: client, email: mail, username: name },{ responseType: 'text' }
    ).subscribe( () => this.snackbar.show('information.passwordReset'))
  }

  reseller(): Reseller | undefined { return this._session.value?.reseller }

  route(token: string, url: string) {
    sessionStorage['token'] = token
    this.loadSession()
    this.router.navigateByUrl(url)
  }

  session(): Observable<Session | null> { return this._session.asObservable() }

  // Used for account or location zone change.
  set(field : keyof User, object: DatabaseObject | null) {
    let session = this._session.value
    if (session) {
      if (field == 'accountZone') session.user.accountZone = object
      if (field == 'flowType') session.user.flowType = object
      if (field == 'locationZone') session.user.locationZone = object
    }
    this._session.next(session)
  }

  setRole(role: Role) {
    let session = this._session.value
    if (session) session.role = role
    this._session.next(session)
  }

  toISO(date: Date | null | undefined): string { return date ? this.isoFormatter.format(date) : '' }

  update(subscriptions: Subscriptions) {
    this.httpClient.put<User>(this.endpoint() + '/user', { subscriptions: subscriptions }).subscribe(user =>
    {
      let session = this._session.value
      if (session) session.user = user
      this._session.next(session)
    })
  }

  abstract url(endpoint: string, version?: string): string

  user(): User | undefined { return this._session.value?.user }

  write(domain:string): boolean {
    if (this._session.value)
      return this._session.value.role.domain.write.includes(domain)
    else
      return false
  }
}
