import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { GenericService } from '../services/generic.service'

export interface Report {
  id: string,
  status: string,
}

export interface Request {
  comment: string,
  containerId: string,
  id: string,
  reason: string,
  report: Report,
  timestamp: string,
  userId: string,
}

@Injectable({
  providedIn: 'root'
})
export class RequestService extends GenericService<Request> {

  reasons: string[] = ['A.m', 'A.x', 'N.c', 'N.i', 'P.p', 'P.t', 'Q.x', 'X.x']

  endpoint(version: string = 'v1'): string { return this.sessionService.url('after-sales-requests', version) }

  override update(request: Request): Observable<Request> {
    return this.putOne(request.id, {
      comment: request.comment,
      containerId: request.containerId,
      reason: request.reason,
      ...(request.userId > '' && { userId: request.userId }),
    })
  }
}
