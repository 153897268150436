import { Component, OnInit } from '@angular/core'

import { Card, CardService } from '../card.service'
import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'

@Component({
  selector: 'app-card-page',
  templateUrl: './card-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css']
})
export class CardPageComponent extends GenericPageComponent<Card> implements OnInit {

  constructor(protected override service: CardService) {
    super(service) ; this.sorting = { field: 'serial', order: 'ASC' }
  }

  override ngOnInit() { /* No default loading */ }
}
