import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core'

import { Container, ContainerService } from '../../framework/container/container.service'
import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'
import {Action} from "../../framework/components/action-pane/action-pane.component";

@Component({
  selector: 'app-container-page',
  templateUrl: './container-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css'],
})
export class ContainerPageComponent extends GenericPageComponent<Container> implements OnInit {

  container: Container = {
    afterSalesRequest: null, alarms: [], alarmLevel: '', containerType: null, id: '', identifier: '', ecolog: null,
    ecopoint: null, forecastDate: '', flowType: null, grouping: null, installationDate: '', installationType: '',
    installationVersion: '', lastAccessDate: '', lastCollectionDate: '', lastLevelDate: '', lastMaintenanceDate: '',
    lastMeasureDate: '', level: 0, levelSource: '', lid: '', locality: null, location: '', position: null, stock: null,
    status: 'OPERATIONAL', volume: 0, zone: null,
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    protected override service: ContainerService,
  ) { super(service) }

  override ngOnInit() {
    const level = this.activatedRoute.snapshot.paramMap.get('level')
    if (level)
      switch(level) {
        case '0': this.onSearch({ levelMax: 80 }) ; break
        case '1': this.onSearch({ levelMin: 81, levelMax: 100 }) ; break
        case '2': this.onSearch({ levelMin: 101 }) ; break
      }
    else {
      const index = this.activatedRoute.snapshot.paramMap.get('index')
      if (index)
        switch (index) {
        case '0': this.onSearch({ communicationState: 'RECEIVED' }) ; break
        case '1': this.onSearch({ communicationState: 'AWAITED'  }) ; break
        case '2': this.onSearch({ communicationState: 'MISSING'  }) ; break
      }
      else {
        const ecopointId = this.activatedRoute.snapshot.paramMap.get('ecopointId')
        if (ecopointId)
          this.onSearch({ ecopointId: ecopointId })
        else
          this.onSearch({id: this.activatedRoute.snapshot.paramMap.get('id')})
      }
    }
  }

  override onAction(action: Action) {
    if (action == Action.export)
      this.service.export(this.current, this.sorting)
    else
      super.onAction(action)
  }
}
