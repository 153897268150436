import { Component } from '@angular/core';

import { GenericOverviewComponent } from '../../framework/generic-overview/generic-overview'
import { LocalityService, Locality } from '../../framework/locality/locality.service'

@Component({
  selector: 'app-locality-overview',
  templateUrl: '../../framework/generic-overview/generic-overview.html',
  styleUrls: ['../../framework/generic-overview/generic-overview.css']
})
export class LocalityOverviewComponent extends GenericOverviewComponent<Locality> {

  constructor(override service: LocalityService) { super(service) }
}
