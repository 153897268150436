import {AfterViewInit, Component, EventEmitter, HostListener, Output} from '@angular/core'

@Component({
  selector: 'app-screensize',
  template: '',
  styles: []
})
export class ScreensizeComponent implements AfterViewInit {

  @Output() event = new EventEmitter<string>()

  @HostListener("window:resize", [])
  private onResize() {
    this.notifyScreenSize()
  }

  notifyScreenSize() {
    if (screen.width < 512)
      this.event.emit('SMALL')
    else
      this.event.emit('LARGE')
  }

  ngAfterViewInit() {
    this.notifyScreenSize();
  }
}
