import {Component, OnInit} from '@angular/core'

import { ClosureService, Closure } from '../closure.service'
import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'

@Component({
  selector: 'app-closure-page',
  templateUrl: './closure-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css']
})
export class ClosurePageComponent extends GenericPageComponent<Closure> implements OnInit {

  closure: Closure | null = null
  id: string = ''                       // Last's closure id if undoable.

  constructor(protected override service: ClosureService) {
    super(service) ; this.sorting = { field: 'enddate', order: 'DESC' }
  }

  override ngOnInit() {
    super.ngOnInit()
    this.updateConditions()
  }

  override onEvent(event: string) {
    if (event == 'delete' || event == 'add') this.updateConditions()
    super.onEvent(event)
  }

  updateConditions() {
    this.service.last(closures => {
      const closure: Closure = { id: '', identifier: '', endDate: '', executionDate: '', label: '', startDate: '', statistics: null, status: 'TEMPORARY' }
      if (closures.length) {
        if (closures[0].status == 'CLOSED') {
          // A new closure may be created.
          let start: Date = new Date(closures[0].endDate)
          start.setDate(start.getDate() + 1)
          closure.startDate = start.toISOString().slice(0,10)
          this.closure = closure
          // The last closed closure may be undone.
          this.id = closures[0].id
        }
        else {
          // No new closures may be created.
          this.closure = null
          // The previous closure can be undone, if existing.
          if (closures.length > 1)
            this.id = closures[1].id
          else
            this.id = ''
        }
      } else
        // A first closure may be created.
        this.closure = closure
    })
  }
}
