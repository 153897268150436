import { Component } from '@angular/core'

import { CategoryService, Category } from "../category.service";
import { GenericOverviewComponent } from '../../framework/generic-overview/generic-overview'

@Component({
  selector: 'app-category-overview',
  templateUrl: '../../framework/generic-overview/generic-overview.html',
  styleUrls: ['../../framework/generic-overview/generic-overview.css']
})
export class CategoryOverviewComponent extends GenericOverviewComponent<Category> {

  constructor(protected override service: CategoryService) { super(service) }
}
