<div class="dialog">
  <h1>{{'EcoLog Association / Dissociation' | translate}} - {{data.container.identifier}}</h1>
  <div *ngIf="current">
    <h3>{{'Current EcoLog' | translate}}</h3>
    <div class="row">
      <app-text-field class="width-25" [label]="'Serial'" [value]="current.serial.toString()"></app-text-field>
      <app-text-field class="width-25" [label]="'Delivery Reference'" value="{{current.deliveryRef}}"></app-text-field>
      <app-text-field class="width-20" [label]="'Delivery Date'" [value]="current.deliveryDate | datestamp"></app-text-field>
      <app-text-field class="width-20" [label]="'Warranty'" [value]="current.warrantyDate | datestamp"></app-text-field>
    </div>
    <div class="row">
      <mat-radio-group class="row" [style.gap]="'20%'" [(ngModel)]="warranty" (change)="onWarranty()">
        <mat-radio-button [color]="'primary'" [value]="false">{{('ecolog.outOfWarranty') | translate}}</mat-radio-button>
        <mat-radio-button [color]="'accent'" [value]="true">{{'ecolog.underWarranty' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <h3><br></h3>
  <h3>{{(current ? 'Replacement EcoLog' : 'New EcoLog') | translate}}</h3>
  <div class="row">
    <mat-form-field class="width-25">
      <mat-label>{{'EcoLog' | translate}}</mat-label>
      <mat-select (selectionChange)="onSelect($event.value)">
        <mat-option *ngIf="current">{{'None' | translate}}</mat-option>
        <mat-option *ngFor="let ecolog of ecologs" value="{{ecolog.id}}">{{ecolog.serial}}</mat-option>
      </mat-select>
    </mat-form-field>
    <app-text-field *ngIf="future"class="width-25" [label]="'Delivery Reference'" value="{{future.deliveryRef}}"></app-text-field>
    <app-text-field *ngIf="future"class="width-20" [label]="'Delivery Date'" [value]="future.deliveryDate | datestamp"></app-text-field>
    <app-text-field *ngIf="future"class="width-20" [label]="'Warranty'" [value]="future.warrantyDate | datestamp"></app-text-field>
  </div>
  <div class="row right">
    <button mat-flat-button [mat-dialog-close]="'cancel'">{{'Cancel' | translate}}</button>
    <button mat-flat-button *ngIf="current || future" (click)="onOK()">{{label() | translate}}</button>
  </div>
</div>
