import { Component } from '@angular/core'

import { GenericOverviewComponent } from '../../framework/generic-overview/generic-overview'
import { Zone, ZoneService } from '../zone.service'

@Component({
  selector: 'app-zone-overview',
  templateUrl: '../../framework/generic-overview/generic-overview.html',
  styleUrls: ['../../framework/generic-overview/generic-overview.css']
})
export class ZoneOverviewComponent extends GenericOverviewComponent<Zone> {

  constructor(protected override service: ZoneService) { super(service) }
}