<div class="editor">
  <div class="form">
    <div class="row">
      <app-date-field class="width-20" [mode]="mode" [(value)]="subject.timestamp"></app-date-field>
      <app-text-field class="width-80" [label]="'Comment'" [length]="200" [mode]="mode" [required]="true"
        [(value)]="subject.comment" (input)="check()"></app-text-field>
    </div>
    <div *ngIf="'AFTER_SALES' | write" class="row">
      <app-dropdown class="width-20" [label]="'Kind'" [mode]="mode" [prefix]="'history'"
        [values]="kinds.includes(subject.kind) ? kinds : [object.kind]" [(value)]="subject.kind"></app-dropdown>
    </div>
  </div>
  <app-form-buttons [actions]="deletable() ? ['delete'] : []" [domain]="domain()" [mode]="mode" [valid]="valid"
    (event)="onEvent($event)"></app-form-buttons>
</div>
