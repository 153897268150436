import {Component, Input, OnInit } from '@angular/core'

import { Container, ContainerService } from '../../framework/container/container.service'
import { Observable} from "rxjs";
import { CalibrationService, Calibration } from "../../framework/container/calibration.service";

@Component({
  selector: 'app-container-calibration',
  templateUrl: './container-calibration.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class ContainerCalibrationComponent implements OnInit {

  @Input() container !: Container

  calibrations !: Observable<Calibration[]>

  constructor(
    protected printer: CalibrationService,
    protected service: ContainerService) { }

  ngOnInit() { this.calibrations = this.service.calibrations(this.container.id) }

  onPrint(calibration: Calibration) { console.log(calibration) ; this.printer.print(calibration) }
}
