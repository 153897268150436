<div class="list-header">
  <list-header class="width-15" [identifier]="'Identifier'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-25" [identifier]="'Name'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [identifier]="'Height'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [identifier]="'Speed'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as trucks">
  <cdk-accordion-item *ngFor="let truck of trucks; let index = index"
    #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span class="width-15">{{truck.identifier}}</span>
      <span class="width-25">{{truck.name | shorten:25}}</span>
      <span class="width-10">{{truck.height}}</span>
      <span class="width-10">{{truck.speed}}</span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-truck-form [object]="truck" (event)="onEvent($event)" (objectChange)="onUpdate(index, $event)"></app-truck-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
