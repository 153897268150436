import { DateAdapter } from '@angular/material/core'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  dateOptions: Intl.DateTimeFormatOptions = { year: "numeric", month: "2-digit", day: "2-digit" }
  timeOptions: Intl.DateTimeFormatOptions = { timeStyle: "short" }

  dateFormatter = new Intl.DateTimeFormat('fr-CH', this.dateOptions)
  timeFormatter = new Intl.DateTimeFormat('fr-CH', this.timeOptions )

  private _language = new BehaviorSubject<string>('en')

  constructor(
      protected dateAdapter: DateAdapter<any>,
      protected translateService: TranslateService,
  ) {
    translateService.setDefaultLang('en')
    let language = navigator.language.slice(0,2)
    if (['de', 'en', 'fr'].indexOf(language) < 0) language = 'en'
    this.select(language)
  }

  datestamp(date: Date): string {
    return this.dateFormatter.format(date)
  }

  language() { return this._language.asObservable() }

  timestamp(date: Date): string {
    return this.dateFormatter.format(date) + ' ' + this.timeFormatter.format(date)
  }

  translate(value: string) { return this.translateService.instant(value) }

  select(language: string) {
    let lowercase = language.toLowerCase()
    const locale = language == 'en' ? 'en-GB' : language + '-CH'
    this.translateService.use(lowercase).subscribe((value: any) => {
      this.dateAdapter.setLocale(locale)
      this.dateFormatter = new Intl.DateTimeFormat(locale, this.dateOptions)
      this.timeFormatter = new Intl.DateTimeFormat(locale, this.timeOptions)
      this._language.next(lowercase)
    })
  }

  set(language: string) {
    localStorage['language'] = language
    this.select(language)
  }
}
