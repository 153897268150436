<div class="editor">
  <div class="form">
    <!-- Form buttons above the form on small displays. -->
    <div class="smallDisplayOnly">
      <app-form-buttons [actions]="['print']" domain="AFTER_SALES" [mode]="('AFTER_SALES' | write) ? mode : Mode.action" [spacing]="false" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
    </div>
    <!-- Request data -->
    <div *ngIf="subject.request">
      <div class="row">
        <app-date-field class="width-20" label="Request" [mode]="Mode.readonly"
          [value]="subject.request.date"></app-date-field>
        <app-text-field class="width-20" label="Requester" [mode]="Mode.reading"
          [value]="subject.request.user.firstname + ' ' + subject.request.user.lastname"></app-text-field>
        <app-text-field class="width-55" label="Reason" [mode]="Mode.readonly"
          [value]="'reason.' + subject.request.reason | translate"></app-text-field>
      </div>
      <div class="row">
        <app-text-field class="width-90" label="Comment" [mode]="Mode.readonly" [value]="subject.request.comment"></app-text-field>
      </div>
    </div>
    <!-- Container data. -->
    <div *ngIf="subject.container; else editContainer" class="row">
      <app-text-field class="width-20" label="Container" [mode]="Mode.readonly"
        [value]="subject.container.identifier"></app-text-field>
      <app-date-field class="width-20" label="Installation Date" [mode]="Mode.readonly"
        [value]="subject.container.installationDate"></app-date-field>
      <app-text-field *ngIf="subject.ecopoint" class="width-55" label="Location" [mode]="Mode.readonly"
        [value]="subject.ecopoint.location"></app-text-field>
    </div>
    <ng-template #editContainer>
      <mat-form-field class="width-35">
        <mat-label>{{"Container" | translate}}</mat-label>
        <input matInput [matAutocomplete]="auto" [(ngModel)]="container" (ngModelChange)="doFilterContainers()" type="text" >
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="containerRef">
          <mat-option *ngFor="let container of containers" [value]="container">{{containerRef(container)}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-template>

    <h3 *ngIf="subject.request">{{'Report' | translate}}</h3>
    <div *ngIf="subject.technician" class="row">
      <app-object-dropdown class="width-20" label="Technician" [mode]="mode" [object]="subject.technician"
        [objects]="technicians" [required]="true" (objectChange)="onTechnician($event)"></app-object-dropdown>
    </div>
    <div class="row">
      <app-dropdown class="width-20" label="Status" [mode]="mode" prefix="report"
        [(value)]="subject.status" [values]="statuses()"></app-dropdown>
      <app-date-field class="width-20" label="intervention_date" [mode]="mode" [required]="true"
        [(value)]="subject.interventionDate" (valueChange)="check()"></app-date-field>
      <app-dropdown class="width-55"  label="Diagnostic" [mode]="mode" [prefix]="'diagnostic'" [required]="false"
        [(value)]="subject.diagnostic" [values]="diagnostics()"></app-dropdown>
    </div>
    <div class="row">
      <mat-form-field class="width-90">
        <mat-label>{{'Comment' | translate}}</mat-label>
        <textarea [style.color]="'black'" matInput [disabled]="mode!=Mode.edition" maxLength="254" [(ngModel)]="subject.comment"></textarea>
      </mat-form-field>
    </div>
    <div *ngIf="mode == Mode.edition" class="row">
      <mat-form-field class="largeDisplayOnly width-35">
        <mat-label>{{'New Position' | translate}}</mat-label>
        <input matInput [matAutocomplete]="auto" [(ngModel)]="filter" (ngModelChange)="doFilterTemplates()" type="text" >
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="templateRef" (optionSelected)="onAddByOption($event)" >
          <mat-option *ngFor="let template of templates" [value]="template">{{templateRef(template)}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <select class="select smallDisplayOnly" [name]="'New Position' | translate" [style.width]="'37%'" (change)="onAddByEvent($event)">
        <option *ngFor="let template of templates" value="{{template.id}}">{{template.name}} - {{template.description}}</option>
      </select>
      <button mat-icon-button *ngIf="container" class="lowered" (click)="onAddTimeAndDistance()"><mat-icon>local_shipping</mat-icon></button>
    </div>
    <div *ngFor="let position of subject.positions; let index = index" class="row">
      <app-text-field class="width-15" label="Name" [mode]="mode" [(value)]="position.name"></app-text-field>
      <app-text-field class="width-30" label="Description" [mode]="mode" [(value)]="position.description"></app-text-field>
      <mat-form-field class="width-10">
        <mat-label>{{'Quantity' | translate}}</mat-label>
        <input matInput #input class="input" [disabled]="mode != Mode.edition" required
          [type]="position.units == 'HH:MM' ? 'time' : 'number'" [(ngModel)]="position.value">
      </mat-form-field>
      <app-text-field class="width-10" label="Units" [mode]="mode" [(value)]="position.units"></app-text-field>
      <app-number-field class="width-10" label="ERP Code" [mode]="mode" [(value)]="position.erpCode"></app-number-field>
      <button mat-icon-button *ngIf="mode == Mode.edition" class="lowered" (click)="onClear(index)"><mat-icon>clear</mat-icon></button>
    </div>
    <div class="row">
      <app-dropdown class="width-15" label="Mode" [mode]="mode" prefix="report" [required]="false"
        [(value)]="subject.handling" [values]="['INTERNAL', 'PRO_FORMA', 'INVOICEABLE']"></app-dropdown>
      <app-date-field *ngIf="invoiceable()" class="width-20" [label]="'Invoice'" [mode]="mode"
        [(value)]="subject.invoiceDate"></app-date-field>
      <app-text-field *ngIf="invoiceable()" class="width-15" label="Reference" [mode]="mode"
        [(value)]="subject.invoiceRef"></app-text-field>
    </div>
  </div>
  <app-form-buttons class="largeDisplayOnly" [actions]="['print','download']" domain="AFTER_SALES" [mode]="('AFTER_SALES' | write) ? mode : Mode.action" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
</div>
