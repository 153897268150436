import { Component } from '@angular/core';

import { GenericFormComponent } from '../../framework/generic-form/generic-form.component'
import { GroupingService, Grouping } from '../../grouping/grouping.service'
import { LocalityService, Locality } from '../../framework/locality/locality.service'
import { DatabaseObject } from '../../framework/objects'
import { ZoneService, Zone } from '../../zone/zone.service'

@Component({
  selector: 'app-locality-form',
  templateUrl: './locality-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class LocalityFormComponent extends GenericFormComponent<Locality>{

  zones: Zone[] = []
  groupings: Grouping[] = []

  constructor(
    protected groupingService: GroupingService,
    protected override service: LocalityService,
    protected zoneService: ZoneService
  ) {
    super(service)
    groupingService.list(groupings => this.groupings = groupings)
    zoneService.list(zones => this.zones = zones,'LOCATION')
  }

  override isValid(): boolean { return this.subject.name > ' ' && this.subject.grouping != null }

  onGrouping(object: DatabaseObject | null) {
    if (object) {
      let grouping = this.groupings.find(grouping => grouping.id == object.id)
      if (grouping && grouping.center && grouping.center.x) this.subject.center = { ...grouping.center }
    }
    this.check()
  }
}
