import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Subscription } from 'rxjs'

import { SessionService } from '../../framework/services/session.service'
import { CategoryService, Category } from '../../category/category.service'
import { Mode } from '../../framework/generic-form/generic-form.component'
import { DatabaseObject } from '../../framework/objects'
import { ZoneService, Zone } from '../../zone/zone.service'
import { LanguageService } from '../../framework/services/language.service'

@Component({
  selector: 'app-account-filter',
  templateUrl: './account-filter.component.html',
  styleUrls: ['../../framework/generic-filter/generic-filter.component.css']
})
export class AccountFilterComponent implements OnDestroy {

  Mode = Mode

  categories: Category[] = []
  subscription: Subscription
  userZone: Zone | null = null
  zone: Zone | null = null
  zones: Zone[] = []

  @Input() filter: any = { categoryId: null, zoneId: null }
  @Output() filterChange = new EventEmitter()

  constructor(
    protected sessionService: SessionService,
    protected categoryService: CategoryService,
    protected languageService: LanguageService,
    protected zoneService: ZoneService,
  ) {
    const without: string = languageService.translate('Without')
    categoryService.list(categories => this.categories = categories,'name', without)
    zoneService.list(zones => this.zones = zones,'ACCOUNT', without)
    this.subscription = sessionService.session().subscribe(session => {
      if (session) {
        this.userZone = session.user.accountZone
        this.zone = session.user.accountZone
        this.onZone(this.zone)
      }
    })
  }

  ngOnDestroy() { this.subscription.unsubscribe() }

  onCategory(category: Category | null) {
    this.filter.categoryId = category ? category.id : null
    this.filterChange.emit(this.filter)
  }

  onZone(zone: DatabaseObject | null) {
    this.filter.zoneId = zone ? zone.id : null
    this.filterChange.emit(this.filter)
  }
}
