<div class="ribbon">
  <div>
    <button [class]="this.period == 1 ? 'highlight' : null" mat-button (click)="onPeriod(1)">1</button>
    <button [class]="this.period == 3 ? 'highlight' : null" mat-button (click)="onPeriod(3)">3</button>
    <button [class]="this.period == 6 ? 'highlight' : null" mat-button (click)="onPeriod(6)">6</button>
    <button [class]="this.period == 12 ? 'highlight' : null" mat-button (click)="onPeriod(12)">12</button>
    <button [class]="this.period == 24 ? 'highlight' : null" mat-button (click)="onPeriod(24)">24</button>
    {{'months' | translate}}
  </div>
  <div>
    <button [class]="this.measure == 'LEVEL' ? 'highlight' : null" mat-button (click)="onMeasure('LEVEL')">
      {{'statistics.LEVEL' | translate}}</button>
    <button [class]="this.measure == 'COLLECTIONS' ? 'highlight' : null" mat-button (click)="onMeasure('COLLECTIONS')">
      {{'statistics.COLLECTIONS' | translate}}</button>
    <button [class]="this.measure == 'PERIODICITY' ? 'highlight' : null" mat-button (click)="onMeasure('PERIODICITY')">
      {{'statistics.PERIODICITY' | translate}}</button>
  </div>
</div>
<div *ngIf="measure == 'LEVEL'; then lineChart; else barChart"></div>
<ng-template #lineChart>
  <app-line-chart [series]="series" [min]="0" [max]="110" units="%"></app-line-chart>
</ng-template>
<ng-template #barChart>
  <app-bar-chart [data]="data" [units]="units"></app-bar-chart>
</ng-template>
