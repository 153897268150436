import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { DatabaseObject, Point } from '../framework/objects'
import { GenericService } from '../framework/services/generic.service'

export interface Grouping extends DatabaseObject {
  center: Point,
  zone: DatabaseObject | null,
}

@Injectable({
  providedIn: 'root'
})
export class GroupingService extends GenericService<Grouping> {

  endpoint(version: string = 'v1'): string { return this.sessionService.url('groupings', version) }

  override update(grouping: Grouping): Observable<Grouping> {
    return this.putOne(grouping.id, {
      center: grouping.center,
      identifier: grouping.identifier,
      name: grouping.name,
      zoneId: grouping.zone?.id,
    })
  }
}
