import { Component } from '@angular/core'

import { GenericFormComponent } from '../../generic-form/generic-form.component'
import { TruckService, Truck } from '../truck.service'

@Component({
  selector: 'app-truck-form',
  templateUrl: './truck-form.component.html',
  styleUrls: ['../../generic-form/generic-form.component.css']
})
export class TruckFormComponent  extends GenericFormComponent<Truck> {

  volume: string = '0'

  constructor(protected override service: TruckService) { super(service) }

  override initializeForm() { super.initializeForm() ; this.volume = this.object.volumeMax.toString() }

  onVolume() { this.subject.volumeMax = +this.volume }

  override updateSubject() { this.subject.volumeMax = +this.volume }
}
