import { Component } from '@angular/core';

import { CollectionService, Collection } from '../collection.service'
import { GenericFormComponent, Mode } from '../../framework/generic-form/generic-form.component'

@Component({
  selector: 'app-collection-form',
  templateUrl: './collection-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class CollectionFormComponent extends GenericFormComponent<Collection> {

  constructor(protected override service: CollectionService) { super(service)
  }
}
