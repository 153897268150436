import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import { MatExpansionModule } from '@angular/material/expansion'
import { MatTabsModule } from '@angular/material/tabs'

import { CardModule } from '../card/card.module'
import { TransactionModule } from '../transaction/transaction.module'
import { WasteModule } from '../waste/waste.module'

import { AccountCardsComponent } from './account-cards/account-cards.component'
import { AccountClosureComponent } from './account-closure/account-closure.component'
import { AccountFilterComponent } from './account-filter/account-filter.component'
import { AccountFormComponent } from './account-form/account-form.component'
import { AccountListComponent } from './account-list/account-list.component'
import { AccountOverviewComponent } from './account-overview/account-overview.component'
import { AccountPageComponent } from './account-page/account-page.component'
import { AccountTransactionsComponent } from './account-transactions/account-transactions.component'
import { AccountViewComponent } from './account-view/account-view.component'
import { AccountWasteComponent } from './account-waste/account-waste.component'
import { AddressFormComponent } from './address-form/address-form.component'

@NgModule({
  declarations: [
    AccountCardsComponent,
    AccountClosureComponent,
    AccountFilterComponent,
    AccountFormComponent,
    AccountListComponent,
    AccountOverviewComponent,
    AccountPageComponent,
    AccountTransactionsComponent,
    AccountViewComponent,
    AccountWasteComponent,
    AddressFormComponent,
  ],
  imports: [
    FrameworkModule,

    MatExpansionModule,
    MatTabsModule,

    CardModule,
    TransactionModule,
    WasteModule,
  ],
  exports: [ AccountPageComponent ]
})
export class AccountModule { }
