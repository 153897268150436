<div class="editor">
  <div class="form">
    <div class="row">
      <app-text-field class="width-15" [label]="'Identifier'" [length]="10" [mode]="mode"
        [(value)]="subject.identifier"></app-text-field>
      <app-text-field [style.width]="'32%'" [label]="'Name'" [length]="200" [mode]="mode" [required]="true"
        [(value)]="subject.name" (valueChange)="check()"></app-text-field>
    </div>
    <div class="row">
      <app-text-field class="width-15" [label]="'Height'" [length]="200" [mode]="mode" [required]="true"
        [(value)]="subject.height" (valueChange)="check()"></app-text-field>
      <app-text-field class="width-15" [label]="'Speed'" [length]="200" [mode]="mode" [required]="true"
        [(value)]="subject.speed" (valueChange)="check()"></app-text-field>
      <app-text-field class="width-15" [label]="'Capacity (kg)'" [length]="200" [mode]="mode" [required]="true"
        [(value)]="subject.weightMax" (valueChange)="check()"></app-text-field>
      <app-number-field class="width-15" [label]="'Capacity (m3)'" [mode]="mode" [required]="true"
        [(value)]="volume" (valueChange)="onVolume()"></app-number-field>
    </div>
  </div>
  <app-form-buttons [actions]="('AFTER_SALES' | write) ? ['delete'] : []" domain="LOCATIONS" [mode]="mode" [valid]="valid"
    (event)="onEvent($event)"></app-form-buttons>
</div>
