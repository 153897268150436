import { Component, EventEmitter, Input, Output } from '@angular/core'

import { Container, ContainerService } from '../../container/container.service'
import { GenericListComponent } from '../../generic-list/generic-list.component'
import { Mode } from '../../generic-form/generic-form.component'
import { SessionService } from '../../services/session.service'
import { Tour } from '../tour.service'

@Component({
  selector: 'app-tour-container-list',
  templateUrl: './tour-container-list.component.html',
  styleUrls: ['../../generic-list/generic-list.component.css', './tour-container-list.component.css']
})
export class TourContainerListComponent extends GenericListComponent<Container> {

  Mode = Mode

  @Input() tour !: Tour
  @Input() filter !: any
  @Input() mode: Mode = Mode.reading

  @Output() change = new EventEmitter<void>()

  last: string = ''

  constructor(
    protected sessionService: SessionService,
    protected override service: ContainerService,
  ) { super(service) }

  color(levelSource: string): string { return (levelSource == 'COLLECTION') ? '#5AA454' : '#C7B42C' }

  selectionIndex(id: string): number {
    if (this.tour.stops)
      return this.tour.stops.findIndex(stop => stop.id == id)
    else return -1
  }

  intervalSelect(second: string) {
    // Indicator for selecting or deselecting the interval.
    let select = this.selectionIndex(this.last) > -1

    this.service.objects().subscribe(list => {

      let first = list.findIndex(container => container.id == this.last)
      let last = list.findIndex(container => container.id == second)

      if (first > -1 && last > -1) {
        if (first > last) [first, last] = [last, first]
        for (let index = first; index <= last; index++) {
          let id = list[index].id
          if (select) {
            if (this.selectionIndex(id) < 0)
              // TODO Missing container here
              this.tour.stops.push({ id: id, type: 'CONTAINER', level: 0, order: 0 })
          } else {
            let position = this.selectionIndex(id)
            if (position > -1) this.tour.stops.splice(position, 1)
          }
        }
      }
    }).unsubscribe()
  }

  isSelected(id: string): boolean { return this.mode == Mode.edition && this.selectionIndex(id) > -1 }

  onSelect(event: any, container: Container) {
    if (this.mode == Mode.edition) {
      if (event.altKey || event.metaKey || event.control)
        this.intervalSelect(container.id)
      else {
        const index = this.selectionIndex(container.id)
        if (index > -1)
          this.tour.stops.splice(index,1)
        else
          this.tour.stops.push({container: container, id: container.id, level: container.level, type: 'CONTAINER', order: 0})
        this.last = container.id
      }
      this.change.next()
    }
  }

  rise(): boolean { return this.sessionService.rise() }
  wise(): boolean { return this.sessionService.wise() }
}
