import { Component, OnInit } from '@angular/core';

import { GenericListComponent } from '../../framework/generic-list/generic-list.component'
import { StockService, Stock } from '../stock.service'

@Component({
  selector: 'app-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class StockListComponent extends GenericListComponent<Stock> {

  constructor(protected override service: StockService) { super(service) }
}
