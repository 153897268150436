<div class="list-header">
  <list-header class="width-15" identifier="delivery_ref" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" identifier="delivery_date" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" identifier="Kind" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" identifier="first" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" identifier="last"></list-header>
  <list-header class="width-10" identifier="Available"></list-header>
  <list-header class="width-25" identifier="Comment"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as batchs">
  <cdk-accordion-item *ngFor="let batch of batchs; let index = index"
      #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span class="width-15">{{batch.deliveryRef}}</span>
      <span class="width-15">{{batch.deliveryDate ? (batch.deliveryDate | datestamp) : ''}}</span>
      <span class="width-10">{{'batch.' + batch.mode | translate}}</span>
      <span class="width-10">{{batch.first}}</span>
      <span class="width-10">{{batch.last}}</span>
      <span class="width-10">{{batch?.availableCards}}</span>
      <span class="width-25">{{batch.comment | shorten:25}}</span>
      <span *ngIf="!batch.generationDate" class="width-5"><button mat-icon-button><mat-icon>bookmark_border</mat-icon></button></span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-batch-form [object]="batch" (objectChange)="onUpdate(index, $event)" (event)="onEvent($event)"></app-batch-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count" #scroll><span *ngIf="total | async as total">{{count | async}} / {{total}}</span></div>
