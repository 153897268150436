import { Component } from '@angular/core'

import { FlowTypeService, FlowType } from '../../framework/flow-type/flow-type.service'
import { GenericFormComponent, Mode } from '../../framework/generic-form/generic-form.component'

@Component({
  selector: 'app-flow-type-form',
  templateUrl: './flow-type-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class FlowTypeFormComponent extends GenericFormComponent<FlowType> {

  Mode = Mode

  constructor(protected override service: FlowTypeService) { super(service)
  }

  override isValid(): boolean { return this.subject.name > ' ' }
}
