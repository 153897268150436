import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion'
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core'
import { Observable } from 'rxjs'

import { GenericService, Order, Sorting, toggle } from '../services/generic.service'

@Component({
  selector: 'app-generic-list',
  template: 'generic-list.component',
  styleUrls: ['./generic-list.component.css']
})
export class GenericListComponent<Type extends { id: string }> {

  count !: Observable<number>
  current: HTMLElement | null = null    // Current selected (open) accordion item.
  frozen: boolean = false
  objects: Observable<Type[]>

  // TODO Rose needs differentiation between selection and expanding.
  selection: number | undefined         // Index of current selection.
  total !: Observable<string | null>

  @Input() sorting !: Sorting

  @Output() event = new EventEmitter<string>()
  @Output() page = new EventEmitter<void>()
  @Output() sort = new EventEmitter<Sorting>()

  @ViewChild(CdkAccordion) accordion !: CdkAccordion
  @ViewChild('scroll', { read: ElementRef }) footer !: ElementRef

  constructor(protected service: GenericService<Type>) {
    this.objects = service.objects()
    this.count = this.service.count()
    this.total = this.service.total()
  }

  // Computation of item class for dimming.
  dim(item: Type): string { return this.isDimmed(item) ? ' dimmed' : '' }

  // To be overridden for objects that need dimming (invalid transactions, canceled waste).
  isDimmed(item: Type): boolean { return false }

  onEvent(event: string) {
    if (event == 'edit')
      this.frozen = true
    else {
      this.unfreeze()
      if (event == 'update' && this.selection != undefined) this.service.refresh(this.selection)
    }
    this.event.next(event)              // Bubble up.
  }

  // Triggered by a click on a sortable list header.
  onSort(field: string, order ?: Order) {
    if (this.frozen)
      this.scroll()
    else {
      let newSorting: Sorting = { field: field, order: order ?? 'ASC' }
      if (this.sorting.field === field && order == undefined)
        newSorting.order = toggle(this.sorting.order)
      this.sort.emit(newSorting)
      this.resetSelection()             // Close any open item.
    }
  }

  // Triggered by accordion opening/closing.
  onToggle(item : CdkAccordionItem, index:number) {
    if (this.frozen)
      this.scroll()
    else {
      this.current = document.getElementById('header-' + index)
      item.toggle()
      if (item.expanded)
        this.selection = index
      else
        this.selection = undefined
    }
  }

  @HostListener('document:scroll')
  public onViewportScroll() {
    if (this.footer && this.footer.nativeElement.getBoundingClientRect().y < window.innerHeight)
      this.page.emit()
  }

  // Update list when an object was edited, triggered by form object change.
  onUpdate(index: number, object: Type) { this.service.set(index, object) }

  resetSelection() { this.selection = undefined }

  scroll() {
    if (!this.current)
      this.current = document.getElementById('header-' + this.selection)
    if (this.current)
      this.current.scrollIntoView({ behavior: "smooth", block: "nearest" })
  }

  unfreeze() { this.frozen = false }
}
