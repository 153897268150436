import { Pipe, PipeTransform } from '@angular/core'

import { LanguageService } from '../services/language.service'

@Pipe({name: 'datestamp'})
export class DatestampPipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  transform(value: string | null): string {
    if (value)
       return this.languageService.datestamp(new Date(value))
    else
      return ''
  }
}
