import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { Account } from '../account.service'
import { Mode } from '../../framework/generic-form/generic-form.component'
import { Sorting } from '../../framework/services/generic.service'
import { TransactionService, Transaction } from '../../transaction/transaction.service'

@Component({
  selector: 'app-account-transactions',
  templateUrl: './account-transactions.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css']
})
export class AccountTransactionsComponent implements OnInit {

  Mode = Mode

  creation: boolean = false
  frozen: boolean = false
  sorting: Sorting = { field: 'timestamp', order: 'DESC' }
  transaction: Transaction = { account: null, amount: 0, card: null, comment: '', id: '', modifiable: true, status: '',
    ticket: '', till: null, timestamp: '', username: null, validity: 'VALID' }

  @Input() abstract: string = ''
  @Input() account !: Account
  @Output() event = new EventEmitter<string>()

  constructor(protected service: TransactionService) { }

  ngOnInit() { this.onSort(null) }

  onAdd() {
    this.creation = true
    this.transaction.account = { id: this.account.id, identifier: '', name: '' }
    this.event.next('edit')
  }

  onEvent(event: string) {
    this.frozen = event == 'edit'
    this.creation = false
    this.event.next(event)
    // The list must be refreshed since there is a new or updated transaction.
    if (event == 'add') this.onSort(null)
  }

  onSort(sorting: Sorting | null) {
    if (sorting) this.sorting = sorting
    this.service.fetch({ accountId: this.account.id, validity: 'VALID' }, this.sorting)
  }
}
