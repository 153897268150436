import { Component } from '@angular/core';

import { BatchService, Batch } from '../batch.service'
import { GenericListComponent} from "../../generic-list/generic-list.component";

@Component({
  selector: 'app-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['../../generic-list/generic-list.component.css']
})
export class BatchListComponent extends GenericListComponent<Batch> {

  constructor(protected override service: BatchService) { super(service) }
}
