import { Component } from '@angular/core'

import { GenericFormComponent, Mode } from '../../framework/generic-form/generic-form.component'
import { FlowTypeService, FlowType } from '../../framework/flow-type/flow-type.service'
import { RoleService, Role } from '../../framework/role/role.service'
import { TillService, Till} from '../../till/till.service'
import { UserService, User } from '../../framework/user/user.service'
import { ZoneService, Zone } from '../../zone/zone.service'

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class UserFormComponent extends GenericFormComponent<User> {

  Mode = Mode

  notificationTypes = ['NONE', 'EMAIL', 'WISE']

  accountZones: Zone[] = []
  flowTypes: FlowType[] = []
  locationZones: Zone[] = []
  roles: Role[] = []
  tills: Till[] = []

  constructor(
    protected flowTypeService: FlowTypeService,
    protected roleService: RoleService,
    protected tillService: TillService,
    protected override service: UserService,
    protected zoneService: ZoneService,
  ) {
    super(service)
    flowTypeService.list(flowTypes => this.flowTypes = flowTypes)
    roleService.list(roles => this.roles = roles)
    tillService.list(tills => this.tills = tills)
    zoneService.list(zones => this.accountZones = zones,'ACCOUNT')
    zoneService.list(zones => this.locationZones = zones,'LOCATION')
  }

  override isValid(): boolean {
    let regex = new RegExp("[a-z0-9]+@[a-z]+\.[a-z]{2,3}")
    return this.subject.username > ' ' && this.subject.role != null && regex.test(this.subject.email) }

  override initializeForm() {
    super.initializeForm()
    this.subject.subscriptions = { ...this.object.subscriptions }
  }
}
