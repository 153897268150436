<div class="embedded-header">
  <list-header class="width-15" identifier="timestamp"></list-header>
</div>
<div *ngIf="calibrations | async as calibrations">
  <div *ngFor="let calibration of calibrations">
    <div class="embedded-item">
      <button mat-button (click)="onPrint(calibration)">{{calibration.timestamp | timestamp}}</button>
    </div>
  </div>
</div>
