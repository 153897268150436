import { Component, EventEmitter, Input, Output } from '@angular/core'

import { Ecolog } from '../ecolog.service'

@Component({
  selector: 'app-ecolog-view',
  templateUrl: './ecolog-view.component.html',
  styleUrls: ['../../generic-view/generic-view.css']
})
export class EcologViewComponent {

  @Input() ecolog !: Ecolog
  @Output() event = new EventEmitter<string>()

  // Edition events from panes must be bubbled up to EcoLog list.
  onEvent(event: string) { this.event.next(event) }
}
