import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { SessionService, Client, Message } from '../../framework/services/session.service'
import { ResetFormComponent } from '../reset-form/reset-form.component'

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent implements OnInit {

  identifier: string | null = null
  client: Client | null = null
  messages: Message[] = []
  password: string = ''
  username: string = ''

  constructor(
    private sessionService: SessionService,
    protected dialog: MatDialog,
  ) { }

  clientId(): string {
    if (this.identifier) return this.identifier
    else if (this.client) return this.client.identifier
    else return ''
  }

  disabled(): boolean { return this.username === '' || this.password === '' || !(this.clientId()) }

  ngOnInit() {
    this.sessionService.logout()
    const client = window.location.hostname.split('.')[0]
    if (!client.startsWith('wise') && client != 'validation' && client != 'localhost')
      this.identifier = client
    this.sessionService.getMessages(messages => {
      for (const message of messages)
        if (message.location == 'OUTSIDE') {
          const element = document.getElementById('message')
          if (element) element.innerHTML = message.message
        }
    })
  }

  onOK() { this.sessionService.login(this.clientId(), this.username, this.password) }

  onReset() {
    const dialog = this.dialog.open(ResetFormComponent, {
      data: { client: this.clientId() }, width: '500px', panelClass: 'dialog'
    })
    dialog.afterClosed().subscribe(() => { })
  }
}
