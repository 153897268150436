import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  show() {
    const overlay = document.getElementById("overlay")
    if (overlay) overlay.style.display = "block"
  }

  hide() {
    const overlay = document.getElementById("overlay")
    if (overlay) overlay.style.display = "none"
  }
}
