import { NgModule } from '@angular/core'
import { MatCheckboxModule } from '@angular/material/checkbox'

import { FrameworkModule } from '../framework.module'

import { RoleFormComponent } from './role-form/role-form.component'
import { RoleListComponent } from './role-list/role-list.component'
import { RoleOverviewComponent } from './role-overview/role-overview.component'
import { RolePageComponent } from './role-page/role-page.component'

@NgModule({
  declarations: [
    RoleFormComponent,
    RoleListComponent,
    RoleOverviewComponent,
    RolePageComponent,
  ],
  imports: [ FrameworkModule, MatCheckboxModule ],
  exports: [ RolePageComponent ]
})
export class RoleModule { }
