import { Injectable } from '@angular/core'

import { GenericService } from '../services/generic.service'
import { DatabaseObject } from '../objects'

export interface Role extends DatabaseObject {
  domain: {
    read: string[]
    write: string[],
  }
}

@Injectable({
  providedIn: 'root'
})
export class RoleService extends GenericService<Role> {

  endpoint(version: string = 'v1'): string { return this.sessionService.url('roles', version) }

}
