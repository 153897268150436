import { Component } from '@angular/core'

import { BatchService, Batch } from '../batch.service'
import { GenericOverviewComponent } from '../../generic-overview/generic-overview'

@Component({
  selector: 'app-batch-overview',
  templateUrl: '../../generic-overview/generic-overview.html',
  styleUrls: ['../../generic-overview/generic-overview.css']
})
export class BatchOverviewComponent extends GenericOverviewComponent<Batch> {

  constructor(override service: BatchService) { super(service) }
}
