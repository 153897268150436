import { Component } from '@angular/core';

import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'
import { ContainerTypeService, ContainerType } from '../container-type.service'

@Component({
  selector: 'app-container-type-page',
  templateUrl: './container-type-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css']
})
export class ContainerTypePageComponent extends GenericPageComponent<ContainerType> {

  containerType: ContainerType = { brand: '', containerTypeModel: null, collectionDuration: '0', id: '', identifier: '', name: '', d1: '', h1: '', h2: '', kind: 'UNDERGROUND' +
      '' +
      '', v1: '', v2: '', }

  constructor(protected override service: ContainerTypeService) { super(service) }
}
