import { Component } from '@angular/core'
import { environment } from '../../../environments/environment'
import { MatDialog } from '@angular/material/dialog'
import { Observable } from "rxjs"
import { Router } from "@angular/router"

import { SessionService, Client, User, Session} from '../../framework/services/session.service'
import { LanguageService } from '../../framework/services/language.service'
import { ParameterFormComponent } from '../parameter-form/parameter-form.component'
import { PasswordFormComponent } from '../password-form/password-form.component'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  session: Observable<Session | null>

  constructor(
    protected dialog: MatDialog,
    protected languageService: LanguageService,
    protected sessionService: SessionService,
    protected router: Router,
  ) { this.session = this.sessionService.session() }

  goto(url: string) { this.router.navigateByUrl(url) }

  onLogout() { this.sessionService.logout() }

  onParameters() {
    const dialog = this.dialog.open(ParameterFormComponent, { data: { }, width: '600px', panelClass: 'dialog' })
    dialog.afterClosed().subscribe(() => { this.sessionService.refresh() })
  }

  onPassword() {
    const dialog = this.dialog.open(PasswordFormComponent, { data: { }, width: '400px', panelClass: 'dialog' })
    dialog.afterClosed().subscribe((result: any ) => { })
  }

  setLanguage(language: string) { this.languageService.set(language) }

  version(): string { return environment.version }
}
