<div class="list-header">
  <list-header class="width-10" identifier="identifier" prefix="report." [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header *ngIf="rise" class="width-15" identifier="Client"></list-header>
  <list-header class="width-12" identifier="Request"></list-header>
  <list-header [class]="rise ? 'width-20' : 'width-35'" identifier="Reason"></list-header>
  <list-header class="width-12" identifier="intervention_date" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-20" identifier="Diagnostic"></list-header>
  <list-header class="width-10" identifier="Status" [sorting]="sorting" (sort)="onSort($event)"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as reports">
  <div *ngFor="let report of reports; let index = index">
    <cdk-accordion-item *ngIf="report.handling == 'INVOICEABLE' || report.handling == 'PRO_FORMA' || ('AFTER_SALES' | read)"
        #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
      <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
        <span class="width-10">{{report.id}}</span>
        <span *ngIf="rise" class="width-15">{{report.client?.shortName}}</span>
        <span class="width-12"><span *ngIf="report.request">{{report.request.date | datestamp}}</span></span>
        <span [class]="rise ? 'width-20' : 'width-35'"><span *ngIf="report.request">{{'reason.' + report.request.reason | translate | shorten:rise ? 20 : 35}}</span></span>
        <span class="width-12">{{report.interventionDate | datestamp}}</span>
        <span class="width-20"><span *ngIf="report.diagnostic">{{'diagnostic.' + report.diagnostic | translate | shorten:25}}</span></span>
        <span class="width-10">{{'report.' + report.status | translate}}</span>
      </div>
      <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
        <app-report-form [object]="report" (objectChange)="onUpdate(index, $event)" (event)="onEvent($event)"></app-report-form>
      </div>
    </cdk-accordion-item>
  </div>
</cdk-accordion>
<div class="count" #scroll><span *ngIf="total | async as total">{{count | async}} / {{total}}</span></div>
