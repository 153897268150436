import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import { Subject } from 'rxjs'

import { EcopointService, Ecopoint } from '../ecopoint.service'
import { Mode } from "../../framework/generic-form/generic-form.component";

@Component({
  selector: 'app-ecopoint-select',
  templateUrl: './ecopoint-select.component.html',
  styleUrls: ['./ecopoint-select.component.css']
})
export class EcopointSelectComponent implements OnInit {

  Mode = Mode

  allEcopoints: Ecopoint[] = []
  ecopoints: Subject<Ecopoint[]> = new Subject<Ecopoint[]>()

  @Input() ecopoint: any = null
  @Input() mode !: Mode
  @Input() required: boolean = false
  @Output() ecopointChange = new EventEmitter<Ecopoint | null>()

  constructor(private ecopointService: EcopointService) { }

  doFilterEcopoints() {
    if (typeof this.ecopoint == 'string') {
      this.ecopointChange.next(null)
      this.ecopoints.next(this.allEcopoints.filter(ecopoint => {
        return ecopoint.location.toLowerCase().includes(this.ecopoint)
          || (ecopoint.identifier && ecopoint.identifier.toLowerCase().includes(this.ecopoint))
      }))}
    else {
      this.ecopoints.next(this.allEcopoints)
      this.ecopointChange.next(this.ecopoint)
    }
  }

  ecopointRef(ecopoint: Ecopoint | null): string {
    const prefix =  ecopoint?.identifier
    if (prefix)
      return prefix + ' - ' + ecopoint.location
    else
      return ecopoint?.location ?? ''
  }

  ngOnInit() {
    this.ecopointService.list(ecopoints => {
      this.allEcopoints = ecopoints
      if (this.ecopoint)
        this.ecopoint = this.allEcopoints.find(ecopoint => ecopoint.id == this.ecopoint?.id)
      this.doFilterEcopoints()
    }, 'identifier')
  }
}
