import { Component, Input } from '@angular/core'
import { Color } from '@swimlane/ngx-charts'

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styles: []
})
export class BarChartComponent {

  colors = { domain: ['#2693bc'] } as Color

  @Input() data: any[] = []
  @Input() units: string = ''

  format(date: string) {
    // Date  is mm-dd-yyyy format
    const parts = date.split('/')
    return parts[0] + '-' + parts[1]
  }
}
