import { Pipe, PipeTransform } from '@angular/core'

import { LanguageService } from '../services/language.service'

@Pipe({name: 'timestamp'})
export class TimestampPipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  transform(value: string | null): string {
    if (value)
      return this.languageService.timestamp(new Date(value))
    else
      return ''
  }
}
