<div [class]="account ? 'embedded-header' : 'list-header'">
  <list-header class="width-15" [embedded]="account" [identifier]="'timestamp'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [embedded]="account" [identifier]="'Ticket'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10 numberHeader" [embedded]="account" [identifier]="'Amount'" [right]="true" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-30" [embedded]="account" [identifier]="account ? 'Comment' : 'account_name'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [embedded]="account" [identifier]="'username'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [embedded]="account" [identifier]="'till_name'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as transactions">
  <cdk-accordion-item *ngFor="let transaction of transactions; let index = index"
    #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="(account ? 'embedded-item' : 'list-item') + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span [class]="'width-15' + dim(transaction)">{{transaction.timestamp | timestamp}}</span>
      <span [class]="'width-15' + dim(transaction)">{{(transaction.ticket) | ticket}}</span>
      <span [class]="'width-10 numberItem' + dim(transaction)">{{transaction.amount | number:'1.2-2'}}</span>
      <span [class]="'width-30'">
        <span *ngIf="account" [class]="dim(transaction)">{{transaction.comment  | shorten:30}}</span>
        <a *ngIf="!account && transaction.account" [class]="dim(transaction)" routerLink="/accounts/{{transaction.account.id}}">
          {{transaction.account | link | shorten:30 }}
        </a>
      </span>
      <span [class]="'width-15' + dim(transaction)">{{transaction.username}}</span>
      <span [class]="'width-15' + dim(transaction)">{{transaction.till ? transaction.till.name : ''}}</span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-transaction-form [balance]="null" [object]="transaction" (objectChange)="onUpdate(index, $event)" (event)="onEvent($event)"></app-transaction-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<!-- There is no total count on transaction requests (because of card transactions).-->