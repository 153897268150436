import { NgModule } from '@angular/core'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatTabsModule } from '@angular/material/tabs'

import { FrameworkModule } from '../framework.module'

import { StopListComponent } from './stop-list/stop-list.component'
import { StopMapComponent } from './stop-map/stop-map.component'
import { TourPageComponent } from './tour-page/tour-page.component'
import { TourListComponent } from './tour-list/tour-list.component'
import { TourOverviewComponent } from './tour-overview/tour-overview.component'
import { TourFormComponent } from './tour-form/tour-form.component'
import { TourContainerListComponent } from './tour-container-list/tour-container-list.component'
import { TourViewComponent } from './tour-view/tour-view.component'

@NgModule({
  declarations: [
    StopListComponent,
    StopMapComponent,
    TourContainerListComponent,
    TourFormComponent,
    TourListComponent,
    TourOverviewComponent,
    TourPageComponent,
    TourViewComponent,
  ],
  imports: [
    MatProgressBarModule,
    MatTabsModule,

    FrameworkModule,
  ],
  exports: [ TourPageComponent ]
})
export class TourModule { }
