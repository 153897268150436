import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core'

import { AccountService, Account, emptyAccount } from '../account.service'
import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'
import {Action} from "../../framework/components/action-pane/action-pane.component";

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css']
})
export class AccountPageComponent extends GenericPageComponent<Account> implements OnInit {

  account: Account = emptyAccount

  constructor(
      protected override service: AccountService,
      protected activatedRoute: ActivatedRoute,
  ) { super(service) ; this.sorting = { field: 'last_modification_date', order: 'DESC' } }

  override ngOnInit() { this.onSearch({ id: this.activatedRoute.snapshot.paramMap.get('id') })}

  override onAction(action: Action) {
    if (action == Action.export)
      this.service.export(this.current, this.sorting)
    else
      super.onAction(action)
  }

  // Change the sort order after a creation or update so the user doesn't lose the new/modified account.
  override onEvent(event: string) {
    this.sorting = { field: 'last_modification_date', order: 'DESC' }
    super.onEvent(event)
  }
}
