import { Component } from '@angular/core'

import { GenericOverviewComponent } from '../../framework/generic-overview/generic-overview'
import { GroupingService, Grouping } from '../grouping.service'

@Component({
  selector: 'app-grouping-overview',
  templateUrl: '../../framework/generic-overview/generic-overview.html',
  styleUrls: ['../../framework/generic-overview/generic-overview.css']
})
export class GroupingOverviewComponent extends GenericOverviewComponent<Grouping> {

  constructor(override service: GroupingService) { super(service) }
}
