import { Injectable } from '@angular/core'

import { DatabaseObject } from '../framework/objects'
import { GenericService } from '../framework/services/generic.service'

export type Till = DatabaseObject

@Injectable({
  providedIn: 'root'
})
export class TillService extends GenericService<Till> {

  endpoint(version: string = 'v1'): string { return this.sessionService.url('tills', version) }

}
