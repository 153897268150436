<div class="editor embedded">
  <div class="form">
    <div class="row">
      <app-text-field class="width-15" label="Date" [mode]="Mode.readonly" [value]="subject.timestamp | datestamp"></app-text-field>
      <app-dropdown class="width-35" label="Reason" [prefix]="'reason'" [mode]="mode" [required]="true"
        [values]="reasons" [(value)]="subject.reason" (valueChange)="check()"></app-dropdown>
      <mat-form-field *ngIf="'AFTER_SALES' | read" class="width-15">
        <mat-label>{{'Report' | translate}}</mat-label>
        <input matInput #input class="input" [disabled]="true" [value]="subject.report.id">
      </mat-form-field>
      <a *ngIf="'AFTER_SALES' | read" class="icon-link" mat-icon-button [href]="'/reports/' + subject.report.id"><mat-icon>link</mat-icon></a>
    </div>
    <div class="row">
      <app-text-field class="width-100" label="Comment" [length]="200" [mode]="mode"
        [(value)]="subject.comment"></app-text-field>
    </div>
    <div *ngIf="('AFTER_SALES' | read) && sessionService.wise()" class="row">
      <div *ngIf="mode == Mode.edition; then edition; else noEdition"></div>
      <ng-template #edition>
        <mat-form-field *ngIf="('AFTER_SALES' | write) && mode == Mode.edition" class="width-25">
          <mat-label>{{"User" | translate}}</mat-label>
          <mat-select [(value)]="subject.userId">
            <mat-option>{{'None' | translate}}</mat-option>
            <mat-option *ngFor="let user of users" value="{{user.id}}">{{user.lastname}}, {{user.firstname}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>
      <ng-template #noEdition>
        <app-text-field *ngIf="('AFTER_SALES' | read) && mode != Mode.edition" class="width-25" [mode]="mode" label="User"
          [value]=user></app-text-field>
      </ng-template>
    </div>
  </div>
  <app-form-buttons [actions]="('AFTER_SALES' | write) ? ['delete'] : []" domain="LOCATIONS" [mode]="mode" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
</div>
