import { Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { ContainerService } from '../../container/container.service'
import { convert, DatabaseObject } from '../../objects'
import { GenericFormComponent, Mode } from '../../generic-form/generic-form.component'
import { LocalityService, Locality } from '../../locality/locality.service'
import { LocationService, Location } from '../../location/location.service'
import { Sorting } from '../../services/generic.service'
import { SessionService, Client } from '../../services/session.service'
import { TourService, Tour } from '../tour.service'
import { TruckService, Truck } from '../../truck/truck.service'
import { FlowTypeService, FlowType } from '../../flow-type/flow-type.service'
import { ClientDropdownComponent } from '../../components/client-dropdown/client-dropdown.component'

@Component({
  selector: 'app-tour-form',
  templateUrl: './tour-form.component.html',
  styleUrls: ['../../generic-form/generic-form.component.css']
})
export class TourFormComponent extends GenericFormComponent<Tour> implements OnInit {

  Mode = Mode

  client: DatabaseObject | null = null
  display: string = ''                  // Display string for volume and truck filling.
  executionDate: string = ''
  filter: any = { }
  flowTypes: FlowType[] = []
  kinds: string[]
  locality: DatabaseObject | null = null
  localities: Locality[] = []
  locations: Location[] = []
  page = 1
  sorting: Sorting = { field: 'Identifier', order: 'ASC' }
  state ?: string
  truck: DatabaseObject | null = null
  trucks: Truck[] = []
  validation: boolean = false

  googleService: google.maps.DirectionsService = new google.maps.DirectionsService

  @ViewChild(ClientDropdownComponent) clientDropdown !: ClientDropdownComponent

  constructor (
    protected containerService: ContainerService,
    protected dialog: MatDialog,
    protected flowTypeService: FlowTypeService,
    protected locationService: LocationService,
    protected localityService: LocalityService,
    protected override service: TourService,
    protected sessionService: SessionService,
    protected truckService: TruckService,
  ) {
    super(service)
    containerService.clear()
    locationService.list(locations => this.locations = locations)
    truckService.list(trucks => this.trucks = trucks)
    if (sessionService.wise()) {
      localityService.list(localities => this.localities = localities)
      flowTypeService.list(flowTypes => this.flowTypes = flowTypes)
      this.kinds = ['COLLECTION', 'MAINTENANCE']
    }
    else
      this.kinds = ['AFTER_SALES']
  }

  actions(): string[] { return this.object.validationDate ? ['print'] : ['checklist','print','route','delete'] }

  collection(): boolean { return this.subject.kind == 'COLLECTION' }

  clearFilters() {
    this.client = null
    this.locality = null
    this.state = ''
  }

  override initializeForm() {
    super.initializeForm()
    this.truck = this.object.truck
    if (this.object.executionDate) this.executionDate = new Date(this.object.executionDate).toISOString()
    if (this.object.id)
      this.service.getSelection(this.object.id, selection => {
        this.subject.stops = selection ; this.updateStops()
      })
    else
      // New tours start with an empty list.
      this.subject.stops = []
  }

  override isValid(): boolean {
    if (this.validation)
      return this.subject.validationDate != null
    else if (this.subject.kind == 'AFTER_SALES')
      return this.subject.name > ' '
    else
    {
      const flowType = this.collection() ? this.subject.flowType != null : true
      return flowType && this.subject.name > ' ' && this.subject.startingPoint != null && this.subject.truck != null
    }
  }

  override onCancel() {
    // Clear selection filters (original selection is restored by initializeForm).
    super.onCancel()
    this.clearFilters()
  }

  onChangeType() {
    // On tour type or flow type change, the current selection must be cleared.
    this.subject.stops.splice(0)
    this.updateVolume()
    this.check()
    this.updateContainers()
  }

  onClient(client: Client | null) { this.client = client ; this.onFilter() }

  override onEdit() { super.onEdit() ; this.updateContainers() }

  override onEvent(event: string) {
    if (event == 'checklist') {
      this.sorting = { field: 'selection', order: 'ASC' }
      this.validation = true
      this.onEdit()
    } else if (event == 'print') {
      if (this.subject.stops) this.service.print(this.subject)
    } else if (event == 'route') {
      if (this.sessionService.wise())
        this.service.optimize(this.object.id).subscribe(tour => { this.objectChange.next(tour) })
      else
        this.optimize()
    } else
      super.onEvent(event)
  }

  onExecution() {
    this.subject.executionDate = this.sessionService.toISO(new Date(this.executionDate))
    this.updateStops()
    this.updateContainers() }

  onFilter() {
    if (this.sorting.field == 'selection') this.sorting.field = 'identifier'
    this.updateContainers()
  }

  override onSave() {
    this.clearFilters()
    if (this.validation)
      this.validate()
    else
      super.onSave()
  }

  onScroll() {
    if (this.mode == Mode.edition && this.containerService.more())
      this.containerService.fetch(this.filter,
        this.sorting.field == 'selection' ? { field: 'identifier', order: 'ASC' } : this.sorting, ++this.page)
  }

  onSort(sorting: Sorting) {
    if (this.sorting.field == 'selection' && sorting.field == 'selection')
      // If sorting is already by selection (hence only selected containers are displayed) sort by identifier.
      this.sorting = { field: 'Identifier', order: 'ASC' }
    else {
      // When setting sorting to selection, filters need to be cleared.
      this.sorting = sorting
      if (this.clientDropdown) this.clientDropdown.reset()
      this.client = null ; this.locality = null ; this.state = ''
    }
    this.updateContainers()
  }

  onTruck() {
    if (this.truck) {
      this.subject.truck = this.truck as Truck
      this.updateVolume()
    }
  }

  optimize() {

    let waypoints: google.maps.DirectionsWaypoint[] = []

    for (const stop of this.subject.stops)
      if (waypoints.length < 25 && stop.container && stop.container.ecopoint)
        waypoints.push({ location: convert(stop.container.ecopoint.center), stopover: true })

    if (this.subject.startingPoint) {

      const origin = convert((<Location>this.subject.startingPoint).position)

      this.googleService.route({
        destination: origin, origin: origin, optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING, waypoints: waypoints
      }).then(response => {
        if (response && response.routes && response.routes.length) {
          response.routes[0].waypoint_order.map((order: number, index: number) =>
            this.subject.stops[order].order = index + 1)
          this.service.update(this.subject).subscribe(tour => this.objectChange.next(tour))
        }
      })
    }
  }

  rise(): boolean { return this.sessionService.rise() }

  updateContainers() {

    const flowType = this.subject.flowType

    // No containers are displayed for collection tours until a flow type is chosen.
    if (this.collection() && flowType == null) return

    this.page = 1
    if (this.mode == Mode.edition) {
      this.filter = {
        afterSales: this.state == 'afterSales',
        clientId: this.client ? this.client.id : null,
        communicationState: this.state == 'missing' ? 'MISSING' : '',
        ...flowType && { flowTypeId: flowType.id },
        forecastDate: this.subject.executionDate,
        localityId: this.locality ? this.locality.id : null,
        ids: this.sorting.field == 'selection' ? this.subject.stops.map(stop => stop.id) : null,
      }
      this.containerService.fetch(this.filter,
        this.sorting.field == 'selection' ? {field: 'identifier', order: 'ASC'} : this.sorting
      )
    } else
      console.log('Error calling updateContainers while not editing...')
  }

  updateStops() { this.service.getStops(this.subject,_ => { this.updateVolume() }) }

  updateVolume() {
    if (this.subject.kind == 'COLLECTION') {
      let volume = 0
      for (const stop of this.subject.stops)
        if (stop.container) volume += stop.container.volume ? stop.container.volume : 1
      if (this.truck) {
        const maximum = (this.truck as Truck).volumeMax
        if (maximum) {
          this.display = (volume / 1000).toFixed(1) + '/' + (maximum / 1000).toFixed(1) + " (" + (100 * volume / maximum).toFixed() + '%)'
          return
        }
      }
      this.display = (volume / 1000).toFixed(1)
    }
  }

  override updateSubject() { for (let stop of this.subject.stops) stop.order = 0 }

  validate() {
    this.service.validate(this.subject).subscribe(tour => {
      this.mode = Mode.reading
      this.event.next('save')
      // Trigger a parent view update with a new object.
      this.objectChange.next(tour)
    })
  }

  wise(): boolean { return this.sessionService.wise() }
}
