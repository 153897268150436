<div class="messages">
  <h3 id=message></h3>
</div>
<div class="container">
  <div class="column">
    <mat-form-field>
      <mat-label>{{"username" | translate}}</mat-label>
      <input matInput [(ngModel)]="username" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{"Password" | translate}}</mat-label>
      <input matInput [(ngModel)]="password" required type="password">
    </mat-form-field>
    <app-client-dropdown *ngIf="!identifier" (client)="client = $event"></app-client-dropdown>
    <button mat-flat-button disabled="{{disabled()}}" (click)="onOK()">OK</button>
    <p *ngIf="clientId()"><a (click)="onReset()">{{"Forgotten password ?" | translate}}</a></p>
  </div>
</div>
