import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import { UserOverviewComponent } from '../framework/user/user-overview/user-overview.component'
import { UserFormComponent } from './user-form/user-form.component'
import { UserListComponent } from '../framework/user/user-list/user-list.component'
import { UserPageComponent } from '../framework/user/user-page/user-page.component'

@NgModule({
  declarations: [
    UserOverviewComponent,
    UserFormComponent,
    UserListComponent,
    UserPageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [ UserPageComponent ]
})
export class UserModule { }
