import { Component } from '@angular/core';

import { GenericFormComponent, Mode } from '../../generic-form/generic-form.component'
import { RequestService, Request } from '../request.service'
import { SessionService } from '../../services/session.service'
import { UserService, User } from '../../user/user.service'

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['../../generic-form/generic-form.component.css']
})
export class RequestFormComponent extends GenericFormComponent<Request> {

  Mode = Mode

  reasons: string[]
  user: string = ''
  users: User[] = []

  constructor(
    protected override service: RequestService,
    protected sessionService: SessionService,
    protected userService: UserService,
  ) {
    super(service)
    this.reasons = service.reasons
    if (sessionService.wise())
      this.userService.list(users => {
        this.users = users
        const user = users.find(user => user.id == this.object.userId)
        if (user) this.user = user.lastname + ', ' + user.firstname
      },'lastName')
  }

  override isValid(): boolean { return this.subject.reason > ' ' }
}
