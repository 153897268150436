import { FileSaverService } from 'ngx-filesaver'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { GenericService } from '../framework/services/generic.service'
import { LanguageService } from '../framework/services/language.service'
import { SpinnerService } from '../framework/services/spinner.service'
import { SessionService } from '../framework/services/session.service'
import { SnackbarService } from '../framework/services/snackbar.service'

export interface Closure {
  id: string,
  identifier: string,
  endDate: string,
  executionDate: string,
  label: string,
  startDate: string,
  status: string,
  statistics: Statistics | null,
}

export interface Statistics {
  accounts: string,
  lines: string,
  totalPrice: number,
}
@Injectable({
  providedIn: 'root'
})
export class ClosureService extends GenericService<Closure> {

  constructor(
    protected override fileSaverService: FileSaverService,
    protected override httpClient: HttpClient,
    protected languageService: LanguageService,
    protected override sessionService: SessionService,
    protected snackbar: SnackbarService,
    protected override spinner: SpinnerService,
  ) { super(fileSaverService, httpClient, sessionService, spinner) }

  close(closure: Closure) {
    this.httpClient.post<void>(this.endpoint() + '/' + closure.id + '/close', { })
      .subscribe(() => { this.snackbar.info(this.languageService.translate('closure.closing'))})
  }

  endpoint(version: string = 'v1'): string { return this.sessionService.url('closures', version) }

  export(id: string, callback: () => void) { this.exportToFile(id,{}, callback) }

  last(callback: (closures: Closure[]) => void) {
    this.httpClient.get<Closure[]>(this.endpoint(),{
      params: { orderBy: 'enddate;DESC' } }).subscribe(closures => callback(closures))
  }

  reopen(closure: Closure) {
    this.httpClient.post<void>(this.endpoint() + '/' + closure.id + '/reopen', { })
      .subscribe(() => { this.snackbar.info(this.languageService.translate('closure.reopening'))})
  }

  override update(closure: Closure): Observable<Closure> {
    return this.putOne(closure.id, {
      endDate: this.sessionService.toISO(new Date(closure.endDate)),
      identifier: closure.identifier,
      label: closure.label,
      startDate: this.sessionService.toISO(new Date(closure.startDate)),
    })
  }
}
