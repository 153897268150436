<div class="action-pane">
  <div>
    <h1>{{'Actions' | translate}}</h1>
    <mat-form-field class="field">
      <mat-label>{{"Quick search..." | translate}}</mat-label>
      <mat-hint>
        <span *ngFor="let item of hint; let i = index" class="hint">{{separator(i)}}{{item | translate}}</span>
      </mat-hint>
      <input matInput [(ngModel)]="magicFilter" (keydown.enter)="magic.emit(this.magicFilter)">
    </mat-form-field>
    <mat-form-field *ngIf="criteria" class="field bottom">
      <mat-label>{{criteria | translate}}</mat-label>
      <input matInput [(ngModel)]="searchFilter" (keydown.enter)="search.emit(this.searchFilter)">
    </mat-form-field>
  </div>
  <div class="button-list">
    <button mat-flat-button *ngIf="export" (click)="action.emit(Action.export)">{{'Export' | translate}}</button>
    <button mat-flat-button *ngIf="print" (click)="action.emit(Action.print)">{{'Print' | translate}}</button>
    <button mat-flat-button *ngIf="domain | write" (click)="action.emit(Action.new)">{{'New' | translate}}</button>
  </div>
</div>
