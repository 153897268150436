import { Pipe, PipeTransform } from '@angular/core'

import { SessionService } from '../services/session.service'

@Pipe({
  name: 'read'
})
export class ReadPipe implements PipeTransform {

  constructor(protected sessionService: SessionService) { }

  transform(value: string): boolean { return this.sessionService.read(value) }
}
