import { Component, OnInit } from '@angular/core'

import { Action } from '../../components/action-pane/action-pane.component'
import { GenericPageComponent } from '../../generic-page/generic-page.component'
import { Report, ReportService } from '../report.service'

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['../../generic-page/generic-page.component.css']
})
export class ReportPageComponent extends GenericPageComponent<Report> implements OnInit {

  Action = Action

  report: Report = {
    comment: '', client: null, locality: null, container: null, diagnostic: '', ecopoint: null, handling: '', id: '',
    interventionDate: '', invoiceDate: '', invoiceRef: '', positions: [], request:  null, status: 'EDITED', technician: null,
  }

  constructor(protected override service: ReportService) {
    super(service) ; this.sorting = { field: 'id', order: 'DESC' }
    this.report.technician = service.technician()
  }

  override ngOnInit() { /* No default loading */ }
}
