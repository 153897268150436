// Basic objects for generic list, form and service implementations.

export interface DatabaseObject {
  id: string,
  identifier: string,
  name: string,
}

export interface Point {
  x: number,
  y: number,
}

export const emptyObject: DatabaseObject = {
  id: '',
  identifier: '',
  name: '',
}

export function convert(point: Point): google.maps.LatLngLiteral { return { lng: point.x, lat: point.y } }
