<div class="pane-container">
  <app-role-overview title="Roles" (selection)="onShortcut($event)"></app-role-overview>
  <app-action-pane domain="SETTINGS" [hint]="['Name']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event})"></app-action-pane>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-role-form [creation]="true" [mode]="Mode.edition" [object]="role" (event)="onEvent($event)"></app-role-form>
</ng-template>
<ng-template #showList>
  <app-role-list #list [sorting]="sorting" (event)="onEvent($event)" (sort)="onSort($event)"></app-role-list>
</ng-template>
