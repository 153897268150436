import { Component } from '@angular/core'

import { StockService, Stock } from '../stock.service'
import { GenericOverviewComponent } from '../../framework/generic-overview/generic-overview'

@Component({
  selector: 'app-stock-overview',
  templateUrl: '../../framework/generic-overview/generic-overview.html',
  styleUrls: ['../../framework/generic-overview/generic-overview.css']
})
export class StockOverviewComponent extends GenericOverviewComponent<Stock>{

  constructor(protected override service: StockService) { super(service) }
}
