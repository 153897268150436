<div class="list-header">
  <list-header *ngIf="rise()" class="width-15" [identifier]="'Client'"></list-header>
  <list-header class="width-12" [identifier]="'Identifier'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-30" [identifier]="'Location'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [identifier]="'last_collection_date'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [identifier]="'last_access_date'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header *ngIf="rise()" class="width-15" [identifier]="'heartbeat'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header *ngIf="wise()" class="width-10" [identifier]="'Forecast'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header *ngIf="wise()" class="width-15" [identifier]="'Level'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <span class="width-2"></span>
  <span class="width-5"></span>
  <span *ngIf="wise()" class="width-5"></span>
</div>
<cdk-accordion *ngIf="objects | async as containers">
  <cdk-accordion-item *ngFor="let container of containers; let index = index"
    #accordionItem="cdkAccordionItem" expanded="{{index == selection}}"  role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span *ngIf="rise()" class="width-15">{{container.client ? container.client.shortName : ''}}</span>
      <span class="width-12">{{container.identifier}}</span>
      <span class="width-30">
        <span *ngIf="container.ecopoint; else noEcopoint">{{container.ecopoint.location | shorten:30}}</span>
        <ng-template #noEcopoint>{{container.stock?.name}}</ng-template>
      </span>
      <span class="width-10">{{container.lastCollectionDate | datestamp}}</span>
      <span class="width-10">{{container.lastAccessDate | datestamp}}</span>
      <span *ngIf="rise()" class="width-15"><span *ngIf="container.ecolog">{{container.ecolog.heartbeat | timestamp}}</span></span>
      <span *ngIf="wise()" class="width-10">{{container.forecastDate | datestamp}}</span>
      <span *ngIf="wise() && container.level != null" class="width-15 wrapper">
        <span>{{container.level}}%</span>
        <mat-progress-bar color="{{container.level > 80 ? 'warn' : 'default'}}" class="overlay" mode="determinate"
          [value]="container.level"></mat-progress-bar>
      </span>
      <span class="width-2"></span>
      <span class="width-5 right">
        <button mat-icon-button *ngIf="container.alarms.length" [matMenuTriggerFor]="alarms" (click)="$event.stopPropagation()">
          <mat-menu #alarms="matMenu" xPosition="after">
            <button mat-menu-item *ngFor="let alarm of container.alarms" (click)="onAlarm(index, container, alarm)">
              {{'alarm.' + alarm | translate}} <mat-icon class="icon">clear</mat-icon>
            </button>
          </mat-menu>
          <mat-icon [matTooltip]="tooltip(container.alarms)">notifications_none</mat-icon>
        </button>
      </span>
      <span *ngIf="wise()" class="width-5 right">
        <button mat-icon-button *ngIf="container.afterSalesRequest" (click)="onIntervention(container.ecopoint)">
          <mat-icon>construction</mat-icon>
        </button>
      </span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-container-view [container]="container" [(index)]="preset" (containerChange)="onUpdate(index, $event)" (event)="onEvent($event)"></app-container-view>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count" #scroll><span *ngIf="total | async as total">{{count | async}} / {{total | big}}</span></div>
