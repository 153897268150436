import {Component, OnInit } from '@angular/core'

import { Waste, WasteService } from '../waste.service'
import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'

@Component({
  selector: 'app-waste-page',
  templateUrl: './waste-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css'],
})
export class WastePageComponent extends GenericPageComponent<Waste> implements OnInit {

  constructor(protected override service: WasteService) {
    super(service) ; this.sorting = { field: 'timestamp', order: 'DESC' }
  }

  override ngOnInit() { /* No default loading */ }
}
