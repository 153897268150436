import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Locality, LocalityService } from '../../framework/locality/locality.service'
import { FlowType, FlowTypeService } from '../../framework/flow-type/flow-type.service'
import { DatabaseObject } from '../../framework/objects'

@Component({
  selector: 'app-container-filter',
  templateUrl: './container-filter.component.html',
  styleUrls: ['../../framework/generic-filter/generic-filter.component.css']
})
export class ContainerFilterComponent {

  flowTypes: FlowType[] = []
  localities: Locality[] = []

  @Input() filter: any = { flowTypeId: null, localityId: null }
  @Output() filterChange = new EventEmitter()

  constructor(
    protected localityService: LocalityService,
    protected flowTypeService: FlowTypeService,
  ) {
    flowTypeService.list(flowTypes => this.flowTypes = flowTypes)
    localityService.list(localities => this.localities = localities)
  }

  onFlowType(flowType: DatabaseObject | null) {
    this.filter.flowTypeId = flowType ? flowType.id : null
    this.filterChange.emit(this.filter)
  }

  onLocality(locality: DatabaseObject | null) {
    this.filter.localityId = locality ? locality.id : null
    this.filterChange.emit(this.filter)
  }
}
