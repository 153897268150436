import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import { ClosureFormComponent } from './closure-form/closure-form.component'
import { ClosureListComponent } from './closure-list/closure-list.component'
import { ClosureOverviewComponent } from './closure-overview/closure-overview.component'
import { ClosurePageComponent } from './closure-page/closure-page.component'

@NgModule({
  declarations: [
    ClosureFormComponent,
    ClosureListComponent,
    ClosureOverviewComponent,
    ClosurePageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [ ClosurePageComponent ],
})
export class ClosureModule { }
