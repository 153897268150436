<div class="embedded-header">
  <span *ngIf="sorting.field == 'selection'; else check" class="width-5">
    <button mat-icon-button class="button" (click)="onSort('selection')"><mat-icon>remove</mat-icon></button>
  </span>
  <ng-template #check>
    <span class="width-5" >
      <button mat-icon-button class="button" (click)="onSort('selection')"><mat-icon>check</mat-icon></button>
    </span>
  </ng-template>
  <list-header *ngIf="rise()" class="width-15" [embedded]="true" identifier="client_name" [sorting]="mode == Mode.edition ? sorting : null"
    (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [embedded]="true" identifier="Identifier" [sorting]="mode == Mode.edition ? sorting : null"
    (sort)="onSort($event)"></list-header>
  <list-header class="width-30" [embedded]="true" identifier="Location" [sorting]="mode == Mode.edition ? sorting : null"
    (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [embedded]="true" identifier="last_collection_date" [sorting]="mode == Mode.edition ? sorting : null"
    (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [embedded]="true" identifier="last_access_date" [sorting]="mode == Mode.edition ? sorting : null"
    (sort)="onSort($event)"></list-header>
  <list-header *ngIf="rise()" class="width-15" [embedded]="true" identifier="heartbeat" [sorting]="mode == Mode.edition ? sorting : null"
    (sort)="onSort($event)"></list-header>
  <list-header *ngIf="wise()" class="width-10" [embedded]="true" identifier="Forecast" [sorting]="mode == Mode.edition ? sorting : null"
    (sort)="onSort($event)"></list-header>
  <list-header *ngIf="wise()" class="width-15" [embedded]="true" identifier="Level" [sorting]="mode == Mode.edition ? sorting : null"
    (sort)="onSort($event)"></list-header>
</div>
<div *ngIf="objects | async as containers">
  <div *ngFor="let container of containers">
    <div [class]="'embedded-item' + (isSelected(container.id) ? ' highlight' : '')" (click)="onSelect($event, container)">
      <span class="width-5 number"><mat-icon *ngIf="isSelected(container.id)" class="check">check</mat-icon></span>
      <span *ngIf="rise()" class="width-15">{{container.client ? container.client.shortName : ''}}</span>
      <span class="width-15">{{container.identifier}}</span>
      <span class="width-30">
        <span *ngIf="container.ecopoint; else noEcopoint">{{container.ecopoint.location | shorten:25}}</span>
        <ng-template #noEcopoint>{{container.stock?.name}}</ng-template>
      </span>
      <span class="width-10">{{container.lastCollectionDate | datestamp}}</span>
      <span class="width-10">{{container.lastAccessDate | datestamp}}</span>
      <span *ngIf="rise() && container.ecolog" class="width-15">{{container.ecolog.heartbeat | timestamp}}</span>
      <span *ngIf="wise()" class="width-10">{{container.forecastDate | datestamp}}</span>
      <span *ngIf="wise() && container.level != null" class="width-15 wrapper">
        <span [style.color]="color(container.levelSource)">{{container.level}}%</span>
        <mat-progress-bar *ngIf="container.levelSource == 'FORECAST'" color="{{container.level > 80 ? 'warn' : 'default'}}"
          class="overlay" mode="determinate" [value]="container.level"></mat-progress-bar>
      </span>
    </div>
  </div>
</div>
<div class="count" #scroll><span *ngIf="total | async as total">{{count | async}} / {{total | big}}</span></div>
