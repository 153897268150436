import { Component } from '@angular/core'

import { SessionService } from '../../framework/services/session.service'
import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'
import { GroupingService, Grouping } from '../grouping.service'

@Component({
  selector: 'app-grouping-page',
  templateUrl: './grouping-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css']
})
export class GroupingPageComponent extends GenericPageComponent<Grouping> {

  grouping: Grouping = { center: this.sessionService.defaultPosition(), id: '', identifier: '', name: '', zone: null }

  constructor(
    protected sessionService: SessionService,
    protected override service: GroupingService,
  ) { super(service) }
}
