<div [class]="container ? ' embedded-header' : 'list-header'">
  <list-header class="width-15" [embedded]="container" [identifier]="'timestamp'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [embedded]="container" [identifier]="'container_identifier'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [embedded]="container" [identifier]="'flowtype_name'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [embedded]="container" [identifier]="'tour_name'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [embedded]="container" [identifier]="'Status'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10 numberHeader" [embedded]="container" [identifier]="'Level'"></list-header>
  <list-header class="width-15 numberHeader" [embedded]="container" [identifier]="'Weight (kg)'"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as collections">
  <cdk-accordion-item *ngFor="let collection of collections; let index = index;"
      #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="(container ? 'embedded-item' : 'list-item') + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span [class]="'width-15' + dim(collection)">{{collection.timestamp | timestamp}}</span>
      <span [class]="'width-15' + dim(collection)">{{collection.container?.identifier}}</span>
      <span [class]="'width-15' + dim(collection)">{{collection.flowType?.name}}</span>
      <span [class]="'width-15' + dim(collection)">{{collection.tour?.name}}</span>
      <span [class]="'width-10' + dim(collection)">{{'collection.' + collection.status  | translate}}</span>
      <span [class]="'width-10 numberItem' + dim(collection)"><span *ngIf="collection.level">{{collection.level}}%</span></span>
      <span [class]="'width-15 numberItem' + dim(collection)"><span *ngIf="collection.finalWeight">{{collection.finalWeight / 1000 | number:'1.2-2'}}</span></span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-collection-form [object]="collection"></app-collection-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count" #scroll><span *ngIf="total | async as total">{{count | async}} / {{total | big}}</span></div>
