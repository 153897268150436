<div class="editor">
  <div class="form">
    <div class="row">
      <app-dropdown class="width-15" [label]="'Status'" [prefix]="'ecopoint'" [mode]="mode"
        [(value)]="subject.status" [values]="['ACCESSIBLE', 'NON_ACCESSIBLE', 'INACTIVE']"></app-dropdown>
      <app-object-dropdown class="width-35" [label]="'Locality'" [mode]="mode" [required]="true"
        [(object)]="subject.locality" [objects]="localities" (objectChange)="onLocality($event)">
      </app-object-dropdown>
      <app-object-dropdown class="width-35" [label]="'Zone'" [mode]="mode"
        [(object)]="subject.zone" [objects]="zones" ></app-object-dropdown>
    </div>
    <div class="row">
      <app-text-field class="width-15" [label]="'Identifier'" [length]="10" [mode]="mode"
        [(value)]="subject.identifier"></app-text-field>
      <app-text-field class="width-35" [label]="'Location'" [length]="200" [mode]="mode" [required]="true"
        [(value)]="subject.location" (valueChange)="check()"></app-text-field>
    </div>
    <app-position [(center)]="subject.center" [mode]="mode" [zoom]="14"></app-position>
  </div>
  <app-form-buttons [actions]="('AFTER_SALES' | write) ? ['delete'] : []" domain="LOCATIONS" [mode]="mode" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
</div>
