<div class="pane-container">
  <app-category-overview title="Categories"
    (selection)="onShortcut($event)"></app-category-overview>
  <app-action-pane [hint]="['Reference', 'Name']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event})"></app-action-pane>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-category-form [creation]="true" [mode]="Mode.edition" [object]="category" (event)="onEvent($event)"></app-category-form>
</ng-template>
<ng-template #showList>
  <app-category-list #list [sorting]="sorting" (event)="onEvent($event)" (sort)="onSort($event)"></app-category-list>
</ng-template>

