import { Component } from '@angular/core';

import { GenericListComponent } from '../../generic-list/generic-list.component'
import { LocationService, Location } from '../location.service'

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['../../generic-list/generic-list.component.css']
})
export class LocationListComponent extends GenericListComponent<Location> {

  constructor(protected override service: LocationService) { super(service) }
}
