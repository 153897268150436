import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework.module'

import { TruckFormComponent } from './truck-form/truck-form.component'
import { TruckListComponent } from './truck-list/truck-list.component'
import { TruckOverviewComponent } from './truck-overview/truck-overview.component'
import { TruckPageComponent } from './truck-page/truck-page.component'

@NgModule({
  declarations: [
    TruckFormComponent,
    TruckListComponent,
    TruckOverviewComponent,
    TruckPageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [ TruckPageComponent ]
})
export class TruckModule { }
