import { FileSaverService } from 'ngx-filesaver'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { Calibration } from './calibration.service'
import { SessionService, Client } from '../services/session.service'
import { DatabaseObject, Point } from '../objects'
import { GenericService, Sorting, toString } from '../services/generic.service'
import { LanguageService } from '../services/language.service'
import { Request } from './request.service'
import { SpinnerService } from '../services/spinner.service'

export interface Collection {
  level: number
  periodicity: number
  status: string
  timestamp: string
  weight: number
}

export interface Container {
  alarmLevel: string
  alarms: string[]
  client ?: Client                      // For inter-compatibility with RISE.
  containerType: DatabaseObject | null
  ecolog: Ecolog | null
  ecopoint: Ecopoint | null
  flowType: DatabaseObject | null
  forecastDate: string | null
  grouping: DatabaseObject | null
  id: string
  identifier: string
  afterSalesRequest: Request | null
  installationDate: string | null
  installationType: string
  installationVersion: string
  lastCollectionDate: string | null
  lastLevelDate: string | null
  lastMeasureDate: string | null
  lastMaintenanceDate: string | null
  lastAccessDate: string | null
  level: number
  levelSource: string
  lid: string
  locality: DatabaseObject | null
  location: string
  position: Point | null
  status: string
  stock: DatabaseObject | null
  volume: number
  zone: DatabaseObject | null
}

export interface Ecolog {
  id: string
  firmware: string
  hardware: string
  heartbeat: string
  serial: string
}

export interface Ecopoint {
  id: string
  identifier: string
  center: Point
  location: string
}

export interface Level {
  level: number
  timestamp: string
}

export interface LocationFilter {
  ecopointId ?: string
  groupingId ?: string
  localityId ?: string
  zoneId ?: string | null
}

@Injectable({
  providedIn: 'root'
})
export class ContainerService extends GenericService<Container> {

  constructor(
    protected override fileSaverService: FileSaverService,
    protected override httpClient: HttpClient,
    protected languageService: LanguageService,
    protected override sessionService: SessionService,
    protected override spinner: SpinnerService,
  ) { super(fileSaverService, httpClient, sessionService, spinner) }

  calibrations(containerId: string): Observable<Calibration[]> {
    return this.httpClient.get<Calibration[]>(this.endpoint() + '/' + containerId + '/calibrations')
  }

  endpoint(version: string = 'v1'): string { return this.sessionService.url('containers', version) }

  export(filter: any, sorting: Sorting) {
    this.exportToFile('',{
      alarms: filter.alarms ?? '',
      localityId: filter.localityId ?? '',
      ecopointId: filter.ecopointId ?? '',
      levelMax: filter.levelMax ?? '',
      levelMin: filter.levelMin ?? '',
      magicFilter: filter.magic ?? '',
      status: filter.status ?? '',
      withAfterSalesRequest : filter.afterSalesRequest ?? '',
      withoutZone: filter.zoneId == '0',
      zoneId: filter.zoneId && (filter.zoneId > '0') ? filter.zoneId : '',
      orderBy: toString(sorting)
    })
  }

  clearAlarm(index: number, id: string, alarm: string) {
    this.httpClient.delete<Container>(this.endpoint() + '/' + id + '/alarms/' + alarm).subscribe(
      container => this.set(index, container)
    )
  }

  collections(containerId: string, start: Date, end: Date) {
    return this.httpClient.get<Collection[]>(this.endpoint() + '/' + containerId + '/collections',
      { params: { startDate: this.sessionService.toISO(start), endDate: this.sessionService.toISO(end) }
    })
  }

  override fetch(filter: any, sorting: Sorting = { field: 'identifier', order: 'ASC'}, page: number = 1) {
    if (filter.id)
      this.getOne(filter.id)
    else if (filter.ids)
      this.getByIds(filter.ids, filter.forecastDate)
    else
      this.getAll({
        alarms: filter.alarms ?? '',
        clientId: filter.clientId ?? '',
        localityId: filter.localityId ?? '',
        communicationState: filter.communicationState ? filter.communicationState.toUpperCase() : '',
        containerIds: filter.ids ?? '',
        heartbeatFrom: filter.date ?? '',
        heartbeatTo: filter.date ?? '',
        ecopointId: filter.ecopointId ?? '',
        ...filter.forecastDate && { forecastDate: this.sessionService.toISO(new Date(filter.forecastDate)) },
        flowTypeId: filter.flowTypeId ?? '',
        levelMax: filter.levelMax ?? '',
        levelMin: filter.levelMin ?? '',
        magicFilter: filter.magic ?? '',
        status: filter.status ?? '',
        ...(filter.afterSales) && { withAfterSalesRequest: true },
        withoutZone: filter.zoneId == '0',
        zoneId: filter.zoneId && (filter.zoneId > '0') ? filter.zoneId : '',
        orderBy: toString(sorting), 'page': page, 'per_page': 50
      }
    )
  }

  getByIds(ids: string[], forecastDate: string | null, callback ?: (containers: Container[]) => void) {
    if (forecastDate) {
      this._more = false
      this.httpClient.post<Container[]>(this.endpoint() + '/find-by-ids', {
        containerIds: ids, forecastDate: this.sessionService.toISO(new Date(forecastDate)) }).subscribe(containers => {
          this._objects.next(containers)
        if (callback) callback(containers)
      })
    }
    else
      console.log('Error - ContainerService::getByIds')
  }

  installationTypes(): string[] { return [
    'EL-GA-SC', 'EL-GA', 'EL-GA-TMB', 'EL-GA-BBD', 'EL-GAN',
    'EL-N',
    'EL-PIMK4', 'EL-PI5-BLB', 'EL-PI5-SCT', 'EL-PI5-C', 'EL-BAL',
    'EL-DUO'
  ] }

  levels(id: string, start: Date, end: Date): Observable<Level[]> {
    return this.httpClient.get<Level[]>(this.endpoint() + '/' + id + '/levels', {
      params: { startDate: this.sessionService.toISO(start), endDate: this.sessionService.toISO(end) }
    })
  }

  removeEcolog(id: string, callback: (container: Container) => void) {
    this.httpClient.delete<Container>(this.endpoint() + '/' + id + '/ecolog').subscribe(container =>
      callback(container)
    )
  }

  replaceEcolog(id: string, ecolog: string, warranty: boolean, callback: (container: Container) => void) {
    this.httpClient.post<Container>(this.endpoint() + '/' + id + '/ecolog', {
      ecologId: ecolog,
      warrantySwap: warranty,
    }).subscribe(container => callback(container))
  }

  statuses(): string[] { return ['BROKEN', 'BURNED', 'DEFECT', 'INACTIVE', 'IN_REPARATION', 'IN_STOCK', 'OPERATIONAL'] }

  override update(container: Container): Observable<Container> {
    return this.putOne(container.id, {
      alarmLevel: container.alarmLevel,
      containerTypeId: container.containerType?.id,
      ecopointId: container.ecopoint?.id,
      flowTypeId: container.flowType?.id,
      identifier: container.identifier,
      installationDate: container.installationDate,
      ...(container.installationType > '') && { installationType: container.installationType?.replace(/-/g,'_') },
      installationVersion: container.installationVersion,
      lid: container.lid,
      location: container.location,
      status: container.status,
      stockId: container.stock?.id,
    })
  }
}
