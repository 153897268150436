import { Component, Input } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { Card, CardService } from '../card.service'
import { GenericFormComponent, Mode } from '../../framework/generic-form/generic-form.component'

@Component({
  selector: 'app-card-form',
  templateUrl: './card-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class CardFormComponent extends GenericFormComponent<Card> {

  Mode = Mode

  @Input() unbindable: boolean = false

  constructor(
    protected dialog: MatDialog,
    protected override service: CardService,
  ) { super(service)
  }

  override onEvent(event: string) {
    if (event == 'delete')
      this.unbind()
    else
      super.onEvent(event)
  }

  // Return full list in edition mode only if the user has the proper right.
  statuses(right: boolean): string[] { return this.service.statuses((this.mode != Mode.edition) || right) }

  unbind() {
    const card: Card = this.object
    if (card.waste.countOf == 0) {
      this.mode = Mode.none             // Avoid entering edition mode while unbinding is in progress.
      // Send a delete event in case of success so that the list is refreshed.
      if (card.account) this.service.unbind(card).subscribe(() => { this.event.next('delete') })
    } else {
      // NOTE Out factored for production.
      // const dialog = this.dialog.open(ConfirmationComponent, {
      //   data: {
      //     title: "WARNING",
      //     message: 'There are ' + card.waste.countOf + ' deposits with this card... \n These deposits will be removed from the account.',
      //     question: "Are you sure ?",
      //   },
      //   width: '400px',
      //   panelClass: 'dialog'
      // })
      // dialog.afterClosed().subscribe(result => { console.log('Answered', result) })
    }
  }
 }
