<div class="list-header">
  <list-header class="width-20" [identifier]="'Identifier'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-60" [identifier]="'Name'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-20" [identifier]="'Zone'"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as groupings">
  <cdk-accordion-item *ngFor="let grouping of groupings; let index = index"
      #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span class="width-20">{{grouping.identifier}}</span>
      <span class="width-60">{{grouping.name}}</span>
      <span class="width-20">{{grouping.zone?.name}}</span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-grouping-form [object]="grouping" (objectChange)="onUpdate(index, $event)" (event)="onEvent($event)"></app-grouping-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count"><span *ngIf="total | async as total">{{count | async}} / {{total}}</span></div>
