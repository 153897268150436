<div class="editor">
    <!-- Width is pane width (1024) - buttons width (112) - image width (350) -->
    <div class="form" [style.width]="'562px'">
      <div>
        <div class="row">
          <app-object-dropdown class="width-30" label="Model" [mode]="mode" prefix="containerType" [objects]="models"
            [(object)]="model" (objectChange)="onModel()"></app-object-dropdown>
          <app-text-field class="width-30" label="Identifier" [length]="10" [mode]="mode"
            [(value)]="subject.identifier"></app-text-field>
          <app-number-field class="width-25" label="Collection Duration" [mode]="mode" [(value)]="subject.collectionDuration"></app-number-field>
        </div>
        <div class="row">
          <app-text-field class="width-30" label="Brand" [length]="10" [mode]="model ? Mode.readonly : mode"
            [(value)]="subject.brand"></app-text-field>
          <app-text-field class="width-30" label="Name" [length]="20" [mode]="model ? Mode.readonly : mode"
            [(value)]="subject.name"></app-text-field>
          <app-dropdown class="width-25" label="Kind" [mode]="model ? Mode.readonly : mode" prefix="containerType" [values]="['AERIAL', 'SEMI_UNDERGROUND', 'UNDERGROUND']"
            [(value)]="subject.kind"></app-dropdown>
        </div>
        <h3>{{'Dimensions' | translate}}</h3>
        <div class="row">
          <app-number-field class="width-25" label="D1" [mode]="model ? Mode.readonly : mode"
            [(value)]="subject.d1" (valueChange)="check()"></app-number-field>
          <app-number-field class="width-25" label="H1" [mode]="model ? Mode.readonly : mode"  [required]="true"
            [(value)]="subject.h1" (valueChange)="check()"></app-number-field>
          <app-number-field class="width-25" label="H2" [mode]="model ? Mode.readonly : mode"  [required]="true"
            [(value)]="subject.h2" (valueChange)="check()"></app-number-field>
        </div>
        <div class="row">
          <span class="width-25"></span>
          <app-number-field class="width-25" label="V1" [mode]="model ? Mode.readonly : mode"  [required]="true"
            [(value)]="subject.v1" (valueChange)="check()"></app-number-field>
          <app-number-field class="width-25" label="V2" [(value)]="subject.v2"></app-number-field>
        </div>
      </div>
    </div>
    <div>
      <p></p>
      <h3 class="width-100">
        <span [style.padding-left]="'64px'">{{(valid ? 'Maximum Filling Level' : error) | translate}}</span>
        <span *ngIf="valid"> : {{maximum}}%</span>
      </h3>
      <img alt="Container dimensions" src="/assets/{{subject.kind.toLowerCase()}}.png" width="350px">
    </div>
    <app-form-buttons [actions]="('AFTER_SALES' | write) ? ['delete'] : []" domain="SETTINGS" [mode]="mode" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
</div>
