<div class="editor">
  <div class="form">
    <div class="row">
      <app-dropdown  *ngIf="balance==null" class="width-15" label="Status" [prefix]="'transaction'" [mode]="mode"
        [(value)]="subject.validity" [values]="statuses()"></app-dropdown>
      <mat-form-field *ngIf="balance!=null" class="width-15">
        <mat-label>{{'Initial Balance' | translate}}</mat-label>
        <input matInput class="input numeric" disabled value="{{balance | number: '1.2'}}">
      </mat-form-field>
      <mat-form-field *ngIf="balance!=null" class="width-15">
        <mat-label>{{'Amount' | translate}}</mat-label>
        <input matInput class="input numeric" (input)="doUpdate()" [(ngModel)]="subject.amount" required type="number">
      </mat-form-field>
      <mat-form-field *ngIf="balance!=null" class="width-15">
        <mat-label>{{'Final Balance' | translate}}</mat-label>
        <input matInput class="input numeric" disabled value="{{final  | number: '1.2'}}">
      </mat-form-field>
    </div>
    <div class="row">
      <app-text-field class="width-100" label="Comment" [length]="200" [mode]="mode"
        [(value)]="subject.comment"></app-text-field>
    </div>
  </div>
  <app-form-buttons domain="ACCOUNTS" [mode]="object.modifiable ? mode : Mode.readonly" [valid]="valid"
    (event)="onEvent($event)"></app-form-buttons>
</div>