<ngx-charts-line-chart [animations]="false" [results]="series" [scheme]="scheme" [view]="[992,400]"
  [showXAxisLabel]="true" [showYAxisLabel]="true" [timeline]="true"
  [xAxis]="true" [xAxisTickFormatting]="format" [yAxis]="true" [yAxisLabel]="units" [yScaleMin]="min" [yScaleMax]="max">
  <ng-template #tooltipTemplate let-model="model">
    {{model.name | timestamp}} {{model.value}}{{units}}
  </ng-template>
  <ng-template #seriesTooltipTemplate let-model="model">
    <span *ngIf="model.length">{{model[0].name | timestamp}} </span>
    <span *ngFor="let item of model">{{item.value}}{{units}} </span>
  </ng-template>
</ngx-charts-line-chart>
