import { Component, EventEmitter, Input, Output } from '@angular/core'

export enum Action { none, new, export, print }

@Component({
  selector: 'app-action-pane',
  templateUrl: './action-pane.component.html',
  styleUrls: ['./action-pane.component.css']
})
export class ActionPaneComponent {

  Action = Action

  magicFilter: string = ''
  searchFilter: string = ''

  @Input() criteria: string = ''
  @Input() domain: string = 'SETTINGS'
  @Input() export: boolean = false
  @Input() hint: string[] = []
  @Input() print: boolean = false

  @Output() action = new EventEmitter<Action>()
  @Output() magic = new EventEmitter<string>()
  @Output() search = new EventEmitter<string>()

  clearFilter() { this.magicFilter = '' ; this.searchFilter = '' }

  separator(i: number): string { return i ? ' / ' : ''}
}
