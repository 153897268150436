import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { GoogleMap } from '@angular/google-maps'

import { Container } from '../../framework/container/container.service'
import { Mode } from '../../framework/generic-form/generic-form.component'
import { SessionService } from '../../framework/services/session.service'
import { convert } from '../../framework/objects'

@Component({
  selector: 'app-container-view',
  templateUrl: './container-view.component.html',
  styleUrls: ['../../framework/generic-view/generic-view.css']
})
export class ContainerViewComponent implements OnInit {

  Mode = Mode

  @Input() index: number = 0
  @Input() container!: Container

  @Output() containerChange = new EventEmitter<Container>()
  @Output() event = new EventEmitter<string>()
  @Output() indexChange = new EventEmitter<number>()

  @ViewChild(GoogleMap) map !: GoogleMap

  options: google.maps.MapOptions = {
    clickableIcons: false,
    minZoom: 10, maxZoom: 20, zoom: 14,
    styles: [
      { featureType: "poi", elementType: "labels.text", stylers: [{ visibility: "off" }] },
      { featureType: "poi", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    ]
  }

  center: google.maps.LatLngLiteral
  calibration: boolean = false
  width: string = "992px"

  constructor(sessionService: SessionService) { this.center = convert(sessionService.defaultPosition()) }

  ngOnInit() {
    this.calibration = this.container.installationType != null && this.container.installationType.startsWith('EL_PI')
    if (this.container.ecopoint) this.center = convert(this.container.ecopoint.center)
  }

  onClick() {
    if (this.map && this.container.ecopoint) {
      const streetView = this.map.getStreetView()
      streetView.setOptions({ position: convert(this.container.ecopoint.center), pov: { heading: 70, pitch: -10 }})
      streetView.setVisible(true)
    }
  }

  // Edition events from panes must be bubbled up to container list.
  onEvent(event: string) {
    if (event == 'construction')
      this.index = 4
    else
      this.event.next(event) }
}
