import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { EcologService, Ecolog } from '../../ecolog.service'

@Component({
  selector: 'app-commands-view',
  templateUrl: './commands-view.component.html',
  styleUrls: ['../../../generic-page/generic-page.component.css', './spinner.css']
})
export class CommandsViewComponent implements OnInit {

  idle: boolean = false
  creation: boolean = false

  @Input() ecolog !: Ecolog

  @Output() event = new EventEmitter<string>()

  constructor(protected service: EcologService) { }

  ngOnInit() { this.updateStatus() }

  onAdd() {
    this.creation = true
    this.event.next('edit')
  }

  onEvent(event: string) {
    this.creation = false
    this.event.next('cancel')     // Avoid the main page to refresh the ecolog list.
  }

  onGenerate() {
    this.service.blacklistGenerate(this.ecolog.id)
    this.updateStatus()
  }

  updateStatus() { this.service.blacklistStatus(this.ecolog.id, (idle: boolean) => this.idle = idle)}
}
