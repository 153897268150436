import { NgModule } from '@angular/core'
import { FrameworkModule } from '../framework/framework.module'

import { EcopointFilterComponent } from './ecopoint-filter/ecopoint-filter.component'
import { EcopointFormComponent } from './ecopoint-form/ecopoint-form.component'
import { EcopointListComponent } from './ecopoint-list/ecopoint-list.component'
import { EcopointOverviewComponent } from './ecopoint-overview/ecopoint-overview.component'
import { EcopointPageComponent } from './ecopoint-page/ecopoint-page.component'
import { EcopointSelectComponent } from './ecopoint-select/ecopoint-select.component'

@NgModule({
  declarations: [
    EcopointFilterComponent,
    EcopointFormComponent,
    EcopointListComponent,
    EcopointOverviewComponent,
    EcopointPageComponent,
    EcopointSelectComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [
    EcopointPageComponent,
    EcopointSelectComponent,
  ],
})
export class EcopointModule { }
