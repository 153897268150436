import { Component } from '@angular/core'
import { CdkAccordionItem } from '@angular/cdk/accordion'
import { TranslateService } from '@ngx-translate/core'

import { Container, ContainerService } from '../container.service'
import { GenericListComponent } from '../../generic-list/generic-list.component'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-container-list',
  templateUrl: './container-list.component.html',
  styleUrls: ['../../generic-list/generic-list.component.css']
})
export class ContainerListComponent extends GenericListComponent<Container> {

  toggle: boolean = false               // Toggle to reset tab after a second click.
  preset: number = 0                    // Preset for opening specific tab.

  constructor(
    protected override service: ContainerService,
    protected sessionService: SessionService,
    protected translateService: TranslateService
  ) { super(service) }

  onAlarm(index: number, container: Container, alarm: string) { this.service.clearAlarm(index, container.id, alarm) }

  onIntervention(ecopoint: any) {
    this.preset = ecopoint == null ? 3 : 4
    this.toggle = true
  }

  override onToggle(item: CdkAccordionItem, index: number) {
    super.onToggle(item, index)
    if (this.toggle)
      this.toggle = false
    else
      this.preset = 0
  }

  rise(): boolean { return this.sessionService.rise() }

  tooltip(alarms: string[]) { return alarms.map(value => this.translateService.instant('alarm.' + value)).join('\n') }

  wise(): boolean { return this.sessionService.wise() }
}
