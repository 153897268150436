import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { MatSnackBar } from '@angular/material/snack-bar'

export enum Severity {
  none,
  info,
  warning,
  error,
}

export type Error =
  'error.unknown' |
  'error.application' |
  'error.authentication' |
  'error.blocked' |
  'error.obsoleteLink' |
  'error.serverUnavailable' |
  'error.unexpected' |
  'information.passwordReset'

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private snackbar : MatSnackBar,
    private translateService: TranslateService) { }

  dismiss() { this.snackbar.dismiss() }

  show(error: Error, reason: string = '') {
    if (reason > '') reason = ' (' + this.translateService.instant(reason) + ')'
    this.snackbar.open(this.translateService.instant(error) + reason)
  }

  info(message: string) {
    this.snackbar.open(this.translateService.instant(message))
  }
}
