import { Component, EventEmitter, Input, Output } from '@angular/core'

import { DatabaseObject } from '../../objects'
import { Mode } from '../../generic-form/generic-form.component'

@Component({
  selector: 'app-object-dropdown',
  templateUrl: './object-dropdown.component.html',
  styles: ['']
})
export class ObjectDropdownComponent {

  Mode = Mode

  @Input() mode: Mode = Mode.edition
  @Input() label: string = ''
  @Input() prefix: string = ''
  @Input() required: boolean = false
  @Input() objects: DatabaseObject[] = []
  @Input() object: DatabaseObject | null = null

  @Output() objectChange: EventEmitter<DatabaseObject | null> = new EventEmitter<DatabaseObject | null>()

  onSelect(id: string) {
    let object = this.objects.find(object => object.id == id)
    this.object = object ?? null
    this.objectChange.next(this.object)
  }
}
