import { Component } from '@angular/core';

import { ContainerTypeService, ContainerType } from '../container-type.service'
import { GenericListComponent } from '../../framework/generic-list/generic-list.component'

@Component({
  selector: 'app-container-type-list',
  templateUrl: './container-type-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class ContainerTypeListComponent extends GenericListComponent<ContainerType> {

  constructor(protected override service: ContainerTypeService) { super(service) }
}
