import {Component, Input, OnInit } from '@angular/core'
import { Color } from '@swimlane/ngx-charts'

export interface Series {
  name: string,
  series: any[]
}

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent {

  // Last color is for dummy data to include the full date range in case of missing data-
  scheme = { domain: ['#2693bc', '#A10A28', '#0'] } as Color

  @Input() colors: string[] = []
  @Input() min = 0
  @Input() max = 110
  @Input() units = ''
  @Input() series: Series[] = []

  format(time: Date) { return time.toISOString().split('T')[0] }
}
