<div class="list-header">
  <list-header class="width-20" [identifier]="'username'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-20" [identifier]="'firstName'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-20" [identifier]="'lastName'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-20" [identifier]="'Role'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-20" [identifier]="'Status'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as users">
  <cdk-accordion-item *ngFor="let user of users; let index = index;"
      #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span class="width-20">{{user.username}}</span>
      <span class="width-20">{{user.firstname}}</span>
      <span class="width-20">{{user.lastname}}</span>
      <span class="width-20">{{user.role?.name}}</span>
      <span class="width-20">{{'user.' + user.status | translate}}</span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-user-form [object]="user" (objectChange)="onUpdate(index, $event)" (event)="onEvent($event)"></app-user-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div *ngIf="total | async as total" class="count">{{count | async}} / {{total}}</div>
