import { Component } from '@angular/core'

import { GenericListComponent } from '../../generic-list/generic-list.component'
import { Report, ReportService } from '../report.service'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['../../generic-list/generic-list.component.css']
})
export class ReportListComponent extends GenericListComponent<Report> {

  rise: boolean

  constructor(
    protected sessionService: SessionService,
    protected override service: ReportService
  ) {
    super(service)
    this.rise = this.sessionService.rise()
  }
}
