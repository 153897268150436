<div class="list-header">
  <list-header class="width-15" [identifier]="'Serial'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [identifier]="'Hardware'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [identifier]="'Firmware'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [identifier]="'Status'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [identifier]="'heartbeat'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header *ngIf="sessionService.rise()" class="width-15" [identifier]="'Client'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" [identifier]="'Container'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as ecologs">
  <cdk-accordion-item *ngFor="let ecolog of ecologs; let index = index"
      #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span class="width-15">{{ecolog.serial}}</span>
      <span class="width-10">{{ecolog.hardware}}</span>
      <span class="width-10">{{ecolog.firmware}}</span>
      <span class="width-15">{{'ecolog.' + ecolog.status | translate}}</span>
      <span class="width-15">{{ecolog.heartbeat | timestamp}}</span>
      <span *ngIf="ecolog.client" class="width-15">{{ecolog.client.shortName}}</span>
      <span class="width-15">
        <a *ngIf="ecolog.container" routerLink="/containers/{{ecolog.container.id}}">{{ecolog.container.identifier}}</a>
      </span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-ecolog-view [ecolog]="ecolog" (event)="onEvent($event)"></app-ecolog-view>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count" #scroll><span *ngIf="total | async as total">{{count | async}} / {{total}}</span></div>
