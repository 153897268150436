import { Component } from '@angular/core'

import { CategoryService, Category } from '../category.service'
import { emptyObject } from '../../framework/objects'
import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'

@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css'],
})
export class CategoryPageComponent extends GenericPageComponent<Category> {

  category: Category = emptyObject

  constructor(protected override service: CategoryService) { super(service) }
}