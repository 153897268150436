<div class="list-header">
  <list-header class="width-15" [identifier]="'Identifier'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-30" [identifier]="'Location'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-20" [identifier]="'locality_name'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-20" [identifier]="'Zone'"></list-header>
  <list-header class="width-15" [identifier]="'Status'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as ecopoints">
  <cdk-accordion-item *ngFor="let ecopoint of ecopoints; let index = index"
    #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span class="width-15">{{ecopoint.identifier}}</span>
      <span class="width-30">{{ecopoint.location | shorten:30}}</span>
      <span class="width-20">{{ecopoint.locality?.name}}</span>
      <span class="width-20">{{ecopoint.zone?.name}}</span>
      <span class="width-15">{{'ecopoint.' + ecopoint.status | translate}}</span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-ecopoint-form [object]="ecopoint" (event)="onEvent($event)" (objectChange)="onUpdate(index, $event)"></app-ecopoint-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count" #scroll><span *ngIf="total | async as total">{{count | async}} / {{total | big}}</span></div>
