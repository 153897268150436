<div class="dialog">
  <h1>{{'Account Closure' | translate}}</h1>
  <div class="row">
    <mat-form-field class="width-20">
      <mat-label>{{'Initial Balance' | translate}}</mat-label>
      <input matInput class="input numeric" disabled [value]="balance | number: '1.2'">
    </mat-form-field>
    <mat-form-field class="width-20">
      <mat-label>{{'Amount' | translate}}</mat-label>
      <input matInput class="input numeric" (input)="doUpdate()" [(ngModel)]="amount" required type="number">
    </mat-form-field>
    <mat-form-field class="width-20">
      <mat-label>{{'Final Balance' | translate}}</mat-label>
      <input matInput class="input numeric" disabled [value]="final | number: '1.2'">
    </mat-form-field>
  </div>
  <div class="row">
    <app-text-field class="width-100" [label]="'Comment'" [length]="200" [mode]="Mode.edition"
      [(value)]="comment"></app-text-field>
  </div>
  <div class="row right">
    <button mat-flat-button (click)="onCancel()">{{'Cancel' | translate}}</button>
    <button mat-flat-button [disabled]="amount == null" (click)="onClose()">{{'account.Close' | translate}}</button>
  </div>
</div>
