import { ActivatedRoute } from '@angular/router'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Observable, Subject } from 'rxjs'

import { ContainerService, Container } from '../../framework/container/container.service'
import { Ecopoint } from '../../ecopoint/ecopoint.service'
import { FlowTypeService, FlowType } from '../../framework/flow-type/flow-type.service'
import { Mode } from '../../framework/generic-form/generic-form.component'
import { CollectionService } from '../collection.service'
import { ZoneService, Zone } from '../../zone/zone.service'
import {WasteService} from "../../waste/waste.service";

@Component({
  selector: 'app-collection-criteria-form',
  templateUrl: './collection-criteria-form.component.html',
  styleUrls: ['../../framework/generic-criteria/generic-criteria.css']
})
export class CollectionCriteriaFormComponent implements OnInit {

  Mode = Mode

  // account: string | null = null
  allContainers: Container[] = []
  container: any = ''                   // Either container filter or selected object.
  count: Observable<number>
  containers: Subject<Container[]> = new Subject<Container[]>()
  ecopoint: Ecopoint | null = null
  filter: any = { }
  flowType: FlowType | null = null
  flowTypes: FlowType[] = []
  grouping: string = 'NONE'
  groupings: string[] = ['NONE', 'ACCOUNT', 'CARD', 'CONTAINER']
  total: Observable<string | null>
  zone: Zone | null = null
  zones: Zone[] = []

  @Output() criteria = new EventEmitter()

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected service: CollectionService,
    protected containerService: ContainerService,
    protected flowTypeService: FlowTypeService,
    protected zoneService: ZoneService
  ) {
    containerService.list(containers => { this.allContainers = containers ; this.doFilterContainers() }, 'identifier')
    flowTypeService.list(flowTypes => this.flowTypes = flowTypes)
    zoneService.list(zones => this.zones = zones, 'LOCATION')
    this.count = service.count()
    this.total = service.total()
  }

  containerRef(container: Container): string { return container ? container.identifier : '' }

  doFilterContainers() {
    const list = this.allContainers.filter(container => {
      return container.identifier.toLowerCase().includes(this.container) &&
        (!this.ecopoint || (container.ecopoint && this.ecopoint.id == container.ecopoint.id))
    })
    this.containers.next(list)
  }

  ngOnInit() {
    // Route may contain a container id.
    const containerId = this.activatedRoute.snapshot.paramMap.get('container')
    if (containerId) {
      this.container = this.allContainers.find(container => container.id == containerId)
    }
  }

  onClear() {
    this.container = ''
    this.ecopoint = null
    this.flowType = null
    this.zone = null
    this.filter = { endDate: null, startDate: null }
    this.doFilterContainers()
  }

  onEcopoint(ecopoint: Ecopoint| null) { this.ecopoint = ecopoint; this.doFilterContainers() }

  onExport() { this.updateFilter(); this.service.export(this.filter) }

  onSearch() { this.updateFilter(); this.criteria.emit(this.filter) }

  updateFilter() {
    this.filter.containerId = this.container ? this.container.id : ''
    this.filter.ecopointId = this.ecopoint ? this.ecopoint.id : ''
    this.filter.flowTypeId = this.flowType ? this.flowType.id : ''
    this.filter.zoneId = this.zone ? this.zone.id : ''
  }
}
