import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import {TransactionCriteriaFormComponent} from './transaction-criteria-form/transaction-criteria-form.component'
import {TransactionFormComponent} from './transaction-form/transaction-form.component'
import {TransactionListComponent} from './transaction-list/transaction-list.component'
import {TransactionPageComponent} from './transaction-page/transaction-page.component'

@NgModule({
  declarations: [
    TransactionCriteriaFormComponent,
    TransactionFormComponent,
    TransactionListComponent,
    TransactionPageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [
    // Transaction form and list are used in Account module.
    TransactionFormComponent,
    TransactionListComponent,
    TransactionPageComponent,
  ]
})
export class TransactionModule { }
