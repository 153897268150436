import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { GenericService } from '../services/generic.service'

export enum Mode { action, edition, reading, readonly, none}

@Component({
  selector: 'app-generic-form',
  template: '',
  styleUrls: ['./generic-form.component.css']
})
export class GenericFormComponent<Type extends { id: string }> implements OnInit {

  subject !: Type                       // Edited object.
  valid: boolean = true

  @Input() object !: Type               // Original object to be edited.
  @Input() mode: Mode = Mode.reading
  @Input() editable: boolean = true
  @Input() creation: boolean = false

  @Output() objectChange = new EventEmitter<Type>()
  @Output() event = new EventEmitter<string>()

  constructor(protected service: GenericService<Type>) { }

  check() { this.valid = this.isValid() }

  clone(): Type | null { return null }

  // Override isValid if specific validation rules apply.
  isValid(): boolean { return true }

  // Override if edition time loading is required.
  load() {}

  ngOnInit() {
    this.initializeForm()
    if (this.mode == Mode.edition) { this.load() ; this.check() }
  }

  onCancel() {
    this.mode = Mode.reading
    this.initializeForm()
    this.event.next('cancel')
  }

  onDelete() {
    this.mode = Mode.none               // Avoid entering edition mode while deletion is in progress.
    this.service.delete(this.object).subscribe({ complete: () => { this.event.next('delete') } })
  }

  onEdit() {
    this.mode = Mode.edition
    this.load()
    this.check()
    this.event.next('edit')
  }

  onEvent(event: string) {
    switch (event) {
      case 'save':   this.onSave()   ; break
      case 'cancel': this.onCancel() ; break
      case 'delete': this.onDelete() ; break
      case 'edit':   this.onEdit()   ; break
      default: this.event.next(event)
    }
  }

  onSave() {
    this.updateSubject()
    this.service.update(this.subject).subscribe(
      object => {
        this.mode = Mode.reading
        if (this.creation)
            this.event.next('add')
        else {
          this.event.next('save')
          // Trigger a parent view update with a new object.
          this.objectChange.next(object)
        }
      }
    )
  }

  // Deep copy must be handled in child class...
  initializeForm() { this.subject = { ...this.object  } }
  updateSubject()  { }
}

