import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { GenericService } from '../services/generic.service'
import { DatabaseObject, Point} from '../objects'

export interface Locality extends DatabaseObject {
  center: Point,
  distance: string,
  grouping: DatabaseObject | null,
  zone: DatabaseObject | null,
}

@Injectable({
  providedIn: 'root'
})
export class LocalityService extends GenericService<Locality> {

  endpoint(version: string = 'v1'): string { return this.sessionService.url('localities', version) }

  override update(locality: Locality): Observable<Locality> {
    return this.putOne(locality.id, {
      center: locality.center,
      distance: locality.distance,
      groupingId: locality.grouping?.id,
      identifier: locality.identifier,
      name: locality.name,
      zoneId: locality.zone?.id,
    })
  }
}
