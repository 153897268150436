import { Injectable } from '@angular/core';

import { LanguageService } from '../services/language.service'
import { PdfService } from '../services/pdf.service'
import { TranslateService } from '@ngx-translate/core'

export interface Calibration {
  timestamp: string
  data: number[]
}

interface Table {
  layout: string
  table: {
    body: string[][]
    fontSize: number
    headerRows: number
    widths: string[]
  }
}

@Injectable({
  providedIn: 'root'
})
export class CalibrationService {

  constructor(
    protected languageService : LanguageService,
    protected pdfService: PdfService,
    protected translateService: TranslateService,
  ) { }

  table2(content: string[][]) : Table {
    return {
      layout: 'lightHorizontalLines',
      table: { body: content, fontSize: 20, headerRows: 0, widths: ['75%', '25%'] },
    }
  }

  table3(content: string[][]) : Table {
    return {
      layout: 'lightHorizontalLines',
      table: { body: content, fontSize: 20, headerRows: 1, widths: ['65%', '15%', '20%'] },
    }
  }

  print(calibration: Calibration) {
    this.pdfService.pdf([
      { image: 'ecowaste', height: 35, width: 143 },
      '\n',
      { style: 'header', text: this.languageService.translate('Calibration Report') },
      '\n',
      {
        columns: [
          this.table2([
            ['Date', new Date(calibration.timestamp).toLocaleDateString()],
            ['Contrôleur', 'jo'],
            ['No OF', '321'],
            ['No UC', '123'],
          ]),
          this.table2([
            ['Examen visuel', 'OK'],
            ['Charge nominale maximale', 'Conforme'],
            ['Fidelité', 'Conforme'],
            ['Excentration', 'Conforme'],
            ['Linéarité', 'Conforme'],
          ]),
        ],
        columnGap: 25,
      },
      '\n',
      {
        columns: [
          {style: 'header', text: 'Fidelité' },
          {style: 'header', text: 'Linéarité'},
        ],
        columnGap: 25,
      },
      '\n',
      {
        columns: [
          this.table3([
            ["Charge à 50% du max: 10'000 gr", 'Mesure', 'Résultat'],
            ['Mise à zéro', '', ''],
            ['Mesure 1', '', ''],
          ]),
          this.table3([
            ['Ascendante', 'Mesure', 'Résultat'],
            ['Mesure 1 : 500', '', ''],
            ["Mesure 2 : 2'500", '', ''],
            ["Mesure 3 : 5'000", '', ''],
          ]),
        ],
        columnGap: 25,
      },
    ])
  }
}
