<div class="list-header">
  <list-header class="width-15" identifier="Identifier" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-30" identifier="Name" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" identifier="Kind" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" identifier="executiondate" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15" identifier="validationdate" [sorting]="sorting" (sort)="onSort($event)"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as tours">
  <cdk-accordion-item *ngFor="let tour of tours; let index = index"
    #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onClick(accordionItem, index, tour)">
      <span class="width-15">{{tour.identifier}}</span>
      <span class="width-30">{{tour.name}}</span>
      <span class="width-15">{{'tour.' + tour.kind | translate}}</span>
      <span class="width-15">{{tour.executionDate | datestamp}}</span>
      <span class="width-15">{{tour.validationDate | datestamp}}</span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-tour-view [tour]="tour" (event)="onEvent($event)" (tourChange)="onUpdate(index, $event)"></app-tour-view>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count" #scroll><span *ngIf="total | async as total">{{count | async}} / {{total | big}}</span></div>
