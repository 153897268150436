<div class="list-header">
  <list-header class="width-15" [identifier]="'Identifier'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-20" [identifier]="'Name'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-20" [identifier]="'Access tariff'"></list-header>
  <list-header class="width-25" [identifier]="'Fixed tariff'"></list-header>
  <list-header class="width-20" [identifier]="'Weight tariff'"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as flowTypes">
  <cdk-accordion-item *ngFor="let flowType of flowTypes; let index = index"
      #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span class="width-15">{{flowType.identifier}}</span>
      <span class="width-20">{{flowType.name | shorten:20}}</span>
      <span class="width-20">
        <span *ngIf="flowType.tariff && flowType.tariff.minWeight">{{flowType.tariff.accessTariff | number: '1.2-2'}} CHF < {{flowType.tariff.minWeight | number: '1.2-2'}} kg</span>
      </span>
      <span class="width-25">
         <span *ngIf="flowType.tariff && flowType.tariff.limWeight">{{flowType.tariff.minWeight | number: '1.2-2'}} kg < {{flowType.tariff.minTariff | number: '1.2-2'}} CHF < {{flowType.tariff.limWeight | number: '1.2-2'}} kg</span>
      </span>
      <span class="width-20">
         <span *ngIf="flowType.tariff">
           <span *ngIf="flowType.tariff.limWeight"> {{flowType.tariff.limWeight | number: '1.2-2'}} kg : </span>
           {{flowType.tariff.weightTariff | number: '1.2-2'}} CHF/kg</span>
      </span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-flow-type-form [object]="flowType" (objectChange)="onUpdate(index, $event)" (event)="onEvent($event)"></app-flow-type-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count"><span *ngIf="total | async as total">{{count | async}} / {{total}}</span></div>
