import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { DatabaseObject } from '../objects'
import { GenericService, Sorting, toString } from '../services/generic.service'
import { PdfService } from '../services/pdf.service'
import { FileSaverService } from 'ngx-filesaver'
import { HttpClient } from '@angular/common/http'
import { LanguageService } from '../services/language.service'
import { SessionService } from '../services/session.service'
import { SpinnerService } from '../services/spinner.service'

export interface Blacklist {
  greylistThreshold: number
  darklistThreshold: number
  blacklist: BlacklistEntry[]
}

export interface BlacklistEntry {
  balance: number
  cardId: number
  level: string
  serial: string
}

export interface Client extends DatabaseObject {
  shortName: string,
}

export interface Command {
    command: string
    creationDate: string
    executionDate: string
    generationDate: string
    id: string
}

export interface Container {
  id: string
  identifier: string
  installationType: string
}

export interface Ecolog {
  id: string
  client: Client | null
  container: DatabaseObject | null
  deliveryDate: string
  deliveryRef: string
  firmware: string
  hardware: string
  heartbeat: string
  measure: string,
  reseller: string | null
  resellDate: string
  resellRef: string
  serial: number
  status: string
  supplyDate: string
  supplyRef: string
  temperature: string
  voltage: string
  warrantyDate: string
}

export interface EcologFilter {         // Filter criteria for exports.
  hardware: string
  first: number
  last: number
}

export interface EcologParameter {
  name: string
  timestamp: string
  value: string
}

export interface ExportData extends EcologFilter {
  date: string
  reference: string
  id: string
}

export interface Measure {
  id: string
  level: number
  measure: number
  timestamp: string
  value: number
}

// Helper for input of serial values.
export interface Record {
  hint: string
  value: string
  serial: number
}

interface Status {
  status: string
}

export interface User {
  id: string
  username: string
}

@Injectable({
  providedIn: 'root'
})
export class EcologService extends GenericService<Ecolog> {

  constructor(
    protected override fileSaverService: FileSaverService,
    protected override httpClient: HttpClient,
    protected languageService : LanguageService,
    protected pdfService: PdfService,
    protected override sessionService: SessionService,
    protected override spinner: SpinnerService,
  ) { super(fileSaverService, httpClient, sessionService, spinner) }

  addCommand(ecologId: string, command: string) {
    return this.httpClient.post<Command>(this.endpoint() + '/' + ecologId + '/commands', {
      command: command
    })
  }

  blacklist(ecologId: string, date: Date): Observable<Blacklist> {
    return this.httpClient.get<Blacklist>(this.endpoint('v2') + '/' + ecologId + '/blacklist', {
      params: { date: this.sessionService.toISO(date) }
    })
  }

  blacklistGenerate(ecologId: string) {
    this.httpClient.post<void>(this.endpoint() + '/' + ecologId + '/blacklist',{ }).subscribe(
      () => { })
  }

  blacklistStatus(ecologId: string, callback: (idle: boolean) => void) {
    this.httpClient.get<Status>(
      this.endpoint() + '/' + ecologId + '/blacklist/generation-request-status'
    ).subscribe(status => callback(status.status == 'IDLE'))
  }

  checkSerial(record: Record) {
    if  (record.value > '') {
      const value = +record.value
      if (value > 0 && value % 9 == 0) {
        record.hint = ''
        record.serial = value
      } else {
        record.hint = 'ecolog.illegalSerial'
        record.serial = 0
      }
    } else {
      record.hint = ''
      record.serial = 0
    }
  }

  commands(id: string): Observable<Command[]> {
    return this.httpClient.get<Command[]>(this.endpoint() + '/' + id + '/commands')
  }

  override delete(object: Ecolog): Observable<Ecolog> {
    return this.httpClient.put<Ecolog>(this.endpoint() + '/' + object.id + '/rip', { })
  }

  deleteCommand(ecologId: string, id: string) {
    return this.httpClient.delete<void>(this.endpoint() + '/' + ecologId + '/commands/' + id)
  }

  endpoint(version: string = 'v1'): string { return this.sessionService.url('ecologs', version) }

  measures(id: string, start: Date, end: Date, measure: string): Observable<Measure[]> {
    return this.httpClient.get<Measure[]>(this.endpoint() + '/' + id + '/measures', {
      params: { startDate: this.sessionService.toISO(start), endDate: this.sessionService.toISO(end), type: measure }
    })
  }

  parameters(id: string): Observable<EcologParameter[]> {
    return this.httpClient.get<EcologParameter[]>(this.endpoint() + '/' + id + '/parameters')
  }

  ecologs(reseller: boolean, callback: (objects: Ecolog[]) => void) {
    this.httpClient.get<Ecolog[]>(this.endpoint(),{
      params: { ownership: reseller ? 'RESELLER' : 'CLIENT', status: 'IN_STOCK', orderBy: 'serial;ASC' }
    }).subscribe(ecologs => callback(ecologs))
  }

  export(data: ExportData) {
    let ids: string[] = []
    for (const ecolog of this._objects.value)
      if (data.first <= +ecolog.serial && +ecolog.serial <= data.last) ids.push(ecolog.id)
    if (ids.length)
      this.httpClient.post<void>(this.endpoint() + '/export',{
        clientId: data.id,
        deliveryDate: data.date,
        deliveryRef: data.reference,
        ecologIds: ids,
      }).subscribe(() => this._objects.next([]))
  }

  override fetch(filter: any, sorting: Sorting = { field: 'serial', order: 'ASC' }, page= 1, max: number = 50, callback ?: () => void) {
    if (filter.id)
      this.getOne(filter.id)
    else
      this.getAll({
        ...(filter.clientId && { clientId: filter.clientId }),
        communicationState: filter.state ?? '',
        deliveryRef: filter.deliveryRef ?? '',
        hardware: filter.hardware ?? '',
        magicFilter: filter.magic ?? '',
        serial: filter.serial ?? '',
        serialFirst: filter.first ?? '',
        serialLast: filter.last ?? '',
        status: filter.status ?? (filter.stock ? 'IN_STOCK' : ''),
        ownership: filter.stock ?? '',
        orderBy: toString(sorting), page: page, per_page: max
      },() => { if (callback) callback() })
  }

  format(ecolog: Ecolog | null, resell: boolean): any[] {
    if (ecolog)
      return [
        ecolog.serial, ecolog.hardware, ecolog.firmware,
        resell ? ecolog.resellRef : '',
        resell ? (ecolog.resellDate ? this.languageService.datestamp(new Date(ecolog.resellDate )) : '-') : '',
        ecolog.deliveryRef,
        ecolog.deliveryDate ? this.languageService.datestamp(new Date(ecolog.deliveryDate )) : '-',
        ecolog.warrantyDate ? this.languageService.datestamp(new Date(ecolog.warrantyDate )) : '-',
      ]
    else
      // Table header.
      return [
        this.languageService.translate('Serial'),
        this.languageService.translate('Hardware'),
        this.languageService.translate('Firmware'),
        resell ? this.languageService.translate('Resell Reference') : '',
        resell ? this.languageService.translate('Resell Date') : '',
        this.languageService.translate('Delivery Reference'),
        this.languageService.translate('Delivery Date'),
        this.languageService.translate('Warranty'),
      ]
  }

  print(filter: any, sorting: Sorting, resell: boolean) {
    this.fetch(filter, sorting,1,1000, () => {

      const data: any[] = []

      // Include the table headers.
      data.push(this.format(null, resell))

      for (let ecolog of this._objects.value)
        data.push(this.format(ecolog, resell))

      this.pdfService.pdf([
        { image: 'ecowaste', height: 35, width: 143 }, '\n',
        { style: 'header', text: this.languageService.translate('EcoLog List') }, '\n',
        {
          layout: 'lightHorizontalLines',
          table: { body: data, fontSize: 20, headerRows: 1,
            widths: resell ?  ['11%', '11%', '11%', '14%', '13%', '14%', '13%', '13%'] : ['12%', '12%', '12%', '0%', '0%', '18%', '18%', '18%']
          },
        },
      ])
    })
  }

  stockOverview(): Observable<any> {
    this.httpClient.get<any>(this.endpoint() + '/stock-overview').subscribe(
      (overview: any) => this._overview.next({ all: overview.all, ...overview.versions }))
    return this._overview.asObservable()
  }
}
