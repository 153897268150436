<div class="criteria-pane">
  <h1>{{'Transactions' | translate}}
    <span *ngIf="filter.accountId" > : <a routerLink="/accounts/{{filter.accountId}}">{{account}}</a></span>
  </h1>
  <div class="row">
    <mat-form-field appearance="fill">
      <mat-label>{{'Date range' | translate}}</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <!-- Manual date entry requires third party date adapter.-->
        <input matStartDate [(ngModel)]="filter.startDate" [placeholder]="'Start date' | translate" readonly>
        <input matEndDate [(ngModel)]="filter.endDate" [placeholder]="'End date' | translate" readonly>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{"Ticket" | translate}}</mat-label>
      <input matInput [(ngModel)]="filter.ticket" (keydown.enter)="onSearch()">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{"User" | translate}}</mat-label>
      <mat-select [(value)]="filter.username">
        <mat-option>{{'None' | translate}}</mat-option>
        <mat-option *ngFor="let user of users" value="{{user.username}}">{{user.lastname}}, {{user.firstname}} </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="row">
    <app-object-dropdown [label]="'Till'" [(object)]="filter.till" [objects]="tills"></app-object-dropdown>
    <app-dropdown [label]="'Status'" [prefix]="'transaction'" [mode]="Mode.edition" [required]="false"
      [(value)]="filter.validity" [values]="statuses()"></app-dropdown>
  </div>
  <div class="row right">
    <button mat-flat-button (click)="onClear()">{{'Clear' | translate}}</button>
    <button mat-flat-button (click)="onSearch()">{{'Search' | translate}}</button>
    <button mat-flat-button (click)="onExport()">{{'Export' | translate}}</button>
  </div>
</div>
