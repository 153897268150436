import { Component } from '@angular/core'

import { CategoryService, Category } from '../category.service'
import { GenericListComponent } from '../../framework/generic-list/generic-list.component'

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class CategoryListComponent extends GenericListComponent<Category> {

  constructor(protected override service: CategoryService) { super(service) }
}
