<div class="editor">
  <div class="form">
    <div class="row">
      <app-text-field class="width-15" label="Identifier" [length]="10" [mode]="mode"
        [(value)]="subject.identifier"></app-text-field>
      <app-text-field class="width-50" label="Name" [length]="200" [mode]="mode" [required]="true"
        [(value)]="subject.name" (input)="check()"></app-text-field>
      <app-dropdown class="width-25" label="Mode" [mode]="mode" [prefix]="'zone'"
        [(value)]="kind" [values]="['ACCOUNT', 'LOCATION']"></app-dropdown>
      <app-text-field *ngIf="('AFTER_SALES' | write) && (subject.code != undefined)" class="width-10" label="Code" [mode]="mode"
        [(value)]="subject.code"></app-text-field>
    </div>
  </div>
  <app-form-buttons [actions]="('AFTER_SALES' | write) ? ['delete'] : []" [mode]="mode" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
</div>
