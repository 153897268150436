import { Component } from '@angular/core'

import { User, UserService } from '../user.service'
import { GenericPageComponent } from '../../generic-page/generic-page.component'

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['../../generic-page/generic-page.component.css'],
})
export class UserPageComponent extends GenericPageComponent<User> {

  user: User

  constructor(protected override service: UserService) {
    super(service) ; this.sorting = { field: 'username', order: 'ASC' }
    this.user = service.user()
    service.setEndpoint('users')
  }
}
