<div class="embedded-header">
  <list-header class="width-25" [identifier]="'Command'"></list-header>
  <list-header class="width-15" [identifier]="'Creation'"></list-header>
  <list-header class="width-15" [identifier]="'Generation'"></list-header>
  <list-header class="width-15" [identifier]="'Execution'"></list-header>
</div>
<div *ngIf="commands | async as commands">
  <div *ngFor="let command of commands" class="embedded-item">
    <span class="width-25">{{command.command}}</span>
    <span class="width-15">{{command.creationDate | timestamp}}</span>
    <span class="width-15">{{command.generationDate | timestamp}}</span>
    <span class="width-15">{{command.executionDate | timestamp}}</span>
    <span class="width-25"></span>
    <span *ngIf="command.generationDate == null" class="width-5">
        <button mat-icon-button (click)="onDelete(command.id)"><mat-icon>delete</mat-icon></button>
    </span>
  </div>
</div>