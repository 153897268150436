import { Component } from '@angular/core'

import { Ecopoint, EcopointService } from '../ecopoint.service'
import { GenericOverviewComponent, Shortcut} from '../../framework/generic-overview/generic-overview'

@Component({
  selector: 'app-ecopoint-overview',
  templateUrl: '../../framework/generic-overview/generic-overview.html',
  styleUrls: ['../../framework/generic-overview/generic-overview.css']
})
export class EcopointOverviewComponent extends GenericOverviewComponent<Ecopoint> {

  override shortcuts: Shortcut[] = [
    { field: 'all', filter: {}, name: 'All' },
    { field: 'accessible', filter: { status: 'ACCESSIBLE' }, name: 'Accessible EcoPoints' },
    { field: 'inaccessible', filter: { status: 'NON_ACCESSIBLE' }, name: 'Inaccessible EcoPoints' },
    { field: 'inactive', filter: { status: 'INACTIVE'   }, name: 'Inactive EcoPoints' },
  ]

  constructor(protected override service: EcopointService) { super(service) }
}
