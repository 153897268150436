import { Component } from '@angular/core'

import { AccountService, Account } from '../account.service'
import { GenericListComponent } from '../../framework/generic-list/generic-list.component'

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class AccountListComponent extends GenericListComponent<Account> {

  constructor(protected override service: AccountService) { super(service) }

  override onEvent(event: string) {
    // TEMP Moved to GenericList
    // Account needs a refresh if there was an update in card, transaction or waste tab.
    // if (event == 'update' && this.selection != undefined) this.service.refresh(this.selection)
    super.onEvent(event)
  }
}
