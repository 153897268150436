import { Component } from '@angular/core'
import { Observable } from 'rxjs'

import { SessionService, Session } from '../../framework/services/session.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  today = new Date()
  session: Observable<Session | null>

  constructor(protected sessionService: SessionService) { this.session = this.sessionService.session() }
}
