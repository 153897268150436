import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import { StockFormComponent } from './stock-form/stock-form.component'
import { StockListComponent } from './stock-list/stock-list.component'
import { StockOverviewComponent } from './stock-overview/stock-overview.component'
import { StockPageComponent } from './stock-page/stock-page.component'

@NgModule({
  declarations: [
    StockFormComponent,
    StockListComponent,
    StockOverviewComponent,
    StockPageComponent
  ],
  imports: [ FrameworkModule ],
  exports: [ StockPageComponent ]
})
export class StockModule { }
