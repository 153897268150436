<div class="navigator">
  <span>
    <a *ngIf="year>lower" (click)="navigate(year-1)">&lt; {{year-1}}</a>
    <span *ngIf="year<=lower" class="disabled">&lt; {{year-1}}</span>
  </span>
  <span>{{year}}</span>
  <span>
    <a *ngIf="year<upper" (click)="navigate(year+1)">{{year+1}} &gt;</a>
    <span *ngIf="year>=upper" class="disabled">{{year+1}} &gt;</span>
  </span>
</div>
