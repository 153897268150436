import { Component } from '@angular/core';

import { GenericFormComponent} from '../../framework/generic-form/generic-form.component'
import { Zone, ZoneService } from '../zone.service'

@Component({
  selector: 'app-zone-form',
  templateUrl: './zone-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class ZoneFormComponent extends GenericFormComponent<Zone> {

  kind: string = ''

  constructor(protected override service: ZoneService) { super(service)
  }

  override initializeForm() {
    super.initializeForm()
    if (!this.object.code) this.subject.code = ''
    this.kind = this.object.kind ?? ''
  }

  override updateSubject() { this.subject.kind = this.kind }

  override isValid(): boolean { return this.subject.name > ' ' }
}
