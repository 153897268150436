import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'

import { EcologService, Command, Ecolog } from '../../ecolog.service'

@Component({
  selector: 'app-commands-list',
  templateUrl: './commands-list.component.html',
  styleUrls: ['../../../generic-list/generic-list.component.css']
})
export class CommandsListComponent implements OnInit {

  commands !: Observable<Command[]>

  @Input() ecolog !: Ecolog

  constructor(protected service: EcologService) { }

  ngOnInit() { this.update() }

  onDelete(id: string) { this.service.deleteCommand(this.ecolog.id, id).subscribe(() => this.update())}

  update() { this.commands = this.service.commands(this.ecolog.id) }
}
