import { FileSaverService } from 'ngx-filesaver'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { GenericService, Sorting, toString } from '../framework/services/generic.service'
import { DatabaseObject } from '../framework/objects'
import { LanguageService } from '../framework/services/language.service'
import { SessionService } from '../framework/services/session.service'
import { SpinnerService } from '../framework/services/spinner.service'

export interface Ecopoint {
  id: string,
  location: string,
}

export interface Collection {
  container: DatabaseObject | null,
  ecopoint: Ecopoint | null,
  estimatedLevel: number,
  estimatedVolume: number,
  estimatedWeight: number,
  finalLevel: number,
  finalVolume: number,
  finalWeight: number,
  flowType: DatabaseObject | null,
  id: string,
  level: string,
  measure: string,
  periodicity: string,
  status: string,
  timestamp: string,
  tour: DatabaseObject | null,
  weight: string,
}

@Injectable({
  providedIn: 'root'
})
export class CollectionService extends GenericService<Collection> {

  constructor(
    protected override fileSaverService: FileSaverService,
    protected override httpClient: HttpClient,
    protected languageService : LanguageService,
    protected override sessionService: SessionService,
    protected override spinner: SpinnerService,
  ) { super(fileSaverService, httpClient, sessionService, spinner) }

  endpoint(version: string = 'v1'): string { return this.sessionService.url('collections', version) }

  export(filter: any) {
    this.exportToFile('', {
      // containerId: [filter.containerId ?? ''],
      ecopointId: filter.ecopointId ?? '',
      endDate: this.sessionService.toISO(filter.endDate),
      flowTypeId: filter.flowTypeId ?? '',
      startDate: this.sessionService.toISO(filter.startDate),
      zoneId: filter.zoneId ?? '',
      orderBy: 'timestamp;ASC',
    })
  }

  override fetch(filter: any, sorting: Sorting, page: number = 1, max: number = 50, callback ?: () => void) {
    this.getAll({
      containerId: filter.containerId ?? '',
      endDate: this.sessionService.toISO(filter.endDate),
      ecopointId: filter.ecopointId ?? '',
      flowTypeId: filter.flowTypeId ?? '',
      startDate: this.sessionService.toISO(filter.startDate),
      zoneId: filter.zoneId ?? '',
      orderBy: toString(sorting), page: page, per_page: max
    }, callback)
  }
}
