<header>
  <span class="box left">
    <span class="wise">WISE</span>
    <span class="largeDisplayOnly version">{{version()}}</span>
    <button *ngIf="session | async" class="smallDisplayOnly" mat-icon-button [matTooltip]="'Password change' | translate" (click)="onPassword()"><mat-icon>password</mat-icon></button>
  </span>
  <span>
    <span *ngIf="session | async as session"><img src="assets/clients/{{session.client.identifier}}.png" alt="{{session.client.name}}" class="logo" title="{{session.client.name}}"></span>
  </span>
  <span class="box right">
    <a *ngIf="session | async as session" class="largeDisplayOnly user" (click)="onPassword()" [matTooltip]="'Password change' | translate">{{session.user.username}}</a>
    <span *ngIf="session | async">
      <button mat-icon-button matTooltip="{{'Logout' | translate}}" (click)="onLogout()">
        <mat-icon>logout</mat-icon>
      </button>
      <button mat-icon-button matTooltip="{{'Parameters' | translate}}" (click)="onParameters()">
        <mat-icon>view_module</mat-icon>
      </button>
      <button class="largeDisplayOnly" mat-icon-button matTooltip="{{'Help' | translate}}" (click)="goto('help')">
        <mat-icon class="blue-100">help</mat-icon>
      </button>
    </span>
    <button mat-icon-button matTooltip="{{'Language' | translate}}" [matMenuTriggerFor]="menu">
      <mat-icon>language</mat-icon>
    </button>
  </span>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="setLanguage('de')">Deutsch</button>
    <button mat-menu-item (click)="setLanguage('fr')">Français</button>
    <button mat-menu-item (click)="setLanguage('en')">English</button>
  </mat-menu>
</header>
