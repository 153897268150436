<div class="editor">
  <div class="form">
    <div class="row">
      <app-text-field class="width-15" [label]="'Identifier'" [length]="10" [mode]="mode"
        [(value)]="subject.identifier"></app-text-field>
      <app-date-field class="width-20" [label]="'startdate'" [mode]="subject.startDate ? Mode.readonly : mode" [required]="true"
        [(value)]="subject.startDate"></app-date-field>
      <app-date-field class="width-20" [label]="'enddate'" [before]="before" [mode]="mode" [required]="true"
        [(value)]="subject.endDate" (valueChange)="check()"></app-date-field>
      <app-text-field class="width-45" [label]="'closure.label'" [length]="50" [mode]="mode"
        [(value)]="subject.label"></app-text-field>
    </div>
    <h3 *ngIf="mode != Mode.edition">{{'Statistics' | translate}}</h3>
    <div *ngIf="mode != Mode.edition && subject.statistics" class="row">
      <app-text-field class="width-15" label="Accounts" [value]="subject.statistics.accounts"></app-text-field>
      <app-text-field class="width-20" label="Lines" [value]="subject.statistics.lines"></app-text-field>
      <app-text-field class="width-20" label="Total Amount"
        [value]="subject.statistics.totalPrice.toLocaleString('fr-CH', { style: 'currency', currency: 'CHF' })"></app-text-field>
    </div>
  </div>
  <div class="column">
    <app-form-buttons *ngIf="open()" [actions]="['delete']" [mode]="mode" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
    <div *ngIf="mode == Mode.reading" class="buttons" [style.padding-top]="open() ? '8px' : '20px'">
      <button mat-icon-button (click)="onDownload()"><mat-icon>download</mat-icon></button>
      <button *ngIf="('SETTINGS' | write) && open()" mat-icon-button (click)="onLock()" [matTooltip]="'closure.close' | translate">
        <mat-icon>lock</mat-icon></button>
      <button *ngIf="('SETTINGS' | write) && undo && object.status=='CLOSED'" mat-icon-button (click)="onUndo()">
        <mat-icon>undo</mat-icon></button>
    </div>
  </div>
</div>
