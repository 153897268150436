<div class="editor embedded">
  <div class="form">
    <div class="row">
      <app-dropdown class="width-20" label="Type"  [mode]="validation ? Mode.reading : mode" prefix="tour"
        [required]="true" [values]="kinds" [(value)]="subject.kind" (valueChange)="onChangeType()"></app-dropdown>
      <app-text-field class="width-20" label="Identifier" [mode]="validation ? Mode.reading : mode"
        [(value)]="subject.identifier"></app-text-field>
      <app-text-field class="width-30" label="Name" [required]="true" [mode]="validation ? Mode.reading : mode"
        [(value)]="subject.name" (valueChange)="check()"></app-text-field>
      <app-date-field class="width-20" label="Execution" [mode]="validation ? Mode.reading : mode"
        [(value)]="executionDate" (valueChange)="onExecution()"></app-date-field>
    </div>
    <div class="row">
      <app-object-dropdown *ngIf="collection()" class="width-20" label="Flow Type" [mode]="validation ? Mode.reading : mode" [objects]="flowTypes"
        [required]="true" [(object)]="subject.flowType" (objectChange)="onChangeType()"></app-object-dropdown>
      <app-object-dropdown *ngIf="subject.kind != 'AFTER_SALES'" class="width-20" label="Truck" [mode]="validation ? Mode.reading : mode" [objects]="trucks"
        [required]="true" [(object)]="truck" (objectChange)="onTruck()"></app-object-dropdown>
      <app-object-dropdown class="width-30" label="Starting Point" [mode]="validation ? Mode.reading : mode" [objects]="locations"
        [required]="true" [(object)]="subject.startingPoint" (objectChange)="check()"></app-object-dropdown>
      <mat-form-field *ngIf="collection()" class="width-20">
        <mat-label>{{'Volume (m3)' | translate}}</mat-label>
        <input matInput #input [disabled]="true" [value]="display">
      </mat-form-field>
    </div>
    <h3 *ngIf="validation || object.validationDate">{{'Validation' | translate}}</h3>
    <div *ngIf="validation  || object.validationDate" class="row">
      <app-date-field class="width-20" label="Validation" [mode]="mode" [required]="true"
        [(value)]="subject.validationDate" (valueChange)="check()"></app-date-field>
    </div>
    <h3 *ngIf="mode != Mode.edition">{{'Route' | translate}}</h3>
    <div *ngIf="mode != Mode.edition" class="row">
      <app-text-field class="width-20" label="Containers"
        [(value)]="subject.numberOfContainers"></app-text-field>
      <app-text-field *ngIf="subject.duration" class="width-20" label="Duration" [mode]="mode"
        [(value)]="subject.duration"></app-text-field>
      <app-text-field *ngIf="subject.duration" class="width-20" label="Distance" [mode]="mode"
        [(value)]="subject.travelDistance"></app-text-field>
    </div>
    <h3 *ngIf="mode == Mode.edition">{{'Filter' | translate}}</h3>
    <div *ngIf="mode == Mode.edition" class="row">
      <app-client-dropdown *ngIf="rise()" class="width-20" (client)="onClient($event)"></app-client-dropdown>
      <app-object-dropdown *ngIf="localities.length" class="width-20" [label]="'Locality'"
        [(object)]="locality" [objects]="localities" (objectChange)="onFilter()"></app-object-dropdown>
      <app-dropdown *ngIf="!collection()" class="width-30" label="State" prefix="tour" [required]="false"
        [values]="['afterSales', 'missing']" [(value)]="state" (valueChange)="onFilter()"></app-dropdown>
    </div>
  </div>
  <app-form-buttons [actions]="actions()" [mode]="object.validationDate ? Mode.action : mode" [valid]="valid"
    (event)="onEvent($event)"></app-form-buttons>
</div>
<div *ngIf="mode == Mode.reading; then stopList else containerList"></div>
<ng-template #stopList>
  <app-stop-list [stops]="subject.stops"></app-stop-list>
</ng-template>
<ng-template #containerList>
  <app-tour-container-list [tour]="subject" [mode]="mode" [sorting]="sorting" (page)="onScroll()"
    (sort)="onSort($event)" (change)="updateVolume()">
  </app-tour-container-list>
</ng-template>
