import { Injectable } from '@angular/core'

import { GenericService, Sorting, toString } from '../services/generic.service'
import { DatabaseObject, Point } from '../objects'

export interface Location extends DatabaseObject {
  location: string,
  position: Point,
}

@Injectable({
  providedIn: 'root'
})
export class LocationService extends GenericService<Location> {

  endpoint(version: string = 'v1'): string { return this.sessionService.url('starting-points', version) }

  override list(callback: (objects: Location[]) => void, order: string = 'name') {
    super.list(locations =>
      callback(locations.map(location => { location.name = location.location ; return location })), 'location;ASC')
  }
}
