<div class="ribbon">
  <button mat-icon-button (click)="onAdd()" [disabled]="frozen"><mat-icon>add</mat-icon></button>
  <a mat-icon-button matTooltip="{{'transactions.ellipsis' | translate}}"
     routerLink="/transactions/{{account.id}}/{{abstract}}"><mat-icon>more_horiz</mat-icon></a>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-transaction-form [creation]="true" [balance]="account.balance" [mode]="Mode.edition" [object]="transaction" (event)="onEvent($event)"></app-transaction-form>
</ng-template>
<ng-template #showList>
  <app-transaction-list [account]="true" [sorting]="sorting" (sort)="onSort($event)" (event)="onEvent($event)"></app-transaction-list>
</ng-template>
