<p *ngIf="mode == Mode.edition">{{'Right click to set location.' | translate}}</p>
<div class="row">
  <div>
    <google-map [center]="{ lat: center.y, lng: center.x }"
      [options]="{ clickableIcons: false, maxZoom: 20, minZoom: 8, zoom: zoom }"
      width="462px" (mapRightclick)="onClick($event)">
      <map-marker [position]="{ lat: center.y, lng: center.x }"></map-marker>
    </google-map>
    <p></p>
  </div>
  <mat-form-field style="height: 78px; width: 15%">
    <mat-label>{{'Latitude' | translate}}</mat-label>
    <input matInput class="input numeric" disabled value="{{center.y | number: '1.5'}}">
  </mat-form-field>
  <mat-form-field style="height: 78px; width: 15%">
    <mat-label>{{'Longitude' | translate}}</mat-label>
    <input matInput class="input numeric" disabled value="{{center.x | number: '1.5'}}">
  </mat-form-field>
</div>
