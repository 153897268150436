import { Component } from '@angular/core'

import { GenericFormComponent, Mode } from '../../generic-form/generic-form.component'
import { RoleService, Role } from '../role.service'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.css', '../../generic-form/generic-form.component.css']
})
export class RoleFormComponent extends GenericFormComponent<Role> {

  Mode = Mode

  accounts: string = ''
  afterSales: string = ''
  locations: string = ''
  settings: string = ''

  kiosk: boolean = false
  rise: boolean = false
  wise: boolean = false

  constructor(
    protected override service: RoleService,
    protected sessionService: SessionService,
  ) { super(service) }

  override initializeForm() {
    super.initializeForm()

    this.accounts = this.right('ACCOUNTS')
    this.afterSales = this.right('AFTER_SALES')
    this.locations = this.right('LOCATIONS')
    this.settings = this.right('SETTINGS')

    this.kiosk= this.subject.domain.read.includes('KIOSK')
    this.rise= this.subject.domain.read.includes('RISE')
    this.wise= this.subject.domain.read.includes('WISE')
  }

  override isValid(): boolean { return this.subject.name > ' ' }

  right(domain: string): string {
    if (this.subject.domain.write.includes(domain))
      return 'WRITE'
    else if (this.subject.domain.read.includes(domain))
      return 'READ'
    else
      return 'NONE'
  }

  update(domain: string, right: string) {
    if (right == 'WRITE')
      this.subject.domain.write.push(domain)
    else if (right == 'READ')
      this.subject.domain.read.push(domain)
  }

  override updateSubject() {
    this.subject.domain.read = []
    this.subject.domain.write = []
    if (this.kiosk) this.update('KIOSK', 'READ')
    if (this.rise) this.update('RISE', 'READ')
    if (this.wise) this.update('WISE', 'READ')
    this.update('ACCOUNTS', this.accounts)
    this.update('AFTER_SALES', this.afterSales)
    this.update('LOCATIONS', this.locations)
    this.update('SETTINGS', this.settings)
  }
}
