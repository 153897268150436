<div *ngIf="sorting; else: noSorting">
<button [class]="embedded ? 'embedded' : ''" (click)="sort.emit(identifier)">
  <span *ngIf="!right">{{prefix + identifier | translate}}</span>
  <span *ngIf="sorting.field == identifier">
    <mat-icon *ngIf="sorting.order=='ASC'">arrow_drop_up</mat-icon>
    <mat-icon *ngIf="sorting.order=='DESC'">arrow_drop_down</mat-icon>
  </span>
  <mat-icon *ngIf="sorting.field !== identifier"></mat-icon>
  <span *ngIf="right">{{prefix + identifier | translate}}</span>
</button>
</div>
<ng-template #noSorting><span class="noSorting">{{prefix + identifier | translate}}</span></ng-template>
