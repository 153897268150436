import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { Address } from '../account.service'
import { Mode } from '../../framework/generic-form/generic-form.component'

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.css', '../../framework/generic-form/generic-form.component.css']
})
export class AddressFormComponent implements OnInit {

  open: boolean = false

  @Input() address !: Address
  @Input() country: boolean = false
  @Input() mode: Mode = Mode.reading
  @Input() title: string = ''
  @Output() change = new EventEmitter<void>()

  abstract(): string {
    return [
      [this.address.title, this.address.firstName, this.address.lastName].filter(x => x && x>' ').join(' '),
      [this.address.address],
      [this.address.postcode, this.address.city].filter(x => x>' ').join(' '),
      [this.address.country]
    ].filter(x => x>' ').join(', ')
  }

  ngOnInit() {
    // Compute initial open state, when this is a main address without name.
    this.open = !this.country && (this.address.lastName == null ||this.address.lastName<' ') }
}
