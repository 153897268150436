import { Component, Output } from '@angular/core'
import { environment } from '../environments/environment'

import { SessionService } from './framework/services/session.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  production: boolean = environment.production
  @Output() size: string = 'LARGE'
  title = 'WISE'

  constructor(protected sessionService: SessionService) {
    if (sessionStorage['token']) sessionService.loadSession()
  }
}
