<div *ngIf="notifications | async as notifications">
  <div *ngFor="let notification of notifications; let i = index" [class]="notification.level == 'ALERT' ? ' alert' : 'information'">
    <div class="header">
      <span class="left center">{{notification.event.timestamp | timestamp}}</span>
      <span class="center">{{'notification.' + notification.event.category | translate }}</span>
      <span class="right">
        <button mat-icon-button (click)="onClick(i)"><mat-icon>clear</mat-icon></button>
      </span>
    </div>
    <div [class]="'content'">
      <div *ngIf="noTranslation(notification.event) ; else translation">
        <span>{{notification.event.text}}</span>
      </div>
      <!-- Notifications without server provided values are translated in front-end. -->
      <ng-template #translation>
        <span>{{'notification.' + notification.event.type | translate}} </span>
        <span *ngIf="notification.event.container"> :
          <a routerLink="/containers/{{notification.event.container.id}}">
            <span>{{'Container' | translate}}</span>
            {{notification.event.container.identifier}}
            <span *ngIf="notification.event.ecopoint">({{notification.event.ecopoint | link}})</span>
          </a>
        </span>
        <span *ngIf="('AFTER_SALES' | read) && notification.event.ecologSerial">
          <a routerLink="/ecologs/serial/{{notification.event.ecologSerial}}">
            EcoLog {{notification.event.ecologSerial}}
          </a>
        </span>
      </ng-template>
    </div>
  </div>
</div>
