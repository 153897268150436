<mat-form-field *ngIf="ecopoints | async as ecopoints" [style.width]="'100%'">
  <mat-label>{{"EcoPoint" | translate}}</mat-label>
  <div *ngIf="mode==Mode.edition; then doEdit else noEdit"></div>
  <ng-template #doEdit>
  <input matInput [disabled]="mode!=Mode.edition" [matAutocomplete]="auto" [required]="required" [(ngModel)]="ecopoint" (ngModelChange)="doFilterEcopoints()" type="text" >
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="ecopointRef">
    <mat-option *ngFor="let ecopoint of ecopoints" [value]="ecopoint">{{ecopointRef(ecopoint)}}</mat-option>
  </mat-autocomplete>
  </ng-template>
  <ng-template #noEdit>
    <input class="input" matInput [value]="ecopointRef(ecopoint)" disabled>
  </ng-template>
</mat-form-field>