import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { FileSaverService } from 'ngx-filesaver'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable} from '@angular/core'

import { SessionService } from './session.service'
import { SpinnerService } from './spinner.service'

export interface Error {
  error: {
    code: string
    reason: string
  }
}

export type Order = 'ASC' | 'DESC'

export interface Sorting {
  field: string,
  order: Order,
}

export function toggle(order: Order): Order {
  if (order == 'ASC')
    return 'DESC'
  else
    return 'ASC'
}

export function toString(sorting: Sorting) {
  return sorting.field + ';' + sorting.order
}

@Injectable()
export abstract class GenericService<Type extends { id: string }> {

  protected _more : boolean = false     // Indicator that there are more results to be gotten.

  protected _count = new BehaviorSubject<number>(0)
  protected _objects: BehaviorSubject<Type[]> = new BehaviorSubject<Type[]>([])
  protected _overview: Subject<any> = new Subject<any>()
  protected _total: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null)

  constructor(
    protected fileSaverService: FileSaverService,
    protected httpClient: HttpClient,
    protected sessionService: SessionService,
    protected spinner: SpinnerService,
  ) { }

  count(): Observable<number> { return this._count.asObservable() }

  // Remove unwanted properties before sending an object in an HTTP header.
  cleanup(object: any): any {
    Object.entries(object).forEach(([key, val]) => { if (val == null || val == "") delete object[key] })
    return object
  }

  // Services with duplicate list views (i.e. card list in account view and card page) need to be cleared.
  clear() {
    this._more = false
    this._objects.next([])
    this._total.next(null)
  }

  delete(object: Type): Observable<Type> {
    return this.httpClient.delete<Type>(this.endpoint() + '/' + object.id)
  }

  abstract endpoint(version ?: string): string

  exportToFile(id: string, options: any, callback: () => void = () => {}) {
    this.spinner.show()
    this.httpClient.get(this.endpoint() + (id ? '/' + id : '') + '/export', {
      params: options,
      responseType: 'blob',
      observe: 'response'
    }).subscribe(response => {
      this.fileSaverService.save(response.body, 'wise-export.csv')
      this.spinner.hide()
      callback()
    })
  }

  // Default implementation with only a magic filter.
  fetch(filter: any, sorting: Sorting, page: number = 1) {
    this.getAll({
      magicFilter: filter.magic ?? '', orderBy: toString(sorting), page: page, per_page: 50 })
  }

  getAll(options: any, callback ?: (objects: Type[]) => void) {
    this._more = false
    this.httpClient.get<Type[]>(this.endpoint(), {
      params: options,
      observe: "response",
    }).subscribe(
      response => {
        if ('page' in options && options.page > 1)
          this._objects.next(this._objects.value.concat(response.body ?? []))
        else
          this._objects.next(response.body ?? [])
        if (response.ok) {
          this.updateCounts(response.headers, options?.page, options?.per_page)
          if (callback) callback(this._objects.value)
        }
        else
          console.log(response)
      }
    )
  }

  get(id: string): Observable<Type> { return this.httpClient.get<Type>(this.endpoint() + '/' + id) }

  getOne(id: string, callback ?: (object: Type) => void) {
    this._more = false
    this.httpClient.get<Type>(this.endpoint() + '/' + id, { observe: "response" })
      .subscribe(response => {
        this._objects.next(response.body ? [response.body] : [])
        if (response.ok) {
          this.updateCounts(response.headers, 1, 1)
          if (callback && response.body) callback(response.body)
        }
        else
          console.log(response)
      })
  }

  list(callback: (objects: Type[]) => void, order: string = 'name') {
    this.httpClient.get<Type[]>(this.endpoint(),{
      params: { orderBy: order + ';ASC', page: 1, per_page: 200 } }).subscribe(objects =>
      callback(objects))
  }

  more(): boolean { return this._more }

  objects(): Observable<Type[]> { return this._objects.asObservable() }

  overview(): Observable<any> {
    this.httpClient.get(this.endpoint() + '/overview').subscribe(
      overview => this._overview.next(overview))
    return this._overview.asObservable()
  }

  putOne(id: string, body: any): Observable<Type> {
    if (id)
      return this.httpClient.put<Type>(this.endpoint() + '/' + id, body)
    else
      return this.httpClient.post<Type>(this.endpoint(), body)
  }

  refresh(index: number) {
    this.httpClient.get<Type>(
      this.endpoint() + '/' + this._objects.getValue()[index].id, { observe: "response" }
    ).subscribe(response => {
      if (response.body)
        this.set(index, response.body)
      else
        console.log(response)
    })
  }

  set(index: number, object: Type) {
    let objects : Type[] = this._objects.value
    objects[index] = object
    this._objects.next(objects)
  }

  total(): Observable<string | null> { return this._total.asObservable() }

  update(object: Type): Observable<Type> { return this.putOne(object.id, object) }

  updateCounts(headers: HttpHeaders, page: number | undefined, per_page: number | undefined) {
    const total = headers.get('x-total-count')
    this._total.next(headers.get('x-total-count'))
    if (total) {
      const count = Math.min((page ?? 1) * (per_page ?? 50), +total)
      this._count.next(count)
      this._more = count < +total
    }
  }
}
