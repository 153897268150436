import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { DatabaseObject, Point } from '../framework/objects'
import { GenericService, Sorting, toString} from '../framework/services/generic.service'
import { LocationFilter } from '../framework/container/container.service'

export interface Ecopoint {
  id: string,
  center: Point,
  locality: DatabaseObject | null,
  identifier: string,
  location: string,
  status: string,
  zone: DatabaseObject | null,
}

export interface Filter extends LocationFilter {
  id ?: string | null,
  magic ?: string | null,
  status ?: string | null,
}

export interface Overview {
  all: number,
  accessible: number,
  inaccessible: number,
  inactive: number,
}

@Injectable({
  providedIn: 'root'
})
export class EcopointService extends GenericService<Ecopoint> {

  endpoint(version: string = 'v1'): string { return this.sessionService.url('ecopoints', version) }

  override fetch(filter: Filter, sorting: Sorting = { field: 'identifier', order: 'ASC'}, page: number = 1) {
    if (filter.id)
      this.getOne(filter.id)
    else
      this.getAll({
        localityId: filter.localityId ?? '',
        magicFilter: filter.magic ?? '',
        status: filter.status ?? '',
        withoutZone: filter.zoneId == '0',
        zoneId: filter.zoneId && (filter.zoneId > '0') ? filter.zoneId : '',
        orderBy: toString(sorting), page: page, per_page: 50,
      }
    )
  }

  override update(ecopoint: Ecopoint): Observable<Ecopoint> {
    return this.putOne(ecopoint.id, {
      center: ecopoint.center,
      localityId: ecopoint.locality?.id,
      identifier: ecopoint.identifier,
      location: ecopoint.location,
      status: ecopoint.status,
      zoneId: ecopoint.zone?.id,
    })
  }
}
