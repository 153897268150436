import { environment } from '../../../environments/environment'
import { Injectable } from '@angular/core'

import { Alignment, Margins, PageOrientation, PageSize } from 'pdfmake/interfaces'

import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'

import { SessionService } from './session.service'

// @ts-ignore
pdfMake.vfs = pdfFonts.default.pdfMake.vfs

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(protected sessionService: SessionService) { }

  assets(): string {
    if (environment.production)
      return 'https://' + window.location.hostname + '/assets/'
    else
      return 'http://localhost:4200/assets/'
  }

  pdf(content: any[], landscape: boolean = false) {
    let reseller = this.sessionService.reseller()
    const documentDefinition = {
      pageMargins: [ 30, 40, 30, 30 ] as Margins,
      pageSize: 'A4' as PageSize,
      pageOrientation: landscape ? 'landscape' : 'portait' as PageOrientation,
      content: content,
      defaultStyle: { fontSize: 10 },
      images: {
        ecowaste: this.assets() + 'ecowaste.png',
        reseller: this.assets() +  'resellers/' + (reseller ? reseller.identifier : 'ecowaste') + '.png',
      },
      styles: {
        header: { fontSize: 16, bold: true, alignment: 'center' as Alignment },
        number: { alignment: 'right' as Alignment },
        total: { alignment: 'right' as Alignment, bold: true },
        small: { fontSize: 8 },
        section: { fontSize: 14, background: 'yellow' }
      }
    }
    pdfMake.createPdf(documentDefinition).open();
  }
}
