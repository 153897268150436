<div class="pane-container">
  <app-user-overview title="Users" (selection)="onShortcut($event)"></app-user-overview>
  <app-action-pane [hint]="['username', 'firstName', 'lastName']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event})"></app-action-pane>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-user-form [creation]="true" [mode]="Mode.edition" [object]="user" (event)="onEvent($event)"></app-user-form>
</ng-template>
<ng-template #showList>
  <app-user-list #list [sorting]="sorting" (event)="onEvent($event)" (sort)="onSort($event)"></app-user-list>
</ng-template>

