import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { EcopointService, Ecopoint } from '../ecopoint.service'
import { emptyObject } from '../../framework/objects'
import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'
import { SessionService } from '../../framework/services/session.service'

@Component({
  selector: 'app-ecopoint-page',
  templateUrl: './ecopoint-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css'],
})
export class EcopointPageComponent extends GenericPageComponent<Ecopoint> {

  ecopoint: Ecopoint = {
    center: this.sessionService.defaultPosition(), locality: null, id:'',
    identifier: '', location: '', status: 'ACCESSIBLE', zone: emptyObject
  }

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected override service: EcopointService,
    protected sessionService: SessionService,
  ) { super(service) }

  override ngOnInit() { this.onSearch({ id: this.activatedRoute.snapshot.paramMap.get('id') }) }
}
