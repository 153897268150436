import { Component, Input, OnInit } from '@angular/core'

import { ContainerService } from '../../framework/container/container.service'
import { Mode } from '../../framework/generic-form/generic-form.component'
import { Series } from '../../framework/charts/line-chart/line-chart.component'
import {LanguageService} from "../../framework/services/language.service";

@Component({
  selector: 'app-container-statistics',
  templateUrl: './container-statistics.component.html',
  styleUrls: ['./container-statistics.component.css']
})
export class ContainerStatisticsComponent implements OnInit {

  Mode = Mode

  data: any[] = []                      // Collections data array.
  end: Date = new Date()
  measure: string = 'LEVEL'
  period: number = 6
  start: Date = new Date()
  series: Series[] = []                  // Level data serie.
  units: string = '%'

  @Input() id: string = ''

  constructor(
    protected service: ContainerService,
    protected languageService: LanguageService,
  ) {
    const measure = window.sessionStorage.getItem('measure')
    const period = window.sessionStorage.getItem('period')
    if (period) this.period = +period
    if (measure && ['LEVEL', 'COLLECTIONS', 'PERIODICITY'].includes(measure)) this.measure = measure
    this.setStart(this.period)
  }

  formatCollections() {
    // Format collection data as [name: Date, value: number] adding missing dates for the entire period.
    this.service.collections(this.id, this.start, this.end).subscribe(
      collections => {
        let array: any[] = []
        let last = new Date(this.start)
        for (let index = 0; index < collections.length; index++) {
          let current = new Date(collections[index].timestamp.slice(0,10))
          while (last < current) {
            array.push({ name: new Date(last), value: 0 })
            last.setDate(last.getDate() + 1)
          }
          let measure = this.measure == 'COLLECTIONS' ? collections[index].level : collections[index].periodicity
          array.push({ name: new Date(current), value: measure ?? 0 })
          last.setDate(current.getDate() + 1)
        }
      while (last < this.end) {
        array.push({ name: new Date(last), value: 0 })
        last.setDate(last.getDate() + 1)
      }
      this.data = array
    })
  }

  formatLevels() {
    // Merge daily levels and collections into { name: string, series: [ name: Date, value: number] }.
    this.service.levels(this.id, this.start, this.end).subscribe(
      levels => this.service.collections(this.id, this.start, this.end).subscribe(
        collections => {
          let count = 0
          let lastCollection = new Date()
          if (collections.length) lastCollection = new Date(collections[count].timestamp)
          let series: any[] = []
          for (let index = 0; index < levels.length; index++) {
            let date = new Date(levels[index].timestamp)
            if (count < collections.length && lastCollection < date) {
              series.push({ name: lastCollection, value: collections[count].level ?? 0 })
              series.push({ name: lastCollection, value: 0 })
              count++
              if (count < collections.length ) lastCollection = new Date(collections[count].timestamp)
            }
            if (levels[index].level) series.push({ name: date, value: levels[index].level })
          }

          // Add dummy data series to include the entire date range.
          this.series = [
            { name: 'Level', series: series },
            { name: 'x', series: [] },
            { name: 'y', series: [{ name: this.start, value: 0 }, { name: this.end, value: 0 }]}
          ]
        }
      )
    )
  }

  ngOnInit() { this.onMeasure(this.measure) }

  onMeasure(measure: string) {
    this.measure = measure
    window.sessionStorage.setItem('measure', measure)
    this.units = measure == 'PERIODICITY' ? this.languageService.translate('Days') : '%'
    this.update()
  }

  onPeriod(period: number) {
    window.sessionStorage.setItem('period', period.toString())
    this.setStart(period)
    this.update()
  }

  setStart(period: number) {
    this.period = period
    this.start = new Date()
    this.start.setDate(this.start.getDate() - 30 * period)
  }

  update() {
    if (this.measure == 'LEVEL')
      this.formatLevels()
    else
      this.formatCollections()
  }
}
