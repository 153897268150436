import { Component } from '@angular/core'

import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'
import { LocalityService, Locality } from '../../framework/locality/locality.service'
import { SessionService } from '../../framework/services/session.service'

@Component({
  selector: 'app-locality-page',
  templateUrl: './locality-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css']
})
export class LocalityPageComponent extends GenericPageComponent<Locality> {

  locality: Locality

  constructor(
    private sessionService: SessionService,
    protected override service: LocalityService
  ) {
    super(service)
    this.locality = {
      center: sessionService.defaultPosition(), distance: '', id: '',
      identifier: '', name: '', grouping: null, zone: null
    }
  }
}
