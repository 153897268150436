import { Component, Input, OnInit } from '@angular/core'
import { Color} from '@swimlane/ngx-charts'

@Component({
  selector: 'app-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.css']
})
export class BubbleChartComponent implements OnInit {

  @Input() colors !: any[]
  @Input() series !: any[]

  scheme = { domain: ['#2693bc'] } as Color

  format(time: Date) { return time.toISOString().split('T')[0] }

  ngOnInit() {  }
}
