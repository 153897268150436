<div class="pane-container">
  <app-ecopoint-overview #overview [sorting]="sorting.field" title="EcoPoints"
    (selection)="onShortcut($event)"></app-ecopoint-overview>
  <app-action-pane domain="LOCATIONS" [hint]="['Identifier', 'Location']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event })"></app-action-pane>
  <app-ecopoint-filter (filterChange)="onFilter($event)"></app-ecopoint-filter>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-ecopoint-form [creation]="true" [mode]="Mode.edition" [object]="ecopoint" (event)="onEvent($event)"></app-ecopoint-form>
</ng-template>
<ng-template #showList>
  <app-ecopoint-list #list [sorting]="sorting" (event)="onEvent($event)" (page)="onScroll()" (sort)="onSort($event)"></app-ecopoint-list>
</ng-template>
