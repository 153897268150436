import { Component, EventEmitter, Input, Output } from '@angular/core'

import { Mode } from '../../generic-form/generic-form.component'
import { Point } from '../../objects'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['../../generic-form/generic-form.component.css']
})
export class PositionComponent {

  Mode = Mode

  @Input() center: Point = this.sessionService.defaultPosition()
  @Input() mode: Mode = Mode.readonly
  @Input() zoom: number = 12

  @Output() centerChange = new EventEmitter<Point>()

  constructor(protected sessionService: SessionService) { }

  onClick(event: google.maps.MapMouseEvent) {
    if (this.mode == Mode.edition && event.latLng) {
      this.center = { x: event.latLng.lng(), y: event.latLng.lat() }
      this.centerChange.next(this.center)
    }
  }
}
