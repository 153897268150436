import { Injectable } from '@angular/core'

import { DatabaseObject } from '../framework/objects'
import { GenericService, Sorting, toString} from '../framework/services/generic.service'

export interface Zone extends DatabaseObject {
  kind ?: string | null                 // Optional so that object dropdowns can be used.
  code ?: string | null
}

@Injectable({
  providedIn: 'root'
})
export class ZoneService extends GenericService<Zone> {

  endpoint(version: string = 'v1'): string { return this.sessionService.url('zones', version) }

  override fetch(filter: any, sorting: Sorting = { field: 'identifier', order: 'ASC' }, page: number = 1) {
    if (filter.id)
      this.getOne(filter.id)
    else
      this.getAll({
        kind: filter.kind ?? '',
        magicFilter: filter.magic ?? '',
        orderBy: toString(sorting), page: page, per_page: 50
      })
  }

  override list(callback: (zones: Zone[]) => void, kind: string = 'LOCATION', without: string = '') {
    this.httpClient.get<Zone[]>(this.endpoint(),{
      params: { kind: kind, orderBy: 'name;ASC' } }).subscribe(zones => {
        if (zones.length && without) zones.unshift({code: '', id: '0', identifier: '', name: without })
        callback(zones)
    })
  }
}
