import { Injectable } from '@angular/core'

import {SessionService} from './framework/services/session.service'

@Injectable({
  providedIn: 'root'
})
export class WiseSessionService extends SessionService {

  override wise() { return true }

  override url(endpoint: string, version: string = 'v1') {
    return '/ipServer/wise/wise/' + version + '/' + endpoint
  }
}
