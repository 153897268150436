import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { SessionService } from '../../framework/services/session.service'

@Component({
  selector: 'app-trampoline',
  template: '<h1>Trampoline</h1>',
  styleUrls: []
})
export class TrampolineComponent implements OnInit {

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected sessionService: SessionService,
  ) { }

  ngOnInit() {
    const token = this.activatedRoute.snapshot.paramMap.get('token')
    const id = this.activatedRoute.snapshot.paramMap.get('id')
    if (token && id) this.sessionService.route(token, '/containers/' + id)
  }
}
