<div class="pane-container">
  <app-truck-overview title="Trucks" (selection)="onShortcut($event)"></app-truck-overview>
  <app-action-pane domain="LOCATIONS" [hint]="['username', 'firstName', 'lastName']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event})"></app-action-pane>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-truck-form [creation]="true" [mode]="Mode.edition" [object]="truck" (event)="onEvent($event)"></app-truck-form>
</ng-template>
<ng-template #showList>
  <app-truck-list #list [sorting]="sorting" (sort)="onSort($event)"></app-truck-list>
</ng-template>

