import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { SessionService } from '../framework/services/session.service'
import { TranslateService } from '@ngx-translate/core'

export interface Data {
  name: string,
  value: number,
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private _access: BehaviorSubject<Data[]> = new BehaviorSubject<Data[]>([])
  private _communication: BehaviorSubject<Data[]> = new BehaviorSubject<Data[]>([])
  private _level: BehaviorSubject<Data[]> = new BehaviorSubject<Data[]>([])
  private _type: BehaviorSubject<string> = new BehaviorSubject<string>('')

  constructor(
    protected httpClient: HttpClient,
    protected sessionService: SessionService,
    protected translateService: TranslateService
  ) { }

  access(): Observable<Data[]> { return this._access.asObservable() }
  communication() : Observable<Data[]> { return this._communication.asObservable() }

  endpoint(): string { return this.sessionService.url('dashboards') }

  fetchAccess(flowTypeId: string, year: number) {
    if (flowTypeId > ' ')
      this.httpClient.get<any>(this.endpoint() + '/access', {
          params: { flowTypeId: flowTypeId, year: year }, observe: "response"
      }).subscribe(response => {
        let access: number[] = Object.values(response.body.weight)
        if (access.reduce((sum, x) => sum + x, 0) > 0)
          this.updateAccess(Object.values(access), 'weight')
        else
          this.updateAccess(Object.values(response.body.access), 'access')
      })
  }

  fetchCommunication() {
    this.httpClient.get<any>(this.endpoint() + '/communication', {
      observe: "response" }).subscribe(response => {
        this._communication.next([
          { name: this.translateService.instant('dashboard.received'), value: response.body.received },
          { name: this.translateService.instant('dashboard.awaited'), value: response.body.awaited },
          { name: this.translateService.instant('dashboard.missing'), value: response.body.missing },
       ])
    })
  }

  fetchLevel() {
    this.httpClient.get<any>(this.endpoint() + '/level', {
      observe: "response" }).subscribe(response => {
      this._level.next([
        {name: '0 - 80%', value: response.body.low},
        {name: '81% - 100%', value: response.body.medium},
        {name: '> 100%', value: response.body.high},
      ])
    })
  }

  level() : Observable<Data[]> { return this._level.asObservable() }
  type(): Observable<string> { return this._type.asObservable() }

  updateAccess(values: number[], type: string) {
    if (values && values.length == 12) {
      const array: Data[] = []
      for (let index = 0; index < 12; index++)
        array.push({name: (index+1).toString(), value: values[index]})
      this._access.next(array)
      this._type.next(type)
    }
  }
}
