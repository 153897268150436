import { Component } from '@angular/core'

import { GenericOverviewComponent, Shortcut } from '../../generic-overview/generic-overview'
import { TourService, Tour } from '../tour.service'

@Component({
  selector: 'app-tour-overview',
  templateUrl: '../../generic-overview/generic-overview.html',
  styleUrls: ['../../generic-overview/generic-overview.css']
})
export class TourOverviewComponent extends GenericOverviewComponent<Tour> {

  override shortcuts: Shortcut[] = [
    { field: 'all', filter: { }, name: 'All' },
    { field: 'open', filter: { validated: false }, name: 'tour.open' },
    { field: 'validated', filter: { validated: true }, name: 'tour.validated' },
  ]

  constructor(protected override service: TourService) { super(service) }
}
