<div [class]="embedded ? ' embedded-header' : 'list-header'">
  <list-header class="width-15" [embedded]="embedded" identifier="timestamp" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header *ngIf="!container" class="width-15 link" [embedded]="embedded" identifier="container_identifier" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-20" [embedded]="embedded" identifier="flowtype_name" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15 link" [embedded]="embedded" identifier="card_serial" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header *ngIf="container" class="width-20 link" [embedded]="embedded" identifier="Account"></list-header>
  <list-header class="width-15 numberHeader" [embedded]="embedded" identifier="Weight" [right]="true" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-15 numberHeader" [embedded]="embedded" identifier="Price" [right]="true" [sorting]="sorting" (sort)="onSort($event)"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as waste">
  <cdk-accordion-item *ngFor="let waste of waste; let index = index;"
      #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="(embedded ? 'embedded-item' : 'list-item') + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span [class]="'width-15' + dim(waste)">{{waste.timestamp | timestamp}}</span>
      <span *ngIf="!container" class="width-15">
        <span *ngIf="waste.container">
          <a *ngIf="'LOCATIONS' | read; else noLink" [class]="dim(waste)" routerLink="/containers/{{waste.container.id}}">{{waste.container.identifier}}</a>
          <ng-template #noLink>{{waste.container.identifier}}</ng-template>
        </span>
      </span>
      <span [class]="'width-20' + dim(waste)">{{waste.flowType?.name}}</span>
      <span class="width-15">
        <span *ngIf="waste.card">
          <a *ngIf="('ACCOUNTS' | read) && !waste.card.special; else noLink" [class]="dim(waste)" routerLink="/cards/{{waste.card.id}}">{{waste.card.label}}</a>
          <ng-template #noLink>{{waste.card.label}}</ng-template>
        </span>
      </span>
      <span *ngIf="container" class="width-20">
        <span *ngIf="waste.card && waste.card.account">
          <a *ngIf="'ACCOUNTS' | read; else noLink" [class]="dim(waste)" routerLink="/accounts/{{waste.card.account.id}}">
            {{waste.card.account | link | shorten:20}}</a>
          <ng-template #noLink>{{waste.card.label}}</ng-template>
        </span>
      </span>
      <span [class]="'width-15 numberItem' + dim(waste)">{{waste.weight | number:'1.2-2'}}</span>
      <span [class]="'width-15 numberItem' + dim(waste)">{{waste.price  | number:'1.2-2'}}</span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-waste-form [object]="waste" (objectChange)="onUpdate(index, $event)" (event)="onEvent($event)"></app-waste-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count" #scroll><span *ngIf="total | async as total">{{count | async}} / {{total | big}}</span></div>
