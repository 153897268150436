<mat-tab-group mat-stretch-tabs (selectedTabChange)="onTabChange($event)">
  <mat-tab label="{{'Information' | translate}}">
    <app-account-form [object]="account" (event)="onEvent($event)" (objectChange)="accountChange.next($event)"></app-account-form>
  </mat-tab>
  <mat-tab label="{{'Waste' | translate}}">
    <ng-template matTabContent>
      <app-account-waste [abstract]="abstract()" [account]="account" (event)="onFilterEvent($event)"></app-account-waste>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{'Transactions' | translate}}">
    <ng-template matTabContent>
      <app-account-transactions [abstract]="abstract()" [account]="account" (event)="onFilterEvent($event)"></app-account-transactions>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{'Cards' | translate}}">
    <ng-template matTabContent>
      <app-account-cards [account]="account" (event)="onFilterEvent($event)"></app-account-cards>
    </ng-template>
  </mat-tab>
</mat-tab-group>
