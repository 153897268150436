import { Component, OnDestroy } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {Subscription, take} from 'rxjs'

import { AccountService, Account } from '../account.service'
import { AccountClosureComponent } from '../account-closure/account-closure.component'
import { CardService } from '../../card/card.service'
import { CategoryService, Category } from '../../category/category.service'
import { emptyObject } from '../../framework/objects'
import { GenericFormComponent, Mode } from '../../framework/generic-form/generic-form.component'
import { SessionService } from '../../framework/services/session.service'
import { ZoneService, Zone } from '../../zone/zone.service'

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class AccountFormComponent extends GenericFormComponent<Account> implements OnDestroy {

  Mode = Mode

  categories: Category[] = []
  subscription: Subscription
  zone: Zone | null = null              // User's account zone, if any.
  zones: Zone[] = []

  constructor(
    protected override service: AccountService,
    protected cardService: CardService,
    protected categoryService: CategoryService,
    protected dialog: MatDialog,
    protected sessionService: SessionService,
    protected zoneService: ZoneService,
  ) {
    super(service)
    this.subscription = sessionService.session().subscribe(session => {
      if (session) { this.zone = session.user.accountZone }
    })
    categoryService.list(categories => this.categories = categories)
    zoneService.list(zones => this.zones = zones,'ACCOUNT')
  }

  ngOnDestroy() { this.subscription.unsubscribe() }

  override initializeForm() {
    this.subject = { ...this.object }
    this.subject.address = { ...this.object.address }
    this.subject.billingAddress = { ...this.object.billingAddress }
    this.subject.category = this.object.category ? { ...this.object.category } : { ...emptyObject }
    this.subject.zone =  this.object.zone ? { ...this.object.zone } : this.zone
  }

  override isValid(): boolean { return this.subject.address.lastName != null && this.subject.address.lastName > ' ' }

  override onEvent(event: string) {
    if (event == 'lock') {
      const dialog = this.dialog.open(AccountClosureComponent, {
        data: { balance: this.subject.balance }, panelClass: 'dialog', width: '600px',
      })
      dialog.afterClosed().subscribe((result: any) => {
        if (result && result.close) this.service.close(this.object.id, result).subscribe(
          account => {
            this.event.next('save')
            // Trigger a parent view update with a new object.
            this.objectChange.next(account)
          }
        )
      })
    }
    else super.onEvent(event)
  }
}
