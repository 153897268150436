<div class="list-header">
  <list-header class="width-15" [identifier]="'Identifier'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-35" [identifier]="'Location'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-20" [identifier]="'Zone'"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as stocks">
  <cdk-accordion-item *ngFor="let stock of stocks; let index = index"
      #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span class="width-15">{{stock.identifier}}</span>
      <span class="width-35">{{stock.name | shorten:35}}</span>
      <span class="width-22">{{stock.zone?.name}}</span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-stock-form [object]="stock" (event)="onEvent($event)" (objectChange)="onUpdate(index, $event)"></app-stock-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>