import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { GenericService } from '../services/generic.service'
import { DatabaseObject } from '../objects'

export interface FlowType extends DatabaseObject {
  density ?: number,
  tariff ?: Tariff,
}

export interface Tariff {
  id: number,
  limWeight: number,
  minWeight: number,
  minTariff: number,
  accessTariff: number,
  weightTariff: number,
  effectiveDate: string,
}

@Injectable({
  providedIn: 'root'
})
export class FlowTypeService extends GenericService<FlowType> {

  endpoint(version: string = 'v1'): string { return this.sessionService.url('flow-types', version) }

  override update(flowType: FlowType): Observable<FlowType> {
    return this.putOne(flowType.id, {
      density: flowType.density ?? 0,
      identifier: flowType.identifier,
      name: flowType.name,
    })
  }
}
