import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'link'
})
export class LinkPipe implements PipeTransform {

  transform(object: any, ...args: unknown[]): string {
    const prefix: string = object.identifier ?? ''
    const suffix: string = object.name ?? object.lastName ?? object.location ?? object.address?.lastName ?? ''
    if (prefix.length)
      return suffix.length ? prefix + ' - ' + suffix : prefix
    else
      return suffix
  }
}
