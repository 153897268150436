import { Component, EventEmitter, Input, Output } from '@angular/core'

import { EcologService, Command } from '../../ecolog.service'
import { Mode } from '../../../generic-form/generic-form.component'

@Component({
  selector: 'app-commands-form',
  templateUrl: './commands-form.component.html',
  styleUrls: ['../../../generic-form/generic-form.component.css']
})
export class CommandsFormComponent {

  Mode = Mode

  command : string = ''

  @Input() ecologId !: string
  @Output() event = new EventEmitter<string>()

  constructor(protected service: EcologService) { }

  onEvent(event: string) {
    if (event == 'save')
      this.service.addCommand(this.ecologId, this.command).subscribe(object => this.event.next('add'))
    else
      this.event.next('cancel')
  }
}
