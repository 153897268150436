import { Component } from '@angular/core'

import { User, UserService } from '../user.service'
import { GenericListComponent } from '../../generic-list/generic-list.component'

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['../../generic-list/generic-list.component.css']
})
export class UserListComponent extends GenericListComponent<User> {

  constructor(protected override service: UserService) { super(service) }
}
