import { Component } from '@angular/core'

import { GenericFormComponent } from '../../framework/generic-form/generic-form.component'
import { StockService, Stock } from '../stock.service'
import { ZoneService, Zone } from '../../zone/zone.service'

@Component({
  selector: 'app-stock-form',
  templateUrl: './stock-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class StockFormComponent extends GenericFormComponent<Stock> {

  zones: Zone[] = []

  constructor(
    protected override service: StockService,
    protected zoneService: ZoneService,
  ) {
    super(service)
    zoneService.list(zones => this.zones = zones,'LOCATION')
  }

  override isValid(): boolean { return this.subject.name > ' ' }
}
