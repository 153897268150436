import { Component } from '@angular/core'
import { CdkAccordionItem } from '@angular/cdk/accordion'

import { GenericListComponent } from '../../generic-list/generic-list.component'
import { Tour, TourService } from '../tour.service'

@Component({
  selector: 'app-tour-list',
  templateUrl: './tour-list.component.html',
  styleUrls: ['../../generic-list/generic-list.component.css']
})

export class TourListComponent  extends GenericListComponent<Tour> {

  constructor(protected override service: TourService) { super(service) }

  onClick(item: CdkAccordionItem, index: number, tour: Tour) { this.onToggle(item, index) }
}
