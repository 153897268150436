<div class="editor">
  <div class="form">
    <div *ngIf="subject.id ; else creation" class="row">
      <app-dropdown class="width-15" label="Status" prefix="waste" [mode]="mode"
                    [(value)]="status" [values]="statuses()"></app-dropdown>
    </div>
    <ng-template #creation>
      <div class="row">
        <app-date-field class="width-20" [label]="'Date'" [mode]="mode" [required]="true"
          [(value)]="subject.timestamp"></app-date-field>
        <app-object-dropdown class="width-25" label="Flow Type" [mode]="mode" [objects]="flowTypes"
          [object]="subject.flowType" [required]="true" (objectChange)="onFlowType($event)"></app-object-dropdown>
        <app-number-field class="width-10" label="Weight" [mode]="mode" [(value)]="weight"></app-number-field>
      </div>
    </ng-template>
  </div>
  <app-form-buttons domain="ACCOUNTS" [mode]="object.modifiable ? mode : Mode.readonly" (event)="onEvent($event)"></app-form-buttons>
</div>
