<div class="editor">
  <div class="form">
    <div class="row">
      <app-text-field class="width-25" [label]="'Name'" [length]="200" [mode]="mode" [required]="true"
        [(value)]="subject.name" (valueChange)="check()"></app-text-field>
      <mat-checkbox [checked]="wise" class="checkbox" color="primary" [disabled]="mode != Mode.edition"
        (change)="wise = !wise">WISE</mat-checkbox>
      <mat-checkbox [checked]="kiosk" class="checkbox" color="primary" [disabled]="mode != Mode.edition"
        (change)="kiosk = !kiosk">KIOSK</mat-checkbox>
      <mat-checkbox *ngIf="sessionService.rise()" [checked]="rise" class="checkbox" color="primary" [disabled]="mode != Mode.edition"
        (change)="rise = !rise">RISE</mat-checkbox>
    </div>
    <div *ngIf="wise || rise" class="row">
      <app-dropdown class="width-25" label="Accounts" [mode]="mode" prefix="role"
        [values]="['NONE', 'READ', 'WRITE']" [(value)]="accounts"></app-dropdown>
      <app-dropdown class="width-25" label="Locations" [mode]="mode" prefix="role"
        [values]="['NONE', 'READ', 'WRITE']" [(value)]="locations"></app-dropdown>
      <app-dropdown class="width-25" label="Settings" [mode]="mode" prefix="role"
        [values]="['NONE', 'READ', 'WRITE']" [(value)]="settings"></app-dropdown>
      <app-dropdown *ngIf="sessionService.rise()" class="width-25" label="After Sales" [mode]="mode" prefix="role"
        [values]="['NONE', 'READ', 'WRITE']" [(value)]="afterSales"></app-dropdown>
    </div>
  </div>
  <app-form-buttons [actions]="['delete']" domain="SETTINGS" [mode]="mode" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
</div>
