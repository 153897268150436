<mat-form-field [style.width]="'100%'">
  <mat-label>{{label | translate}}</mat-label>
    <mat-select [disabled]="mode != Mode.edition" [required]="required" [value]="value" (selectionChange)="valueChange.emit($event.value)">
      <mat-option *ngIf="!required">{{'None' | translate}}</mat-option>
      <mat-divider></mat-divider>
      <span *ngFor="let item of values">
        <mat-option *ngIf="item != '-'; else divider" value="{{item}}">{{(prefix ? prefix + '.' + item : item) | translate}}</mat-option>
        <ng-template #divider><mat-divider></mat-divider></ng-template>
      </span>
    </mat-select>
</mat-form-field>
