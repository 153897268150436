<div class="editor embedded">
  <div *ngIf="initialized" class="form">
    <div class="row">
      <h3 [style.min-width]="'15%'">{{'Diagnostic' | translate}}</h3>
      <app-text-parameter class="width-20" [parameter]="params[Index.ccid]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.diag]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.fs]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.xlist]"></app-text-parameter>
    </div>
    <div *ngIf="params[Index.ra].value" class="row">
      <h3 [style.min-width]="'15%'">{{'Regions' | translate}}</h3>
      <app-text-parameter *ngFor="let index of [Index.ra, Index.rb, Index.rc, Index.rd]"
        class="width-20" [parameter]=params[index]></app-text-parameter>
    </div>
    <div *ngIf="lastRegion > Index.rd" class="row">
      <h3 [style.min-width]="'15%'"></h3>
      <app-text-parameter *ngFor="let index of [Index.re, Index.rf, Index.rg, Index.rh]"
        class="width-20" [parameter]=params[index]></app-text-parameter>
    </div>
    <div *ngIf="lastRegion > Index.rh" class="row">
      <h3 [style.min-width]="'15%'"></h3>
      <app-text-parameter *ngFor="let index of [Index.ri, Index.rj, Index.rk, Index.rl]"
        class="width-20" [parameter]=params[index]></app-text-parameter>
    </div>
    <div class="row">
      <h3 [style.min-width]="'15%'">{{'Communication' | translate}}</h3>
      <app-text-parameter class="width-20" [parameter]="params[Index.cp]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.cs]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.ce]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.ct]"></app-text-parameter>
    </div>
    <div class="row">
      <h3 [style.min-width]="'15%'">{{'Level Measurement' | translate}}</h3>
      <app-text-parameter class="width-20" [parameter]="params[Index.st]"></app-text-parameter>
      <app-text-parameter *ngIf="st" class="width-20" [parameter]="params[Index.al]"></app-text-parameter>
      <app-text-parameter *ngIf="st" class="width-20" [parameter]="params[Index.dp]"></app-text-parameter>
      <app-text-parameter *ngIf="st" class="width-20" [parameter]="params[Index.es]"></app-text-parameter>
    </div>
    <div *ngIf="st == 4" class="row">
      <h3 [style.min-width]="'15%'"></h3>
      <app-text-parameter class="width-20" [parameter]="params[Index.cc]"></app-text-parameter>
    </div>
    <div *ngIf="generation == 3" class="row">
      <h3 [style.min-width]="'15%'">{{'Access Control' | translate}}</h3>
      <app-text-parameter class="width-20" [parameter]="params[Index.am]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.ap]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.cm]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.sz]"></app-text-parameter>
    </div>
    <div *ngIf="generation == 3" class="row">
      <h3 [style.min-width]="'15%'"></h3>
      <app-text-parameter class="width-20" [parameter]="params[Index.cg]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.et]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.lt]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.pw]"></app-text-parameter>
    </div>
    <div class="row" *ngIf="pi || !wi">
      <h3 [style.min-width]="'15%'">{{'Weighing' | translate}}</h3>
      <app-text-parameter class="width-20" [parameter]="params[Index.pi]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.bl]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.cl]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.te]"></app-text-parameter>
    </div>
    <div *ngIf="pi" class="row">
      <h3 [style.min-width]="'15%'"></h3>
      <app-text-parameter class="width-20" [parameter]="params[Index.ac]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.ae]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.ah]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.at]"></app-text-parameter>
    </div>
    <div *ngIf="pi" class="row">
      <h3 [style.min-width]="'15%'"></h3>
      <app-text-parameter *ngIf="pi" class="width-20" [parameter]="params[Index.dw]"></app-text-parameter>
    </div>
    <div *ngIf="wi" class="row">
      <h3  [style.min-width]="'15%'">{{'Weighing Interface' | translate}}</h3>
      <app-text-parameter class="width-20" [parameter]="params[Index.wi]"></app-text-parameter>
    </div>
    <div class="row">
      <h3 [style.min-width]="'15%'">{{'Technical' | translate}}</h3>
      <app-text-parameter class="width-20" [parameter]="params[Index.hw]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.th]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.tp]"></app-text-parameter>
      <app-text-parameter class="width-20" [parameter]="params[Index.vp]"></app-text-parameter>
    </div>
  </div>
</div>
