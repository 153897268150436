import { Component } from '@angular/core'

import { GenericListComponent } from '../../generic-list/generic-list.component'
import { EcologService, Ecolog } from '../ecolog.service'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-ecolog-list',
  templateUrl: './ecolog-list.component.html',
  styleUrls: ['../../generic-list/generic-list.component.css']
})
export class EcologListComponent extends GenericListComponent<Ecolog> {

  constructor(
    protected sessionService: SessionService,
    protected override service: EcologService,
  ) { super(service) }
}
