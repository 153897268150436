import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import { ContainerTypePageComponent } from './container-type-page/container-type-page.component'
import { ContainerTypeListComponent } from './container-type-list/container-type-list.component'
import { ContainerTypeOverviewComponent } from './container-type-overview/container-type-overview.component'
import { ContainerTypeFormComponent } from './container-type-form/container-type-form.component'

@NgModule({
  declarations: [
    ContainerTypeFormComponent,
    ContainerTypeListComponent,
    ContainerTypeOverviewComponent,
    ContainerTypePageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [ ContainerTypePageComponent ],
})
export class ContainerTypeModule { }
