import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'

import { GenericService, Sorting, toString } from '../framework/services/generic.service'
import { DatabaseObject } from '../framework/objects'

export interface Card {
  account ?: DatabaseObject | null,
  id: string,
  comment: string,
  label: string,
  status: string,
  lastModificationDate: string | null,
  passive: boolean
  waste: { countOf: number }
}

@Injectable({
  providedIn: 'root'
})
export class CardService extends GenericService<Card> {

  private _cards: Subject<Card[]> = new Subject<Card[]>()
  private _statuses = ['ACTIVE', 'BLOCKED', 'ESCHEATED', 'EXPIRED', 'LOST', 'RETURNED']
  private _specials = ['-', 'CALIBRATION', 'CLOSURE', 'COLLECTION', 'MAINTENANCE', 'OPENING', 'PASS', 'TRANSMISSION', '-']

  bind(card: Card, accountId: string): Observable<Card | null> {
    return this.httpClient.put<Card>(this.endpoint() + '/' + card.id + '/bind',
      { accountId: accountId }
    )
  }

  endpoint(version: string = 'v1'): string { return this.sessionService.url('cards', version) }

  // TODO Complete this filter
  export(filter: any) {
    this.exportToFile('',{
      status: filter.status ? filter.status : '',
      orderBy: 'serial;ASC'
    })
  }

  override fetch(filter: any, sorting: Sorting, page: number = 1) {
    if (filter.id)
      this.getOne(filter.id)
    else
      this.getAll({
        accountId: filter.accountId ?? '',
        availability: filter.status && filter.status == 'AVAILABLES' ? 'AVAILABLE' : ( filter.status == 'ESCHEATEDS' ? 'AVAILABLE_AND_USED' : ''),
        label: filter.label ?? '',
        status: filter.status && filter.status != 'AVAILABLES' && filter.status != 'ESCHEATEDS' ? filter.status : '',
        withPassive: filter.accountId != undefined,
        orderBy:toString(sorting), page: page, per_page: 50,
      }
    )
  }

  isNormal(status: string) { return this._statuses.includes(status) }

  suggest(magic: string): Observable<Card[]> {
    this.httpClient.get<Card[]>(this.endpoint() + '/next-available', {
      params: { label: magic },
      observe: "response",
    }).subscribe(response => this._cards.next(response.body ?? []))
    return this._cards.asObservable()
  }

  statuses(full: boolean): string[] {
    if (full)
      return this._statuses.concat(this._specials)
    else
      return this._statuses
  }

  unbind(card: Card): Observable<Card | null> {
    return this.httpClient.put<Card>(this.endpoint() + '/' + card.id + '/unbind',
      { accountId: card?.account?.id }
    )
  }
}
