import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { Mode } from '../../generic-form/generic-form.component'

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.css']
})
export class DateFieldComponent implements OnInit {

  Mode = Mode

  date: Date | null = null

  @Input() before: Date | null = null
  @Input() label: string = 'Date'
  @Input() mode: Mode = Mode.reading
  @Input() required: boolean = false
  @Input() value: string | null = null

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>()

  ngOnInit() { if (this.value) this.date = new Date(this.value) }

  onDate() { if (this.date) this.valueChange.next(this.date.toISOString()) }
}
