<div class="pane-container">
  <app-closure-overview #overview [sorting]="sorting.field" title="Closures"
    (selection)="onShortcut($event)"></app-closure-overview>
  <app-action-pane [domain]="closure ? 'SETTINGS' : 'none'" [hint]="['Identifier']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event })"></app-action-pane>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-closure-form *ngIf="closure" [creation]="true" [mode]="Mode.edition" [object]="closure" (event)="onEvent($event)"></app-closure-form>
</ng-template>
<ng-template #showList>
  <app-closure-list #list [id]="id" [sorting]="sorting" (event)="onEvent($event)" (sort)="onSort($event)"></app-closure-list>
</ng-template>
