import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Observable } from 'rxjs'

import { Account } from '../account.service'
import { Mode } from '../../framework/generic-form/generic-form.component'
import { Sorting } from '../../framework/services/generic.service'
import { WasteService, Waste } from '../../waste/waste.service'

@Component({
  selector: 'app-account-waste',
  templateUrl: './account-waste.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css']
})
export class AccountWasteComponent {

  protected readonly Mode = Mode;

  count !: Observable<number>
  creation: boolean = false
  frozen: boolean = false
  total !: Observable<string | null>
  sorting: Sorting = { field: 'timestamp', order: 'DESC' }
  waste: Waste = { canceled: false, card: null, container: null, flowType: null, id: '', modifiable: true, price: 0, timestamp: new Date().toISOString(), weight: 0 }
  year !: number

  @Input() abstract: string = ''
  @Input() account !: Account
  @Output() event = new EventEmitter<string>()

  constructor(protected wasteService: WasteService) { }

  navigate(year: number) {
    this.year = year
    this.wasteService.yearly(+this.account.id, this.year, this.sorting)
    this.count = this.wasteService.count()
    this.total = this.wasteService.total()
  }

  onAdd() {
    this.creation = true
    this.event.next('edit')
  }

  // Edition events from waste list must be bubbled up to account view.
  onEvent(event: string) {
    this.frozen = event == 'edit'
    this.creation = false
    this.event.next(event)
    // The list must be refreshed since there is new or updated waste.
    if (event == 'add') this.onSort(null)
  }

  onSort(sorting: Sorting | null) {
    if (sorting) this.sorting = sorting
    this.wasteService.yearly(+this.account.id, this.year, this.sorting)
  }
}
