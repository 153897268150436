import { Injectable } from '@angular/core'

import { GenericService, Sorting, toString } from '../framework/services/generic.service'
import { DatabaseObject } from '../framework/objects'

export interface CategoryOverview {
  ACCESS: number,
  AFTER_SALES: number,
  COLLECTION: number,
  INFORMATION: number,
  MALFUNCTION: number,
  TECHNICAL: number,
}

export interface Ecopoint {
  id: string,
  identifier: string,
  location: string,
}

export interface Event {
  id: number,
  category: string,
  clientID: string,
  locality: DatabaseObject,
  container: DatabaseObject,
  creationDate: string,
  ecopoint: Ecopoint,
  ecologSerial: string,
  status: string,
  text: string,
  timestamp: string,
  type: string,
}

export interface Filter {
  category ?: string | null
}

export interface Notification {
  id: string,
  event: Event,
  level: string,
}

export interface Overview {
  all: number,
  categories: CategoryOverview,
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends GenericService<Notification> {

  categories(): (keyof CategoryOverview)[] {
    return ['ACCESS', 'AFTER_SALES', 'COLLECTION', 'INFORMATION', 'MALFUNCTION', 'TECHNICAL']
  }

  deleteOne(index: number) {
    let notification = this._objects.getValue()[index]
    if (notification)
      this.httpClient.delete(this.endpoint() + '/' + notification.id).subscribe({
        next: () => {
          let values = this._objects.getValue()
          values.splice(index, 1)
          this._objects.next(values)
          this.overview()
        }
      })
  }

  deleteAll(category: string | null) {
    this.httpClient.delete(this.endpoint(), {
      body: { category: category } }).subscribe({ next: () => {
        this._objects.next([])
        this.overview()
      }
    })
  }

  endpoint(version: string = 'v1'): string { return this.sessionService.url('notifications', version) }

  override fetch(filter: Filter, sorting: Sorting = { field: 'timestamp', order: 'DESC' }, page: number = 1) {
    this.getAll({ eventCategory: filter.category ?? '', orderBy: toString(sorting), page: page, per_page: 50 })
  }
}
