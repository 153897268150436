import { Component } from '@angular/core';

import { GenericOverviewComponent } from '../../framework/generic-overview/generic-overview'
import { DumpService, Dump } from '../dump.service'

@Component({
  selector: 'app-dump-overview',
  templateUrl: '../../framework/generic-overview/generic-overview.html',
  styleUrls: ['../../framework/generic-overview/generic-overview.css']
})
export class DumpOverviewComponent extends GenericOverviewComponent<Dump> {

  constructor(protected override service: DumpService) { super(service) }
}
