import { FileSaverService } from 'ngx-filesaver'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { DatabaseObject } from '../framework/objects'
import { GenericService, Sorting, toString } from '../framework/services/generic.service'
import { LanguageService } from '../framework/services/language.service'
import { SpinnerService } from '../framework/services/spinner.service'
import {SessionService} from "../framework/services/session.service";

export interface Card {
  id: number,
  label: string,
}

export interface Transaction {
  account: DatabaseObject | null,
  amount: number,
  card: Card | null,
  comment: string
  id: string,
  modifiable: boolean,
  status: string,
  ticket: string,
  till: DatabaseObject | null,
  timestamp: string,
  username: string | null,
  validity: string,
}

@Injectable({
  providedIn: 'root'
})
export class TransactionService extends GenericService<Transaction>  {

  constructor(
    protected override fileSaverService: FileSaverService,
    protected override httpClient: HttpClient,
    protected languageService : LanguageService,
    protected override sessionService: SessionService,
    protected override spinner: SpinnerService,
  ) { super(fileSaverService, httpClient, sessionService, spinner) }

  endpoint(version: string = 'v1'): string { return this.sessionService.url('transactions', version) }

  export(filter: any) {
    this.exportToFile('',{
      accountId: filter.accountId ?? '',
      endDate: this.sessionService.toISO(filter.endDate),
      startDate: this.sessionService.toISO(filter.startDate),
      ticket: filter.ticket ?? '',
      tillId: filter?.till?.id ?? '',
      username: filter.username ?? '',
      validity: filter.validity ?? '',
      orderBy: 'ticket;ASC'
    })
  }

  override fetch(filter: any, sorting: Sorting, page: number = 1) {
    this.getAll({
      accountId: filter.accountId ?? '',
      endDate: this.sessionService.toISO(filter.endDate),
      startDate: this.sessionService.toISO(filter.startDate),
      ticket: filter.ticket ?? '',
      tillId: filter?.till?.id ?? '',
      username: filter.username ?? '',
      validity: filter.validity ?? '',
      orderBy: toString(sorting), page: page, per_page: 50,
    })
  }

  statuses(): string[] { return ['INVALID', 'VALID'] }

  override update(transaction: Transaction): Observable<Transaction> {
    if (transaction.id)
      return this.putOne(transaction.id, {
        comment: transaction.comment ?? '',
        validity: transaction.validity,
      })
    else
      return this.putOne('', {
        accountId: transaction.account?.id,
        amount: transaction.amount ?? 0,
        comment: transaction.comment ?? '',
      })
  }
}
