import { NgModule } from '@angular/core'
import { FrameworkModule } from '../framework.module'

import { LocationFormComponent } from './location-form/location-form.component'
import { LocationListComponent } from './location-list/location-list.component'
import { LocationOverviewComponent } from './location-overview/location-overview.component'
import { LocationPageComponent } from './location-page/location-page.component'

@NgModule({
  declarations: [
    LocationFormComponent,
    LocationListComponent,
    LocationOverviewComponent,
    LocationPageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: []
})
export class LocationModule { }
