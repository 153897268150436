import { Component, OnInit, ViewChild } from '@angular/core'

import { ActionPaneComponent, Action } from '../components/action-pane/action-pane.component'
import { GenericListComponent } from '../generic-list/generic-list.component'
import { GenericOverviewComponent, Shortcut } from '../generic-overview/generic-overview'
import { GenericService, Sorting } from '../services/generic.service'
import { Mode } from '../generic-form/generic-form.component'

@Component({
  selector: 'app-generic-page',
  template: 'none',
  styleUrls: ['./generic-page.component.css']
})
export class GenericPageComponent<Type extends { id: string }> implements OnInit {

  Mode = Mode

  creation: boolean = false             // Set to switch between creation form and list.
  current: any                          // Current filter (combines all criteria).
  filter: any                           // Permanent filter (from filter pane).
  page: number = 1
  sorting: Sorting = { field: 'Identifier', order: 'ASC' }

  @ViewChild(ActionPaneComponent) action !: ActionPaneComponent
  @ViewChild('overview') overview !: GenericOverviewComponent<Type>
  @ViewChild('list') list !: GenericListComponent<Type>

  constructor(protected service: GenericService<Type>) {
    this.service.clear()
    this.filter = this.current = { }
  }

  // Obtain the initial list content for standard object pages (categories, tills, ...).
  ngOnInit() { this.update(null, null) }

  // Triggered by a button in the action pane.
  onAction(action: Action) { this.creation = action == Action.new }

  onEvent(event: string) {
    // Standard handling is to update the list when items are added or removed.
    if (event == 'add' || event == 'delete') {
      if (event == 'add') {
        this.action?.clearFilter()
        this.current = this.filter
      }
      this.update(null, null)
    }
    this.creation = false
  }

  // Triggered by a permanent filter.
  onFilter(filter: any) {
    this.filter = filter
    this.update(this.current,null) }

  onScroll() { if (this.service.more()) this.service.fetch(this.current, this.sorting, ++this.page) }

  // Triggered by a magic filter search, does not alter sorting.
  onSearch(filter: any) { this.update(filter, null) }

  // Triggered by a shortcut selection in the overview pane.
  onShortcut(shortcut: Shortcut) {
    this.action?.clearFilter()
    this.update(shortcut.filter, shortcut.sorting)
  }

  // Triggered by a click on a list header to change the sort order.
  onSort(sorting: Sorting) { this.update(null, sorting) }

  update(filter: any | null, sorting: Sorting | null | undefined) {

    if (filter) this.current = { ...filter, ...this.filter }
    if (sorting) this.sorting = { ...sorting }

    // Any list update cancels an ongoing edition and closes current selection.
    this.list?.unfreeze()
    this.list?.resetSelection()

    this.page = 1
    this.service.fetch(this.current, this.sorting, this.page)
  }
}
