<div class="criteria-pane">
  <h1>{{'Cards' | translate}}
    <span *ngIf="filter.accountId"> : <a routerLink="/accounts/{{filter.accountId}}">{{account}}</a></span>
    <span *ngIf="total | async as total">({{count | async}} / {{total | big}})</span>
  </h1>
  <div class="row">
    <app-dropdown label="State / Status" [prefix]="'card'" [required]="false" [(value)]="filter.status" [values]="statuses()"></app-dropdown>
    <mat-form-field>
      <mat-label>{{'Card' | translate}}</mat-label>
      <input matInput maxlength="8" [(ngModel)]="filter.label" (keydown.enter)="onSearch()">
    </mat-form-field>
  </div>
  <div class="row right">
    <button mat-flat-button (click)="onClear()">{{'Clear' | translate}}</button>
    <button mat-flat-button (click)="onSearch()">{{'Search' | translate}}</button>
    <button mat-flat-button (click)="onExport()">{{'Export' | translate}}</button>
  </div>
</div>
