import {Component, Input, OnInit} from '@angular/core'

import { GenericFormComponent, Mode } from '../../generic-form/generic-form.component'
import { HistoryService, HistoryEntry, Report } from '../history.service'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-history-entry-form',
  templateUrl: './history-entry-form.component.html',
  styleUrls: ['../../generic-form/generic-form.component.css']
})
export class HistoryEntryFormComponent extends GenericFormComponent<HistoryEntry> {

  Mode = Mode

  @Input() kinds: string[] = []

  constructor(
    protected override service: HistoryService,
    protected sessionService: SessionService,
  ) { super(service) }

  deletable(): boolean {
    if (['ASSOCIATION', 'DISSOCIATION', 'MAINTENANCE'].includes(this.subject.kind))
      return this.sessionService.write('AFTER_SALES')
    else
      return !this.service.visible(this.subject.report)
  }

  domain(): string {
    if (this.subject.kind == 'COMMENT')
      return 'LOCATIONS'
    else
      return 'AFTER_SALES'
  }

  override isValid(): boolean { return this.subject.comment > ' '}
}
