import { NgModule } from '@angular/core'
import { FrameworkModule } from '../framework.module'

import { HistoryEntryFormComponent } from './history-entry-form/history-entry-form.component'
import { HistoryListComponent } from './history-list/history-list.component'
import { HistoryViewComponent } from './history-view/history-view.component'

@NgModule({
  declarations: [
    HistoryEntryFormComponent,
    HistoryListComponent,
    HistoryViewComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [ HistoryViewComponent ]
})
export class HistoryModule { }
