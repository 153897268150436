<div class="embedded-header">
  <list-header class="width-10" [identifier]="'Date'"></list-header>
  <list-header class="width-15" [identifier]="'Type'"></list-header>
  <list-header class="width-45" [identifier]="'Comment'"></list-header>
  <list-header class="width-10" [identifier]="container() ? 'EcoLog' : 'Container'"></list-header>
  <list-header class="width-10" [identifier]="'Report'"></list-header>
  <list-header class="width-10" [identifier]="'User'"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as history">
  <div *ngFor="let entry of history; let index = index">
    <cdk-accordion-item *ngIf="entry.kind == 'AFTER_SALES_RESELLER' ? ('AFTER_SALES' | read) : true"
        #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
      <div [class]="'embedded-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
        <span class="width-10">{{entry.timestamp | datestamp}}</span>
        <span class="width-15">{{'history.' + entry.kind | translate}}</span>
        <span class="width-45">{{entry.comment | shorten:45}}</span>
        <span class="width-10">
          <div *ngIf="container(); then showEcolog else showContainer"></div>
          <ng-template #showContainer>
            <a *ngIf="entry.container" routerLink="/containers/{{entry.container.id}}">{{entry.container.identifier}}</a>
          </ng-template>
          <ng-template #showEcolog>
            <a *ngIf="entry.ecolog" routerLink="/ecologs/{{entry.ecolog.id}}">{{entry.ecolog.serial}}</a>
          </ng-template>
        </span>
        <span class="width-10">
          <a *ngIf="entry.report && visible(entry.report)" [routerLink]="'/reports/' + entry.report.id">#{{entry.report.id}}</a>
        </span>
        <span class="width-10"><span *ngIf="entry.user">{{entry.user.username}}</span></span>
      </div>
      <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
        <app-history-entry-form [kinds]="kinds" [object]="entry" (objectChange)="onUpdate(index, $event)"
          (event)="onEvent($event)"></app-history-entry-form>
      </div>
    </cdk-accordion-item>
  </div>
</cdk-accordion>
