import { Pipe, PipeTransform } from '@angular/core'

import { SessionService } from '../services/session.service'

@Pipe({
  name: 'write'
})
export class WritePipe implements PipeTransform {

  constructor(protected sessionService: SessionService) { }

  transform(domain: string): boolean { return this.sessionService.write(domain) }
}
