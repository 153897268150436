import {Component } from '@angular/core'

import { EcologService, Ecolog } from '../ecolog.service'
import { GenericFormComponent, Mode } from '../../generic-form/generic-form.component'

@Component({
  selector: 'app-ecolog-form',
  templateUrl: './ecolog-form.component.html',
  styleUrls: ['../../generic-form/generic-form.component.css']
})
export class EcologFormComponent extends GenericFormComponent<Ecolog> {

  Mode = Mode

  constructor(protected override service: EcologService) { super(service) }
}
