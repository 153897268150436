import { Component } from '@angular/core'

import { SessionService } from '../../services/session.service'
import { GenericPageComponent } from '../../generic-page/generic-page.component'
import { LocationService, Location } from '../location.service'

@Component({
  selector: 'app-location-page',
  templateUrl: './location-page.component.html',
  styleUrls: ['../../generic-page/generic-page.component.css']
})
export class LocationPageComponent extends GenericPageComponent<Location> {

  location: Location = { id: '', identifier: '', location: '', name: '', position: { x: 0, y: 0} }

  constructor(
    protected sessionService: SessionService,
    protected override service: LocationService
  ) {
    super(service)
    this.location.position = this.sessionService.defaultPosition()
  }
}
