import { Component, EventEmitter, Input, Output } from '@angular/core'

import { Mode } from '../../generic-form/generic-form.component'

@Component({
  selector: 'app-number-field',
  templateUrl: './number-field.component.html',
  styles: ['']
})
export class NumberFieldComponent {

  Mode = Mode

  @Input() hint: string = ''
  @Input() label: string = ''
  @Input() max: number = 100
  @Input() min: number = 0
  @Input() mode: Mode = Mode.reading
  @Input() required: boolean = false
  @Input() value: string = ''

  @Output() valueChange = new EventEmitter<string>()
 }
