import { Component } from '@angular/core'

import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'
import { Zone, ZoneService } from '../zone.service'

@Component({
  selector: 'app-zone-page',
  templateUrl: './zone-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css'],
})
export class ZonePageComponent extends GenericPageComponent<Zone> {

  zone: Zone = { code: '', id:'', identifier: '', kind: 'LOCATION', name: ''}

  constructor(protected override service: ZoneService) { super(service) }
}
