import {Component, Input} from '@angular/core'

import { GenericListComponent } from '../../framework/generic-list/generic-list.component'
import { CollectionService, Collection } from '../collection.service'

@Component({
  selector: 'app-collection-list',
  templateUrl: './collection-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class CollectionListComponent extends GenericListComponent<Collection> {

  @Input() container: boolean = false   // Indicates if the list is a container sublist.

  constructor(protected override service: CollectionService) { super(service) }

  override isDimmed(collection: Collection): boolean { return collection.status == 'DETECTED' }
}
