import { Component } from '@angular/core'

import { ClosureService, Closure } from '../closure.service'
import { GenericOverviewComponent } from '../../framework/generic-overview/generic-overview'

@Component({
  selector: 'app-closure-overview',
  templateUrl: '../../framework/generic-overview/generic-overview.html',
  styleUrls: ['../../framework/generic-overview/generic-overview.css']
})
export class ClosureOverviewComponent extends GenericOverviewComponent<Closure> {

  constructor(protected override service: ClosureService) { super(service) }
}
