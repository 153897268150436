import { Component } from '@angular/core'

import { GenericListComponent } from '../../generic-list/generic-list.component'
import { Truck, TruckService } from '../truck.service'

@Component({
  selector: 'app-truck-list',
  templateUrl: './truck-list.component.html',
  styleUrls: ['../../generic-list/generic-list.component.css']
})
export class TruckListComponent extends GenericListComponent<Truck> {

  constructor(protected override service: TruckService) { super(service) }

}
