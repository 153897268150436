import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { SessionService, Client } from '../../services/session.service'

@Component({
  selector: 'app-client-dropdown',
  templateUrl: './client-dropdown.component.html',
})
export class ClientDropdownComponent implements OnInit {

  clients: Client[] = []
  filter: any = ''
  sublist: Client[] = []

  @Input() status: string[] = ['ACTIVE']
  @Input() width: string = '100%'

  @Output() client: EventEmitter<Client | null> = new EventEmitter<Client | null>()

  constructor(protected sessionService: SessionService) { }

  ngOnInit() {
    this.sessionService.clients(clients => { this.clients = this.sublist = clients }, this.status)
  }

  // TODO Clear and reset dont clear selected item in the dropdown...
  clear() { this.filter = '' ; this.doFilter() }

  doFilter() {
    if (typeof this.filter == 'string') {
      this.sublist = this.clients.filter(client =>
        client.identifier.includes(this.filter?.toLowerCase()) ||
        client.shortName.toLowerCase().includes(this.filter?.toLowerCase())
      )
      this.client.next(null)
    }
    else {
      this.client.next(this.filter)
    }
  }

  reset() { this.filter = '' ; this.sublist = this.clients }

  shortName(client: Client): string { return client ? client.shortName : ''}
}
