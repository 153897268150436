import { Component, Input } from '@angular/core'

import { Card, CardService } from '../card.service'
import { GenericListComponent } from '../../framework/generic-list/generic-list.component'

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class CardListComponent extends GenericListComponent<Card> {

  @Input() account: boolean = false     // Indicates if the list is an account sublist.

  constructor(protected override service: CardService) { super(service) }

  override isDimmed(card: Card): boolean { return card.passive }

  isNormal(status: string) { return this.service.isNormal(status) }
}
