import {Component, Input} from '@angular/core'

import { ClosureService, Closure } from '../closure.service'
import { GenericFormComponent, Mode } from '../../framework/generic-form/generic-form.component'

@Component({
  selector: 'app-closure-form',
  templateUrl: './closure-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class ClosureFormComponent extends GenericFormComponent<Closure> {

  Mode = Mode

  before: Date = new Date()

  @Input() undo: string = ''

  constructor(protected override service: ClosureService) {
    super(service)
    this.before.setDate(this.before.getDate() - 10)
  }

  override isValid(): boolean {
    return this.subject.startDate > '' && this.subject.endDate > '' && this.subject.endDate > this.subject.startDate
  }

  open(): boolean { return this.object.status == 'TEMPORARY' || this.object.status == 'REOPENED' }

  onDownload() {
    this.service.export(this.object.id, () =>
      // Object needs an update to get export statistics.
      this.event.next('update')
    )
  }

  onLock() { this.service.close(this.object) ; this.object.status = 'UPDATING' }
  onUndo() { this.service.reopen(this.object) ; this.object.status = 'UPDATING' }
}
