import { Component, EventEmitter, Output } from '@angular/core'

import { DatabaseObject } from '../../objects'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-batch-filter',
  templateUrl: './batch-filter.component.html',
  styleUrls: ['../../generic-filter/generic-filter.component.css']
})
export class BatchFilterComponent {

  @Output() filterChange = new EventEmitter()

  constructor(protected sessionService: SessionService) { }

  onClient(client: DatabaseObject | null) { this.filterChange.emit({ clientId: client ? client.id : null }) }
}
