import { Component, EventEmitter, Input, Output } from '@angular/core'

import { Mode } from '../../generic-form/generic-form.component'

@Component({
  selector: 'app-form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: ['./form-buttons.component.css']
})
export class FormButtonsComponent {

  Mode = Mode

  @Input() actions: string[] = []
  @Input() domain: string = 'SETTINGS'
  @Input() mode: Mode = Mode.edition
  @Input() spacing: boolean = true
  @Input() valid: boolean = true
  @Output() event = new EventEmitter<string>()
}

