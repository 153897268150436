<div class="pane-container">
  <div class="pane">
    <h1>{{'dashboard.COMMUNICATION' | translate}}</h1>
    <app-pie-chart *ngIf="communication | async as data" [data]="data" route="/containers/communication/"></app-pie-chart>
  </div>
  <div class="pane">
    <h1>{{'dashboard.LEVEL' | translate}}</h1>
    <app-pie-chart *ngIf="level | async as data" [data]="data" route="/containers/level/"></app-pie-chart>
  </div>
  <div class="pane">
    <div class="flow-type">
      <select [(ngModel)]="flowTypeId" (change)="onFlowType()">
        <option *ngFor="let flowType of flowTypes" [value]="flowType.id">{{flowType.name | shorten:15}}</option>
      </select>
    </div>
    <div class="hint" *ngIf="type | async as type">{{'dashboard.' + type | translate}}</div>
    <div class="navigator">
      <app-navigator (emitter)="navigate($event)" [lower]="2019"></app-navigator>
    </div>
    <ngx-charts-bar-vertical *ngIf="access | async as data" [animations]="false" [results]="data" [scheme]="colors" [view]="[322, 233]"
      [xAxis]="true" [yAxis]="true" [yAxisTickFormatting]="format" (select)="onSelect($event)">
      <ng-template #tooltipTemplate let-model="model">
        <span *ngIf="type | async as type">
          <span *ngIf="type == 'weight'">{{months[model.name] | translate}} : {{model.value.toLocaleString(undefined, { minimumFractionDigits: 2 })}} kg</span>
          <span *ngIf="type == 'access'">{{months[model.name] | translate}} : {{model.value}}</span>
        </span>
      </ng-template>
    </ngx-charts-bar-vertical>
  </div>
</div>
<div *ngIf="session | async">
  <h1 class="notifications">Notifications</h1>
  <div *ngIf="overview | async as overview" class="ribbon">
    <mat-chip-set>
      <span *ngFor="let category of categories">
        <!-- Background color from class is not taken into account... -->
        <mat-chip *ngIf="overview.categories[category]"
          [style.background-color]="category == current ? 'var(--light)' : 'var(--medium)'"
          [value]="category.toUpperCase()" (click)="onChip(category)">
          {{'notification.' + category | translate}} ({{overview.categories[category]}})
        </mat-chip>
      </span>
    </mat-chip-set>
    <button mat-icon-button (click)="onDeleteAll()"><mat-icon>delete</mat-icon></button>
  </div>
  <app-notification-list></app-notification-list>
</div>
