import { Injectable } from '@angular/core'

import { GenericService } from '../framework/services/generic.service'
import { DatabaseObject, Point } from '../framework/objects'

export interface Dump extends DatabaseObject {
  position: Point
}

@Injectable({
  providedIn: 'root'
})
export class DumpService extends GenericService<Dump> {

  endpoint(version: string = 'v1'): string { return this.sessionService.url('dumps', version) }

}
