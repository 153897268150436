import { Component } from '@angular/core'

import { GenericListComponent } from '../../generic-list/generic-list.component'
import { RoleService, Role } from '../role.service'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css','../../generic-list/generic-list.component.css']
})
export class RoleListComponent extends GenericListComponent<Role> {

  constructor(
    protected override service: RoleService,
    protected sessionService: SessionService,
  ) { super(service) }

  application(role: Role, app: string): string {
    if (role.domain.read.includes(app))
      return 'done'
    else
      return 'close'
  }

  right(role: Role, domain: string): string {
    if (role.domain.write.includes(domain))
      return 'role.WRITE'
    else if (role.domain.read.includes(domain))
      return 'role.READ'
    else return ''
  }
}
