<div class="pane-container">
  <app-container-overview #overview [sorting]="sorting.field" title="Containers" (selection)="onShortcut($event)"></app-container-overview>
  <app-action-pane domain="LOCATIONS" [export]="true" [hint]="['Identifier', 'EcoPoint', 'Stock']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event })"></app-action-pane>
  <app-container-filter (filterChange)="onFilter($event)"></app-container-filter>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-container-form [creation]="true" [mode]="Mode.edition" [object]="container" (event)="onEvent($event)"></app-container-form>
</ng-template>
<ng-template #showList>
  <app-container-list #list [sorting]="sorting" (event)="onEvent($event)" (page)="onScroll()" (sort)="onSort($event)"></app-container-list>
</ng-template>
