import {Component, Input, OnInit } from '@angular/core'

import { GenericListComponent } from '../../framework/generic-list/generic-list.component'
import { Waste, WasteService } from '../waste.service'

@Component({
  selector: 'app-waste-list',
  templateUrl: './waste-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class WasteListComponent extends GenericListComponent<Waste> implements OnInit {

  @Input() account: boolean = false     // Indicates if the list is an account sublist.
  @Input() container: boolean = false   // Indicates if the list is a container sublist.

  embedded: boolean = false

  constructor(protected override service: WasteService) { super(service) }

  ngOnInit() { this.embedded = this.account || this.container }

  override isDimmed(waste: Waste): boolean { return waste.canceled }
}
