import { Injectable } from '@angular/core'

import { GenericService } from '../services/generic.service'
import { DatabaseObject } from '../objects'

export interface Truck extends DatabaseObject {
  capacity: string
  distanceCost: string
  fixedCost: string
  height: string
  kind: string
  speed: string
  timeCost: string
  volumeMax: number
  weightMax: string
}

@Injectable({
  providedIn: 'root'
})
export class TruckService extends GenericService<Truck>{

  endpoint(version: string = 'v1'): string { return this.sessionService.url('trucks', version) }
}
