import { Component, Input } from '@angular/core'

import { DatabaseObject } from '../../framework/objects'
import { FlowTypeService, FlowType } from '../../framework/flow-type/flow-type.service'
import { GenericFormComponent, Mode } from '../../framework/generic-form/generic-form.component'
import { WasteService, Waste } from '../waste.service'

@Component({
  selector: 'app-waste-form',
  templateUrl: './waste-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class WasteFormComponent extends GenericFormComponent<Waste> {

  Mode = Mode

  flowTypes: FlowType[] = []
  status: string = ''
  weight: string = ''

  @Input() accountId: string = ''

  constructor(
    protected flowTypeService: FlowTypeService,
    protected override service: WasteService,
    ) { super(service) }

  override initializeForm() {
    super.initializeForm()
    this.flowTypeService.list(flowTypes => {
      this.flowTypes = flowTypes
      if (flowTypes.length) this.subject.flowType = flowTypes[0]
    })
    this.status = this.object.canceled ? 'CANCELED' : 'VALID'
    this.weight = this.weight == null ? this.object.weight.toFixed(2) : ''
  }

  onFlowType(flowType: DatabaseObject | null) { if (flowType) this.subject.flowType = flowType as FlowType }

  statuses(): string[] { return ['CANCELED', 'VALID'] }

  override updateSubject() {
    if (this.subject.id)
      this.subject.canceled = this.status == 'CANCELED'
    else {
      this.subject.accountId = this.accountId
      this.subject.weight = +this.weight
    }
  }
}
