import { ActivatedRoute } from '@angular/router'
import { Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {Observable, Subscription, take} from 'rxjs'

import { ContainerService, Container } from '../../framework/container/container.service'
import { Ecopoint } from '../../ecopoint/ecopoint.service'
import { FlowTypeService, FlowType } from '../../framework/flow-type/flow-type.service'
import { Mode } from '../../framework/generic-form/generic-form.component'
import { WasteService } from '../waste.service'
import { ZoneService, Zone } from '../../zone/zone.service'
import { SessionService, Client, User } from '../../framework/services/session.service'

@Component({
  selector: 'app-waste-criteria-form',
  templateUrl: './waste-criteria-form.component.html',
  styleUrls: ['../../framework/generic-criteria/generic-criteria.css']
})
export class WasteCriteriaFormComponent implements OnInit, OnDestroy {

  Mode = Mode

  account: string | null = null
  accountZone: Zone | null = null
  accountZones: Zone[] = []
  allContainers: Container[] = []
  card: string = ''
  container: any = ''                   // Either container filter or selected object.

  containers: Container[] = []
  count: Observable<number>
  ecopoint: Ecopoint | null = null
  export: string | null = null
  filter: any = { }
  flowType: FlowType | null = null
  flowTypes: FlowType[] = []
  grouping: string = 'NONE'
  groupings: string[] = ['NONE', 'ACCOUNT', 'CARD', 'CONTAINER']
  locationZone: Zone | null = null
  locationZones: Zone[] = []
  total: Observable<string | null>
  subscription: Subscription

  @Output() criteria = new EventEmitter()

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected sessionService: SessionService,
    protected containerService: ContainerService,
    protected flowTypeService: FlowTypeService,
    protected service: WasteService,
    protected zoneService: ZoneService
  ) {
    zoneService.list(zones => this.accountZones = zones, 'ACCOUNT')
    zoneService.list(zones => this.locationZones = zones, 'LOCATION')
    this.count = service.count()
    this.total = service.total()
    this.subscription = sessionService.session().subscribe(session => { if (session) this.updateUserConstraints() })
    let client = sessionService.client()
    if (client) this.export = client.configuration.wasteExportFormat
  }

  containerRef(container: Container): string { return container ? container.identifier : '' }

  doFilterContainers() {
    this.containers = this.allContainers.filter(container => {
      return container.identifier.toLowerCase().includes(this.container) &&
        (!this.ecopoint || (container.ecopoint && this.ecopoint.id == container.ecopoint.id))
    })
  }

  ngOnDestroy() { this.subscription.unsubscribe() }

  ngOnInit() {

    // Route may contain an account id, identification and closing date.
    const id = this.activatedRoute.snapshot.paramMap.get('id')
    if (id) {
      this.filter.accountId = id
      this.account = this.activatedRoute.snapshot.paramMap.get('account')
      const start = this.activatedRoute.snapshot.paramMap.get('date')
      if (start) {
        this.filter.startDate = new Date(start)
        this.filter.startDate.setDate(this.filter.startDate.getDate() + 1)
        this.filter.endDate = new Date()
      }
      this.onSearch()
    }

    // Route may contain a container id.
    const containerId = this.activatedRoute.snapshot.paramMap.get('container')
    this.containerService.list(containers => {
      this.allContainers = containers
      this.doFilterContainers()
      if (containerId) {
        this.container = this.allContainers.find(container => container.id == containerId)
        this.onSearch()
      }
    }, 'identifier')

    // Route may contain a flow-type, year and month.
    const flowTypeId = this.activatedRoute.snapshot.paramMap.get('flowtype')

    if (flowTypeId) {
      const year = this.activatedRoute.snapshot.paramMap.get('year')
      const month = this.activatedRoute.snapshot.paramMap.get('month')
      if (year && month) {
        this.filter.startDate = new Date(+year, +month - 1, 1)
        this.filter.endDate = new Date(+year, +month, 0)
      }
    }

    this.flowTypeService.list(flowTypes => {
      this.flowTypes = flowTypes
      if (flowTypeId) {
        const flowType = flowTypes.find(flowType => flowType.id == flowTypeId)
        if (flowType) this.flowType = flowType
      }
      if (flowTypeId) this.onSearch()
    })
  }

  onClear() {
    this.account = null
    this.container = ''
    this.ecopoint = null
    this.filter = { endDate: null, startDate: null }
    this.updateUserConstraints();
    this.doFilterContainers()
  }

  onEcopoint(ecopoint: Ecopoint| null) { this.ecopoint = ecopoint; this.doFilterContainers() }

  onExport(grouping: string) { this.updateFilter(); this.service.export(this.filter, grouping) }

  onPrint() { this.updateFilter(); this.service.print(this.filter, this.account) }

  onSearch() { this.updateFilter(); this.criteria.emit(this.filter) }

  updateFilter() {
    this.filter.containerId = this.container ? this.container.id : ''
    this.filter.ecopointId = this.ecopoint ? this.ecopoint.id : ''
    this.filter.flowTypeId = this.flowType ? this.flowType.id : ''
    this.filter.accountZoneId = this.accountZone ? this.accountZone.id : ''
    this.filter.locationZoneId = this.locationZone ? this.locationZone.id : ''
  }

  updateUserConstraints() {
    let user = this.sessionService.user()
    if (user) {
      this.accountZone = user.accountZone
      this.flowType = user.flowType
      this.locationZone = user.locationZone
    }
  }
}

