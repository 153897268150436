import { Component, Input } from '@angular/core'

import { Parameter } from '../ecolog-parameters.component'

@Component({
  selector: 'app-text-parameter',
  templateUrl: './text-parameter.component.html',
  styleUrls: []
})
export class TextParameterComponent {

  @Input() parameter !: Parameter
}
