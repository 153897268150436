import { Stop } from '../tour/tour.service'
import { convert } from '../objects'
import { GoogleMap } from '@angular/google-maps'

import Marker = google.maps.Marker
import Point = google.maps.Point
import Size = google.maps.Size

export function stopMarker(stop:Stop, map: GoogleMap): Marker {

  let marker = new Marker

  if (stop.container) {
    let order = stop.order ?? 0
    marker.setOptions({
      // Icon selection, either without badge, or with small or large badge.
      ...(!order) && { icon: {
          url: 'assets/ecopoint.png', scaledSize: new google.maps.Size(37, 37) }},
      ...(order > 0 && order <= 9) && { icon: {
          url: 'assets/ecopoint-1.png', labelOrigin: { x: 38, y: 12 } as Point, scaledSize: new Size(50, 60)
        }},
      ...(order > 9) && { icon:  {
          url: 'assets/ecopoint-2.png', labelOrigin: { x: 42, y: 12 } as Point, scaledSize: new Size(63, 60)
        }},
      // Label to be positionned in badge.
      ...stop.order && { label: { color: '#1968a2', text: order.toString(), fontSize: '18px', fontWeight: 'bold' } },
      ...stop.container.ecopoint && { position: convert(stop.container.ecopoint.center) }
    })
  }
  else if (stop.dump)
    marker.setOptions({
      icon: {url: 'assets/dump.png', scaledSize: new Size(37, 37)},
      position: convert(stop.dump.position)
    })

  if (map.googleMap) marker.setMap(map.googleMap)

  return marker
}
