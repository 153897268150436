import { NgModule } from '@angular/core'
import { FrameworkModule } from '../framework.module'

import { NgxChartsModule } from '@swimlane/ngx-charts'

import { BarChartComponent } from './bar-chart/bar-chart.component'
import { BubbleChartComponent } from './bubble-chart/bubble-chart.component'
import { LineChartComponent } from './line-chart/line-chart.component'
import { PieChartComponent } from './pie-chart/pie-chart.component'

@NgModule({
  declarations: [
    BarChartComponent,
    BubbleChartComponent,
    LineChartComponent,
    PieChartComponent,
  ],
  imports: [ FrameworkModule, NgxChartsModule ],
  exports: [
    NgxChartsModule,
    BarChartComponent,
    BubbleChartComponent,
    LineChartComponent,
    PieChartComponent,
  ]
})
export class ChartsModule { }
