<div class="ribbon">
  <span class="group">
    <mat-form-field>
      <mat-label>{{"Card" | translate}}</mat-label>
      <input matInput [matAutocomplete]="auto" [(ngModel)]="card" (ngModelChange)="doFilterCards()" required type="text" >
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="cardLabel">
        <mat-option *ngFor="let card of cards" [value]="card">{{card.label}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button mat-flat-button (click)="onAdd()" [disabled]="frozen || noSelection()">{{'Add' | translate}}</button>
  </span>
  <a mat-icon-button routerLink="/cards/account/{{account.id}}/{{account | link}}"><mat-icon>more_horiz</mat-icon></a>
</div>
<div class="list">
  <app-card-list [account]="true" [sorting]="sorting" (event)="onEvent($event)" (sort)="onSort($event)"></app-card-list>
</div>
