import { Component, Input } from '@angular/core'

import { Transaction, TransactionService } from '../transaction.service'
import { GenericListComponent } from '../../framework/generic-list/generic-list.component'

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class TransactionListComponent extends GenericListComponent<Transaction> {

  @Input() account: boolean = false     // Indicates if the list is an account sublist.

  constructor(protected override service: TransactionService) { super(service) }

  override isDimmed(transaction: Transaction): boolean { return transaction.validity != 'VALID' }
}
