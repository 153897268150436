import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { Container, ContainerService } from '../container.service'
import { Ecolog, EcologService } from '../../ecolog/ecolog.service'

export interface SelectionData {
  container: Container
}

@Component({
  selector: 'app-ecolog-select',
  templateUrl: './ecolog-select.component.html',
  styleUrls: ['../../generic-form/generic-form.component.css']
})
export class EcologSelectComponent {

  current: Ecolog | null = null
  ecologs: Ecolog[] = []
  future: Ecolog | null = null
  warranty: boolean = false

  constructor(
    public dialog: MatDialogRef<EcologSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectionData,
    protected containerService: ContainerService,
    protected ecologService: EcologService,
  ) {
    if (data.container.ecolog) ecologService.get(data.container.ecolog.id).subscribe(ecolog => {
      this.current = ecolog
      if (ecolog.warrantyDate && ecolog.warrantyDate > new Date().toISOString())
        this.warranty = true
    })
    ecologService.ecologs(data.container.ecolog != null, ecologs => this.ecologs = ecologs)
  }

  label(): string {
    if (this.current)
      return this.future ? 'Replacement' : 'Dissociation'
    else
      return 'Association'
  }
  onOK() { this.dialog.close({ ecolog: this.future, warranty: this.warranty }) }

  onSelect(id: string) {
    if (id) {
      let ecolog = this.ecologs.find(ecolog => ecolog.id == id)
      if (ecolog) this.future = ecolog
      this.onWarranty()
    }
    else
      this.future = null
  }

  onWarranty() {
    const ecolog = this.future
    if (ecolog) {
      if (this.current) {
        ecolog.deliveryRef = 'SAV'
        if (this.warranty) {
          // EcoLog replacement under warranty.
          ecolog.deliveryDate = this.current.deliveryDate
          ecolog.warrantyDate = this.current.warrantyDate
        } else {
          // EcoLog replacement out of warranty.
          ecolog.deliveryDate = new Date().toISOString()
          this.setWarrantyDate(ecolog)
        }
      } else {
        // New EcoLog.
        if (ecolog.deliveryRef == null) ecolog.deliveryRef = 'SAV'
        if (ecolog.deliveryDate == null) ecolog.deliveryDate = new Date().toISOString()
        this.setWarrantyDate(ecolog)
      }
    }
  }

  setWarrantyDate(ecolog: Ecolog) {
    let warranty = new Date()
    warranty.setFullYear(warranty.getFullYear() + 2)
    ecolog.warrantyDate = warranty.toISOString()
  }
}
