<div [class]="account ? ' embedded-header' : 'list-header'">
  <list-header class="width-10" [embedded]="account" [identifier]="'serial'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10" [embedded]="account" [identifier]="'Status'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-10 numberHeader" [identifier]="'Deposits'"></list-header>
  <list-header class="width-20" [embedded]="account" [identifier]="'last_modification_date'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="{{account ? 'width-50' : 'width-25'}}" [embedded]="account" [identifier]="'Comment'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header *ngIf="!account" class="link width-25" [identifier]="'account_name'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as cards">
  <cdk-accordion-item *ngFor="let card of cards; let index = index"
    #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="(account ? 'embedded-item' : 'list-item') + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span [class]="'width-10' + dim(card)">{{card.label}}</span>
      <span [class]="'width-10' + dim(card)">{{('card.' + card.status | translate)}}</span>
      <span [class]="'width-10 numberItem' + dim(card)">{{card.waste.countOf}}</span>
      <span [class]="'width-20' + dim(card)">{{card.lastModificationDate | datestamp}}</span>
      <span [class]="(account ? 'width-50' : 'width-25') + dim(card)">{{card.comment | shorten:(account ? 50 : 25)}}</span>
      <span *ngIf="!account" class="width-25">
        <a *ngIf="card.account" routerLink="/accounts/{{card.account.id}}">{{card.account | link | shorten:25 }}</a>
      </span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-card-form [object]="card" [unbindable]="account" (objectChange)="onUpdate(index, $event)" (event)="onEvent($event)"></app-card-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
<div class="count" #scroll><span *ngIf="total | async as total">{{count | async}} / {{total | big}}</span></div>
