<div class="pane-container">
  <app-dump-overview #overview [sorting]="sorting.field" title="Dumps" (selection)="onShortcut($event)"></app-dump-overview>
  <app-action-pane domain="LOCATIONS" [hint]="['Reference', 'Name']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event})"></app-action-pane>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-dump-form [creation]="true" [mode]="Mode.edition" [object]="dump" (event)="onEvent($event)"></app-dump-form>
</ng-template>
<ng-template #showList>
  <app-dump-list #list [sorting]="sorting" (event)="onEvent($event)" (sort)="onSort($event)"></app-dump-list>
</ng-template>

