import { Component, Input } from '@angular/core';

import { ClosureService, Closure } from '../closure.service'
import { GenericListComponent } from '../../framework/generic-list/generic-list.component'

@Component({
  selector: 'app-closure-list',
  templateUrl: './closure-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class ClosureListComponent extends GenericListComponent<Closure> {

  @Input() id: string = ''              // Undoable closure id.

  constructor(protected override service: ClosureService) { super(service) }
}
