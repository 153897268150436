import { Component, EventEmitter, Input, OnInit, Output  } from '@angular/core'
import { Observable } from 'rxjs'

import { GenericService, Sorting } from '../services/generic.service'

export interface Shortcut {
  field ?: string,
  filter: any,
  name: string,
  sorting ?: Sorting | null,
}

@Component({
  selector: 'app-generic-overview',
  template: '',
  styleUrls: ['./generic-overview.css']
})
export class GenericOverviewComponent<Type extends { id: string }> implements OnInit {

  overview !: Observable<any>           // Should be assigned in initialization.

  shortcuts: Shortcut[] = [{ field: 'all', filter: {}, name: 'All' }]
  sorter: Shortcut | null = null

  @Input() sorting: string = ''
  @Input() title: string = ''

  @Output() selection = new EventEmitter<Shortcut>()

  constructor(protected service: GenericService<Type>) { }

  // Instead of the constructor, this is overridable in component derivations.
  ngOnInit() { this.overview = this.service.overview() }
}
