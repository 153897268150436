import { FileSaverService } from 'ngx-filesaver'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { GenericService, Sorting, toString } from '../services/generic.service'
import { LanguageService } from '../services/language.service'
import { SessionService, Client } from '../services/session.service'
import { SpinnerService } from '../services/spinner.service'

export interface Batch {
  client: Client | null
  code: number,
  comment: string,
  deliveryDate: string | null,
  deliveryRef: string,
  encryption: string,
  first: string,
  generationDate: string,
  id: string,
  mode: string,
  last: string,
  availableCards ?: number,
}

@Injectable({
  providedIn: 'root'
})
export class BatchService extends GenericService<Batch> {

  constructor(
    protected override fileSaverService: FileSaverService,
    protected override httpClient: HttpClient,
    protected languageService : LanguageService,
    protected override sessionService: SessionService,
    protected override spinner: SpinnerService,
  ) { super(fileSaverService, httpClient, sessionService, spinner) }

  endpoint(version: string = 'v1'): string { return this.sessionService.url('batches', version) }

  override fetch(filter: any, sorting: Sorting = { field: 'first', order: 'ASC' }, page= 1, max: number = 50) {
    if (filter.id)
      this.getOne(filter.id)
    else
      this.getAll({
        ...(filter.clientId && { clientId: filter.clientId }),
        orderBy: toString(sorting), page: page, per_page: max
      })
  }

  generate(batchId: string, callback: () => void) {
    this.httpClient.post(this.endpoint() + '/' + batchId + '/cards/generate', {}).subscribe(() =>
      callback()
    )
  }

  override update(batch: Batch): Observable<Batch> { return this.putOne(batch.id, {
    ...batch.client && { clientId : batch.client.id },
    code: batch.code,
    comment: batch.comment,
    deliveryDate: batch.deliveryDate ? this.sessionService.toISO(new Date(batch.deliveryDate)) : '',
    deliveryRef: batch.deliveryRef,
    encryption: batch.encryption,
    first: batch.first,
    mode: batch.mode,
    last: batch.last,
  }) }
}
