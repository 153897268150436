<div class="list-header">
  <list-header class="width-15" [identifier]="'Identifier'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
  <list-header class="width-35" [identifier]="'Name'" [sorting]="sorting" (sort)="onSort($event)"></list-header>
</div>
<cdk-accordion *ngIf="objects | async as dumps">
  <cdk-accordion-item *ngFor="let dump of dumps; let index = index"
    #accordionItem="cdkAccordionItem" expanded="{{index == selection}}" role="button" tabindex="0" [attr.id]="'header-' + index">
    <div [class]="'list-item' + (selection == index ? ' selected' : '')" (click)="onToggle(accordionItem, index)">
      <span class="width-15">{{dump.identifier}}</span>
      <span class="width-35">{{dump.name | shorten:35}}</span>
    </div>
    <div *ngIf="accordionItem.expanded" role="region" [style.display]='' [attr.id]="'body-' + index">
      <app-dump-form [object]="dump" (event)="onEvent($event)" (objectChange)="onUpdate(index, $event)"></app-dump-form>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
