import { Component } from '@angular/core'

import { GenericFormComponent } from '../../framework/generic-form/generic-form.component'
import { DumpService, Dump } from '../dump.service'

@Component({
  selector: 'app-dump-form',
  templateUrl: './dump-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class DumpFormComponent extends GenericFormComponent<Dump> {

  constructor(protected override service: DumpService) { super(service) }
}
