import { NgModule } from '@angular/core'
import { MatTabsModule } from '@angular/material/tabs'

import { ChartsModule } from '../charts/charts.module'
import { FrameworkModule } from '../framework.module'
import { HistoryModule } from '../history/history.module'

import { CommandsListComponent } from './commands/commands-list/commands-list.component'
import { CommandsViewComponent } from './commands/commands-view/commands-view.component'
import { CommandsFormComponent } from './commands/commands-form/commands-form.component'
import { EcologBlacklistsComponent } from './ecolog-blacklists/ecolog-blacklists.component'
import { EcologFilterComponent } from './ecolog-filter/ecolog-filter.component'
import { EcologFormComponent } from './ecolog-form/ecolog-form.component'
import { EcologListComponent } from './ecolog-list/ecolog-list.component'
import { EcologMeasuresComponent } from './ecolog-measures/ecolog-measures.component'
import { EcologOverviewComponent } from './ecolog-overview/ecolog-overview.component'
import { EcologPageComponent } from './ecolog-page/ecolog-page.component'
import { EcologParametersComponent } from './ecolog-parameters/ecolog-parameters.component'
import { EcologViewComponent } from './ecolog-view/ecolog-view.component'
import { TextParameterComponent } from './ecolog-parameters/text-parameter/text-parameter.component'

@NgModule({
  declarations: [
    CommandsFormComponent,
    CommandsListComponent,
    CommandsViewComponent,
    EcologBlacklistsComponent,
    EcologFilterComponent,
    EcologFormComponent,
    EcologListComponent,
    EcologMeasuresComponent,
    EcologOverviewComponent,
    EcologPageComponent,
    EcologParametersComponent,
    EcologViewComponent,
    TextParameterComponent,
  ],
  imports: [
    MatTabsModule,

    ChartsModule,
    FrameworkModule,
    HistoryModule,
  ],
  exports: [
    EcologViewComponent,
    EcologPageComponent ]
})
export class EcologModule { }
