import { Component } from '@angular/core';

import { GenericOverviewComponent } from '../../framework/generic-overview/generic-overview'
import { TillService, Till } from '../till.service'

@Component({
  selector: 'app-till-overview',
  templateUrl: '../../framework/generic-overview/generic-overview.html',
  styleUrls: ['../../framework/generic-overview/generic-overview.css']
})
export class TillOverviewComponent extends GenericOverviewComponent<Till> {

  constructor(override service: TillService) { super(service) }
}
