import { NgModule } from '@angular/core'
import { FrameworkModule } from '../framework.module'

import { BatchFilterComponent } from './batch-filter/batch-filter.component';
import { BatchFormComponent } from './batch-form/batch-form.component'
import { BatchListComponent } from './batch-list/batch-list.component'
import { BatchOverviewComponent } from './batch-overview/batch-overview.component'
import { BatchPageComponent } from './batch-page/batch-page.component'

@NgModule({
  declarations: [
    BatchFilterComponent,
    BatchFormComponent,
    BatchListComponent,
    BatchOverviewComponent,
    BatchPageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [ BatchPageComponent ]
})
export class BatchModule { }
