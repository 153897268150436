import { Component } from '@angular/core'

import { ContainerTypeService, ContainerType, DatabaseObject } from '../container-type.service'
import { GenericFormComponent, Mode } from '../../framework/generic-form/generic-form.component'

@Component({
  selector: 'app-container-type-form',
  templateUrl: './container-type-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class ContainerTypeFormComponent extends GenericFormComponent<ContainerType>  {

  Mode = Mode

  error: string = ''
  maximum: string = ''
  model: DatabaseObject | null = null
  models: ContainerType[] = []

  constructor(protected override service: ContainerTypeService) {
    super(service)
    this.service.models(models => this.models = models)
  }

  override initializeForm() {
    super.initializeForm()
    this.model = this.subject.containerTypeModel
    // Calculate maximum level.
    this.isValid()
  }

  override isValid(): boolean {

    const d1 = +this.subject.d1
    const h1 = +this.subject.h1
    const h2 = +this.subject.h2
    const v1 = +this.subject.v1

    if (h1 > 199 && h2 > 199)
      if (h2 <= h1) {
        this.subject.v2 = (h2 / h1 * v1).toFixed()
        this.maximum = (100 * (h1 - d1 - 200) / h2).toFixed()
        return true
      }
      else
        this.error = 'containerType.h2>h1'
    else
      this.error = 'containerType.h<200'

    this.subject.v2 = ''
    this.maximum = ''
    return false
  }

  onModel() {
    if (this.model) {
      const id = this.model.id
      const model: ContainerType | undefined = this.models.find(model => model.id == id)
      if (model) {
        this.subject.brand = model.brand
        this.subject.kind = model.kind
        this.subject.name = model.name
        this.subject.d1 = model.d1
        this.subject.h1 = model.h1
        this.subject.h2 = model.h2
        this.subject.v1 = model.v1
        this.check()
      }
    }
  }

  override updateSubject() { this.subject.containerTypeModel = this.model }
}
