import { ActivatedRoute} from '@angular/router'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Observable } from 'rxjs'

import { CardService } from '../card.service'

@Component({
  selector: 'app-card-criteria-form',
  templateUrl: './card-criteria-form.component.html',
  styleUrls: ['../../framework/generic-criteria/generic-criteria.css']
})
export class CardCriteriaFormComponent implements OnInit {

  count: Observable<number>
  total: Observable<string | null>

  account: string | null = null
  filter: any = { }

  @Output() criteria = new EventEmitter()

  constructor(
      protected activatedRoute: ActivatedRoute,
      protected service: CardService
  ) {
    this.count = service.count()
    this.total = service.total()
  }

  ngOnInit() {
    let route = this.activatedRoute.snapshot
    if (route.url.length > 1 && route.url[1].path == 'account') {
      this.filter.accountId = route.paramMap.get('id')
      this.account = route.paramMap.get('account')
    }
    else
      this.filter.id = route.paramMap.get('id')
    if (this.filter.id || this.filter.accountId) this.onSearch()
  }

  onClear() { this.filter = { } }

  onExport() { this.service.export(this.filter) }

  onSearch() { this.criteria.emit(this.filter) }

  statuses(): string[] { return ['AVAILABLES', 'ESCHEATEDS', '-'].concat(this.service.statuses(true)) }
}

