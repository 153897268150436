import { Component } from '@angular/core'

import { GenericListComponent } from '../../framework/generic-list/generic-list.component'
import { GroupingService, Grouping } from '../grouping.service'

@Component({
  selector: 'app-grouping-list',
  templateUrl: './grouping-list.component.html',
  styleUrls: ['../../framework/generic-list/generic-list.component.css']
})
export class GroupingListComponent extends GenericListComponent<Grouping> {

  constructor(protected override service: GroupingService) { super(service) }
}
