import { ActivatedRoute } from "@angular/router";
import { Component, EventEmitter, OnInit, Output } from '@angular/core'

import { Mode } from '../../framework/generic-form/generic-form.component'
import { TillService, Till } from "../../till/till.service"
import { TransactionService } from '../transaction.service'
import { UserService, User } from "../../framework/user/user.service"

@Component({
  selector: 'app-transaction-criteria-form',
  templateUrl: './transaction-criteria-form.component.html',
  styleUrls: ['../../framework/generic-criteria/generic-criteria.css']
})
export class TransactionCriteriaFormComponent implements OnInit {

  Mode = Mode

  account !: string | null
  filter: any = { }
  tills: Till[] = []
  users: User[] = []

  @Output() criteria = new EventEmitter();

  constructor(
      private activatedRoute: ActivatedRoute,
      private tillService: TillService,
      private service: TransactionService,
      private userService: UserService,
  ) {
    this.userService.list(users => this.users = users,'lastName')
    this.tillService.list(tills => this.tills = tills)
  }

  ngOnInit() {
    this.filter = { accountId: this.activatedRoute.snapshot.paramMap.get('id') }
    this.account = this.activatedRoute.snapshot.paramMap.get('account')
    this.onSearch()
  }

  onClear() { this.filter = {} }

  onExport() { this.service.export(this.filter) }

  onSearch() { this.criteria.emit(this.filter) }

  statuses(): string[] { return this.service.statuses() }
}
