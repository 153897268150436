import { Component } from '@angular/core'

import { GenericFormComponent } from '../../framework/generic-form/generic-form.component'
import { Category, CategoryService } from '../category.service'

@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class CategoryFormComponent extends GenericFormComponent<Category> {

  constructor(protected override service: CategoryService) { super(service)
  }

  override isValid(): boolean { return this.subject.name > ' ' }
}
