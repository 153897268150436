<div class="editor">
  <div class="form">
    <div class="row">
      <app-text-field class="width-15" [label]="'Identifier'" [length]="10" [mode]="mode"
        [(value)]="subject.identifier"></app-text-field>
      <app-text-field class="width-35" [label]="'Name'" [length]="200" [mode]="mode" [required]="true"
        [(value)]="subject.name" (valueChange)="check()"></app-text-field>
      <app-object-dropdown class="width-20" [label]="'Grouping'" [mode]="mode" [required]="true"
        [(object)]="subject.grouping" [objects]="groupings" (objectChange)="onGrouping($event)">
      </app-object-dropdown>
      <app-object-dropdown class="width-20" [label]="'Zone'" [mode]="mode"
        [(object)]="subject.zone" [objects]="zones" ></app-object-dropdown>
    </div>
    <div *ngIf="'AFTER_SALES' | write" class="row">
      <app-number-field class="width-15" label="Distance" [mode]="mode" [(value)]="subject.distance"></app-number-field>
    </div>
    <app-position [(center)]="subject.center" [mode]="mode" [zoom]="12"></app-position>
  </div>
  <app-form-buttons [actions]="('AFTER_SALES' | write) ? ['delete'] : []" domain="LOCATIONS" [mode]="mode" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
</div>
