import { AfterContentInit, Component, ViewChild } from '@angular/core'

import { SessionService } from '../../framework/services/session.service'
import { convert, Point } from '../../framework/objects'
import { Ecopoint, EcopointService } from '../../ecopoint/ecopoint.service'
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps'

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements AfterContentInit {

  bounds = new google.maps.LatLngBounds()
  center: Point
  content: string = ''
  ecopointId: string = ''
  ecopoints: Ecopoint[] = []

  icon: google.maps.MarkerOptions = {
    icon: { url:'assets/ecopoint.png', scaledSize: new google.maps.Size(32, 32) }
  }

  options: google.maps.MapOptions = {
    clickableIcons: false,
    minZoom: 8, maxZoom:20, zoom: 14,
    styles: [
      { featureType: "poi", elementType: "labels.text", stylers: [{ visibility: "off" }] },
      { featureType: "poi", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    ]
  }

  @ViewChild(GoogleMap) map !: GoogleMap
  @ViewChild(MapInfoWindow, { static: false }) info !: MapInfoWindow

  constructor(
    protected sessionService: SessionService,
    protected ecopointService: EcopointService)
  {
    this.center = sessionService.defaultPosition()
    ecopointService.list(ecopoints => {
      this.ecopoints = ecopoints
      for (let ecopoint of ecopoints) this.bounds.extend(convert(ecopoint.center))
    },'identifier')
  }

  convert(point: Point): google.maps.LatLngLiteral { return convert(point) }

  ngAfterContentInit() {
    setTimeout(() => { if (this.map) this.map.fitBounds(this.bounds, 16) },1000)
  }

  onEcopoint(marker: MapMarker, ecopoint: Ecopoint) {
    // The info window has peculiar behaviour, only string references are dynamic.
    this.ecopointId = ecopoint.id
    this.content = (ecopoint.identifier > '' ? (ecopoint.identifier + ' - ') : '') + ecopoint.location
    if (this.info) this.info.open(marker)
  }
}
