import { Component } from '@angular/core'
import { Observable } from 'rxjs'

import { NotificationService, Event, Notification } from '../notification.service'
@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent {

  notifications !: Observable<Notification[]>

  constructor(private notificationService: NotificationService) {
    this.notifications = this.notificationService.objects()
  }

  format(message: string): string {
    let index = message.indexOf(']')
    if (index > 0)
      return message.substring(index + 2)
    else
      return message
  }

  onClick(index: number) { this.notificationService.deleteOne(index) }

  noTranslation(event: Event ): boolean {
    return ['COMMENT', 'CARD_CSV_IMPORT_SUCCESS', 'PRODUCTION_EXPORT_SUCCESS'].includes(event.type)
  }
}
