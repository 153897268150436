import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import { FlowTypeFormComponent } from './flow-type-form/flow-type-form.component'
import { FlowTypeOverviewComponent } from './flow-type-overview/flow-type-overview.component'
import { FlowTypeListComponent } from './flow-type-list/flow-type-list.component'
import { FlowTypePageComponent } from './flow-type-page/flow-type-page.component'

@NgModule({
  declarations: [
    FlowTypeFormComponent,
    FlowTypeListComponent,
    FlowTypeOverviewComponent,
    FlowTypePageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [ FlowTypePageComponent ],
})
export class FlowTypeModule { }
