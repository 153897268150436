import { AppModule } from './app/app.module'
import { enableProdMode } from '@angular/core'
import { environment } from './environments/environment'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import * as Sentry from '@sentry/angular-ivy'

if (environment.production) {
  enableProdMode()
  Sentry.init({
    dsn: "https://d19074b277764e15ba152ad3432c36f9@o1321817.ingest.sentry.io/6578508",
    environment: window.location.hostname,
    integrations: [
      new Sentry.BrowserTracing({ routingInstrumentation: Sentry.routingInstrumentation })
    ],
    release: environment.version,
    replaysOnErrorSampleRate: 1.0,
  })
}

platformBrowserDynamic()
.bootstrapModule(AppModule)
.catch(error => console.error(error))
