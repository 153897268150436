import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import {TillFormComponent} from './till-form/till-form.component'
import {TillPageComponent} from './till-page/till-page.component'
import {TillOverviewComponent} from './till-overview/till-overview.component'
import {TillListComponent} from './till-list/till-list.component'

@NgModule({
  declarations: [
    TillFormComponent,
    TillListComponent,
    TillOverviewComponent,
    TillPageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [ TillPageComponent ]
})
export class TillModule { }
