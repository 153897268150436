import { Component } from '@angular/core'

import { StockService, Stock } from '../stock.service'
import { emptyObject } from '../../framework/objects'
import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'
import { SessionService } from '../../framework/services/session.service'

@Component({
  selector: 'app-stock-page',
  templateUrl: './stock-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css']
})
export class StockPageComponent extends GenericPageComponent<Stock> {

  stock: Stock = { center: this.sessionService.defaultPosition(), id:'', identifier: '', name: '', zone: emptyObject }

  constructor(
    protected sessionService: SessionService,
    protected override service: StockService
  ) { super(service) }
}
