<div class="pane-container">
  <app-grouping-overview title="Groupings" (selection)="onShortcut($event)"></app-grouping-overview>
  <app-action-pane domain="LOCATIONS" [hint]="['Identifier', 'Name']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event})"></app-action-pane>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-grouping-form [creation]="true" [mode]="Mode.edition" [object]="grouping" (event)="onEvent($event)"></app-grouping-form>
</ng-template>
<ng-template #showList>
  <app-grouping-list #list [sorting]="sorting" (event)="onEvent($event)" (sort)="onSort($event)"></app-grouping-list>
</ng-template>
