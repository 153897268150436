import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

import { SessionService, User } from '../../framework/services/session.service'

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class PasswordFormComponent {

  user: User | undefined

  newPassword = ""
  oldPassword = ""
  passwordCheck = ""

  constructor(
    public dialog: MatDialogRef<PasswordFormComponent>,
    protected sessionService: SessionService) {
    this.user = sessionService.user()
  }

  disableCheck(): boolean { return this.oldPassword == '' || this.invalidNew() }

  invalidNew(): boolean {
    return !this.newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/)
  }

  onClose() {
    this.sessionService.changePassword(this.oldPassword, this.newPassword)
    this.dialog.close()
  }
}
