import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { GenericService, Sorting, toString } from '../services/generic.service'
import { DatabaseObject } from '../objects'

export interface Subscriptions {
  access: string
  afterSales: string
  collection: string
  technical: string
}

export interface User {
  accountZone: DatabaseObject | null
  dashboards: string[]
  email: string
  id: string
  firstname: string
  flowType: DatabaseObject | null
  lastname: string
  locationZone: DatabaseObject | null
  role: DatabaseObject | null
  status: string
  till: DatabaseObject | null
  subscriptions: Subscriptions
  username: string
}

@Injectable({
  providedIn: 'root'
})
export class UserService extends GenericService<User> {

  private _user: User = {
    email: '', id: '', firstname: '', lastname: '', status: 'ACTIVE', username: '',
    accountZone: null, flowType: null, locationZone: null, role: null, till: null,
    dashboards: [],
    subscriptions: { access: 'NONE', afterSales: 'NONE', collection: 'NONE', technical: 'NONE' },
  }

  private _endpoint: string = 'users'

  endpoint(version: string = 'v1'): string { return this.sessionService.url(this._endpoint, version) }

  override list(callback: (objects: User[]) => void, order: string = 'name') {
    this.httpClient.get<User[]>(this.endpoint(),{
      params: { domains: 'WISE', status: 'ACTIVE', orderBy: order + ';ASC' } }).subscribe(objects =>
      callback(objects))
  }

  override fetch(filter: any, sorting: Sorting = { field: 'identifier', order: 'ASC' }, page: number = 1) {
    if (filter.id)
      this.getOne(filter.id)
    else
      this.getAll({
        status: filter.status ?? '',
        magicFilter: filter.magic ?? '',
        orderBy: toString(sorting), page: page, per_page: 50
      })
  }

  setEndpoint(endpoint: string) { this._endpoint = endpoint }

  override update(user: User): Observable<User> {
    return this.putOne(user.id, {
      username: user.username,
      firstname: user.firstname,
      lastname: user.lastname,
      status: user.status,
      email: user.email,
      roleId: user.role?.id,
      ...user.accountZone && { accountZoneId: user.accountZone.id },
      ...user.locationZone && { locationZoneId: user.locationZone.id },
      ...user.flowType && { flowTypeId: user.flowType.id },
      ...user.till && { tillId: user.till.id },
      dashboards: user.dashboards,
      subscriptions: user.subscriptions,
    })
  }

  user(): User { return this._user }
}
