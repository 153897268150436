import { FileSaverService } from 'ngx-filesaver'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { GenericService, Sorting, toString } from '../services/generic.service'
import { LanguageService } from '../services/language.service'
import { SessionService } from '../services/session.service'
import { SpinnerService } from '../services/spinner.service'

export interface Container {
  id: string,
  identifier: string,
}

export interface Ecolog {
  id: string,
  serial: string,
}

export interface HistoryEntry {
  id: string,
  comment: string,
  container: Container | null,
  ecolog: Ecolog | null,
  kind: string,
  report: Report | null,
  timestamp: string,
  user: User | null,
}

export interface Report {
  id: string,
  status: string,
}

export interface User {
  id: string,
  username: string,
}

@Injectable({
  providedIn: 'root'
})
export class HistoryService extends GenericService<HistoryEntry> {

  constructor(
    protected override fileSaverService: FileSaverService,
    protected override httpClient: HttpClient,
    protected languageService: LanguageService,
    protected override sessionService: SessionService,
    protected override spinner: SpinnerService,
  ) { super(fileSaverService, httpClient, sessionService, spinner) }

  endpoint(version: string = 'v1'): string { return this.sessionService.url('history', version) }

  override fetch(filter: any, sorting: Sorting, page: number = 1) {
    this.getAll({
      containerId: filter.containerId ?? '',
      ecologId: filter.ecologId ?? '',
      orderBy: toString(sorting), page: page, per_page: 50,
    })
  }

  override update(entry: HistoryEntry): Observable<HistoryEntry> {
    return this.putOne(entry.id, {
      comment: entry.comment,
      containerId: entry?.container?.id,
      date: this.sessionService.toISO(new Date(entry.timestamp)),
      ecologId: entry?.ecolog?.id,
      kind: entry.kind,
    })
  }

  visible(report: Report | null): boolean {
    return report != null && report.status != 'CREATED' && report.status != 'EDITED'
  }
}
