<div class="editor embedded">
  <div class="form">
    <div class="smallDisplayOnly">
      <app-form-buttons [actions]="('AFTER_SALES' | write) ? action('SMALL') : []" domain="LOCATIONS"
        [mode]="mode" [spacing]="false" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
    </div>
    <div class="row">
      <app-text-field class="width-15" label="Identifier" [length]="15" [mode]="mode" [required]="true"
        [(value)]="subject.identifier" (valueChange)="check()"></app-text-field>
      <app-object-dropdown class="width-30" label="Container Type" [mode]="mode" [objects]="containerTypes"
        [required]="true" [(object)]="subject.containerType" (objectChange)="check()"></app-object-dropdown>
      <app-object-dropdown class="width-22" label="Flow Type" [mode]="mode" [objects]="flowTypes" [required]="true"
        [(object)]="subject.flowType" (objectChange)="check()"></app-object-dropdown>
      <app-dropdown class="width-22" label="Status'" prefix="container" [mode]="mode" [required]="true"
        [values]="statuses()" [(value)]="subject.status" (valueChange)="check()"></app-dropdown>
    </div>
    <div class="row">
      <app-ecopoint-select *ngIf="object.ecopoint || mode == Mode.edition" [style.width]="'47%'"
        [(ecopoint)]="subject.ecopoint" [mode]="mode" [required]="subject.stock == null"
        (ecopointChange)="onEcopoint($event)"></app-ecopoint-select>
      <app-object-dropdown *ngIf="object.stock || mode == Mode.edition" [style.width]="'47%'"
        label="Stock" [mode]="mode" [objects]="stocks" [required]="subject.ecopoint == null"
        [(object)]="subject.stock" (objectChange)="onStore()"></app-object-dropdown>
      <app-text-field *ngIf="mode != Mode.edition && subject.locality" [style.width]="'47%'" label="Locality"
        [value]="subject.locality.name"></app-text-field>
    </div>
    <div class="row">
      <app-text-field [style.width]="'47%'" label="Location" [mode]="mode"
        [(value)]="subject.location"></app-text-field>
      <app-number-field class="width-15" label="Alarm Level" [mode]="mode"
        [(value)]="subject.alarmLevel"></app-number-field>
      <app-object-dropdown *ngIf="mode == Mode.reading" class="width-30" label="Zone" [mode]="mode"
        [(object)]="subject.zone" [objects]="zones"></app-object-dropdown>
    </div>
    <div *ngIf="mode == Mode.reading || ('AFTER_SALES' | write)" class="row">
      <app-date-field class="width-22" label="Installation Date" [mode]="mode"
        [(value)]="subject.installationDate"></app-date-field>
      <app-dropdown class="width-22" label="Installation Type" [mode]="mode" [required]="false"
        [(value)]="subject.installationType" [values]="installationTypes"></app-dropdown>
      <app-text-field class="width-22" label="Installation Version" [length]="10" [mode]="mode"
        [(value)]="subject.installationVersion"></app-text-field>
      <app-text-field class="width-22" label="Weighing No" [length]="10" [mode]="mode"
        [(value)]="subject.lid"></app-text-field>
    </div>
    <div *ngIf="mode == Mode.reading" class="row">
      <mat-form-field [style.width]="'16%'">
        <mat-label>{{'EcoLog' | translate}}</mat-label>
        <input matInput class="input" [disabled]="true" [value]="subject.ecolog?.serial">
      </mat-form-field>
      <a *ngIf="subject.ecolog" mat-icon-button class="icon-link" [href]="'/ecologs/'+subject.ecolog.id"
        [matTooltip]="subject.ecolog.hardware + ' - ' + subject.ecolog.firmware">
        <mat-icon>link</mat-icon>
      </a>
      <app-text-field class="width-22" label="Last Measure" [value]="subject.lastMeasureDate | timestamp"></app-text-field>
      <app-text-field class="width-22" label="Last Communication"
        [value]="subject.ecolog && subject.ecolog.heartbeat ? (subject.ecolog.heartbeat | timestamp) : '-'">
      </app-text-field>
    </div>
  </div>
  <app-form-buttons class="largeDisplayOnly" [actions]="('AFTER_SALES' | write) ? action('LARGE') : []"
    domain="LOCATIONS" [mode]="mode" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
</div>
