<div class="filter-pane">
  <h1>{{'Filter' | translate}}</h1>
  <div class="column">
    <app-dropdown class="width-80" label="Status" prefix="ecolog" [required]="false"
      [values]="['OPERATIONAL', 'IN_STOCK', 'IN_REPARATION', 'RIP']" (valueChange)="onStatus($event)"></app-dropdown>
  </div>
  <div *ngIf="sessionService.rise()">
    <app-client-dropdown width="80%" (client)="onClient($event)"></app-client-dropdown>
  </div>
</div>
