import { Component, EventEmitter, Input, Output } from '@angular/core'

import { Mode } from '../../generic-form/generic-form.component'

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.css']
})
export class TextFieldComponent {

  State = Event
  Mode = Mode

  @Input() color: string = 'black'
  @Input() hint: string = ''
  @Input() length: number = 100
  @Input() label: string = ''
  @Input() mode: Mode = Mode.reading
  @Input() required: boolean = false
  @Input() type = 'text'
  @Input() value: string = ''

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>()
}
