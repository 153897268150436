import {Component, Input } from '@angular/core'

import { HistoryService, HistoryEntry, Report } from '../history.service'
import { GenericListComponent } from '../../generic-list/generic-list.component'

@Component({
  selector: 'history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['../../generic-list/generic-list.component.css']
})
export class HistoryListComponent extends GenericListComponent<HistoryEntry> {

  @Input() parent: 'CONTAINER' | 'ECOLOG' = 'CONTAINER'
  @Input() kinds: string[] = []

  constructor(protected override service: HistoryService) { super(service) }

  container(): boolean { return this.parent == 'CONTAINER'}

  visible(report: Report | null): boolean { return this.service.visible(report) }
}
