<div class="pane-container">
  <app-tour-overview title="Tours" (selection)="onShortcut($event)"></app-tour-overview>
  <app-action-pane domain="LOCATIONS" [hint]="['Reference', 'Name']" (action)="onAction($event)"
    (magic)="onSearch({ magic: $event})"></app-action-pane>
</div>
<div *ngIf="creation; then showForm else showList"></div>
<ng-template #showForm>
  <app-tour-form [creation]="true" [mode]="Mode.edition" [object]="tour" (event)="onEvent($event)"></app-tour-form>
</ng-template>
<ng-template #showList>
  <app-tour-list #list [sorting]="sorting" (event)="onEvent($event)" (page)="onScroll()" (sort)="onSort($event)"></app-tour-list>
</ng-template>


