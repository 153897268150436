<div class="editor">
  <div class="form">
    <div class="row">
      <app-text-field class="width-15" [label]="'Identifier'" [length]="10" [mode]="mode"
        [(value)]="subject.identifier"></app-text-field>
      <app-text-field class="width-35" [label]="'Name'" [length]="200" [mode]="mode" [required]="true"
        [(value)]="subject.name" (valueChange)="check()"></app-text-field>
      <app-object-dropdown class="width-35" [label]="'Zone'" [mode]="mode"
        [(object)]="subject.zone" [objects]="zones" ></app-object-dropdown>
    </div>
    <app-position [(center)]="subject.center" [mode]="mode"></app-position>
  </div>
  <app-form-buttons [actions]="('AFTER_SALES' | write) ? ['delete'] : []" domain="LOCATIONS" [mode]="mode" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
</div>
