import { environment } from '../../environments/environment'
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { GenericService } from '../framework/services/generic.service'
import { DatabaseObject } from '../framework/objects'

export { DatabaseObject }

export interface ContainerType extends DatabaseObject {
  brand: string,
  containerTypeModel: DatabaseObject | null,
  collectionDuration: string,
  d1: string,
  h1: string,
  h2: string,
  kind: string,
  v1: string,
  v2: string,
}

@Injectable({
  providedIn: 'root'
})
export class ContainerTypeService extends GenericService<ContainerType> {

  endpoint(version: string = 'v1'): string { return this.sessionService.url('container-types', version) }

  models(callback: (models: ContainerType[]) => void) {
    this.httpClient.get<ContainerType[]>(this.sessionService.url('container-type-models')).subscribe(models =>
      callback(models))
  }


  override update(containerType: ContainerType): Observable<ContainerType> {
    return this.putOne(containerType.id, {
      brand: containerType.brand,
      ...(containerType.containerTypeModel) && { containerTypeModelId: containerType.containerTypeModel.id },
      collectionDuration: +containerType.collectionDuration,
      d1: +containerType.d1,
      h1: +containerType.h1,
      h2: +containerType.h2,
      identifier: containerType.identifier,
      kind: containerType.kind,
      name: containerType.name,
      v1: +containerType.v1,
      v2: +containerType.v2,
    })
  }
}
