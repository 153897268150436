import { Component, EventEmitter, Input, Output } from '@angular/core'

import { Mode } from '../../generic-form/generic-form.component'

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styles: ['']
})
export class DropdownComponent {

  Mode = Mode

  @Input() hint: string = ''
  @Input() mode: Mode = Mode.edition
  @Input() label: string = ''
  @Input() prefix: string = ''
  @Input() required: boolean = true
  @Input() values: string[] = []
  @Input() value: string | undefined
  @Output() valueChange = new EventEmitter<string>()
}
