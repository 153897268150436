<mat-expansion-panel [expanded]="mode && open">
  <mat-expansion-panel-header>
    <mat-panel-title>{{title | translate}}</mat-panel-title>
    <mat-panel-description>{{abstract() | shorten:52}}</mat-panel-description>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <div class="row">
      <app-text-field class="width-20" [label]="'Title'" [mode]="mode" [(value)]="address.title"></app-text-field>
      <app-text-field class="width-40" [label]="'firstName'" [mode]="mode" [(value)]="address.firstName"></app-text-field>
      <app-text-field class="width-40" [label]="'Name'" [mode]="mode" [required]="true"
        [(value)]="address.lastName" (valueChange)="change.next()"></app-text-field>
    </div>
    <div class="row">
      <app-text-field class="width-100" [label]="'Address'" [mode]="mode"
        [length]="110" [(value)]="address.address"></app-text-field>
    </div>
    <div class="row">
      <app-text-field class="width-20" [label]="'Post Code'" [mode]="mode"
        [length]="10" [(value)]="address.postcode"></app-text-field>
      <app-text-field class="width-40" [label]="'City'" [mode]="mode" [(value)]="address.city"></app-text-field>
      <app-text-field *ngIf="country" class="width-40" [label]="'Country'" [mode]="mode"
        [(value)]="address.country"></app-text-field>
    </div>
  </ng-template>
</mat-expansion-panel>
