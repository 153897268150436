import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core'

import { ContainerService, Container } from '../container.service'
import { Mode } from '../../generic-form/generic-form.component'

@Component({
  selector: 'app-container-requests',
  templateUrl: './container-requests.component.html',
  styleUrls: ['../../generic-page/generic-page.component.css']
})
export class ContainerRequestsComponent implements OnDestroy {

  Mode = Mode

  creation: boolean = false

  @Input() container !: Container
  @Output() event = new EventEmitter<string>()

  constructor(protected service: ContainerService ) { }

  ngOnDestroy() {
    // Container's after-sales request creation was not confirmed.
    if (this.creation) this.container.afterSalesRequest = null
  }

  onAdd() {
    this.creation = true
    this.container.afterSalesRequest = {
      id: '', comment: '', containerId: this.container.id,
      timestamp: new Date().toISOString(), reason: '', report: { id: '', status: ''}, userId: ''
    }
    this.event.next('edit')
  }

  onEvent(event: string) {
    if (event == 'cancel' || event == 'delete') this.container.afterSalesRequest = null
    this.creation = false
    // Container needs an update if an after-sales request was created or deleted.
    if (event == 'delete' || event == 'save')
      this.event.next('update')
    else
      this.event.next(event)
  }
}
