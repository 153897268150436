import { Component } from '@angular/core'

import { GenericPageComponent } from '../../generic-page/generic-page.component'
import { TruckService, Truck } from '../truck.service'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-truck-page',
  templateUrl: './truck-page.component.html',
  styleUrls: ['../../generic-page/generic-page.component.css']
})
export class TruckPageComponent extends GenericPageComponent<Truck> {

  truck: Truck = { capacity: '', distanceCost: '', fixedCost: '', height: '', id: '', identifier: '',
    kind: 'MAINTENANCE', name: '', speed: '', timeCost: '', volumeMax: 0, weightMax: '' }

  constructor(
    protected sessionService: SessionService,
    protected override service: TruckService,
  ) {
    super(service)
    if (sessionService.rise()) this.truck.kind = 'AFTER_SALES'
  }
}
