import { Component } from '@angular/core'

import { DumpService, Dump } from '../dump.service'
import { GenericPageComponent } from '../../framework/generic-page/generic-page.component'
import { SessionService } from '../../framework/services/session.service'

@Component({
  selector: 'app-dump-page',
  templateUrl: './dump-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css']
})
export class DumpPageComponent extends GenericPageComponent<Dump> {

  dump: Dump = { id: '', identifier: '', name: '', position: this.sessionService.defaultPosition() }

  constructor(
    protected sessionService: SessionService,
    protected override service: DumpService
  ) { super(service) }
}
