import {Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { Mode } from '../../framework/generic-form/generic-form.component'

export interface DialogData {
  balance: number
}

@Component({
  selector: 'app-account-closure',
  templateUrl: './account-closure.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class AccountClosureComponent implements OnInit {

  Mode = Mode

  amount: number | null = 0
  balance: number = 0
  comment: string = ''
  final: number = 0

  constructor(
    public dialog: MatDialogRef<AccountClosureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {  this.balance = data.balance }

  doUpdate() {
    if (this.amount != null)
      this.final = this.balance + this.amount
    else
      this.final = this.balance
  }

  ngOnInit() { this.amount = -this.balance }

  onCancel() { this.dialog.close({ close: false}) }

  onClose() { this.dialog.close({ amount: this.amount, close: true, comment: this.comment}) }
}
