import {Component, Input, OnInit} from '@angular/core'

import { GenericFormComponent, Mode } from '../../framework/generic-form/generic-form.component'
import { Transaction, TransactionService } from '../transaction.service'

@Component({
  selector: 'app-transaction-form',
  templateUrl: './transaction-form.component.html',
  styleUrls: ['../../framework/generic-form/generic-form.component.css']
})
export class TransactionFormComponent extends GenericFormComponent<Transaction> implements OnInit {

  Mode = Mode

  final !: number
  status: string = 'VALID'

  @Input() balance: number | null = null

  constructor(protected override service: TransactionService) { super(service)
  }

  doUpdate() {
    if (this.balance)
      this.final = this.subject.amount ? this.balance + this.subject.amount : this.balance
    this.check()
  }

  override isValid() { return this.subject.amount != null }

  override ngOnInit() { super.ngOnInit() ; this.doUpdate() }

  statuses(): string[] { return this.service.statuses() }
}
