import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import { LocalityFormComponent } from './locality-form/locality-form.component'
import { LocalityListComponent } from './locality-list/locality-list.component'
import { LocalityOverviewComponent } from './locality-overview/locality-overview.component'
import { LocalityPageComponent } from './locality-page/locality-page.component'


@NgModule({
  declarations: [
    LocalityFormComponent,
    LocalityListComponent,
    LocalityOverviewComponent,
    LocalityPageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [ LocalityPageComponent ]
})
export class LocalityModule { }
