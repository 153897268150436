<div class="overview-pane" *ngIf="overview | async as overview">
  <h1>{{title | translate}}</h1>
  <div *ngIf="sorter && sorter.sorting" class="shortcut sorter">
    <a (click)="selection.emit(sorter)">{{sorter.name | translate}}
      <mat-icon *ngIf="sorter.sorting.field == sorting">arrow_drop_down</mat-icon>
    </a>
  </div>
  <div *ngFor="let shortcut of shortcuts" class="shortcut selector">
    <a (click)="selection.emit(shortcut)">{{shortcut.name | translate}}</a>
    <span *ngIf="shortcut.field">{{overview[shortcut.field]}}</span>
  </div>
</div>
