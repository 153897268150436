import { Component, Input } from '@angular/core'
import { Color, LegendPosition } from '@swimlane/ngx-charts'
import { TranslateService } from '@ngx-translate/core'
import { Router } from "@angular/router";

interface Data {
  name: string,
  value: number,
}

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styles: [],
})
export class PieChartComponent {

  colorScheme = { domain: ['#5AA454', '#C7B42C', '#A10A28'] } as Color
  position = 'below' as LegendPosition;

  @Input() data !: Data[]
  @Input() route: string = ''

  constructor(
    protected translateService: TranslateService,
    protected router: Router,
  ) { }

  onSelect(selection: Data): void {
    const name = selection.name ?? selection
    if (this.route)
      this.router.navigateByUrl(this.route + this.data.findIndex(data => name == data.name))
  }
}
