<mat-tab-group mat-stretch-tabs>
  <mat-tab label="{{'Information' | translate}}">
    <app-tour-form [object]="tour" (event)="onEvent($event)" (objectChange)="tourChange.next($event)" ></app-tour-form>
  </mat-tab>
  <mat-tab *ngIf="!edition" label="{{'Map' | translate}}">
    <ng-template matTabContent>
      <app-stop-map [tour]="tour"></app-stop-map>
    </ng-template>
  </mat-tab>
</mat-tab-group>
