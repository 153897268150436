<div class="editor">
  <div class="form">
    <div class="row">
      <app-text-field class="width-15" [label]="'Identifier'" [length]="10" [mode]="mode"
        [(value)]="subject.identifier"></app-text-field>
      <app-text-field class="width-35" [label]="'Location'" [length]="200" [mode]="mode" [required]="true"
        [(value)]="subject.location" (valueChange)="check()"></app-text-field>
    </div>
    <app-position [(center)]="subject.position" [mode]="mode"></app-position>
  </div>
  <app-form-buttons [actions]="('AFTER_SALES' | write) ? ['delete'] : []" domain="LOCATIONS" [mode]="mode" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
</div>
