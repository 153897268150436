import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import { CategoryFormComponent } from './category-form/category-form.component'
import { CategoryPageComponent } from './category-page/category-page.component'
import { CategoryListComponent } from './category-list/category-list.component'
import { CategoryOverviewComponent } from './category-overview/category-overview.component'

@NgModule({
  declarations: [
    CategoryFormComponent,
    CategoryListComponent,
    CategoryOverviewComponent,
    CategoryPageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [ CategoryPageComponent ]
})
export class CategoryModule { }
