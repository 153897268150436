<mat-tab-group mat-stretch-tabs [(selectedIndex)]="index" (selectedIndexChange)="this.indexChange.next(index)">
  <mat-tab label="{{'Information' | translate}}">
    <app-container-form [object]="container" (event)="onEvent($event)" (objectChange)="containerChange.next($event)" ></app-container-form>
  </mat-tab>
  <mat-tab *ngIf="container.ecopoint" label="{{'Map' | translate}}">
    <ng-template matTabContent>
      <google-map [center]="center" [options]="options" width="width" (mapRightclick)="onClick()">
        <map-marker [position]="center"></map-marker>
      </google-map>
      <p></p>
      <p>{{'Right click for Street View...' | translate}}</p>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{'Waste' | translate}}">
    <ng-template matTabContent>
      <app-container-waste [container]="container"></app-container-waste>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{'History' | translate}}">
    <ng-template matTabContent>
      <app-history-view [object]="container" (event)="onEvent($event)"></app-history-view>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{'Interventions' | translate}}">
    <ng-template matTabContent>
      <app-container-requests [container]="container" (event)="onEvent($event)"></app-container-requests>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="calibration" label="{{'Calibrations' | translate}}">
    <ng-template matTabContent>
      <app-container-calibration [container]="container"></app-container-calibration>
    </ng-template>
  </mat-tab>
  <mat-tab label="{{'Statistics' | translate}}">
    <ng-template matTabContent>
      <app-container-statistics [id]="container.id"></app-container-statistics>
    </ng-template>
  </mat-tab>
</mat-tab-group>
