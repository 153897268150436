import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

// Framework services.
import { FileSaverService } from 'ngx-filesaver'
import { GenericService, Sorting, toString } from '../services/generic.service'
import { DatabaseObject } from '../objects'
import { LanguageService } from '../services/language.service'
import { PdfService } from '../services/pdf.service'
import { SessionService } from '../services/session.service'
import { SpinnerService } from '../services/spinner.service'

export interface Client extends DatabaseObject {
  shortName: string
}

export interface Locality {
  distance: string
  id: string
  identifier: string
  name: string
}

export interface Container {
  containerType: ContainerType
  id: string
  identifier: string
  installationDate: string
  installationType: string
}

export interface ContainerType {
  id: string
  identifier: string
  name: string
}

export interface Ecopoint {
  id: string
  location: string
}

export interface Position extends DatabaseObject{
  description: string
  erpCode: string
  value: number | string
  units: string
}

export interface Report {
  comment: string
  client: Client | null
  container: Container | null
  diagnostic: string
  ecopoint: Ecopoint | null
  handling: string
  id: string
  interventionDate: string
  invoiceDate: string
  invoiceRef: string
  locality: Locality | null
  positions: Position[]
  request: Request | null
  status: string
  technician: Technician | null
}

export interface Request {
  comment: string
  date: string
  id: string
  reason: string
  user: User
}

export interface Technician extends DatabaseObject {
  username: string
}

export interface User extends DatabaseObject {
  firstname: string
  lastname: string
}

@Injectable({
  providedIn: 'root'
})
export class ReportService extends GenericService<Report> {

  constructor(
    protected override fileSaverService: FileSaverService,
    protected override httpClient: HttpClient,
    protected languageService : LanguageService,
    protected pdfService: PdfService,
    protected override sessionService: SessionService,
    protected override spinner: SpinnerService,
  ) { super(fileSaverService, httpClient, sessionService, spinner) }

  columns(names: string[], values: string[]): any {
    return {
      columns: [
        { width: '20%', text: names.map(name => this.languageService.translate(name)).join('\n') + '\n\n' },
        { width: '80%', text: values.join('\n') }
      ]}
  }

  diagnostics(): string[] { return ['A.m', 'A.v', 'B.x', 'E.f', 'E.p', 'E.x', 'N.x', 'P.e'] }

  endpoint(version: string = 'v1'): string { return this.sessionService.url('reports', version) }

  export(_report: Report) { }

  override fetch(filter: any, sorting: Sorting, page: number = 1) {
    if (filter.id)
      this.getOne(filter.id)
    else
      this.getAll({
        ...(filter.clientId && { clientId: filter.clientId }),
        endDate: this.sessionService.toISO(filter.endDate),
        id: filter.identifier ?? '',
        reportId: filter.reportId ?? '',
        startDate: this.sessionService.toISO(filter.startDate),
        status: filter.status ?? '',
        orderBy: toString(sorting), page: page, per_page: 50
      })
  }

  format(position: Position | null): any[] {
    if (position )
      return [
        position.name,
        position.description,
        {
          style: 'number',
          text: position.units[2] == ':'
            ? new Date(+position.value * 60000).toISOString().slice(11,16)
            : position.value
        },
        position.units,
      ]
    else
      // Table header.
      return [
        this.languageService.translate('Name'),
        this.languageService.translate('Description'),
        { style: 'number', text: this.languageService.translate('Quantity') },
        this.languageService.translate('Units'),
      ]
  }

  header(name: string): any {
    return { table: { body: [[{ fontSize: 10, fillColor: 'lightgrey', columns: [this.languageService.translate(name)] }]], widths: ['*'] } }
  }

  print(report: Report) {
    this.spinner.show()

    let data = new Array<any[]>()
    // Include the table headers.
    data.push(this.format(null))
    for (let index = 0; index < report.positions.length; index += 1)
        data.push(this.format(report.positions[index]))

    // Client determination for WISE and reseller specific databases..
    let client = this.sessionService.reportClient(report)

    this.pdfService.pdf([
      { image: 'reseller', height: 44, width: 120 }, '\n',
      { style: 'header', text: 'Rapport SAV' + ' #' + report.id }, '\n',
      'OptiWaste\nCh de Budron C7\n1052 Le Mont-sur-Lausanne\nTél: +41 21 671 31 03', '\n',
      this.header('Intervention'),'\n',
      this.columns(
        ["Client", "Request", "Intervention"],
        [
          client,
          report.request ?
            report.request.date.slice(0,10) + ' ' + report.request.user.firstname + ' ' + report.request.user.lastname : '',
          report.interventionDate?.slice(0,10) + ' ' + report.technician?.username
        ]
      ),
      this.header('Container'),'\n',
      this.columns(
        ["Identifier", "Container Type", "Installation Type", "Installed on", "Location"],
        [
          report.container?.identifier + '',
          report.container?.containerType.name + '',
          report.container?.installationType ? report.container.installationType.replace(/_/g, '-') : '',
          report.container?.installationDate?.slice(0,10) + '',
          report.ecopoint?.location + ''
        ]
      ),
      this.header('Problem'),'\n',
      this.columns(
        ["Reason", "Comment"],
        report.request ?
          [this.languageService.translate('reason.' + report.request.reason), report.request.comment] : ['', '']
      ),
      this.header('Diagnostic'),'\n',
      this.columns(
        ["Diagnostic", "Comment"],
        [report.diagnostic ? this.languageService.translate('diagnostic.' + report.diagnostic) : '', report.comment]
      ),
      this.header('Facturation'),'\n',
      this.columns(
        ["Identifier", "Date"],
        [report.invoiceRef, report.invoiceDate ? report.invoiceDate.slice(0,10) : '']
      ),
      {
        layout: 'lightHorizontalLines',
        table: { body: data, fontSize: 20, headerRows: 1, widths: ['19%', '50%', '15%', '15%'] },
      },
      '\n\n\n',
      { style: 'small', text: this.languageService.translate('report.waiver')}
    ])

    this.spinner.hide()
  }

  statuses(): string[] { return ['CREATED', 'EDITED', 'VALIDATED', 'CLOSED'] }

  technicians(callback: (objects : Technician[]) => void) {
    if (this.sessionService.read('MANUFACTURER'))
        this.httpClient.get<Technician[]>(this.sessionService.url('manufacturer/users'),{
          params: { orderBy: 'username;ASC' }}).subscribe(objects =>
            callback(objects.map((value: Technician) => {
              return { id: value.id, identifier: value.username, name: value.username, username: value.username }
            }))
          )
  }

  templates(callback: (objects: Position[]) => void) {
    this.httpClient.get<Position[]>(this.sessionService.url('position-templates')).subscribe(positions =>
      callback(positions)
    )
  }

  override update(report: Report): Observable<Report> {

    // Convert time position from HH:MM to minutes.
    let positions = []
    for (let index = 0; index < report.positions.length; index++) {
      let position: Position = { ...report.positions[index] }
      positions.push(position)
      if (typeof position.value == 'string' && position.value.indexOf(':') == 2)
        position.value = (+position.value.slice(0, 2) * 60 + +position.value.slice(3, 5)).toString()
    }

    return this.putOne(report.id, {
      ...(report.request == null && report.container && { containerId: report.container.id }),
      comment: report.comment,
      diagnostic: report.diagnostic,
      handling: report.handling,
      interventionDate: this.sessionService.toISO(new Date(report.interventionDate)),
      invoiceRef: report.invoiceRef,
      invoiceDate: report.invoiceDate ? this.sessionService.toISO(new Date(report.invoiceDate)) : '',
      positions: positions,
      status: report.status,
      ...(report.technician && { technicianId: report.technician.id }),
    })
  }

  technician() : Technician | null {
    const user = this.sessionService.user()
    if (user) return { id: user.id, identifier: user.username, name: user.username, username: user.username }
    return null
  }
}
