import { NgModule } from '@angular/core';
import { FrameworkModule } from '../framework/framework.module'

import { GroupingFormComponent } from './grouping-form/grouping-form.component'
import { GroupingOverviewComponent } from './grouping-overview/grouping-overview.component'
import { GroupingListComponent } from './grouping-list/grouping-list.component'
import { GroupingPageComponent } from './grouping-page/grouping-page.component'


@NgModule({
  declarations: [
    GroupingFormComponent,
    GroupingListComponent,
    GroupingOverviewComponent,
    GroupingPageComponent,
  ],
  imports: [ FrameworkModule ],
  exports: [ GroupingPageComponent ]
})
export class GroupingModule { }
