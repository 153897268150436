<div class="editor">
  <div class="form">
    <div *ngIf="sessionService.rise()" class="row">
      <app-client-dropdown *ngIf="creation" [status]="['ACTIVE', 'PASSIVE']" (client)="onClient($event)"></app-client-dropdown>
      <app-text-field *ngIf="object.client" class="width-20" label="Client" [value]="object.client.shortName"></app-text-field>
    </div>
    <div class="row">
      <app-number-field *ngIf="subject.encryption != 'UID'" class="width-20" label="batch.first"
        [mode]="subject.generationDate ? Mode.readonly : mode" [required]="true"
        [(value)]="subject.first" (valueChange)="check()"></app-number-field>
      <app-number-field *ngIf="subject.encryption != 'UID'" class="width-20" label="batch.last"
        [mode]="subject.generationDate ? Mode.readonly : mode" [required]="true"
        [(value)]="subject.last" (valueChange)="check()"></app-number-field>
      <app-text-field class="width-60" label="Comment" [length]="200" [mode]="mode"
        [(value)]="subject.comment"></app-text-field>
    </div>
    <div *ngIf="'AFTER_SALES' | read" class="row">
      <app-dropdown class="width-20" label="Encryption" [required]="true"
        [values]="['125k', '125k + 13C-S5', '13A', '13C-S1', '13C-S5', '13C-S5 + GVP', 'UID']"
        [mode]="mode" [(value)]="subject.encryption" (valueChange)="check()"></app-dropdown>
      <app-text-field *ngIf="subject.encryption != 'UID'" class="width-20" label="Region Code" [length]="8" [mode]="mode" [required]="true"
        [(value)]="region" (valueChange)="check()"></app-text-field>
      <app-text-field *ngIf="subject.encryption != 'UID'" class="width-20" label="Zone" [length]="10" [mode]="mode"  [required]="true"
        [(value)]="zone" (valueChange)="check()"></app-text-field>
    </div>
    <div class="row">
      <app-dropdown class="width-20" label="Mode" [mode]="mode" prefix="batch" [required]="true"
        [values]="['ANONYMOUS', 'NOMINATIVE', 'PREPAID', 'SPECIAL']"
        [(value)]="subject.mode" (valueChange)="check()"></app-dropdown>
      <app-text-field class="width-20" label="Delivery Reference" [length]="10" [mode]="mode"
        [(value)]="subject.deliveryRef"></app-text-field>
      <app-date-field class="width-20" label="Delivery Date" [mode]="mode"
        [(value)]="subject.deliveryDate"></app-date-field>
      <app-date-field class="width-20" label="Generation Date"
        [(value)]="subject.generationDate"></app-date-field>
    </div>
    <div *ngIf="mode == Mode.reading && subject.encryption != 'UID' && ('AFTER_SALES' | read)" class="column">
      <h3>{{'Order Information' | translate}}</h3>
      <p>{{'Encryption' | translate}} : {{object.encryption}}</p>
      <p>{{'Start Code' | translate}} : {{object.code.toString(16).padStart(10,'0') + (+object.first).toString(16).padStart(6,'0')}}</p>
      <p>{{'First Card' | translate}} : {{object.first}}</p>
    </div>
    </div>
  <app-form-buttons [actions]="generable() ? ['add_box', 'delete'] : ['delete']" domain="MANUFACTURER" [mode]="mode" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
</div>
