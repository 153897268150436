import { Component } from '@angular/core'

import { Container, ContainerService } from '../../framework/container/container.service'
import { GenericOverviewComponent, Shortcut } from '../../framework/generic-overview/generic-overview'

@Component({
  selector: 'app-container-overview',
  templateUrl: '../../framework/generic-overview/generic-overview.html',
  styleUrls: ['../../framework/generic-overview/generic-overview.css']
})
export class ContainerOverviewComponent extends GenericOverviewComponent<Container> {

  override sorter: Shortcut = { filter: { }, name: 'Last modified', sorting: { field: 'last_modification_date', order: 'DESC' }}

    override shortcuts: Shortcut[] = [
    { field: 'all', filter: { }, name: 'All' },
    { field: 'full', filter: { levelMin: 80 }, name: 'Full containers' },
    { field: 'operational', filter: { status: 'OPERATIONAL' }, name: 'Operational containers' },
    { field: 'intervention', filter: { afterSales: true }, name: 'After-sales request' },
    { field: 'alarms', filter: { alarms: true  }, name: 'With alarm' },
  ]

  constructor(protected override service: ContainerService) { super(service) }
}
