<div class="dialog">
  <div class="row split">
    <h1>{{'Parameters' | translate}}</h1>
    <button mat-icon-button [mat-dialog-close]="true"><mat-icon>close</mat-icon></button>
  </div>
  <mat-tab-group *ngIf="user" mat-stretch-tabs>
    <mat-tab label="{{'Subscriptions' | translate}}">
      <div class="form">
        <div class="row">
          <h3 class="width-30">{{'Access Control' | translate}}</h3>
          <app-dropdown class="width-30" label="Notification" [mode]="Mode.edition" prefix='subscription'
            [values]="['NONE', 'EMAIL', 'WISE']" [(value)]="user.subscriptions.access"></app-dropdown>
          <mat-icon class="tooltip" fontSet="material-icons-outlined" [matTooltip]="'subscription.info.access' | translate"
            matTooltipPosition="right">info</mat-icon>
        </div>
      <div class="row">
        <h3 class="width-30">{{'After Sales' | translate}}</h3>
        <app-dropdown class="width-30" label="Notification" [mode]="Mode.edition" prefix='subscription'
          [values]="['NONE', 'EMAIL', 'WISE']" [(value)]="user.subscriptions.afterSales"></app-dropdown>
        <mat-icon class="tooltip" fontSet="material-icons-outlined" [matTooltip]="'subscription.info.afterSales' | translate"
          matTooltipPosition="right">info</mat-icon>
      </div>
      <div class="row">
        <h3 class="width-30">{{'Collection' | translate}}</h3>
        <app-dropdown class="width-30" label="Notification" [mode]="Mode.edition" prefix='subscription'
          [values]="['NONE', 'EMAIL', 'WISE']" [(value)]="user.subscriptions.collection"></app-dropdown>
        <mat-icon class="tooltip" fontSet="material-icons-outlined" [matTooltip]="'subscription.info.collection' | translate"
          matTooltipPosition="right">info</mat-icon>
      </div>
      <div class="row">
        <h3 class="width-30">{{'Technical' | translate}}</h3>
        <app-dropdown class="width-30" label="Notification" [mode]="Mode.edition" prefix='subscription'
          [values]="['NONE', 'EMAIL', 'WISE']" [(value)]="user.subscriptions.technical"></app-dropdown>
        <mat-icon class="tooltip" fontSet="material-icons-outlined" [matTooltip]="'subscription.info.technical' | translate"
          matTooltipPosition="right">info</mat-icon>
      </div>
        <div class="row right">
          <button mat-flat-button (click)="onUpdate()">{{'Update' | translate}}</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{'Restrictions' | translate}}">
      <div class="form">
        <div class="row">
        <h3 class="width-30">{{'Role' | translate}}</h3>
        <app-object-dropdown class="width-50" label="{{'Role' | translate}}"
          [mode]="('SETTINGS' | write) ? Mode.edition : Mode.readonly"
          [object]="user.role" [objects]="roles" (objectChange)="onRole($event)"></app-object-dropdown>
        </div>
        <div class="row">
          <h3 class="width-30">{{'Restrictions' | translate}}</h3>
          <app-object-dropdown class="width-50" label="{{'Flow Type' | translate}}"
            [mode]="('SETTINGS' | write) ? Mode.edition : Mode.readonly"
            [object]="user.flowType" [objects]="flowTypes" (objectChange)="onFlowType($event)"></app-object-dropdown>
        </div>
        <div *ngIf="accountZones.length" class="row">
          <h3 class="width-30"></h3>
          <app-object-dropdown class="width-50" label="{{'Accounts Zone' | translate}}"
            [mode]="('SETTINGS' | write) ? Mode.edition : Mode.readonly"
            [object]="user.accountZone" [objects]="accountZones" (objectChange)="onAccountZone($event)"></app-object-dropdown>
        </div>
        <div *ngIf="locationZones.length" class="row">
          <h3 class="width-30"></h3>
          <app-object-dropdown class="width-50" label="{{'Locations Zone' | translate}}"
              [mode]="('SETTINGS' | write) ? Mode.edition : Mode.readonly"
              [object]="user.locationZone" [objects]="locationZones"
              (objectChange)="onLocationZone($event)"></app-object-dropdown>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="'AFTER_SALES' | write" label="{{'Rights' | translate}}">
      <div *ngIf="session | async as session" class="form">
        <h3>{{'Test' | translate}}</h3>
        <div class="row">
          <p class="width-30">{{'Domain' | translate}}</p>
          <mat-form-field class="width-30">
            <mat-label>{{'Domain' | translate}}</mat-label>
            <mat-select [(value)]="domain">
              <mat-option *ngFor="let domain of session.role.domain.write" value="{{domain}}">{{domain}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row right">
          <button mat-flat-button (click)="onRemove()">{{'Remove' | translate}}</button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
