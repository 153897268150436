<div *ngIf="session | async" class="navigation">
  <span *ngIf="sessionService.read('WISE')">

    <!--Large screen menu -->
    <span class="largeDisplayOnly">
      <!-- Pipe on session necessary otherwise there's no refresh on role change... -->
      <span *ngIf="session | async">
        <button routerLink="/">{{'Dashboard' | translate}}</button>
        <button *ngIf="'ACCOUNTS' | read" routerLink="/accounts">{{'Accounts' | translate}}</button>
        <button [matMenuTriggerFor]="location">{{'Locations' | translate}}</button>
        <button [matMenuTriggerFor]="admin">{{'Administration' | translate}}</button>
        <button *ngIf="'LOCATIONS' | read" routerLink="/ecologs">{{'EcoLogs' | translate}}</button>
        <button [matMenuTriggerFor]="search">{{'Search' | translate}}</button>
        <button *ngIf="'LOCATIONS' | read" routerLink="/map">{{'Map' | translate}}</button>
        </span>
    </span>

    <!--Small screen menu -->
    <span class="smallDisplayOnly">
      <button class="white" mat-icon-button [matMenuTriggerFor]="popup"><mat-icon>menu</mat-icon></button>
      <button routerLink="/">{{'Dashboard' | translate}}</button>
      <button routerLink="/containers">{{'Containers' | translate}}</button>
      <button *ngIf="'LOCATIONS' | read" routerLink="/reports">{{'Reports' | translate}}</button>
    </span>

    <mat-menu #popup="matMenu">
      <button mat-menu-item *ngIf="'ACCOUNTS' | read" class="menu" routerLink="/accounts">{{'Accounts' | translate}}</button>
      <button mat-menu-item routerLink="/ecopoints">{{'EcoPoints' | translate}}</button>
      <button mat-menu-item [matMenuTriggerFor]="admin">{{'Administration' | translate}}</button>
      <button mat-menu-item *ngIf="'LOCATIONS' | read" class="menu" routerLink="/ecologs">{{'EcoLogs' | translate}}</button>
      <button mat-menu-item [matMenuTriggerFor]="search">{{'Search' | translate}}</button>
      <button mat-menu-item *ngIf="'LOCATIONS' | read" class="menu" routerLink="/map">{{'Map' | translate}}</button>
    </mat-menu>

    <mat-menu #location="matMenu">
      <button mat-menu-item routerLink="/containers"><span class="menu">{{'Containers' | translate}}</span></button>
      <button mat-menu-item class="menu" routerLink="/ecopoints"><span class="menu">{{'EcoPoints' | translate}}</span></button>
      <button mat-menu-item *ngIf="'LOCATIONS' | read" class="menu" routerLink="/tours"><span class="menu">{{'Tours' | translate}}</span></button>
    </mat-menu>

    <mat-menu #admin="matMenu">
      <ng-container *ngFor="let item of adminMenu">
        <button mat-menu-item *ngIf="item.domain | read" [routerLink]="item.link"><span class="menu">{{item.name | translate}}</span></button>
      </ng-container>
    </mat-menu>

    <mat-menu #search="matMenu">
      <ng-container *ngFor="let item of searchMenu">
        <button mat-menu-item *ngIf="item.domain | read" [routerLink]="item.link"><span class="menu">{{item.name | translate}}</span></button>
      </ng-container>
    </mat-menu>

  </span>
</div>
