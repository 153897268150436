<div class="dialog">
  <h1>{{'Password reset' | translate}}</h1>
  <p>{{'login.reset' | translate}}</p>
  <br>
  <div class="row">
    <app-text-field class="width-30" [label]="'username'" [mode]="Mode.edition" [required]="true" [(value)]="name" (valueChange)="check()"></app-text-field>
    <mat-form-field>
      <mat-label>{{'Email' | translate}}</mat-label>
      <input matInput #input class="input" (input)="check()" required type="email" [(ngModel)]="mail" >
    </mat-form-field>
  </div>
  <div class="row right">
    <button mat-flat-button mat-dialog-close>{{'Cancel' | translate}}</button>
    <button mat-flat-button (click)="onReset()" [disabled]="!valid" mat-dialog-close>{{'Reset' | translate}}</button>
  </div>
</div>
