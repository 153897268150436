import { Component, EventEmitter, Input, Output } from '@angular/core'

import { Sorting } from '../../services/generic.service'

@Component({
  selector: 'list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.css']
})
export class ListHeaderComponent {

  @Input() embedded: boolean = false
  @Input() identifier: string = ''
  @Input() prefix: string = ''
  @Input() right: boolean = false
  @Input() sorting: Sorting | null = null

  @Output() sort = new EventEmitter<string>()
}
