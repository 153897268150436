import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'

import { SessionService, Session } from '../../framework/services/session.service'
import { Color } from '@swimlane/ngx-charts'
import { DashboardService, Data } from '../dashboard.service'
import { FlowTypeService, FlowType } from '../../framework/flow-type/flow-type.service'
import { NotificationService, CategoryOverview, Overview } from '../notification.service'

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['../../framework/generic-page/generic-page.component.css', './dashboard-page.component.css']
})
export class DashboardPageComponent implements OnInit {

  access: Observable<Data[]>
  current: string | null = null
  categories: (keyof CategoryOverview)[]
  colors = { domain: ['#2693bc'] } as Color
  communication: Observable<Data[]>
  flowTypeId: string = ''
  flowTypes: FlowType[] = []
  level: Observable<Data[]>
  overview: Observable<Overview>
  session: Observable<Session | null>
  type: Observable<string>
  year: number

  constructor(
    protected dashboardService: DashboardService,
    private flowTypeService: FlowTypeService,
    protected notificationService: NotificationService,
    protected router: Router,
    protected sessionService: SessionService
  ) {
    this.access = dashboardService.access()
    this.categories = notificationService.categories()
    this.communication = dashboardService.communication()
    this.level = dashboardService.level()
    this.overview = notificationService.overview()
    this.type = dashboardService.type()
    this.session = sessionService.session()
    this.year = new Date().getFullYear()
  }

  months = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  format(y: number) { return y.toLocaleString() }

  navigate(year: number) {
    this.year = year
    this.dashboardService.fetchAccess(this.flowTypeId, this.year)
  }

  ngOnInit() {
    this.dashboardService.fetchCommunication()
    this.dashboardService.fetchLevel()
    // Skip first result (current list) and consider only second (result of fetch), don't care further updates.
    this.flowTypeService.list(flowTypes => {
      this.flowTypes = flowTypes
      if (flowTypes.length) {
        this.flowTypeId = flowTypes[0].id
        this.navigate(this.year)
      }
    },'identifier')
    this.updateNotifications()
  }

  onChip(category: keyof CategoryOverview ) {
    this.current = this.current != category ? category : null
    this.updateNotifications()
  }

  onDeleteAll() {
      this.notificationService.deleteAll(this.current)
      this.updateNotifications()
  }

  onFlowType() { this.dashboardService.fetchAccess(this.flowTypeId, this.year) }

  onSelect(data: Data) { void this.router.navigateByUrl('waste/month/' + this.flowTypeId + '/' + this.year + '/' + data.name) }

  updateNotifications() { this.notificationService.fetch({ category: this.current }) }
}
