<div class="editor">
  <div class="form">
    <div class="row">
      <app-text-field class="width-15" [label]="'Identifier'" [length]="10" [mode]="mode"
        [(value)]="subject.identifier"></app-text-field>
      <app-text-field class="width-35" [label]="'Name'" [length]="200" [mode]="mode" [required]="true"
        [(value)]="subject.name" (valueChange)="check()"></app-text-field>
      <mat-form-field class="width-15">
        <mat-label>{{'Density' | translate}}</mat-label>
        <input matInput class="input numeric" [disabled]="mode != Mode.edition" min="0" [(ngModel)]="subject.density" type="number">
      </mat-form-field>
      <br>
    </div>
  </div>
  <app-form-buttons [actions]="('AFTER_SALES' | write) ? ['delete'] : []" [mode]="mode" [valid]="valid" (event)="onEvent($event)"></app-form-buttons>
</div>
