import { catchError, filter, Observable } from 'rxjs'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import * as Sentry from '@sentry/angular-ivy'

import { SnackbarService } from './framework/services/snackbar.service'
import { SpinnerService } from './framework/services/spinner.service'

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(
    protected router: Router,
    protected snackbar: SnackbarService,
    protected spinner: SpinnerService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = sessionStorage.getItem('token');
    if (token !== null) request = request.clone({ setHeaders: { 'X-Client-Token': token } })
    return next.handle(request).pipe(
      catchError(response => {
        this.spinner.hide()
        if (response.status == 400 && response.error.error) {
          let reason = response.error.error.reason
          if (reason == 'INVALID_PASSWORD') {
            this.snackbar.show('error.authentication', reason)
            return new Observable<HttpEvent<unknown>>()
          }
        }
        if (response.status == 401) {
          if (response.error && response.error.error) {
            let reason = response.error.error.reason
            if (reason == 'USER_BLOCKED')
              this.snackbar.show('error.blocked')
            else
              if (reason != 'ILLEGAL_TOKEN') this.snackbar.show('error.authentication', reason)
          }
          this.router.navigateByUrl('/login')
          return new Observable<HttpEvent<unknown>>()
        } else if (response.status == 403) {
          this.snackbar.show('error.application', 'error.403')
          this.router.navigateByUrl('/login')
          return new Observable<HttpEvent<unknown>>()
        }
        else if (response.status == 409)
          this.snackbar.show('error.application', 'error.409')
        else
          this.snackbar.show('error.unexpected', response.status + (response.error ? ' - ' + response.error.error : ''))

        if (response.status == 0) {
          // TEMP for clarification
          console.log(response)
          Sentry.captureException(new Error('AuthenticationInterceptor::intercept/temp'))
          throw response.message
        } else {
          console.log(response)
          Sentry.captureException(new Error('AuthenticationInterceptor::intercept/1'))
          throw response.message
        }
      }),
      filter(response => {
        if (request.responseType == 'text')
          return true
        else if ('body' in response && response.body && 'error' in response.body) {
          let error = response.body.error
          console.log(response.body)
          Sentry.captureException(new Error('AuthenticationInterceptor::intercept/2'))
          this.snackbar.show('error.application', error?.reason)
          return false
        }
        else
          return true
      })
    )
  }
}
