<mat-tab-group mat-stretch-tabs>
  <mat-tab label="{{'Information' | translate}}">
    <app-ecolog-form [object]="ecolog" (event)="event.next($event)"></app-ecolog-form>
  </mat-tab>
  <div *ngIf="'AFTER_SALES' | read">
    <mat-tab label="{{'Parameters' | translate}}">
      <ng-template matTabContent>
        <app-ecolog-parameters [ecolog]="ecolog"></app-ecolog-parameters>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{'History' | translate}}">
      <ng-template matTabContent>
        <app-history-view [object]="ecolog" [parent]="'ECOLOG'" (event)="onEvent($event)"></app-history-view>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{'Commands' | translate}}">
      <ng-template matTabContent>
        <app-commands-view [ecolog]="ecolog" (event)="onEvent($event)"></app-commands-view>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="ecolog.container" label="{{'Blacklists' | translate}}">
      <ng-template matTabContent>
        <app-ecolog-blacklists [ecolog]="ecolog"></app-ecolog-blacklists>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{'Measures' | translate}}">
      <ng-template matTabContent>
        <app-ecolog-measures [ecolog]="ecolog"></app-ecolog-measures>
      </ng-template>
    </mat-tab>
  </div>
</mat-tab-group>
