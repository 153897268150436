// Angular Framework
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { DatePipe } from "@angular/common"
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { Router } from '@angular/router'
import { NoopAnimationsModule } from '@angular/platform-browser/animations'

// Translation Framework
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

// Material Components
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete'
import { MatDialogModule } from '@angular/material/dialog'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'
import { MatNativeDateModule } from '@angular/material/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar'
import { MatTabsModule } from '@angular/material/tabs'

// Various
import { FileSaverModule } from 'ngx-filesaver'
import * as Sentry from '@sentry/angular-ivy'

// Framework Modules
import { BatchModule } from './framework/batch/batch.module'
import { ChartsModule } from './framework/charts/charts.module'
import { EcologModule } from './framework/ecolog/ecolog.module'
import { FrameworkModule } from './framework/framework.module'
import { LocalityModule } from './locality/locality.module'
import { LocationModule } from './framework/location/location.module'
import { TruckModule } from './framework/truck/truck.module'
import { ReportModule } from './framework/report/report.module'
import { TourModule } from './framework/tour/tour.module'

// Framework Components
import { GenericDialog } from './framework/generic-dialog/generic-dialog.component'
import { SessionService } from './framework/services/session.service'

// Application classes
import { AppRoutingModule } from './app-routing.module'
import { AuthenticationInterceptor } from './authentication.interceptor'
import { WiseSessionService } from './wise-session.service'

// Application Components
import { AppComponent } from './app.component'
import { ConfirmationFormComponent } from './components/confirmation-form/confirmation-form.component'
import { FooterComponent } from './components/footer/footer.component'
import { HeaderComponent } from './components/header/header.component'
import { HelpComponent } from './components/help/help.component'
import { LoginFormComponent } from "./components/login-form/login-form.component"
import { MapComponent } from './components/map/map.component'
import { MenuBarComponent } from './components/menu-bar/menu-bar.component'
import { ParameterFormComponent } from './components/parameter-form/parameter-form.component'
import { PasswordFormComponent } from './components/password-form/password-form.component'
import { ResetFormComponent } from './components/reset-form/reset-form.component'
import { ScreensizeComponent } from './components/screensize/screensize.component'
import { TrampolineComponent } from './components/trampoline/trampoline.component'

// Application Modules
import { AccountModule } from './account/account.module'
import { CardModule } from './card/card.module'
import { CategoryModule } from './category/category.module'
import { ClosureModule } from './closure/closure.module'
import { CollectionModule } from './collection/collection.module'
import { ContainerModule } from './container/container.module'
import { ContainerTypeModule } from './container-type/container-type.module'
import { DashboardModule } from './dashboard/dashboard.module'
import { DumpModule } from './dump/dump.module'
import { EcopointModule } from './ecopoint/ecopoint.module'
import { FlowTypeModule } from './flow-type/flow-type.module'
import { GroupingModule } from './grouping/grouping.module'
import { RoleModule } from './framework/role/role.module'
import { StockModule } from './stock/stock.module'
import { TillModule } from './till/till.module'
import { TransactionModule } from './transaction/transaction.module'
import { UserModule } from './user/user.module'
import { WasteModule } from './waste/waste.module'
import { ZoneModule } from './zone/zone.module'

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationFormComponent,
    GenericDialog,
    FooterComponent,
    HeaderComponent,
    HelpComponent,
    LoginFormComponent,
    MapComponent,
    MenuBarComponent,
    ParameterFormComponent,
    PasswordFormComponent,
    ResetFormComponent,
    ScreensizeComponent,
    TrampolineComponent,
  ],

  imports: [
    // Angular Framework
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    // Material Components
    MatDialogModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    NoopAnimationsModule,

    // Various
    FileSaverModule,
    Sentry.TraceModule,

    // Framework Modules
    BatchModule,
    ChartsModule,
    EcologModule,
    FrameworkModule,
    LocalityModule,
    LocationModule,
    TruckModule,
    ReportModule,
    TourModule,

    // Application Modules
    AccountModule,
    CardModule,
    CategoryModule,
    ClosureModule,
    CollectionModule,
    ContainerModule,
    ContainerTypeModule,
    DashboardModule,
    DumpModule,
    EcopointModule,
    FlowTypeModule,
    GroupingModule,
    RoleModule,
    StockModule,
    TillModule,
    TransactionModule,
    UserModule,
    WasteModule,
    ZoneModule,
  ],
  providers: [
      DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: { autoActiveFirstOption: true } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' }},
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 }},
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
    { provide: SessionService, useClass: WiseSessionService },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http)
}
