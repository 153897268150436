import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { GenericService, Sorting, toString } from '../framework/services/generic.service'
import { DatabaseObject } from '../framework/objects'

export interface Account {
  address: Address,
  balance: number,
  billingAddress: Address,
  category: DatabaseObject | null,
  closingDate: string | null,
  comment: string,
  email: string,
  id: string,
  identifier: string,
  kind: string,
  mode: string,
  phone: string,
  status: string,
  zone: DatabaseObject | null,
}

export interface Address {
  address: string,
  city: string,
  country: string,
  firstName: string,
  lastName: string,
  postcode: string,
  title: string,
}

export interface Overview {
  all: number,
  active: number,
  blocked: number,
  closed: number,
  invoice: number,
  prepaid: number,
}

const emptyAddress: Address = {
  address: '',
  city: '',
  country: '',
  firstName: '',
  lastName: '',
  postcode: '',
  title: '',
}

export const emptyAccount: Account = {
  address: emptyAddress,
  balance: 0,
  billingAddress: emptyAddress,
  category: null,
  closingDate: null,
  comment: '',
  email: '',
  id: '',
  identifier: '',
  kind: 'PERSON',
  mode: 'INVOICE',
  phone: '',
  status: 'ACTIVE',
  zone: null,
}

@Injectable({
  providedIn: 'root'
})
export class AccountService extends GenericService<Account> {

  close(id: string, body: any): Observable<Account> {
    return this.httpClient.post<Account>(this.endpoint() + '/' + id + '/close', body)
  }

  endpoint(version: string = 'v1'): string { return this.sessionService.url('accounts', version) }

  export(filter: any, sorting: Sorting) {
    this.exportToFile('',{
      categoryId: filter.categoryId ?? '',
      magicFilter: filter.magic ? filter.magic : '',
      mode: filter.mode ? filter.mode : '',
      status: filter.status ? filter.status : '',
      withoutCategory: filter.categoryId == '0',
      withoutZone: filter.zoneId == '0',
      zoneId: filter.zoneId && (filter.zoneId > '0') ? filter.zoneId : '',
      orderBy: toString(sorting)
    })
  }

  override fetch(filter: any, sorting: Sorting, page: number = 1) {
    if (filter.id)
      this.getOne(filter.id)
    else
      this.getAll({
        categoryId: filter.categoryId ?? '',
        magicFilter: filter.magic ? filter.magic : '',
        mode: filter.mode ? filter.mode : '',
        status: filter.status ? filter.status : '',
        withoutCategory: filter.categoryId == '0',
        withoutZone: filter.zoneId == '0',
        zoneId: filter.zoneId && (filter.zoneId > '0') ? filter.zoneId : '',
        orderBy: toString(sorting), page: page, per_page: 50
      }
    )
  }

  override update(account: Account): Observable<Account> {
    this.cleanup(account)
    return this.putOne(account.id, {
      address: this.cleanup(account.address),
      billingAddress: this.cleanup(account.billingAddress),
      category: account.category ? { id: account.category.id } : null,
      comment: account.comment ?? null,
      identifier:account.identifier ??  null,
      kind: account.kind,
      mode: account.mode,
      status: account.status,
      zone: account.zone ? { id: account.zone.id } : null,
    })
  }
}
