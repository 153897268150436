import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

import { GoogleMapsModule } from '@angular/google-maps'

// Translation Framework
import { TranslateModule } from '@ngx-translate/core'

// Material Components
import { CdkAccordionModule } from "@angular/cdk/accordion"
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatSelectModule } from '@angular/material/select'
import { MatTooltipModule } from '@angular/material/tooltip'

// Framework Pipes
import { BigPipe } from './pipes/big.pipe'
import { DatestampPipe } from './pipes/datestamp.pipe'
import { LinkPipe } from './pipes/link.pipe'
import { ReadPipe } from './pipes/read.pipe'
import { ShortenPipe } from './pipes/shorten.pipe'
import { TicketPipe } from './pipes/ticket.pipe'
import { TimestampPipe } from './pipes/timestamp.pipe'
import { WritePipe } from './pipes/write.pipe'

// Framework Components
import { ActionPaneComponent } from './components/action-pane/action-pane.component'
import { ClientDropdownComponent } from './components/client-dropdown/client-dropdown.component'
import { DateFieldComponent } from './components/date-field/date-field.component'
import { DropdownComponent } from './components/dropdown/dropdown.component'
import { FormButtonsComponent } from './components/form-buttons/form-buttons.component'
import { ListHeaderComponent } from './components/list-header/list-header.component'
import { NavigatorComponent } from './components/navigator/navigator.component'
import { NumberFieldComponent } from './components/number-field/number-field.component'
import { ObjectDropdownComponent } from './components/object-dropdown/object-dropdown.component'
import { PositionComponent } from './components/position/position.component'
import { TextFieldComponent } from './components/text-field/text-field.component'

@NgModule({
  declarations: [
    // Components
    ActionPaneComponent,
    ClientDropdownComponent,
    DateFieldComponent,
    DropdownComponent,
    FormButtonsComponent,
    ListHeaderComponent,
    NavigatorComponent,
    NumberFieldComponent,
    ObjectDropdownComponent,
    PositionComponent,
    TextFieldComponent,

    // Pipes
    BigPipe,
    DatestampPipe,
    LinkPipe,
    ReadPipe,
    ShortenPipe,
    TicketPipe,
    TimestampPipe,
    WritePipe,

  ],
  imports: [

    // Angular Fundamentals
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule,

    GoogleMapsModule,

    // Material Components
    CdkAccordionModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSelectModule,
    MatTooltipModule,
  ],
  exports: [

    // Angular Fundamentals
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,

    GoogleMapsModule,

    // Material Components
    CdkAccordionModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSelectModule,
    MatTooltipModule,

    // Components
    ActionPaneComponent,
    ClientDropdownComponent,
    DateFieldComponent,
    DropdownComponent,
    FormButtonsComponent,
    ListHeaderComponent,
    NavigatorComponent,
    NumberFieldComponent,
    ObjectDropdownComponent,
    PositionComponent,
    TextFieldComponent,

    // Pipes
    BigPipe,
    DatestampPipe,
    LinkPipe,
    ReadPipe,
    ShortenPipe,
    TicketPipe,
    TimestampPipe,
    WritePipe,
  ]
})
export class FrameworkModule { }
