<h1>{{'Help' | translate}}</h1>
<div class="container">
  <h2>Différences majeures avec la version précédente de WISE</h2>
  <ul>
  <li>
    Le tri dans les listes persiste même si on change de filtre ou de raccourci.
    Cas particulier : le raccourci <i>Derniers modifiés</i> change l'ordre de tri,
    et cet ordre persiste également jusqu'à ce qu'on le change.
  </li><li>
    Les textes dans les listes sont abrégés s'ils sont trop longs avec ... à la fin.
  </li><li>
  Lorsqu'on appuie sur la touche &lt;Enter&gt; dans un champ textuel ou numérique d'un filtre, en particulier
    les filtres <i>magiques</i> cela provoque immédiatement une recherche.
  </li><li>
    Depuis les volets <i>Dépôts</i>, <i>Transactions</i> et <i>Cartes</i> des comptes d'usager il est possible
    de naviguer vers les écrans de recherche correspondants.
  </li><li>
    L'annulation de dépôts et la validation des transactions ne s'effectue plus directement dans la liste,
    il faut cliquer sur le dépôt ou la transaction correspondante et entrer en mode édition.
  </li>
  </ul>
</div>