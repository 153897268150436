import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule, Routes } from '@angular/router'

import { AccountPageComponent } from './account/account-page/account-page.component'
import { BatchPageComponent} from './framework/batch/batch-page/batch-page.component'
import { CardPageComponent } from './card/card-page/card-page.component'
import { CategoryPageComponent } from './category/category-page/category-page.component'
import { ContainerPageComponent } from './container/container-page/container-page.component'
import { CollectionPageComponent } from './collection/collection-page/collection-page.component'
import { ContainerTypePageComponent } from './container-type/container-type-page/container-type-page.component'
import { ClosurePageComponent } from './closure/closure-page/closure-page.component'
import { DashboardPageComponent } from './dashboard/dashboard-page/dashboard-page.component'
import { DumpPageComponent } from './dump/dump-page/dump-page.component'
import { EcologPageComponent } from './framework/ecolog/ecolog-page/ecolog-page.component'
import { EcopointPageComponent } from './ecopoint/ecopoint-page/ecopoint-page.component'
import { FlowTypePageComponent } from './flow-type/flow-type-page/flow-type-page.component'
import { GroupingPageComponent } from './grouping/grouping-page/grouping-page.component'
import { HelpComponent } from './components/help/help.component'
import { LocalityPageComponent } from './locality/locality-page/locality-page.component'
import { LocationPageComponent } from './framework/location/location-page/location-page.component'
import { LoginFormComponent } from './components/login-form/login-form.component'
import { MapComponent } from './components/map/map.component'
import { ReportPageComponent } from './framework/report/report-page/report-page.component'
import { ResetFormComponent } from './components/reset-form/reset-form.component'
import { RolePageComponent } from './framework/role/role-page/role-page.component'
import { StockPageComponent } from './stock/stock-page/stock-page.component'
import { TillPageComponent } from './till/till-page/till-page.component'
import { TourPageComponent } from './framework/tour/tour-page/tour-page.component'
import { TrampolineComponent } from './components/trampoline/trampoline.component'
import { TruckPageComponent } from './framework/truck/truck-page/truck-page.component'
import { TransactionPageComponent } from './transaction/transaction-page/transaction-page.component'
import { UserPageComponent } from './framework/user/user-page/user-page.component'
import { WastePageComponent } from './waste/waste-page/waste-page.component'
import { ZonePageComponent } from './zone/zone-page/zone-page.component'

// import {MaintenanceComponent} from './framework/maintenance/maintenance.component'

const routes: Routes = [
  { path: '', component: DashboardPageComponent },
  { path: 'accounts', component: AccountPageComponent },
  { path: 'accounts/:id', component: AccountPageComponent },
  { path: 'batchs', component: BatchPageComponent },
  { path: 'cards', component: CardPageComponent },
  { path: 'cards/:id', component: CardPageComponent },
  { path: 'cards/account/:id/:account', component: CardPageComponent },
  { path: 'categories', component: CategoryPageComponent },
  { path: 'collections', component: CollectionPageComponent },
  { path: 'containers', component: ContainerPageComponent },
  { path: 'containers/:id', component: ContainerPageComponent },
  { path: 'containers/communication/:index', component: ContainerPageComponent },
  { path: 'containers/ecopoint/:ecopointId', component: ContainerPageComponent },
  { path: 'containers/level/:level', component: ContainerPageComponent },
  { path: 'container-types', component: ContainerTypePageComponent },
  { path: 'closures', component: ClosurePageComponent },
  { path: 'dumps', component: DumpPageComponent },
  { path: 'ecologs', component: EcologPageComponent },
  { path: 'ecologs/:id', component: EcologPageComponent },
  { path: 'ecologs/serial/:serial', component: EcologPageComponent },
  { path: 'ecopoints', component: EcopointPageComponent },
  { path: 'ecopoints/:id', component: EcopointPageComponent },
  { path: 'flow-types', component: FlowTypePageComponent },
  { path: 'groupings', component: GroupingPageComponent },
  { path: 'help', component: HelpComponent },
  { path: 'link/:token/containers/:id', component: TrampolineComponent },
  { path: 'localities', component: LocalityPageComponent },
  { path: 'locations', component: LocationPageComponent },
  { path: 'login', component: LoginFormComponent },
  { path: 'map', component: MapComponent },
  { path: 'reports', component: ReportPageComponent },
  { path: 'reports/:id', component: ReportPageComponent },
  { path: 'roles', component: RolePageComponent },
  { path: 'stocks', component: StockPageComponent },
  { path: 'tills', component: TillPageComponent },
  { path: 'tours', component: TourPageComponent },
  { path: 'transactions', component: TransactionPageComponent },
  { path: 'transactions/:id/:account', component: TransactionPageComponent },
  { path: 'trucks', component: TruckPageComponent },
  { path: 'users', component: UserPageComponent },
  { path: 'waste', component: WastePageComponent },
  { path: 'waste/account/:id/:account', component: WastePageComponent },
  { path: 'waste/account/:id/:account/:date', component: WastePageComponent },
  { path: 'waste/container/:container', component: WastePageComponent },
  { path: 'waste/month/:flowtype/:year/:month', component: WastePageComponent },
  { path: 'reset/:client', component: ResetFormComponent },
  { path: 'zones', component: ZonePageComponent },
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
